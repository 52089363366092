import { useRosterRouterContextFind } from '../../../../../../../platform/api/endpoints';
import { useEffect, useState } from 'react';
import { ProfileAppContext } from '../../../appContext/ProfileAppContext';

export const useProfileDAO = () => {
  const { data: routers, loading } = useRosterRouterContextFind();
  const [ProfileDAO, setProfileDAO] = useState<ProfileAppContext | null>(null);
  useEffect(() => {
    if (routers) {
      setProfileDAO(new ProfileAppContext(routers));
    }
  }, [routers]);
  return { routers, ProfileDAO, loading };
};
