import React, { Fragment, useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { MenuDivider, MenuItem, MenuRoute } from '../profiles/types';
import { usePermissionContext } from '../permission/permissionContext';

const normalizePrefix = (prefix: string) =>
  !prefix ? '' : prefix.slice(-1) === '/' ? prefix : `${prefix}/`;
const fullPathJoiner = (path: string, prefix = ''): string => {
  if (!prefix) {
    return path;
  }
  const normalizedPrefix = normalizePrefix(prefix);
  const normalizedPath = path[0] === '/' ? path.slice(1) : path;
  return `${normalizedPrefix}${normalizedPath}`;
};

const isMenuItem = (menu: MenuItem | MenuDivider): menu is MenuItem =>
  (menu as MenuDivider).divider === undefined;

const getDefaultMenuItem = (items: MenuItem[]): MenuItem => {
  const defaultItem = items.find((item) => !!item.default);
  return defaultItem || items[0];
};

const getDefaultRoute = (items: MenuRoute[]): MenuRoute => {
  const defaultItem = items.find((item) => !!item.default);
  return defaultItem || items[0];
};

// TODO: apply custom redirect strategy
export const Routes = ({
  config,
  prefix = '',
}: {
  config: MenuRoute;
  prefix?: string;
}) => {
  if (!config.children?.length && !config.component) {
    // eslint-disable-next-line
    console.error(
      'Phinx Router: one of the route doesnt have neither component or children configured',
    );
    return null;
  }
  const fullPath = fullPathJoiner(config.path, prefix);
  if (!config.children || !config.children.length) {
    const Comp = config.component as React.ComponentType<any>;
    return <Comp />;
  }
  const defaultItem = getDefaultRoute(config.children);
  const fullPathRedirect = fullPathJoiner(defaultItem.path || '', fullPath);
  return (
    <Switch>
      {config.children.map((item) => {
        const itemPath = fullPathJoiner(item.path, fullPath);
        return (
          <Route path={itemPath} key={itemPath} {...(item.options || {})}>
            <Routes config={item} prefix={fullPath} />
          </Route>
        );
      })}
      <Redirect to={fullPathRedirect} />
    </Switch>
  );
};

// TODO: apply custom redirect strategy
export const MenuRoutes = ({
  config,
  prefix = '',
}: {
  config: MenuItem[];
  prefix?: string;
}) => {
  const { activeRules } = usePermissionContext();

  const filterConfig = useMemo(() => {
    const innerConfig: MenuItem[] = [];
    config.forEach((item) => {
      if (
        !(
          activeRules.routes.filter((_item) => _item.key === item.key).length >
          0
        )
      ) {
        innerConfig.push({ ...item });
      }
    });
    return innerConfig;
  }, [activeRules, config]);

  if (!config.length) {
    return null;
  }
  const defaultItem = getDefaultMenuItem(filterConfig);

  const fullPathRedirect = fullPathJoiner(defaultItem.basePath || '', prefix);
  return (
    <Switch>
      {config.map((item) => {
        const fullPath = fullPathJoiner(item.basePath || '', prefix);
        const menuChildren = item.children.filter(isMenuItem);
        const Container =
          item.target && menuChildren.length ? Switch : Fragment;
        return (
          <Route key={item.key} path={fullPath}>
            <Container>
              {!!item.target && (
                <Route
                  path={fullPathJoiner(item.target.path, fullPath)}
                  {...(item.target.options || {})}
                >
                  <Routes config={item.target} prefix={fullPath} />
                </Route>
              )}
              {!item.children.length ? null : (
                <Route path={fullPath}>
                  <MenuRoutes config={menuChildren} prefix={fullPath} />
                </Route>
              )}
            </Container>
          </Route>
        );
      })}
      <Redirect to={fullPathRedirect} />
    </Switch>
  );
};
