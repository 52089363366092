import React, { useCallback, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import LoadingButton from '@material-ui/lab/LoadingButton';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import { useInputState } from '../../../../../../../../../../../commons/hooks';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { StubbornDatasourceKind, StubbornDatasourceType } from '../../../../../../../../../../../business/StubbornAsset';

export interface NewDatasourceDialogProps {
  open: boolean;
  onClose: () => void;
  onCreateDatasource: (name: string, kind: StubbornDatasourceKind, type: StubbornDatasourceType, host: string, port: number, user: string, password: string, database: string, onconnect: string) => Promise<void>;
  defaultData: { name?: string; kind?: StubbornDatasourceKind; type?: StubbornDatasourceType; host?: string; port?: number; user?: string; password?: string; database?: string; onconnect?: string };
  update?: boolean;
}

export const NewDatasourceDialog = ({ open, onClose, onCreateDatasource, defaultData, update }: NewDatasourceDialogProps) => {
  const [name, onNameChange, setName] = useInputState(defaultData.name || '');
  const [kind, onKindChange, setKind] = useInputState<StubbornDatasourceKind>(defaultData.kind || StubbornDatasourceKind.SQL);
  const [port, onPortChange, setPort] = useInputState<number>(defaultData.port || 8080);
  const [host, onHostChange, setHost] = useInputState(defaultData.host || '');
  const [user, onUserChange, setUser] = useInputState(defaultData.user || '');
  const [password, onPasswordChange, setPassword] = useInputState(defaultData.password || '');
  const [database, onDatabaseChange, setDatabase] = useInputState(defaultData.database || '');
  const [onConnect, onOnConnectChange, setOnConnect] = useInputState(defaultData.onconnect || '');
  //const [type, setType] = useState<string>(defaultData.type || StubbornDatasourceType.POSTGRES);
  const [type, onTypeChange, setType] = useInputState<StubbornDatasourceType>(defaultData.type || StubbornDatasourceType.POSTGRES);

  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);

  const onCreate = useCallback(async () => {
    setDirty(true);
    if (name && kind) {
      try {
        setLoading(true);
        await onCreateDatasource(name, kind, type, host, port, user, password, database, onConnect);
        setLoading(false);
        onClose();
        setName('');
        setHost('');
        setUser('');
        setPassword('');
        setDatabase('');
        setOnConnect('');
        setPort(8080);
        setDirty(false);
      } catch (e: any) {
        alert(e.message); // TODO: notistack
        setLoading(false);
      }
    }
  }, [name, kind, type, host, port, user, password, database, onConnect, onCreateDatasource, onClose, setName, setDatabase, setHost, setOnConnect, setPassword, setPort, setUser]);

  return (
    <Dialog open={open} onClose={loading ? undefined : onClose} maxWidth="md" fullWidth>
      <DialogTitle>Nuevo Datasource</DialogTitle>
      <DialogContent>
        <TextField autoFocus margin="dense" id="name" label="Nombre" name="name" fullWidth value={name} onChange={onNameChange} error={dirty && !name} helperText={dirty && !name && 'Nombre requerido'} disabled={loading || update} />
        <TextField autoFocus margin="dense" id="kind" name="kind" label="Tipo" fullWidth value={kind} onChange={onKindChange} disabled />
        <FormControl sx={{ width: '100%', marginTop: 1 }}>
          <InputLabel id="tipo">Manejador de Base de Datos</InputLabel>
          <Select margin="dense" labelId="tipo" id="tipo-input" value={type} label="Manejador de Base de Datos" disabled={kind === StubbornDatasourceKind.REDIS} onChange={(e) => setType(e.target.value as StubbornDatasourceType)}>
            {kind === StubbornDatasourceKind.SQL ? (
              Object.values(StubbornDatasourceType).map((type) => {
                if (type !== StubbornDatasourceType.REDIS)
                  return (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  );
              })
            ) : (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            )}
          </Select>
        </FormControl>
        <TextField autoFocus margin="dense" id="host" name="host" label="Host" fullWidth value={host} onChange={onHostChange} error={dirty && !host} helperText={dirty && !host && 'Host requerida'} disabled={loading} />
        <TextField type="number" autoFocus margin="dense" id="port" name="port" label="Puerto" fullWidth value={port} onChange={onPortChange} error={dirty && !port} helperText={dirty && !port && 'Puerto requerido'} disabled={loading} />
        <TextField autoFocus margin="dense" id="user" name="user" label="Usuario" fullWidth value={user} onChange={onUserChange} error={dirty && !user} helperText={dirty && !user && 'Usuario requerido'} disabled={loading} />
        <TextField type="password" autoFocus margin="dense" id="password" name="password" label="Contraseña" fullWidth value={password} onChange={onPasswordChange} error={dirty && !password} helperText={dirty && !password && 'Contraseña requerida'} disabled={loading} />
        <TextField autoFocus margin="dense" id="database" name="database" label="Base de Datos" fullWidth value={database} onChange={onDatabaseChange} error={dirty && !database} helperText={dirty && !database && 'Base de datos requerida'} disabled={loading} />
        <TextField autoFocus margin="dense" id="onConnect" name="onConnect" label="OnConnect" fullWidth value={onConnect} onChange={onOnConnectChange} disabled={loading} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" disabled={loading}>
          Cerrar
        </Button>
        <LoadingButton color="primary" variant="contained" autoFocus onClick={onCreate} disabled={loading} loading={loading}>
          {update ? 'Actualizar' : 'Crear'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
