import React, { useEffect, useState } from 'react';
import { TextField as MuiTextfield } from '@material-ui/core';
import { FieldBaseProps } from '../FieldBaseProps';
import { FieldBaseEvents } from '../FieldBaseEvents';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';

interface TextFieldEvents extends FieldBaseEvents {
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
}
export interface TextfieldProps extends FieldBaseProps {
  component: {
    autofocus?: boolean;
    color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
    defaultValue?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    inputLabelProps?: any;
    inputProps?: any;
    label?: string;
    margin?: 'dense' | 'none' | 'normal';
    maxRows?: string;
    minRows?: string;
    multiline?: boolean;
    placeholder?: string;
    required?: boolean;
    variant?: 'filled' | 'outlined' | 'standard';
    size?: 'medium' | 'small';
    controlled?: boolean;
  };
  states: {
    error?: boolean;
    value?: string;
    helperText?: string;
  };
  events: TextFieldEvents;
}

export const Textfield = ({ component, events, states, styles }: TextfieldProps) => {
  const { assetSelector, editable, screen, setScreen } = useEditorScreenContext();
  const [intermediateValue, setIntermediateValue] = useState(states?.value);

  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: any) => {
    setIntermediateValue(e.target.value);
    const updatedScreen = {
      ...screen,
      metadata: screen.metadata.map((el, index) => {
        if (index === assetSelector[0])
          return {
            ...el,
            attributes: { ...el.attributes, states: { value: e.target.value } },
          };
        else return el;
      }),
    };
  
    if (events.onChange) events.onChange(e);
    else setScreen(updatedScreen);
  };  

  const valueProps = {
    ...(component.controlled ? { value: intermediateValue } : { defaultValue: component?.defaultValue }),
  };

  return (
    <MuiTextfield
      {...valueProps}
      autoFocus={component.autofocus}
      onBlur={events.onBlur}
      margin={component.margin}
      minRows={component.minRows}
      maxRows={component.maxRows}
      label={component.label}
      multiline={component.multiline}
      InputProps={component.inputProps}
      InputLabelProps={component.inputLabelProps}
      disabled={component.disabled}
      fullWidth={component.fullWidth}
      placeholder={component.placeholder}
      onChange={handleChange}
      variant={component.variant}
      error={states?.error}
      helperText={states?.helperText}
      required={component.required}
      size={component.size}
      color={component.color}
      style={styles}
    />
  );
};
