import { createContext, ReactNode, ReactNodeArray, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useEditorScreenContext } from '../../../EditorScreenContext';
import { getAssetByAssetSelector, setAssetByAssetSelector } from '../../../../../../utils';
import { StubbornScreen } from '../../../../../../business/StubbornAsset';
import { useProjectDAOContext } from './ProjectDAOContext';
import { AnyObject } from '../../../../../../commons/types';
import { RouterAppContext } from '../../../appContext/RouterAppContext';
import { ProfileAppContext } from '../../../appContext/ProfileAppContext';
import { useProfileDAO } from '../hooks';
import { useHistory } from 'react-router';
import { StubbornRoute } from '../../../../../../business/StubbornRouter';
import { useRosterScreenContextFind } from '../../../../../../../platform/api/endpoints';

export type RouterDAOContextValue = {
  ProfileDAO: ProfileAppContext | null;
};

export const RouterDAOContext = createContext<RouterDAOContextValue>({
  // @ts-ignore
  // @ts-ignore
  ProfileDAO: null,
});

export interface RouterDAOContextProviderProps {
  children: ReactNode | ReactNodeArray;
}

export const RouterDAOContextProvider = ({ children }: RouterDAOContextProviderProps) => {
  /*const { ProjectDAO } = useProjectDAOContext();
  const { editable } = useEditorScreenContext();
  const { data } = useRosterScreenContextFind();
  const { screen } = useEditorScreenContext();
  const history = useHistory();

  useEffect(() => {
    if (ProjectDAO) {
      ProjectDAO?.routers?.RouterDAO?.routeStack?.push({
        name: screen.name,
        target: screen.name,
        path: '/',
        type: 'Route'
      });
    }
  }, [ProjectDAO]);

  /*useEffect(() => {
    if (ProjectDAO) {
      ProjectDAO?.routers?.setOnNavigate((path: StubbornRoute, params?: AnyObject) => {
        const selectedScreen = data?.filter((screen: StubbornScreen) => screen.name === path.target);
        selectedScreen?.length && ProjectDAO?.screens?.onScreenGo?.(selectedScreen[0], params);
        //history.push(path.path || '/', params);
      });
      ProjectDAO?.routers?.RouterDAO?.setOnGoBack(() => {
        const selectedScreen = data?.filter((screen: StubbornScreen) => screen.name === ProjectDAO.routers.RouterDAO?.routeStack[ProjectDAO.routers.RouterDAO?.routeStack.length - 2].name);
        selectedScreen?.length && ProjectDAO?.screens?.onScreenGo?.(selectedScreen[0]);
        //history.goBack();
      });
    }
  }, [history, ProjectDAO, ProjectDAO.routers.RouterDAO?.routeStack, data, editable]);

  const value = useMemo<RouterDAOContextValue>(
    () => ({
      ProfileDAO: ProjectDAO.routers,
    }),
    [ProjectDAO.routers],
  );
  return <RouterDAOContext.Provider value={value}>{children}</RouterDAOContext.Provider>; */
  return null;
};

export const useRouterDAOContext = () => useContext<RouterDAOContextValue>(RouterDAOContext);
