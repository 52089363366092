export const breakpoints = [
  {
    name: "Extra-small (xs)",
    code: "xs",
    res: "600px",
  },
  {
    name: "Small (sm)",
    code: "sm",
    res: "960px",
  },
  {
    name: "Medium (md)",
    code: "md",
    res: "1280px",
  },
  {
    name: "Large (lg)",
    code: "lg",
    res: "1980px",
  },
  {
    name: "Extra-large (xl)",
    code: "xl",
    res: "2560px",
  },
];

export enum HIDDEN_SCREENS {
  DIALOG = "dialog"
};

export const toolboxCategories = ['Layouts', 'Inputs', 'Navigation', 'Surfaces', 'Feedback', 'Data Display', 'Data Grid', 'Utils']

export const acceptedFiles = [{
  label: 'Jpg',
  type: '.jpg',
},
{
  label: 'Png',
  type: '.png',
},
{
  label: 'Gif',
  type: '.gif',
},
{
  label: 'Tiff',
  type: '.tiff',
},
{
  label: 'Psd',
  type: '.psd',
},
{
  label: 'Bmp',
  type: '.bmp',
},
{
  label: 'MP4',
  type: '.mp4',
},
{
  label: 'AVI',
  type: '.avi',
},
{
  label: 'MPEG',
  type: '.mpeg',
},
{
  label: 'MOV',
  type: '.mov',
},
{
  label: 'MKV',
  type: '.mkv',
},
{
  label: 'Pdf',
  type: '.pdf'
},
{
  label: 'Doc',
  type: '.doc'
},
{
  label: 'Docx',
  type: '.docx'
},
{
  label: 'Txt',
  type: '.txt'
},
{
  label: 'Mp3',
  type: '.mp3'
},
{
  label: 'Wav',
  type: '.wav'
},
{
  label: 'Wma',
  type: '.wma'
},
{
  label: 'Zip',
  type: '.zip'
},
{
  label: 'Rar',
  type: '.rar'
},
];