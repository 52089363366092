import {useBreakpointsState} from "./useBreakpointsState";
import {useMemo} from "react";

export const useCurrentBreakpoint = () => {
  const breakpoints = useBreakpointsState();
  return useMemo(() => {
    const [breakpoint] = Object.entries(breakpoints).find(([,value]) => !!value) || ['sm'];
    return breakpoint;
  }, [breakpoints]);
};
