import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {StubbornAssetType, StubbornScreen, StubbornService} from "../../../../../business/StubbornAsset";
import {MenuCardList, MenuCardItem, Loading} from "../../../../../components";
import {Toolbar} from "./components";
import {useRosterFind} from "../../../../../../platform/api/endpoints";
import {QueryObject} from "../../../../../../lib/HttpService";
import {
  useEditionScreenApi, useEditionScreenCreate, useEditionScreenRemove,
  useEditionServiceApi,
  useEditionServiceCreate,
  useEditionServiceRemove,
  useEditionRouterApi, useEditionRouterCreate, useEditionRouterRemove
} from "../../../../../../platform/api/endpoints/edition";
import {useCardActions} from "./hooks";
import {useEditorContext} from "../../../Editor/EditorContext";
import {EditorRouterItem, EditorScreenItem, EditorServiceItem} from "../../../Editor/EditorItem";
import {FieldTypeManager} from "../../../Editor/editors/ScreenEditor/fields";
import { StubbornRouter } from '../../../../../business/StubbornRouter';

const AssetTypeImages: Record<StubbornAssetType, string> = {
  Field: "",
  Image: "",
  Menu: "",
  Screen: 'https://image.shutterstock.com/image-vector/dashboard-vector-icon-website-other-260nw-1421089664.jpg',
  Service: "https://cdn.iconscout.com/icon/premium/png-256-thumb/cloud-server-57-734343.png",
  Router: "https://www.websolutions.com/Customer-Content/www/CMS/files/nav.png",
  Hidden: "",
}

export const AssetList = () => {
  const history = useHistory();
  const editionServiceApi = useEditionServiceApi();
  const editionScreenApi = useEditionScreenApi();
  const editionRouterApi = useEditionRouterApi();
  const { addEditor } = useEditorContext();
  // Search
  const [search, setSearch] = useState('');
  const { loading, data, call } = useRosterFind({ autoCall: false });
  const assets = useMemo(() => !data ? [] : [...data], [data]);

  const items = useMemo<MenuCardItem[]>(() => assets.map<MenuCardItem>(asset => ({
    id: `${asset.type}#${asset.name}`,
    title: asset.name,
    image: AssetTypeImages[asset.type],
    asset,
  })), [assets]);

  const query = useMemo<QueryObject>(() => {
    const _query: QueryObject = !search ? {} : {search};
    return _query;
  }, [search]);

  const recallRoster = useCallback(() => call({ query }), [call, query]);

  useEffect(() => {
    recallRoster();
  }, [recallRoster]);

  // Clone and remove
  const { call: createService } = useEditionServiceCreate();
  const { call: removeService } = useEditionServiceRemove();
  const { call: createScreen } = useEditionScreenCreate();
  const { call: removeScreen } = useEditionScreenRemove();
  const { call: createRouter } = useEditionRouterCreate();
  const { call: removeRouter } = useEditionRouterRemove();
  const serviceFns = useMemo(() => ({ create: createService, remove: removeService }), [createService, removeService]);
  const screenFns = useMemo(() => ({ create: createScreen, remove: removeScreen }), [createScreen, removeScreen]);
  const routerFns = useMemo(() => ({ create: createRouter, remove: removeRouter }), [createRouter, removeRouter]);
  const actions = useCardActions(screenFns, serviceFns, routerFns, recallRoster);

  const onCreateService = useCallback(async (name: string, description: string) => {
    const newService: StubbornService = {
      name,
      description,
      type: 'Service',
      parameters: [],
      actions: [],
      tags: [],
      methods: [],
      title: name,
      attributes: { basic: { layouts: {} } }, // TODO: arreglar esto cuando arreglemos los atributos
    };
    await createService(newService);
    recallRoster();
  }, [recallRoster, createService]);

  const onCreateScreen = useCallback(async (name: string) => {
    const newScreen: StubbornScreen = {
      name,
      type: 'Screen',
      tags: [],
      metadata: [],
      attributes: { ...FieldTypeManager.getDefaultAttributes('container') },
    };
    await createScreen(newScreen);
    recallRoster();
  }, [createScreen, recallRoster]);

  const onCreateRouter = useCallback(async (name: string) => {
    const newRouter: StubbornRouter = {
      name,
      type: 'Router',
      basePath: '',
      layout: undefined, // <== StubbornScreen con target
      routes: [],
      redirect: [],
      events: {}, // code
      attributes: { basic: { layouts: {} } }, // TODO: arreglar esto cuando arreglemos los atributos
      tags: [],
    };
    await createRouter(newRouter);
    recallRoster();
  }, [createRouter, recallRoster]);

  const onItemClick = useCallback(async (data: MenuCardItem) => {
    if (data.asset.type === 'Service') {
      const response = await editionServiceApi.find({ name: data.asset.name });
      if (response.data && response.data.length) {
        const [_service] = response.data as StubbornService[];
        const editor = new EditorServiceItem(_service);
        addEditor(editor);
        history.push('/editor');
      }
    } else if (data.asset.type === 'Screen') {
      const response = await editionScreenApi.find({ name: data.asset.name });
      if (response.data && response.data.length) {
        const [screen] = response.data as StubbornScreen[];
        const editor = new EditorScreenItem(screen);
        addEditor(editor);
        history.push('/editor');
      }
    } else if (data.asset.type === 'Router') {
      const response = await editionRouterApi.find({ name: data.asset.name });
      if (response.data && response.data.length) {
        const [_router] = response.data as StubbornRouter[];
        const editor = new EditorRouterItem(_router);
        addEditor(editor);
        history.push('/editor');
      }
    }
    }, [addEditor, editionScreenApi, editionServiceApi, editionRouterApi, history]);

  return (
    <>
      <Typography variant="h5">Assets</Typography>
      <Toolbar search={search} onSearchChange={setSearch} onCreateService={onCreateService} onCreateScreen={onCreateScreen} onCreateRouter={onCreateRouter} />
      {loading ? (
        <Loading />
      ) : !items || !items.length ? (
        <Typography variant="body2">Nada encontrado</Typography>
      ) :  (
        <MenuCardList items={items} actions={actions} onItemClick={onItemClick}/>
      )}
    </>
  );
};
