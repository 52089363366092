import { BaseAttributeEditorProps } from './BaseAttributeEditorProps';
import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelectOnChangeValue } from '../../../../../../../commons/hooks';
import { useRosterRouterContextFind } from '../../../../../../../../platform/api/endpoints';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    paddingRight: 16,
    position: 'relative',
  },
  selectContainer: { width: '100%', height: '100%' },
});

export interface TargetRouterAttributeEditorProps extends BaseAttributeEditorProps {}

export const TargetRouterAttributeEditor = ({ value, onChange, onSave }: TargetRouterAttributeEditorProps) => {
  const classes = useStyles();
  const { data, loading } = useRosterRouterContextFind();

  const handleChange = useSelectOnChangeValue(onChange);

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLSelectElement>) => {
      onSave();
      event.stopPropagation();
    },
    [onSave],
  );
  return (
    <div className={classes.root}>
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <select className="selectContainer" value={value?.toString()} onChange={handleChange} onBlur={handleOnBlur}>
          <option key={`option-select-none`} value={''}>
            Seleccione una opción
          </option>
          {data
            ?.filter((route) => route.name)
            ?.map((option, index) => (
              <option key={`option-select-${option?.name}-${index}`} value={option?.name.toString()}>
                {option.name}
              </option>
            ))}
        </select>
      )}
    </div>
  );
};
