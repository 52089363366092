import React, { useEffect } from "react";
import { Typography as MuiTypography } from "@material-ui/core";
import { FieldBaseProps } from "../FieldBaseProps";
import { useEditorScreenContext } from "../../../../../EditorScreenContext";

type Variant = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline";

type Align = "center" | "inherit" | "justify" | "left" | "right";

export interface TextProps extends FieldBaseProps {
  component: {
    content?: string;
    variant?: Variant;
    align?: Align;
    noWrap?: boolean;
    paragraph?: boolean;
  };
}

export const Text = ({ component, events, states, styles }: TextProps) => {
  const { editable } = useEditorScreenContext();

  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <MuiTypography style={styles} align={component.align} paragraph={component.paragraph} noWrap={component.noWrap} variant={component.variant}>
      {component.content}
    </MuiTypography>
  );
};
