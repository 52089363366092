import { StubbornField, StubbornScreen } from '../business/StubbornAsset';

const isStubbornField = (asset: StubbornField | StubbornScreen): asset is StubbornField => {
  return !!(asset as StubbornField).fieldType;
};

export class AssetNamingHelper {
  protected typeIterator = new Map<string, number>();
  protected registeredNames = new Set<string>();
  public screen;

  constructor(screen: StubbornScreen) {
    this.screen = screen;

    (this.screen.metadata as Array<StubbornScreen | StubbornField>).forEach((screenAsset) => {
      const type = isStubbornField(screenAsset) ? screenAsset.fieldType : 'screen';
      this.setName(screenAsset.name, type);
    });
  }

  protected nameIsAvailable(name: string): boolean {
    return !this.registeredNames.has(name);
  }

  protected addToIterator(type: string): number {
    let typeIterator = this.typeIterator.get(type);
    if (!typeIterator) typeIterator = 0;
    typeIterator += 1;
    this.typeIterator.set(type, typeIterator);
    return typeIterator;
  }

  getDefaultNameByType(asset: StubbornScreen | StubbornField): string {
    const isSterScreen = asset.name.includes('ster');
    const type = isStubbornField(asset) ? asset.fieldType : 'screen';
    const newName = type === 'screen' ? (!isSterScreen ? `${type}${this.addToIterator(type)}` : `sterScreen${this.addToIterator('sterScreen')}`) : `${type}${this.addToIterator(type)}`;
    if (!this.nameIsAvailable(newName)) return this.getDefaultNameByType(asset);
    return newName;
  }

  setName(name: string, type: string): boolean {
    this.addToIterator(type);
    const availability = this.nameIsAvailable(name);
    if (availability) this.registeredNames.add(name);
    return availability;
  }
}
