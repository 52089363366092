import React, { useCallback, useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Icon } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Editor from "@monaco-editor/react";
import { BaseAttributeEditorProps } from "./BaseAttributeEditorProps";
import {Modal} from "../../../../../../../commons/ui";

const useStyles = makeStyles({
  backdrop: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingRight: 16,
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#00000080",
    zIndex: 2000000,
    top: 0,
    left: 0,
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    background: "white",
    height: "75%",
    width: "80%",
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 10px",
  },
  modalContent: {
    display: "flex",
    flex: 1,
    padding: "0px 10px",
  },
  modalFooter: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    justifyContent: "flex-end",
  },
  cancelButton: { marginRight: 20 },
});

export interface JsonAttributeEditorProps extends BaseAttributeEditorProps {}

export const JsonAttributeEditor = ({
  value,
  onChange,
  onCancel,
  onSave,
  cellName
}: JsonAttributeEditorProps) => {
  const classes = useStyles();
  const [intermediateValue, setIntermediateValue] = useState<string>(JSON.stringify(value, null, "\t"));
  const [readyToSave, setReadyToSave] = useState(false);

  const handleOnBlur = useCallback(
    (event) => {
      onChange(JSON.parse(intermediateValue));
      setReadyToSave(true)
      event.stopPropagation();
    },
    [intermediateValue, setReadyToSave, onChange]
  );

  useEffect(() => {
    if (readyToSave) onSave();
  }, [readyToSave, onSave]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case "Escape":
          onCancel();
          e.stopPropagation();
          e.preventDefault();
          break;
      }
    },
    [onCancel]
  );

  return (
    <Modal>
      <div className={classes.backdrop} onClick={onCancel}>
        <div
          className={classes.modal}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={handleKeyPress}
        >
          <div className={classes.modalHeader}>
            <h3>{cellName}</h3>
            <Icon component={CloseIcon} onClick={onCancel} />
          </div>
          <div className={classes.modalContent}>
            <Editor
              height="100%"
              width="100%"
              theme="vs-dark"
              defaultLanguage="json"
              defaultValue={intermediateValue}
              onChange={(value) => setIntermediateValue(value ? value?.toString() : '')}
            />
          </div>
          <div className={classes.modalFooter}>
            <Button
              className={classes.cancelButton}
              variant="outlined"
              fullWidth={false}
              onClick={onCancel}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              fullWidth={false}
              onClick={handleOnBlur}
            >
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
