import { SyntheticEvent, Dispatch, SetStateAction } from 'react';
import { Chip, Grid, TextField, Autocomplete } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import LoadingButton from '@material-ui/lab/LoadingButton';

interface AddTagsProps {
  handleSaveTags: () => {};
  handleAddTag: (e: SyntheticEvent, value: (string | string[])[]) => void;
  newTags: string[];
  loading: boolean;
}

export const AddTags = ({ handleSaveTags, newTags, handleAddTag, loading }: AddTagsProps) => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item sx={{ width: 600, marginX: 5 }}>
        <Autocomplete
          value={newTags}
          limitTags={4}
          multiple
          fullWidth
          id="add-tags"
          onChange={handleAddTag}
          options={[]}
          freeSolo
          renderTags={(value, getTagProps) => value.map((tag, index) => <Chip variant="outlined" deleteIcon={<DeleteIcon />} label={tag} {...getTagProps({ index })} />)}
          renderInput={(params) => <TextField {...params} variant="outlined" label="Add tags" placeholder="Insert tag and press Enter" />}
        />
      </Grid>
      <Grid sx={{ position: 'relative' }}>
        <LoadingButton sx={{ position: 'absolute', width: 120, top: '50%', transform: 'translateY(-50%)' }} color="primary" variant="contained" onClick={handleSaveTags} disabled={!newTags.length || loading} loading={loading}>
          Save tags
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
