import React, { useEffect, useState } from 'react';
import { Table as MuiTable, TableBody, TableContainer as MuiTableContainer, TableCell, TableHead, Paper, TableRow, Button, IconButton, Icon, Grid } from '@material-ui/core';
import { FieldBaseProps } from '../FieldBaseProps';
import { useServicesDAO } from '../../../hooks';
import { tableKeysFormatter } from '../../utils';
import { useScreenDAOContext } from '../../../context/ScreenDAOContext';
import { securedJSRunner } from '../../../../../../../../utils/functionHelpers';
import { ComponentAction } from '../../../properties/editors/CollectionAttributeEditor';
import { useRouterDAOContext } from '../../../context/RouterDAOContext';
import { useProjectDAOContext } from '../../../context/ProjectDAOContext';
import { AnyObject } from '../../../../../../../../commons/types';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';

export interface SterTableProps extends FieldBaseProps {
  component: {
    hover: boolean;
    tableData: { [key: string]: string | number }[];
    tableName: string;
    size: 'medium' | 'small';
  };
  datasource: {
    enabled: boolean;
    service?: {
      serviceName: string;
      params: {
        keys: Array<string>;
        values: Array<string>;
      };
      path: string;
    };
    items?: AnyObject[];
  };
  actions: {
    enabled?: boolean;
    actionColumnTitle: string;
    actions?: ComponentAction[];
    style: any;
  };
}

export const SterTable = ({ component, events, styles, datasource, actions, Context = {}, Params }: SterTableProps) => {
  const { ServicesDAO } = useServicesDAO();
  const { editable, screen } = useEditorScreenContext();
  const [items, setItems] = useState<AnyObject[]>([]);
  const [tableKeys, setTableKeys] = useState<Array<string>>();
  const { ScreenDAO } = useScreenDAOContext();
  const { ProfileDAO } = useRouterDAOContext();
  const { ProjectDAO } = useProjectDAOContext();
  const keyNames = Object.keys(component.tableData?.[0] || []);
  const datasourceItems = datasource.items || [];

  const customActions = actions.actions?.map((action: ComponentAction, index: number) => {
    const isButton = action.type === 'button';
    const onClick = (event: any) => securedJSRunner(action.onClick, event, ScreenDAO, ProfileDAO?.RouterDAO, ProjectDAO.services, ProjectDAO, { ...Context, Params });
    return isButton ? (
      <Button key={`action-entry-${index}`} variant="contained" onClick={onClick}>
        {action.label}
      </Button>
    ) : (
      <IconButton key={`action-entry-${index}`} onClick={onClick}>
        <Icon>{action.icon}</Icon>
      </IconButton>
    );
  });

  useEffect(() => {
    (async () => {
      if (datasource?.enabled) {
        let query = {};
        datasource.service?.params?.keys.forEach((key, index) => {
          query = {
            ...query,
            [key]: datasource.service?.params?.values[index],
          };
        });
        // @ts-ignore
        const res = await ServicesDAO?.[datasource.service?.serviceName]?.(query);
        if (res) {
          const result = res.data[datasource.service?.path || ''];
          setItems(Array.isArray(result) && !datasourceItems.length ? result : datasourceItems);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasource, ServicesDAO, screen]);

  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const keys = datasource.enabled ? tableKeysFormatter(items) : keyNames;
  const itemsBody = datasource.enabled ? items : component.tableData;

  useEffect(() => {
    if (actions?.enabled && actions.actions?.length) {
      setTableKeys(actions.actionColumnTitle ? keys.concat(actions.actionColumnTitle) : keys.concat(''));
    } else {
      setTableKeys(keys);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions]);

  return (
    <>
      {!itemsBody?.length && <p>Empty table</p>}
      <MuiTableContainer component={Paper} style={styles}>
        <MuiTable size={component.size} style={{ width: '100%' }}>
          <TableHead>
            <TableRow key={`${component.tableName}-row-titles`}>{tableKeys && tableKeys.map((keyName, index) => <TableCell key={`${component.tableName}-cell-title-${index}`}>{keyName}</TableCell>)}</TableRow>
          </TableHead>

          <TableBody>
            {itemsBody.map((entry, index) => (
              <>
                <TableRow hover={component.hover} key={`${component.tableName}-row-${index}`}>
                  {tableKeys &&
                    tableKeys.map((keyName, index) => {
                      return (
                        <>
                          <TableCell key={`${component.tableName}-cell-${index}`}> {keyName === actions.actionColumnTitle ? customActions : entry[keyName]} </TableCell>
                        </>
                      );
                    })}
                </TableRow>
              </>
            ))}
          </TableBody>
        </MuiTable>
      </MuiTableContainer>
    </>
  );
};
