import {StubbornAttribute, StubbornAttributeGroup} from "../../../../../../../../business/StubbornAttributeMapping";
import { makeComponentAttributeGroup, makeStateAttributeGroups, makeEventAttributeGroup } from "../../commons/attributes";

const attributes = new Set<StubbornAttribute>();
attributes.add({
  label: 'Language',
  name: 'language',
  type: 'select',
  default: 'HTML',
  list: ['HTML', 'CSS', 'JavaScript', 'TypeScript']
});
attributes.add({
  label: 'Height',
  name: 'height',
  type: 'number',
  default: 200,
});

const basicAttributes = new Set<StubbornAttribute>();
basicAttributes.add({
  label: 'Layout',
  name: 'layouts',
  type: 'breakpoints',
  default: { md: { col: 12 } },
  metadata: { dataType: 'layout' },
});

basicAttributes.add({
  label: 'Style',
  name: 'styles',
  type: 'breakpoints',
  default: {},
  metadata: { dataType: 'styles' },
});

basicAttributes.add({
  label: 'Render condition',
  name: 'renderConditions',
  type: 'code',
  default: ''
});

export const basicAttributeGroup: StubbornAttributeGroup = {
  label: 'Basic',
  name: 'basic',
  priority: 1,
  attributes: basicAttributes,
};

const eventAttributes: StubbornAttribute[] = [
  {
    label: "OnChange",
    name: "onChange",
    type: "code",
    default: "",
  }
];

const stateAttributes: StubbornAttribute[] = [
  {
    label: "Value",
    name: "value",
    type: 'code',
    default: "",
  },
];

export const attributeGroups: StubbornAttributeGroup[] = [
  basicAttributeGroup,
  makeStateAttributeGroups(stateAttributes),
  makeComponentAttributeGroup(attributes),
  makeEventAttributeGroup(eventAttributes),
];
