import React, { useCallback, useState, useEffect } from 'react';
import { AppBar, Box, Breadcrumbs, Link, Paper, Toolbar, Avatar, Grid, Card, CardHeader, CardActionArea, Button, IconButton, Icon } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { createStyles, makeStyles } from '@material-ui/styles';
import { AppThemeType } from '../../../../../../theme';
import { PreviewModal } from './components/PreviewModal';
import { BaseAppConst } from '../../../../../const/BaseAppConst';
import { useEditorRouterContext } from '../../EditorRouterContext';
import { link, router } from '../../../../../../theme/images';
import { useDrop } from 'react-dnd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { StubbornRoute, StubbornRouter } from '../../../../../business/StubbornRouter';
import { availablePanelsByType, useAssetContext } from '../../../../Private2/commons/context/AssetContext';

const { bg_color, dot_size, dot_space } = BaseAppConst.uiEditor;

const useStyles = makeStyles<AppThemeType>((theme) =>
  createStyles({
    container: {
      padding: 0,
      display: 'flex',
      height: '97%',
      margin: '5px',
    },
    dotsStyle: {
      width: '100%',
      height: '94%',
      margin: 0,
      overflowY: 'auto',
      padding: 10,
      backgroundSize: `${dot_space}px ${dot_space}px`,
    },
    cardSelected: {
      boxShadow: '0px 0px 0px 5px red',
    },
    clickable: {
      cursor: 'pointer',
      color: 'white',
      textDecoration: 'none',
    },
    nonClickable: {
      color: 'black',
      textDecoration: 'none',
    },
    icons: { display: 'flex', padding: 10, height: 40, width: 40, justifyContent: 'center', alignContent: 'center', backgroundColor: '#1976D2' },
  }),
);

const actionTypes: StubbornRoute['type'][] = ['Router', 'Route'];

const actionImages: Record<string, string> = {
  route: link,
  router,
};

export const RouterEditor = () => {
  const { router, selectedRoute, setRouter, setSelectedRoute } = useEditorRouterContext();
  const classes = useStyles();
  const [draggableRoutes, setDraggableRoutes] = useState<StubbornRoute[]>(router.routes);
  const [openPreview, setOpenPreview] = useState(false);
  const { setActivePanel, selectedAsset } = useAssetContext();

  useEffect(() => {
    setDraggableRoutes(router.routes);
  }, [router]);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'router',
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const reorder = (list: StubbornRoute[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(draggableRoutes, result.source.index, result.destination.index);
    setSelectedRoute(null);
    setDraggableRoutes(items);
    const newRouter = {
      ...router,
      routes: items,
    };
    setRouter(newRouter);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <PreviewModal open={openPreview} onClose={() => setOpenPreview(false)} />
      <AppBar position="static" style={{ margin: 0, padding: 0, background: '#2F343D' }}>
        <Toolbar style={{ margin: 0, padding: 10, height: 66 }}>
          <IconButton
            style={{ color: 'white' }}
            onClick={() => {
              selectedAsset && setActivePanel(availablePanelsByType[selectedAsset?.type][0]);
            }}
          >
            <Icon>settings</Icon>
          </IconButton>
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />} sx={{ flexGrow: 1 }}>
            <Link color="inherit" onClick={() => setSelectedRoute(null)} className={classes.clickable}>
              {router.name}
            </Link>
            {selectedRoute !== null && (
              <Link color="inherit" className={classes.nonClickable}>
                {router.routes[selectedRoute].name}
              </Link>
            )}
          </Breadcrumbs>
        </Toolbar>
      </AppBar>
      <Paper className={classes.container}>
        <Box className={classes.dotsStyle} mt={1} ref={drop}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} style={{ marginTop: 20 }}>
                <Grid container direction="column" spacing={2} alignItems="center">
                  {draggableRoutes.map((route, index) => (
                    <Draggable key={route.name} draggableId={route.name} index={index}>
                      {(provided) => (
                        <Card onClick={() => setSelectedRoute(index)} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} sx={{ width: 400, margin: 1, background: "#EEEEEE" }} className={index === selectedRoute ? classes.cardSelected : undefined}>
                          <CardHeader avatar={<Avatar src={actionImages[route?.type?.toLowerCase()]} className={classes.icons} />} title={route.name} subheader={route.target} />
                        </Card>
                      )}
                    </Draggable>
                  ))}
                </Grid>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Box>
      </Paper>
    </DragDropContext>
  );
};
