import _ from "lodash";
import React, { useMemo, Fragment } from "react";
import { StubbornField } from "../../../../../../../../business/StubbornAsset";
import { securedJSRunner } from "../../../../../../../../utils/functionHelpers";
import { FieldTypeManager } from "../../../fields";
import { NoFieldTypeRegistered } from "../../NoFieldTypeRegistered";
import { useScreenDAOContext } from "../../../context/ScreenDAOContext";
import { useCurrentBreakpoint } from "../../../../../../../../commons/hooks";
import { processGroupStyles } from "../../../fields/utils";
import {useProjectDAOContext} from "../../../context/ProjectDAOContext";
import {AnyObject} from "../../../../../../../../commons/types";
import { RendererContainer } from '../../RendererContainer';
import { GridSpans } from '../../GridSpans';
import { useEditorScreenContext } from "../../../../../EditorScreenContext";
import { useRouterDAOContext } from "../../../context/RouterDAOContext";
export interface HiddenFieldRendererProps extends StubbornField {
  Context?: AnyObject;
}

const dummyObj = {};

// TODO: definir bien los atributos
export const HiddenFieldRenderer = ({Context = dummyObj, ...props}: HiddenFieldRendererProps) => {
  const { ScreenDAO, params } = useScreenDAOContext();
  const { editable } = useEditorScreenContext();
  const { ProjectDAO } = useProjectDAOContext();
  const { ProfileDAO } = useRouterDAOContext();
  const FieldType = FieldTypeManager.get(props.fieldType);
  const Comp = FieldType?.component || NoFieldTypeRegistered;
  const defaultValues = FieldTypeManager.getDefaultAttributes(props.fieldType);

  const formattedAttributes = useMemo(() => {
    let fullAttributes: AnyObject = {};
    Object.keys(defaultValues).forEach((attributeGroup) => {
      fullAttributes = {
        ...fullAttributes,
        [attributeGroup]: {
          ...defaultValues[attributeGroup],
          ...props.attributes[attributeGroup],
        },
      };
    });
    // doing interpolation of strings
    const attributesTypes = FieldTypeManager.getAttributesTypes(props.fieldType);
    Object.keys(fullAttributes).forEach(attributeGroup => {
      Object.keys(fullAttributes[attributeGroup]).forEach(attributeName => {
        const type = attributesTypes[attributeGroup][attributeName];
        const value = fullAttributes[attributeGroup][attributeName];
        if (type === 'string') {
          const compiled = _.template(value);
          fullAttributes[attributeGroup][attributeName] = compiled({
            Screen: ScreenDAO,
            ...fullAttributes,
          });
        }
      });
    });

    return fullAttributes;
  }, [ScreenDAO, defaultValues, props.attributes, props.fieldType]);

  const eventFormatted = useMemo(() => {
    let events = {};
    if (!editable) {
      props.attributes.events &&
        Object.keys(props.attributes.events).forEach((event) => {
          if (!!props.attributes.events[event]) {
            events = {
              ...events,
              [event]: (e: React.EventHandler<any>) => securedJSRunner(props.attributes.events[event], e, ScreenDAO, ProfileDAO?.RouterDAO, ProjectDAO.services, ProjectDAO, { ...Context, Params: params }),
            };
          }
        });
    }
    return events;
  }, [ProjectDAO, editable, ScreenDAO, ProfileDAO, props.attributes.events, Context, params]);

  const bp = useCurrentBreakpoint();

  const componentStyles = useMemo(() => {
    const styles = _.get(formattedAttributes, "basic.styles");
    return processGroupStyles(styles, bp);
  }, [formattedAttributes, bp]);

  // @ts-ignore
  const parentAssetSelector = props.parentAssetSelector;
  // @ts-ignore
  const assetIndex = props.assetIndex;

  const newParentAssetSelector = useMemo(() => (!assetIndex ? [] : [...parentAssetSelector, assetIndex]), [parentAssetSelector, assetIndex]);

  return <Comp {...formattedAttributes} events={eventFormatted} styles={componentStyles}>
      { // @ts-ignore
      props.metadata.map((item, index) => {
        return (
          <Fragment key={index}>
            <GridSpans layouts={item.attributes.basic.layouts} />
            <RendererContainer {...item} parentAssetSelector={newParentAssetSelector} assetIndex={index} /> {/* aqui deberiamos tener un render por cada tipo de container */}
            {/* {dialog} */}
            <GridSpans after layouts={item.attributes.basic.layouts} />
          </Fragment>
        );
      })}
    </Comp>;
};
