import { FieldType } from '../../manager/FieldType';
import { Checkbox } from './Checkbox';
import { attributeGroups } from './attributeGroups';
import { toolbox } from './toolbox';
import { FieldTypeManager } from '../../manager/FieldTypeManager';

export const checkboxFieldType: FieldType = {
  name: 'checkbox',
  component: Checkbox,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(checkboxFieldType.name, checkboxFieldType);
