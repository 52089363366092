import React, {useState} from 'react';
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {NewRouterDialog, NewScreenDialog, NewServiceDialog} from "./dialogs";
import {useDialogState} from "../../../../../../../../../commons/hooks";

export interface AddAssetMenuProps {
  onCreateService: (name: string, description: string) => Promise<void>;
  onCreateScreen: (name: string) => Promise<void>;
  onCreateRouter: (name: string) => Promise<void>;
}

export const AddAssetMenu = ({ onCreateService, onCreateScreen, onCreateRouter }: AddAssetMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = !!anchorEl;
  const [open, onOpen, onClose] = useDialogState(false);
  const [openScreen, onOpenScreen, onCloseScreen] = useDialogState(false);
  const [openRouter, onOpenRouter, onCloseRouter] = useDialogState(false);
  return (
    <>
      <IconButton color="primary" size="large" title="Nuevo" onClick={(event) => { setAnchorEl(event.currentTarget); }}>
        <AddIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => { setAnchorEl(null); onOpenScreen(); }}>Screen</MenuItem>
        <MenuItem onClick={() => { setAnchorEl(null); onOpen(); }}>Servicio</MenuItem>
        <MenuItem onClick={() => { setAnchorEl(null); onOpenRouter(); }}>Router</MenuItem>
      </Menu>
      <NewServiceDialog open={open} onClose={onClose} onCreateService={onCreateService} />
      <NewScreenDialog open={openScreen} onClose={onCloseScreen} onCreateScreen={onCreateScreen} />
      <NewRouterDialog open={openRouter} onClose={onCloseRouter} onCreateRouter={onCreateRouter} />
    </>
  );
};
