import React, { useMemo } from 'react';
import ContentCopyIcon from '@material-ui/icons/ContentCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import { CardButtonAction } from '../../../../../../../commons/ui';
import { MenuCardItem } from '../../../../../../../components';
import { confirmDialog } from '../../../../../../../services';
import { StubbornDatasource } from '../../../../../../../business/StubbornAsset';

interface Fns<T> {
  create: (item: T) => Promise<any>;
  remove: (id?: string, item?: T) => Promise<any>;
}

export const useCardActions = (datasourceFns: Fns<StubbornDatasource>, recallRoster: () => void) =>
  useMemo<CardButtonAction<MenuCardItem>[]>(
    () => [
      {
        id: 'clone',
        icon: <ContentCopyIcon />,
        title: 'Clonar',
        onClick: async (data) => {
          const confirm = await confirmDialog.show({
            title: 'Confirmacion',
            content: 'Esta seguro de clonar este datasource?',
          });
          if (confirm) {
            // TODO: evaluar si usar el naming strategy aqui
            const newDatasource: StubbornDatasource = { ...data.datasource, name: `${data.datasource.name}Clon` };
            await datasourceFns.create(newDatasource);
            recallRoster();
          }
        },
      },
      {
        id: 'remove',
        icon: <DeleteIcon />,
        title: 'Eliminar',
        onClick: async (data) => {
          const confirm = await confirmDialog.show({
            title: 'Confirmacion',
            content: 'Esta seguro de eliminar este datasource?',
          });
          if (confirm) {
            await datasourceFns.remove(undefined, data.datasource);
            recallRoster();
          }
        },
      },
    ],
    [recallRoster, datasourceFns],
  );
