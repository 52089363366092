import { StubbornAttribute } from "../../../../../../../../business/StubbornAttributeMapping";

const coreEventAttributes: StubbornAttribute[] = [
  {
    label: "OnMount",
    name: "onMount",
    type: "code",
    default: "",
  },
  {
    label: "OnUnmount",
    name: "onUnmount",
    type: "code",
    default: "",
  },
];

export const makeEventAttributeGroup = (otherEventAttributes?: StubbornAttribute[]) => {
  const otherEvents = otherEventAttributes ? otherEventAttributes : [];
  return {
    label: "Eventos",
    name: "events",
    priority: 1,
    attributes: new Set([...coreEventAttributes, ...otherEvents ]),
  };
};
