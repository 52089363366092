import { Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FunctionComponent } from 'react';
import { QueryObject } from '../../../../../../../lib/HttpService';
import { useEditionDatasourceFind, useEditionDatasourceCreate, useEditionDatasourceRemove, useEditionDatasourceUpdate } from '../../../../../../../platform/api/endpoints/edition/datasources';
import { Loading, MenuCardItem, MenuCardList } from '../../../../../../components';
import { useCardActions } from './hooks';
import { Toolbar } from './components';
import { StubbornDatasource, StubbornDatasourceKind, StubbornDatasourceType } from '../../../../../../business/StubbornAsset';
import { NewDatasourceDialog } from './components/Toolbar/components/AddDatasourceMenu/dialogs';
import { useDialogState } from '../../../../../../commons/hooks';
import { useEditionDatasourceApi } from '../../../../../../../platform/api/endpoints/edition/datasources/useEditionDatasourceApi';
import { AnyObject } from '../../../../../../../lib/phinxapp/profiles/types';

const DatasourceTypeImages: Record<string, string> = {
  postgres: 'https://miro.medium.com/max/765/1*EqIzKO1Nl4vjkK2Bss-35g.png',
  redis: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRE5erWEaRlysAjJByu-dGsrKcQMQ6Ly6tbOuV4J817MgTDyzmGeQVJZgBuso8pR2BzVBg&usqp=CAU',
};

export const DatasourcesList: FunctionComponent = () => {
  const [search, setSearch] = useState('');
  const { loading, data, call } = useEditionDatasourceFind({ autoCall: false });
  const datasources = useMemo(() => (!data ? [] : [...data]), [data]);
  const EditionDatasourceApi = useEditionDatasourceApi();
  const { call: createDatasource } = useEditionDatasourceCreate();
  const { call: removeDatasource } = useEditionDatasourceRemove();
  const { call: updateDatasource } = useEditionDatasourceUpdate();
  const datasourceFns = useMemo(() => ({ create: createDatasource, remove: removeDatasource }), [createDatasource, removeDatasource]);
  const [open, onOpen, onClose] = useDialogState(false);
  const [defaultData, setDefaultData] = useState<StubbornDatasource | AnyObject>({});

  const query = useMemo<QueryObject>(() => {
    const _query: QueryObject = !search ? {} : { name: search };
    return _query;
  }, [search]);

  const recallRoster = useCallback(() => call({ query }), [call, query]);
  const actions = useCardActions(datasourceFns, recallRoster);

  useEffect(() => {
    recallRoster();
  }, [recallRoster]);

  const items = useMemo<MenuCardItem[]>(
    () =>
      datasources.map<MenuCardItem>((datasource) => ({
        id: `${datasource.type}#${datasource.name}`,
        title: datasource.name,
        image: DatasourceTypeImages[datasource.type],
        datasource,
      })),
    [datasources],
  );

  const onCreateDatasource = useCallback(
    async (name: string, kind: StubbornDatasourceKind, type: StubbornDatasourceType, host: string, port: number, user: string, password: string, database: string, onconnect: string) => {
      const newDatasource: StubbornDatasource = {
        name,
        kind,
        type,
        host,
        port: +port,
        user,
        password,
        database,
        onconnect,
      };
      await createDatasource(newDatasource);
      recallRoster();
    },
    [createDatasource, recallRoster],
  );

  const onUpdateDatasource = useCallback(
    async (name: string, kind: StubbornDatasourceKind, type: StubbornDatasourceType, host: string, port: number, user: string, password: string, database: string, onconnect: string) => {
      const newDatasource: StubbornDatasource = {
        name,
        kind,
        type,
        host,
        port: +port,
        user,
        password,
        database,
        onconnect,
      };
      await updateDatasource(undefined, newDatasource);
      recallRoster();
    },
    [updateDatasource, recallRoster],
  );

  const onItemClick = useCallback(
    async (data: MenuCardItem) => {
      const response = await EditionDatasourceApi.find({ name: data.datasource.name });
      if (response.data && response.data.length) {
        const [_service] = response.data as StubbornDatasource[];
        setDefaultData(_service);
        onOpen();
      }
    },
    [EditionDatasourceApi, onOpen],
  );

  return (
    <>
      <Toolbar search={search} onSearchChange={setSearch} onCreateDatasource={onCreateDatasource} />
      {loading ? <Loading /> : !items || !items.length ? <Typography variant="body2">Nada encontrado</Typography> : <MenuCardList items={items} actions={actions} onItemClick={onItemClick} />}
      {open && <NewDatasourceDialog open={open} onClose={onClose} onCreateDatasource={onUpdateDatasource} defaultData={defaultData} update/>}
    </>
  );
};
