import { AssetView } from '../types';
import { BasicInformationPanel, RouterRelatedAssetsPanel } from '../panels';
import { RemoveAction, CloneAction } from '../actions';

export const RouterAssetView: AssetView = {
  type: 'Router',
  panels: [
    {
      label: 'Basic',
      component: BasicInformationPanel,
    },
    {
      label: 'Related assets',
      component: RouterRelatedAssetsPanel,
    },
  ],
  actions: [CloneAction, RemoveAction],
};
