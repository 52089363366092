import _ from "lodash";
import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Select, MenuItem, Switch } from "@material-ui/core";
import { AppThemeType } from "../../../../../../../theme";
import { makeStyles, useTheme, createStyles } from "@material-ui/styles";
import { breakpoints } from "../../../constants";

const colAndSpanSelectValues = ["Unset", 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const useStyles = makeStyles<AppThemeType>((theme) =>
  createStyles({
    editLayoutContent: {
      minWidth: 500,
    },
    column: {
      display: "flex",
      flexDirection: "row",
    },
  }),
);

interface EditorLayoutProps {
  layoutState: any;
  setBreakpointParams: (property: string, value: any, breakpointCode: string) => void;
  setBreakPointDisabled: (breakpointCode: string, val: boolean) => void;
}

export function EditorLayout({ layoutState, setBreakpointParams, setBreakPointDisabled }: EditorLayoutProps) {
  const theme = useTheme();
  //@ts-ignore
  const classes = useStyles(theme);

  const editors = breakpoints.map((breakpoint, index) => {
    const colSelectValue = layoutState[breakpoint.code]?.col || layoutState[breakpoint.code]?.col === 0 ? layoutState[breakpoint.code]?.col : "Unset";
    const spanSelectValue = layoutState[breakpoint.code]?.span || layoutState[breakpoint.code]?.span === 0 ? layoutState[breakpoint.code]?.span : "Unset";
    const spanAfterSelectValue = layoutState[breakpoint.code]?.spanAfter || layoutState[breakpoint.code]?.spanAfter === 0 ? layoutState[breakpoint.code]?.spanAfter : "Unset";
    return (
      <Accordion key={`breakpoint-editor-${index}`}>
        <AccordionSummary>
          <Typography>{breakpoint.name}</Typography>
        </AccordionSummary>

        <div className={classes.column}>
          <AccordionDetails>
            <Typography>Column factor</Typography>
            <Select
              MenuProps={{
                style: { zIndex: 2500 },
              }}
              size="small"
              disabled={!layoutState.hasOwnProperty(breakpoint.code)}
              value={colSelectValue}
              onChange={(e) => setBreakpointParams("col", e.target.value, breakpoint.code)}
            >
              {colAndSpanSelectValues.map((num, index) => (
                <MenuItem key={`breakpoint-editor-col-select-${index}`} value={num}>
                  {num}
                </MenuItem>
              ))}
            </Select>
          </AccordionDetails>

          <AccordionDetails>
            <Typography>Span factor</Typography>
            <Select
              MenuProps={{
                style: { zIndex: 2500 },
              }}
              size="small"
              disabled={!layoutState.hasOwnProperty(breakpoint.code)}
              value={spanSelectValue}
              onChange={(e) => setBreakpointParams("span", e.target.value, breakpoint.code)}
            >
              {colAndSpanSelectValues.map((num, index) => (
                <MenuItem key={`breakpoint-editor-span-select-${index}`} value={num}>
                  {num}
                </MenuItem>
              ))}
            </Select>
          </AccordionDetails>

          <AccordionDetails>
            <Typography>SpanAfter factor</Typography>
            <Select
              MenuProps={{
                style: { zIndex: 2500 },
              }}
              size="small"
              disabled={!layoutState.hasOwnProperty(breakpoint.code)}
              value={spanAfterSelectValue}
              onChange={(e) => setBreakpointParams("spanAfter", e.target.value, breakpoint.code)}
            >
              {colAndSpanSelectValues.map((num, index) => (
                <MenuItem key={`breakpoint-editor-span-after-select-${index}`} value={num}>
                  {num}
                </MenuItem>
              ))}
            </Select>
          </AccordionDetails>

          <AccordionDetails>
            <Typography>Activar el breakpoint</Typography>
            <Switch checked={!!layoutState.hasOwnProperty(breakpoint.code)} onChange={(e) => setBreakPointDisabled(breakpoint.code, e.target.checked)} />
          </AccordionDetails>
        </div>
      </Accordion>
    );
  });

  return <div className={classes.editLayoutContent}>{editors}</div>;
}
