import React, { useCallback, useMemo } from 'react';
import { List, Paper, Accordion, AccordionSummary, Typography, AccordionDetails, Grid } from '@material-ui/core';
import { makeStyles, useTheme, createStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import { DraggableItemList } from './DraggableItemList';
import { AppThemeType } from '../../../../../../../theme';
import { FieldTypeManager } from '../fields';
import { FieldType } from '../fields/manager/FieldType';
import { useEditorScreenContext } from '../../../EditorScreenContext';
import { StubbornField, StubbornScreen } from '../../../../../../business/StubbornAsset';
import { BaseAppConst } from '../../../../../../const/BaseAppConst';
import { getAssetByAssetSelector } from '../../../../../../utils';
import { HIDDEN_SCREENS, toolboxCategories } from '../../../constants';

const useStyles = makeStyles<AppThemeType>((theme) =>
  createStyles({
    drawer: {
      width: '100%',
      flexShrink: 0,
      maxHeight: '95%',
      overflowY: 'auto',
      overflowX: 'hidden',
      backgroundcolor: '#2F343D',
    },
    drawerPaper: {
      width: '100%',
    },
    groupTitle: {
      margin: '0 10px 0 5px',
      fontWeight: 'bold',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 10,
      marginLeft: 10,
    },
    icons: { display: 'flex', padding: 10, height: 40, width: 40, justifyContent: 'center', alignContent: 'center', backgroundColor: '#1976D2' },
  }),
);

export interface BoxProps {
  name: string;
}

function EditorToolboxRaw() {
  const theme = useTheme();
  //@ts-ignore
  const classes = useStyles(theme);
  const fields = useMemo(() => FieldTypeManager.getRegisteredFields()?.sort((a, b) => a.toolbox.category.localeCompare(b.toolbox.category)), []);

  const { addAsset, assetSelector, editable, screen, parentSelector } = useEditorScreenContext();

  const onAssetClick = useCallback(
    (field: FieldType) => {
      let asset: StubbornField | StubbornScreen;
      if (field.name === 'container' || field.name === 'sterContainer') {
        asset = {
          type: 'Screen',
          name: `${field.name}${Math.floor(Math.random() * 10)}`,
          attributes: {
            ...FieldTypeManager.getDefaultAttributes(field.name),
          },
          metadata: [],
          tags: [],
        };
      } else if (Object.values(HIDDEN_SCREENS).includes(field.name as HIDDEN_SCREENS)) {
        asset = {
          type: 'Screen',
          name: `${field.name}${Math.floor(Math.random() * 10)}`,
          attributes: {
            ...FieldTypeManager.getDefaultAttributes(field.name),
          },
          metadata: [],
          tags: [],
          fieldType: field.name,
        };
      } else {
        asset = {
          type: 'Field',
          name: `${field.name}${Math.floor(Math.random() * 10)}`,
          attributes: {
            ...FieldTypeManager.getDefaultAttributes(field.name),
          },
          fieldType: field.name,
          tags: [],
        };
      }
      addAsset(asset, assetSelector);
    },
    [addAsset, assetSelector],
  );

  // @ts-ignore
  const isDialogScreen = useMemo(() => Object.values(HIDDEN_SCREENS).includes((getAssetByAssetSelector(screen, parentSelector) as StubbornField).fieldType), [screen, parentSelector]);

  return editable ? (
    <Box className={classes.drawer}>
      <Paper className={classes.drawerPaper}>
        {toolboxCategories.map((category, index) => (
          <Accordion key={`toolbox-group-${index}`} style={{ margin: 0, padding: 0, backgroundColor: '#2F343D', color: 'white' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white', margin: 0, padding: 0 }} />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography className={classes.groupTitle}>{category}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              <Grid container component="nav" className={classes.container} aria-label="main mailbox folders">
                {fields
                  .filter((field) => field.toolbox.category === category)
                  .map((field) => {
                    // @ts-ignore
                    const isDialogField = Object.values(HIDDEN_SCREENS).includes(field?.name);
                    const disabled = isDialogScreen && isDialogField;
                    return <DraggableItemList id={field.toolbox.name} key={field.toolbox.name} name={field.toolbox.name} disabled={disabled} field={field} label={field.toolbox.label} image={field.toolbox.image} onClick={(field) => onAssetClick(field)} />;
                  })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>
    </Box>
  ) : (
    <></>
  );
}

export const EditorToolbox = EditorToolboxRaw;
