import {FieldType} from "../../manager/FieldType";
import {CardList} from "./CardList";
import {attributeGroups} from "./attributeGroups";
import {toolbox} from "./toolbox";
import {FieldTypeManager} from "../../manager/FieldTypeManager";

export const cardListFieldType: FieldType = {
  name: 'cardList',
  component: CardList,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(cardListFieldType.name, cardListFieldType);
