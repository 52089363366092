import { StubbornAttribute, StubbornAttributeGroup } from '../../../../../../../../business/StubbornAttributeMapping';

import { basicAttributeGroup, makeComponentAttributeGroup, makeEventAttributeGroup } from '../../commons/attributes';

const attributes = new Set<StubbornAttribute>();
attributes.add({
  label: 'Label',
  name: 'label',
  type: 'string',
  default: '',
});
attributes.add({
  label: 'Checked',
  name: 'checked',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Default Checked',
  name: 'defaultChecked',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Disabled',
  name: 'disabled',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Color',
  name: 'color',
  type: 'select',
  default: 'primary',
  list: ['primary', 'secondary', 'success', 'error', 'info', 'warning'],
});
attributes.add({
  label: 'Size',
  name: 'size',
  type: 'select',
  default: 'medium',
  list: ['small', 'medium'],
});
attributes.add({
  label: 'Required',
  name: 'required',
  type: 'boolean',
  default: false,
});

const eventAttributes: StubbornAttribute[] = [
  {
    label: 'OnChange',
    name: 'onChange',
    type: 'code',
    default: '',
  },
];

export const attributeGroups: StubbornAttributeGroup[] = [basicAttributeGroup, makeComponentAttributeGroup(attributes), makeEventAttributeGroup(eventAttributes)];
