import _ from 'lodash';
import React, { useMemo } from 'react';
import { StubbornField } from '../../../../../../../../business/StubbornAsset';
import { securedJSRunner } from '../../../../../../../../utils/functionHelpers';
import { FieldTypeManager } from '../../../fields';
import { NoFieldTypeRegistered } from '../../NoFieldTypeRegistered';
import { useScreenDAOContext } from '../../../context/ScreenDAOContext';
import { useRouterDAOContext } from '../../../context/RouterDAOContext';
import { useCurrentBreakpoint } from '../../../../../../../../commons/hooks';
import { processGroupStyles } from '../../../fields/utils';
import { useProjectDAOContext } from '../../../context/ProjectDAOContext';
import { AnyObject } from '../../../../../../../../commons/types';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';

export interface FieldRendererProps extends StubbornField {
  Context?: AnyObject;
}

const dummyObj = {};

// TODO: definir bien los atributos
export const FieldRenderer = ({ Context = dummyObj, ...props }: FieldRendererProps) => {
  const { editable } = useEditorScreenContext();
  const { ScreenDAO, params } = useScreenDAOContext();
  const { ProfileDAO } = useRouterDAOContext();
  const { ProjectDAO } = useProjectDAOContext();
  const FieldType = FieldTypeManager.get(props.fieldType);
  const Comp = FieldType?.component || NoFieldTypeRegistered;
  const defaultValues = FieldTypeManager.getDefaultAttributes(props.fieldType);

  const formattedAttributes = useMemo(() => {
    let fullAttributes: AnyObject = {};
    Object.keys(defaultValues).forEach((attributeGroup) => {
      fullAttributes = {
        ...fullAttributes,
        [attributeGroup]: {
          ...defaultValues[attributeGroup],
          ...props.attributes[attributeGroup],
        },
      };
    });
    // doing interpolation of strings
    const attributesTypes = FieldTypeManager.getAttributesTypes(props.fieldType);
    Object.keys(fullAttributes).forEach((attributeGroup) => {
      Object.keys(fullAttributes[attributeGroup]).forEach((attributeName) => {
        const type = attributesTypes[attributeGroup][attributeName];
        const value = fullAttributes[attributeGroup][attributeName];
        if (type === 'string') {
          const compiled = _.template(value);
          fullAttributes[attributeGroup][attributeName] = compiled({
            SBScreen: ScreenDAO,
            ...fullAttributes,
            SBContext: { ...Context, Params: params },
          });
        }
      });
    });

    return fullAttributes;
  }, [ScreenDAO, Context, defaultValues, props.attributes, props.fieldType, params]);

  const eventFormatted = useMemo(() => {
    let events = {};
    if (!editable) {
      props.attributes.events &&
        Object.keys(props.attributes.events).forEach((event) => {
          if (!!props.attributes.events[event]) {
            events = {
              ...events,
              [event]: (e: React.EventHandler<any>) => securedJSRunner(props.attributes.events[event], e, ScreenDAO, ProfileDAO?.RouterDAO, ProjectDAO.services, ProjectDAO, { ...Context, Params: params }),
            };
          }
        });
    }
    return events;
  }, [ProjectDAO, ScreenDAO, ProfileDAO?.RouterDAO, editable, props.attributes.events, Context, params]);

  const bp = useCurrentBreakpoint();
  // eslint-disable-next-line no-eval
  const renderCondition = useMemo(() => eval(formattedAttributes.basic.renderConditions || true), [formattedAttributes]);

  const componentStyles = useMemo(() => {
    const styles = _.get(formattedAttributes, 'basic.styles');
    return processGroupStyles(styles, bp, renderCondition);
  }, [formattedAttributes, bp, renderCondition]);

  return !renderCondition && !editable ? null : <Comp {...formattedAttributes} events={eventFormatted} styles={componentStyles} asset={props} Context={Context} Params={params} />;
};
