import { StubbornAttribute, StubbornAttributeGroup } from '../../../../../../../../business/StubbornAttributeMapping';

import { basicAttributeGroup, makeComponentAttributeGroup, makeEventAttributeGroup } from '../../commons/attributes';

const attributes = new Set<StubbornAttribute>();
attributes.add({
  label: 'Title',
  name: 'title',
  type: 'string',
  default: 'Novatech',
});
attributes.add({
  label: 'Link',
  name: 'href',
  type: 'string',
  default: 'https://www.novatech.com.ar',
});
attributes.add({
  label: 'Target',
  name: 'target',
  type: 'select',
  default: '_blank',
  list: ['_blank', '_self'],
});
attributes.add({
  label: 'Variant',
  name: 'variant',
  type: 'select',
  default: 'body1',
  list: ['body1', 'body2', 'button', 'caption', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'inherit', 'overline', 'subtitle1', 'subtitle2'],
});
attributes.add({
  label: 'Underline',
  name: 'underline',
  type: 'select',
  default: 'always',
  list: ['always', 'hover', 'none'],
});

export const attributeGroups: StubbornAttributeGroup[] = [basicAttributeGroup, makeComponentAttributeGroup(attributes), makeEventAttributeGroup()];
