import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import {NavLink, useLocation} from 'react-router-dom';
import {createStyles, makeStyles} from "@material-ui/styles";
import HomeIcon from '@material-ui/icons/Home';
import MiscellaneousServicesIcon from '@material-ui/icons/MiscellaneousServices';
import AppRegistrationIcon from '@material-ui/icons/AppRegistration';
import {AppThemeType} from "../../../../theme";
import {BaseAppConst} from "../../../const/BaseAppConst";
import {SIDEMENU_ID} from "../../../../platform/permission/const";
import {useStubbornMenuContext} from "../../../context";
import palette from "../../../../theme/palette";
import {usePermissionSideFilter} from "../../../../lib/phinxapp/permission";

const useStyles = makeStyles<AppThemeType>((theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: BaseAppConst.drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: BaseAppConst.drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    closeDrawer: {
      position: 'absolute',
      right: 0,
      top: 60
    },
    selectedIcon: {
      textDecoration: 'none',
      height: 37,
      display: 'flex',
      paddingLeft: 25,
      alignItems: 'center',
      justifyContent: 'flex-start',
      color: palette.primary.dark,
      width: "100% !important"
    },
    defaultIcon: {
      textDecoration: 'none',
      height: 37,
      paddingLeft: 25,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      color: palette.text.primary,
      width: "100% !important"
    },
    titleStyle: {
      transition: '0.5s',
      fontSize: 13,
      paddingLeft: 15,
      textDecoration: 'none',
      color: palette.text.primary,
    },
    selectedTitle: {
      transition: '0.5s',
      fontSize: 13,
      paddingLeft: 15,
      textDecoration: 'none',
      color: palette.primary.dark,
    },
  }),
);

const items = [
  {
    id: SIDEMENU_ID.DASHBOARD,
    title: 'Dashboard',
    icon: <HomeIcon titleAccess="Dashboard"/>,
    path: '/',
  },
  {
    id: SIDEMENU_ID.EDITOR,
    title: 'Editor',
    icon: <AppRegistrationIcon titleAccess="Editor"/>,
    path: '/editor',
  },
  {
    id: SIDEMENU_ID.CONFIGURATION,
    title: 'Configuration',
    icon: <MiscellaneousServicesIcon titleAccess="Configuration"/>,
    path: '/configuration',
  },
];

export const AppMenu = () => {
  const classes = useStyles();
  const {isMenuOpen, toggleMenu} = useStubbornMenuContext();
  const location = useLocation();
  const menuItems = usePermissionSideFilter(items);

  return !isMenuOpen ? null : (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{paper: classes.drawerPaper}}
    >
      <Toolbar/>
      <div className={classes.drawerContainer}>
        {menuItems.map((item, index) => (
          <ListItem
            button
            key={index}
            disableGutters
            className={classes.iconContainer}
            onClick={() => {
              toggleMenu()
            }}
          >
            <NavLink
              to={item.path}
              className={
                item.path === location.pathname
                  ? classes.selectedIcon
                  : classes.defaultIcon
              }
            >
              {item.icon}
              <Typography
                className={
                  item.path === location.pathname
                    ? classes.selectedTitle
                    : classes.titleStyle
                }
              >
                {item.title}
              </Typography>
            </NavLink>
          </ListItem>
        ))}
      </div>
    </Drawer>
  );
};
