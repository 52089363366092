import {StubbornAttribute, StubbornAttributeGroup} from "../../../../../../../../business/StubbornAttributeMapping";

import { basicAttributeGroup, makeComponentAttributeGroup, makeEventAttributeGroup } from "../../commons/attributes";

const attributes = new Set<StubbornAttribute>();
attributes.add({
  label: 'Avatar source',
  name: 'src',
  type: 'string',
  default: ''
});
attributes.add({
  label: 'Variante',
  name: 'variant',
  type: 'select',
  default: 'circular',
  list: ['circular', 'rounded', 'square']
});
attributes.add({
  label: 'Descripción de Avatar',
  name: 'alt',
  type: 'string',
  default: 'Por defecto'
});

export const attributeGroups: StubbornAttributeGroup[] = [
  basicAttributeGroup,
  makeComponentAttributeGroup(attributes),
  makeEventAttributeGroup(),
];

