import { AssetView } from '../types';
import { CloneAction, RemoveAction } from '../actions';
import { BasicInformationPanel, ServiceRelatedAssetsPanel } from '../panels';

export const ServiceAssetView: AssetView = {
  type: 'Service',
  panels: [
    {
      label: 'Basic',
      component: BasicInformationPanel,
    },
    {
      label: 'Related Assets',
      component: ServiceRelatedAssetsPanel,
    },
  ],
  actions: [CloneAction, RemoveAction],
};
