import React, { useEffect, Fragment, useState } from 'react';
import { Grid } from '@material-ui/core';
import { CardProps } from '../Card/Card';
import { CardListItem } from './CardListItem';
import { useServicesDAO } from '../../../hooks';
import { AnyObject } from '../../../../../../../../../lib/phinxapp/profiles/types';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';

export interface CardListProps extends CardProps {}

export const CardList = ({ events, states, asset, datasource }: CardListProps) => {
  const { ServicesDAO } = useServicesDAO();
  const { screen } = useEditorScreenContext();
  const [intermediateItems, setIntermediateItems] = useState<Array<AnyObject>>(states.items);

  useEffect(() => {
    if (!datasource.enabled) {
      setIntermediateItems(states.items);
    }
  }, [states.items, datasource.enabled]);

  useEffect(() => {
    (async () => {
      if (datasource?.enabled) {
        let query = {};
        datasource.service?.params?.keys.forEach((key, index) => {
          query = {
            ...query,
            [key]: datasource.service?.params?.values[index],
          };
        });
        // @ts-ignore
        const res = await ServicesDAO?.[datasource.service?.serviceName]?.(query);
        if (res) {
          const result = res.data[datasource.service?.path || ''];
          setIntermediateItems(Array.isArray(result) ? result : []);
        }
      } else {
        setIntermediateItems([{}]);
      }
    })();
  }, [datasource, ServicesDAO, screen]);

  useEffect(() => {
    if (events.onMount) events.onMount();

    if (events.onUnmount)
      return () => {
        events.onUnmount();
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!Array.isArray(states.items)) {
    return <p>Items must be an array</p>;
  }

  // TODO: handlear que mostrar cuando esta en modo editable y cuando esta modo vacio
  return (
    <Grid container>
      {!intermediateItems?.length && <p>Empty card list</p>}
      {intermediateItems.map((item, index) => (
        <Fragment key={index}>
          <CardListItem item={item} index={index} listAsset={asset} />
        </Fragment>
      ))}
    </Grid>
  );
};
