import {useApiEndpoint} from "./useApiEndpoint";
import {useCallback, useEffect, useRef, useState} from "react";
import {QueryObject} from "../../RESTEndpoint";

export interface ApiFindOptions {
  query?: QueryObject;
  autoCall?: boolean;
}

export interface ApiFindReturn<T> {
  data: T[] | null | undefined;
  error?: any;
  loading: boolean;
  call: (opts?: Omit<ApiFindOptions, 'autoCall'>) => Promise<T[] | null | undefined>;
}

export const useApiFind = <T = any>(path: string, options: ApiFindOptions = {}): ApiFindReturn<T> => {
  const ServiceEndpoint = useApiEndpoint<T>(path);
  // Refs
  const queryOption = useRef<QueryObject | undefined>(options.query);
  const autoCallOption = useRef<boolean>(options.autoCall !== undefined ? options.autoCall : true);

  // Inner state
  const [data, setData] = useState<T[] | null | undefined>(undefined);
  const [error, setError] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const call = useCallback(async (opts: Omit<ApiFindOptions, 'autoCall'> = {}) => {
    const { query } = opts
    try {
      setLoading(true);
      const queryToUse = query || queryOption.current;
      const response = await ServiceEndpoint.find(queryToUse);
      setError(undefined);
      setData(response.data);
      return response.data;
    } catch (e: any) {
      setData(undefined);
      setError(e.error);
      throw e;
    } finally {
      setLoading(false);
    }
  }, [ServiceEndpoint]);

  useEffect(() => {
    if (autoCallOption.current) {
      call();
    }
  }, [call]);

  return { data, loading, error, call };
};
