import React, { useMemo } from 'react';
import { usePermissionContext } from '../permissionContext';

export const PermissionBlocker = ({
  children,
  id,
}: {
  children: JSX.Element;
  id: number | string;
}) => {
  const { activeRules } = usePermissionContext();

  const isBlock = useMemo(() => {
    return !!activeRules.blockers.find((blocker) => blocker.id === id);
  }, [activeRules, id]);

  return <>{isBlock ? null : children}</>;
};
