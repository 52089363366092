// import {StubbornAttribute} from "../../../business/StubbornAttributeMapping";
import {BaseAttributeEditorProps} from "./editors/BaseAttributeEditorProps";
import { BaseAttributeViewProps } from "./views/BaseAttributeViewProps";

export type EditorAttributeEditor = (props:BaseAttributeEditorProps) => JSX.Element;
export type EditorAttributeView = (props:BaseAttributeViewProps) => JSX.Element;

class EditorPropertiesAttributesEditorManager {
    protected editors = new Map<string,EditorAttributeEditor>();
    protected views = new Map<string,EditorAttributeView>();

    get(name:string):EditorAttributeEditor {
        const editor = this.editors.get(name);
        if (!editor) throw new Error(`Editor not found`);
        return editor;
    }

    getView(name:string):EditorAttributeView {
        const view = this.views.get(name);
        if (!view) throw new Error(`View not found`);
        return view;
    }

    register(name:string,editor:EditorAttributeEditor, view: EditorAttributeView):void {
        this.editors.set(name,editor);
        this.views.set(name,view);
    }


}

const i = new EditorPropertiesAttributesEditorManager();
export {i as EditorPropertiesManager};
