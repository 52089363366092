import React, {useEffect} from 'react';
import {useProfileContext} from "../../../lib/phinxapp/profiles/ProfileContext";
import {Loading} from "../../components";

export const Initial = () => {
  const profile = useProfileContext();

  useEffect(() => {
    if (profile) {
      const token = localStorage.getItem('stubborn:logged:account');
      if (token) {
        profile.setProfile('private2');
      } else {
        profile.setProfile('public');
      }
    }
  }, [profile]);
  return (
    <Loading />
  );
};
