import {FieldType} from "../../manager/FieldType";
import {Icon} from "./Icon";
import {attributeGroups} from "./attributeGroups";
import {toolbox} from "./toolbox";
import {FieldTypeManager} from "../../manager/FieldTypeManager";

export const iconFieldType: FieldType = {
  name: 'icon',
  component: Icon,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(iconFieldType.name, iconFieldType);
