import React, { useCallback } from 'react';
import { Typography, Button } from '@material-ui/core';
import { StubbornServiceAction } from '../../../../../../../business/StubbornAsset';
import { StubbornAttribute } from '../../../../../../../business/StubbornAttributeMapping';
import { EditorPropertiesDataGrid } from '../../../ScreenEditor/properties/EditorPropertiesDataGrid';
import { AnyObject } from '../../../../../../../commons/types';
import { useRowsFromAttributes } from '../../../../../../../commons/hooks/useRowsFromAttributes';
import { makeStyles } from '@material-ui/styles';
import { AppThemeType } from '../../../../../../../../theme';
// import { SearchInput } from '../../../../../Dashboard/components/AssetList/components/Toolbar/components';

const attributes = new Set<StubbornAttribute>();
attributes.add({
  name: 'name',
  type: 'string',
  label: 'Nombre',
});
attributes.add({
  name: 'datasource',
  type: 'datasource',
  label: 'Datasource',
  default: '',
});
attributes.add({
  name: 'script',
  type: 'SQL',
  label: 'Script',
  default: '',
});
attributes.add({
  name: 'condition',
  type: 'code',
  label: 'Condicion',
  default: '',
});
attributes.add({
  name: 'hasResponse',
  type: 'boolean',
  label: 'Tiene respuesta',
  default: true,
});
attributes.add({
  name: 'isList',
  type: 'boolean',
  label: 'Es una lista',
  default: true,
});
attributes.add({
  name: 'mapper',
  type: 'breakpoints',
  label: 'Mapper',
  default: [],
  metadata: {
    dataType: 'mapper',
  },
});
// Usable only for specific Actions (HttpCall)
attributes.add({
  name: 'options',
  type: 'actionOptions',
  label: 'Options',
  default: {},
  metadata: {
    url: {
      control: 'input',
      type: 'string',
      label: 'URL',
    },
    method: {
      control: 'select',
      type: 'string',
      label: 'Método HTTP',
      options: ['GET', 'POST', 'PUT', 'DELETE', 'PATCH'],
    },
  },
});

export interface ActionEditorProps {
  action: StubbornServiceAction;
  onActionChange: (newAction: StubbornServiceAction) => void;
  onRemoveClick: () => void;
}

const useStyles = makeStyles<AppThemeType>(() => ({
  actionTitle: {
    color: 'white',
    fontWeight: 600,
    padding: 10,
  },
  actionSubTitle: {
    color: 'white',
    fontWeight: 600,
    padding: 10,
  },
}));

export const ActionEditor = ({ action, onActionChange, onRemoveClick }: ActionEditorProps) => {
  const rows = useRowsFromAttributes(attributes, action, action.type);
  const classes = useStyles();

  const onChange = useCallback(
    (newRows: AnyObject[]) => {
      const newAction: StubbornServiceAction = { ...action };
      newRows.forEach((item) => {
        // @ts-ignore
        newAction[item.prop as string] = item.value as any;
      });
      onActionChange(newAction);
    },
    [onActionChange, action],
  );

  return (
    <>
      {/* <SearchInput search={search} onSearchChange={setSearch} key={search} fontColor="white" /> */}
      <Typography className={classes.actionTitle}>{action.type}</Typography>
      <EditorPropertiesDataGrid initialRows={rows} key={action.type} onChange={onChange} />
      <Button onClick={onRemoveClick}>Eliminar</Button>
    </>
  );
};
