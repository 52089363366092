import { AssetView } from '../types';
import { CloneAction } from '../actions/CloneAction';
import { SessionConfigurationPanel } from '../panels';

export const SessionConfigurationAssetView: AssetView = {
  type: 'SessionConfiguration',
  panels: [
    {
      label: 'Session Config',
      component: SessionConfigurationPanel,
    },
  ],
  actions: [CloneAction],
};
