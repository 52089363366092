import {HttpService, HttpServiceConfig} from "./HttpService";
import {EndpointManager} from "./EndpointManager";
import {TokenManager, WebTokenManager} from "./TokenManager";
import {AnyObject} from "../../application/commons/types";

export interface SessionConfiguration {
  name: string;
  header: string;
}

export type HttpServiceConstructor = new (initialConfig?: Partial<HttpServiceConfig>) => HttpService;

export abstract class Api {
  protected baseUrl: string;
  protected options: Omit<HttpServiceConfig, 'baseUrl'>;
  protected _httpService?: HttpService;
  protected endpointManager = new EndpointManager();
  protected tokenManager: TokenManager = new WebTokenManager();
  protected sessionTokens: Map<string, string> = new Map<string, string>();
  protected sessionConfigurationsMap: Map<string, SessionConfiguration> = new Map<string, SessionConfiguration>();

  constructor(baseUrl: string, options?: Omit<HttpServiceConfig, 'baseUrl'>) {
    this.baseUrl = baseUrl;
    this.options = options || {};
    this.init();
  }

  set SessionConfiguration(configuration: SessionConfiguration[]) {
    this.sessionConfigurationsMap.clear();
    for (const config of configuration) {
      this.sessionConfigurationsMap.set(config.name, config);
    }
  }

  async setSessionToken(tokens: Array<{name: string, token: string}>) {
    for (const { name, token} of tokens) {
      const config = this.sessionConfigurationsMap.get(name);
      if (!config) {
        throw new Error(`Config for token session ${name} not found`);
      }
      await this.tokenManager.persist(name, token);
    }
    this.setSessionHeaders();
  }

  async addSessionToken(name: string, token: string) {
    const config = this.sessionConfigurationsMap.get(name);
    if (!config) {
      throw new Error(`Config for token session ${name} not found`);
    }
    await this.tokenManager.persist(name, token);
    this.setSessionHeaders();
  }

  get httpService() {
    if (!this._httpService) {
      throw new Error('Http Service not configured properly');
    }
    return this._httpService;
  }

  async loadSessionTokens() {
    this.sessionTokens.clear();
    for (const config of this.sessionConfigurationsMap.values()) {
      const token = await this.tokenManager.retrieve(config.name);
      if (token) {
        this.sessionTokens.set(config.name, token);
      }
    }
    this.setSessionHeaders();
  }

  setSessionHeaders() {
    if (this._httpService) {
      const headers: AnyObject = {...this._httpService.Headers};
      for (const config of this.sessionConfigurationsMap.values()) {
        const token = this.sessionTokens.get(config.name);
        if (token) {
          headers[config.header] = token;
        } else {
          delete headers[config.header];
        }
      }
      this._httpService.Headers = headers;
    }
  }

  async clearSessionTokens() {
    await this.tokenManager.cleanAll();
    this.sessionTokens.clear();
    this.setSessionHeaders();
  }

  private init() {
    const HttpConfiguredService = this.getHttpService();
    this._httpService = new HttpConfiguredService({ baseUrl: this.baseUrl, ...this.options });
    this.config();
    this.loadSessionTokens();
  }

  // TODO: manegar el resto de los parametros
  protected registerEndpoint(path: string) {
    this.endpointManager.register(path);
    return this;
  }

  getEndpoint(path: string) {
    // TODO: manejar las opciones
    const [RESTEndpointConfigured] = this.endpointManager.get(path);
    const instance = new RESTEndpointConfigured(path);
    instance.httpService = this.httpService;
    return instance;
  }

  protected abstract getHttpService(): HttpServiceConstructor;
  protected abstract config(): void;
}
