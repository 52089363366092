import { StubbornAsset, StubbornScreen } from './StubbornAsset';

export interface StubbornRouteEvents {
  // ver qcomo nombrear estas vergas
  beforeMount?: string;
  onMount?: string;
  beforeRouteChange?: string;
  onRouteChange?: string;
}

export interface StubbornRouteCondition {
  condition?: string; // esto es una expresion que devuelva true o false
  router: string; //  router name. ej: publico.nahnahnah
  params?: object; // en caso de necesitarlo
}

export interface StubbornRoute {
  name: string;
  path?: string;
  target: string; // el string puede ser un nombre de otro Router
  type: "Router" | "Route"
}

export enum StubbornRouteKey {
  ROUTER = "Router",
  ROUTE = "Route"
}

export interface StubbornRouter extends StubbornAsset {
  name: string;
  type: 'Router',
  basePath?: string;
  layout?: StubbornScreen; // <== StubbornScreen con target
  layoutTargetName?: string; // StubbornScreen2.StubbornScreen35.elTargetStubbornScreenADondeSeVaAMontar
  routes: StubbornRoute[];
  redirect?: StubbornRouteCondition[];
  events?: StubbornRouteEvents; // code
}
