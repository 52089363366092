import React, { useCallback, useEffect, useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import { useDebounce } from '../../../../../../../../../commons/hooks';

export interface SearchInputProps {
  search: string;
  onSearchChange: (search: string) => void;
}

export const SearchInput = ({ search, onSearchChange }: SearchInputProps) => {
  const [searchState, setSearchState] = useState(search);
  const searchDebounced = useDebounce(searchState, 300);

  const onSearchInputChange = useCallback((event) => {
    setSearchState(event.target.value);
  }, []);

  useEffect(() => {
    if (search !== searchDebounced) {
      onSearchChange(searchDebounced);
    }
  }, [onSearchChange, search, searchDebounced]);

  return (
    <TextField
      label=""
      value={searchState}
      onChange={onSearchInputChange}
      InputProps={{
        startAdornment: <InputAdornment position="start">Search:</InputAdornment>,
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
