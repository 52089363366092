import { FieldType } from '../../manager/FieldType';
import { BreadCrumb } from './BreadCrumb';
import { attributeGroups } from './attributeGroups';
import { toolbox } from './toolbox';
import { FieldTypeManager } from '../../manager/FieldTypeManager';

export const breadCrumbFieldType: FieldType = {
  name: 'breadcrumb',
  component: BreadCrumb,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(breadCrumbFieldType.name, breadCrumbFieldType);
