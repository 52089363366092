import {FieldType} from "../../manager/FieldType";
import {Link} from "./Link";
import {attributeGroups} from "./attributeGroups";
import {toolbox} from "./toolbox";
import {FieldTypeManager} from "../../manager/FieldTypeManager";

export const linkFieldType: FieldType = {
  name: 'link',
  component: Link,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(linkFieldType.name, linkFieldType);
