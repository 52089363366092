import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { NewDatasourceDialog } from './dialogs';
import { useDialogState } from '../../../../../../../../../../commons/hooks';
import { StubbornDatasourceKind, StubbornDatasourceType } from '../../../../../../../../../../business/StubbornAsset';

export interface AddDatasourceMenuProps {
  onCreateDatasource: (name: string, kind: StubbornDatasourceKind, type: StubbornDatasourceType, host: string, port: number, user: string, password: string, database: string, onconnect: string) => Promise<void>;
}

export const AddDatasourceMenu = ({ onCreateDatasource }: AddDatasourceMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = !!anchorEl;
  const [open, onOpen, onClose] = useDialogState(false);
  const [openRedis, onOpenRedis, onCloseRedis] = useDialogState(false);
  return (
    <>
      <IconButton
        color="primary"
        size="large"
        title="Nuevo"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <AddIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            onOpen();
          }}
        >
          SQL
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            onOpenRedis();
          }}
        >
          Redis
        </MenuItem>
      </Menu>
      <NewDatasourceDialog open={open} onClose={onClose} onCreateDatasource={onCreateDatasource} defaultData={{ kind: StubbornDatasourceKind.SQL }} />
      <NewDatasourceDialog open={openRedis} onClose={onCloseRedis} onCreateDatasource={onCreateDatasource} defaultData={{ kind: StubbornDatasourceKind.REDIS, type: StubbornDatasourceType.REDIS }} />
    </>
  );
};
