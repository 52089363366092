import {MenuCollection} from "../../../lib/phinxapp/profiles/types";
import {routes} from "./routes";


export const private2Menu: MenuCollection = [
  {
    key: 'private2',
    basePath: '/',
    enabled: true,
    icon: 'List',
    text: 'Private2',
    children: routes,
    default: true,
  },
];
