import {StubbornAttribute, StubbornAttributeGroup} from "../../../../../../../../business/StubbornAttributeMapping";

import { basicAttributeGroup, makeStateAttributeGroups, makeComponentAttributeGroup, makeEventAttributeGroup } from "../../commons/attributes";

const attributes = new Set<StubbornAttribute>();

attributes.add({
  label: 'Label',
  name: 'label',
  type: 'string',
  default: "",
});
attributes.add({
  label: 'Placeholder',
  name: 'placeholder',
  type: 'string',
  default: "",
});
attributes.add({
  label: 'Default value',
  name: 'defaultValue',
  type: 'string',
  default: "",
});
attributes.add({
  label: 'Variant',
  name: 'variant',
  type: 'select',
  default: 'standard',
  list: ['filled', 'outlined', 'standard']
});
attributes.add({
  label: 'Size',
  name: 'size',
  type: 'select',
  default: 'medium',
  list: ['medium', 'small']
});
attributes.add({
  label: 'Color',
  name: 'color',
  type: 'select',
  default: 'primary',
  list: ['primary', 'secondary', 'error', 'info', 'success', 'warning']
});
attributes.add({
  label: 'Disabled',
  name: 'disabled',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Autofocus',
  name: 'autofocus',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Full width',
  name: 'fullWidth',
  type: 'boolean',
  default: true,
});
attributes.add({
  label: 'Multiline',
  name: 'multiline',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Min rows',
  name: 'minRows',
  type: 'string',
  default: "",
});
attributes.add({
  label: 'Max rows',
  name: 'maxRows',
  type: 'string',
  default: "",
});
attributes.add({
  label: 'Margin',
  name: 'margin',
  type: 'select',
  default: 'normal',
  list: ['dense', 'none', 'normal']
});
attributes.add({
  label: 'Controlled',
  name: 'controlled',
  type: 'boolean',
  default: true,
});
attributes.add({
  label: 'InputProps',
  name: 'inputProps',
  type: 'object',
  default: {},
});
attributes.add({
  label: 'InputLabelProps',
  name: 'inputLabelProps',
  type: 'object',
  default: {},
});
attributes.add({
  label: 'Required',
  name: 'required',
  type: 'boolean',
  default: false,
});

const stateAttributes: StubbornAttribute[] = [
  {
    label: "Value",
    name: "value",
    type: 'string',
    default: "",
  },
  {
    label: "Error",
    name: "error",
    type: 'boolean',
    default: false,
  },
  {
    label: "Helper text",
    name: "helperText",
    type: 'string',
    default: "",
  },
];

const eventAttributes: StubbornAttribute[] = [
  {
    label: "OnBlur",
    name: "onBlur",
    type: "code",
    default: "",
  },
  {
    label: "OnChange",
    name: "onChange",
    type: "code",
    default: "",
  }
];

export const attributeGroups: StubbornAttributeGroup[] = [
  basicAttributeGroup,
  makeStateAttributeGroups(stateAttributes),
  makeComponentAttributeGroup(attributes),
  makeEventAttributeGroup(eventAttributes),
];
