import {StubbornAttribute, StubbornAttributeGroup} from "../../../../../../../../business/StubbornAttributeMapping";

import { hiddenFieldsBasicAttributeGroup, makeComponentAttributeGroup, makeCustomAttributeGroup, makeEventAttributeGroup, makeStateAttributeGroups } from "../../commons/attributes";

const attributes = new Set<StubbornAttribute>();
attributes.add({
  label: 'Title',
  name: 'title',
  type: 'string',
  default: ''
});

const actionAttributes = new Set<StubbornAttribute>();
actionAttributes.add({
  label: 'Acciones',
  name: 'actions',
  type: 'collection',
  default: [],
  metadata: {
    dataType: "actions"
  },
});
actionAttributes.add({
  label: 'Style',
  name: 'style',
  type: 'breakpoints',
  default: {},
  metadata: { dataType: 'styles' },
});

const contentAttributes = new Set<StubbornAttribute>();
contentAttributes.add({
  label: 'Style',
  name: 'style',
  type: 'breakpoints',
  default: {},
  metadata: { dataType: 'styles' },
});

const stateAttributes: StubbornAttribute[] = [
  {
    label: "Open",
    name: "open",
    type: 'boolean',
    default: false,
  },{
    label: 'Data',
    name: 'listData',
    type: 'object',
    default: {},
  }
];

const eventAttributes: StubbornAttribute[] = [
  {
    label: "OnClose",
    name: "onClose",
    type: 'code',
    default: "",
  }
];

export const attributeGroups: StubbornAttributeGroup[] = [
  hiddenFieldsBasicAttributeGroup,
  makeStateAttributeGroups(stateAttributes),
  makeComponentAttributeGroup(attributes),
  makeCustomAttributeGroup('Contenido', 'content', contentAttributes),
  makeCustomAttributeGroup('Acciones', 'actions', actionAttributes),
  makeEventAttributeGroup(eventAttributes),
];
