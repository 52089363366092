import {StubbornAttribute, StubbornAttributeGroup} from "../../../../../../../../business/StubbornAttributeMapping";
import { basicAttributeGroup, makeComponentAttributeGroup, makeEventAttributeGroup } from "../../commons/attributes";

const attributes = new Set<StubbornAttribute>();
attributes.add({
  label: 'Entries',
  name: 'entries',
  type: 'collection',
  default: [],
  metadata: {
    dataType: 'accordion'
  },
});
attributes.add({
  label: 'Disabled',
  name: 'disabled',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Expandido por defecto',
  name: 'defaultExpanded',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Desactivar el margen',
  name: 'disableGutters',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Cuadrado',
  name: 'square',
  type: 'boolean',
  default: false,
});

export const attributeGroups: StubbornAttributeGroup[] = [
  basicAttributeGroup,
  makeComponentAttributeGroup(attributes),
  makeEventAttributeGroup(),
];
