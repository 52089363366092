import { StubbornAttribute, StubbornAttributeGroup } from '../../../../../../../../business/StubbornAttributeMapping';

import { basicAttributeGroup, makeComponentAttributeGroup, makeEventAttributeGroup } from '../../commons/attributes';

const attributes = new Set<StubbornAttribute>();
attributes.add({
  label: 'Max Items',
  name: 'maxItems',
  type: 'number',
  default: '5',
});
attributes.add({
  label: 'Separator',
  name: 'separator',
  type: 'string',
  default: '/',
});
attributes.add({
  label: 'Router',
  name: 'router',
  type: 'targetRouter',
  default: [],
});
export const attributeGroups: StubbornAttributeGroup[] = [basicAttributeGroup, makeComponentAttributeGroup(attributes), makeEventAttributeGroup()];
