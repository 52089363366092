import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  RadioGroup,
  FormControlLabel, Radio, Select, Box, Icon, IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LoadingButton from '@material-ui/lab/LoadingButton';
import Editor from '@monaco-editor/react';
import { useEditorServiceContext } from '../../../../EditorServiceContext';
import { useApiFindOne } from '../../../../../../../../lib/HttpService';
import { AnyObject } from '../../../../../../../commons/types';
import MenuItem from '@material-ui/core/MenuItem';
import { useEditionConfigurationSessionFind } from '../../../../../../../../platform/api/endpoints/edition';

export interface PreviewModalProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  modalContent: {
    display: 'flex',
    flex: 1,
    height: 600,
  },
  columnFlex: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
  },
  paramsInput: {
    padding: 10,
  },
  editorNotEditable: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
  },
});

export const PreviewModal = ({ open, onClose }: PreviewModalProps) => {
  const classes = useStyles();
  const { service } = useEditorServiceContext();
  // @ts-ignore
  const {data:dataSession} = useEditionConfigurationSessionFind();
  const { parameters } = service;
  const { data, loading, call, error } = useApiFindOne('/service', { autoCall: false, id: service.name });
  const [params, setParams] = useState<AnyObject>({});
  const [selectedToken, setSelectedToken] = useState<string[]>([]);
  const tokens = localStorage['sb-editors-token'] ?  JSON.parse(localStorage['sb-editors-token']) : '';
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [sessions, setSessions] = useState();
  const [trigger, setTrigger] = useState(false);




  const handleChangeParams = useCallback(
    (event, name: string) => {
      setParams({
        ...params,
        [name]: event.target.value,
      });
    },
    [setParams, params],
  );

  const handleRunQuery = useCallback(() => {
    //@ts-ignore
    let myheaders = {};
     for(let item of selectedSessions){
      console.log('item',item);
      //@ts-ignore
         myheaders[item.token[0]] = item.token[1];
    }
    console.log('headers',myheaders);
    if (selectedSessions.length === 0 ){
    call({ query: params});
    }else {
      //@ts-ignore
     call({ query: params, options: { headers:myheaders }});
    }
  }, [call, params,selectedSessions]);

  const addSession = () => {
    if (selectedSessions.length === 0){
      const obj = {id:Math.floor(Math.random() * (5000 - 1) + 1), session:'',token:[]}
      // @ts-ignore
      setSelectedSessions([obj]);
    } else {
      const obj = {id:Math.floor(Math.random() * (5000 - 1) + 1), session:'',token:[]}
      // @ts-ignore
      setSelectedSessions([...selectedSessions,obj]);
    }
  }

  const updateSession = (id:any,value:any) => {
    const obj = selectedSessions;
    //@ts-ignore
    for(let item of obj){
      //@ts-ignore
      if (item.id === id){
        //@ts-ignore
        item.session = value;
      }
    }
    //@ts-ignore
    setSelectedSessions(obj);
    setTrigger(!trigger);
  }

  const updateToken = (id:any,value:any) => {
    const obj = selectedSessions;
    //@ts-ignore
    for(let item of obj){
      //@ts-ignore
      if (item.id === id){
        //@ts-ignore
        item.token = value;
      }
    }
    //@ts-ignore
    setSelectedSessions(obj);
    setTrigger(!trigger);
  }

  const deleteSelectedSession = (id:any) => {
    const obj = selectedSessions;
    //@ts-ignore
    let filtered = obj.filter(item => item.id !== id)
    setSelectedSessions(filtered);
    setTrigger(!trigger);
  }




  useEffect(()=>{
    console.log('cambio');
  },[selectedSessions]);

  useEffect(()=>{
    if (!sessions){
      //@ts-ignore
      setSessions(dataSession)
    }
  },[dataSession,sessions]);



  const response = useMemo(() => JSON.stringify(data || error, null, '\t'), [data, error]);

  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Preview</DialogTitle>
      <DialogContent>
        <div className={classes.modalContent}>
          <div className={classes.columnFlex} style={{ marginRight: 20 }}>
            <h4 style={{ margin: 0, padding: 10 }}>Parametros</h4>
            {parameters.map(({ name }) => (
              <div className={classes.paramsInput} key={name}>
                <TextField name={name} fullWidth label={name} value={`${params?.[name] || ''}`} onChange={(e) => handleChangeParams(e, name)} />
              </div>
            ))}
            <h4 style={{ margin: 0, padding: 10 }}>Sessions</h4>
            {selectedSessions.length !== 0 && (
              //@ts-ignore
              selectedSessions.map(item=>{
                return (
                  <Box>
                  <Select
                    //@ts-ignore
                    key={item.id}
                  sx={{width:'40%'}}
                  onChange={(e)=> {
                    //@ts-ignore
                    updateSession(item.id, e.target.value);
                  }}
                  >
                  {//@ts-ignore
                    sessions && (
                      //@ts-ignore
                      sessions.map((item)=>{
                      return (
                        <MenuItem value={item.name}>{item.name}</MenuItem>
                      )
                    }))}
                </Select>
                {//@ts-ignore
                  tokens[item.session] && (<Select
                  sx={{ width: '40%' }}
                  onChange={(e)=> {
                    //@ts-ignore
                    updateToken(item.id, tokens[item.session][e.target.value]);
                  }}
                >
                  {
                    //@ts-ignore
                    Object.keys(tokens[item.session]).map((item) => {
                      return (
                        <MenuItem value={item}>{item}</MenuItem>
                      );
                    })
                  }
                </Select>)}
                    {<IconButton onClick={() => {
                      //@ts-ignore
                      deleteSelectedSession(item.id);
                    }}
                    >
                      <Icon style={{ color: 'black', marginLeft:'0' }}>delete_forever</Icon>
                    </IconButton>}
                  </Box>
                  )
              })
            )}
            <Button sx={{marginTop:'15px', width:'185px'}} variant={'outlined'} onClick={()=> addSession()}>Agregar Token</Button>
          </div>
          <div className={classes.columnFlex}>
            <Editor height="100%" width="100%" theme="vs-dark" defaultLanguage="json" value={response || ''} />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
        <LoadingButton variant="contained" onClick={handleRunQuery} loading={loading}>
          Run
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
