import { FieldType } from '../../manager/FieldType';
import { RadioButton } from './RadioButton';
import { attributeGroups } from './attributeGroups';
import { toolbox } from './toolbox';
import { FieldTypeManager } from '../../manager/FieldTypeManager';

export const radioButtonFieldType: FieldType = {
  name: 'radioButton',
  component: RadioButton,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(radioButtonFieldType.name, radioButtonFieldType);
