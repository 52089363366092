import { NodeData } from "../../types";
import {IconButton} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { useAssetContext } from '../../../../../commons/context/AssetContext';


export interface CloseAssetButtonsProps {
    node: NodeData;
  }

export const CloseAssetButton = ({node}: CloseAssetButtonsProps) => {
    const {removeRecentAsset} = useAssetContext();
    return !node.data ? null : (
      <IconButton color="inherit" onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        removeRecentAsset(node.data);
      }}>
        <CloseIcon />
      </IconButton>
    );
  }