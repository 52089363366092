import { useCallback, useState } from 'react';
import { StubbornDatasource, StubbornDatasourceKind, StubbornDatasourceType } from '../../../../../../../business/StubbornAsset';
import { AnyObject } from '../../../../../../../../lib/phinxapp/profiles/types';
import { DatasourcePanelForm } from './form';
import { useAssetContext } from '../../../../context/AssetContext';
import { AssetPanelCompProps } from '../../types';

export interface DatasourcesPanelProps extends AssetPanelCompProps<StubbornDatasource> {}

export const DatasourcesPanel = ({ asset }: DatasourcesPanelProps) => {
  const [defaultData] = useState<StubbornDatasource | AnyObject>({ ...asset });
  const { assetFns, reloadAssets } = useAssetContext();

  const onUpdateDatasource = useCallback(
    async (name: string, kind: StubbornDatasourceKind, type: StubbornDatasourceType, host: string, port: number, user: string, password: string, database: string, onconnect: string) => {
      const newDatasource: StubbornDatasource = {
        name,
        kind,
        type,
        host,
        port: +port,
        user,
        password,
        database,
        onconnect,
      };
      try {
        await assetFns.update(newDatasource.type, newDatasource);
        reloadAssets({});
      } catch (error) {
        console.error(error);
      }
    },
    [assetFns, reloadAssets],
  );

  const onTestDatasource = useCallback(
    async (name: string, kind: StubbornDatasourceKind, type: StubbornDatasourceType, host: string, port: number, user: string, password: string, database: string, onconnect: string) => {
      const newDatasource: StubbornDatasource = {
        name,
        kind,
        type,
        host,
        port: +port,
        user,
        password,
        database,
        onconnect,
      };
      try {
        let response = await fetch(`http://localhost:9099/test/test?name=${name}`);
        let text = await response.text();
        alert(text);
      } catch (error) {
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assetFns, reloadAssets],
  );

  return <DatasourcePanelForm onUpdateDatasource={onUpdateDatasource} onTestDatasource={onTestDatasource} defaultData={defaultData} update />;
};
