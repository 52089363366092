import React, {ReactNode, ReactNodeArray, useCallback, useContext, useMemo, useState} from "react";

export type StubbornMenuContextType = {
  isMenuOpen: boolean,
  toggleMenu: (state?: boolean | undefined) => void,
}

export const StubbornMenuContext = React.createContext<StubbornMenuContextType>({
  isMenuOpen: true,
  toggleMenu: () => {},
});

export type StubbornMenuContextProviderProps = {
  children: ReactNode | ReactNodeArray;
}

export const StubbornMenuContextProvider = ({ children }: StubbornMenuContextProviderProps) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = useCallback((state: boolean | undefined) => {
    state === undefined ? setMenuOpen(!isMenuOpen) : setMenuOpen(state)
  }, [isMenuOpen]);

  const value = useMemo(() => ({
    isMenuOpen,
    toggleMenu,
  }), [isMenuOpen, toggleMenu]);

  return (
    <StubbornMenuContext.Provider value={value}>
      {children}
    </StubbornMenuContext.Provider>
  );
};

export const useStubbornMenuContext = () => useContext<StubbornMenuContextType>(StubbornMenuContext);
