import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ApiFindOptions } from '../../../../../lib/HttpService';
import { useRosterFind } from '../../../../../platform/api/endpoints';
import { useEditionAssetUpdate, useEditionAssetRemove, useEditionAssetCreate } from '../../../../../platform/api/endpoints/edition';
import { StubbornData } from '../../../../business';
import { StubbornAsset, StubbornScreen, StubbornService } from '../../../../business/StubbornAsset';
import { StubbornRouter } from '../../../../business/StubbornRouter';
import { useEditorContext } from '../../../Private/Editor/EditorContext';
import { EditorRouterItem, EditorScreenItem, EditorServiceItem } from '../../../Private/Editor/EditorItem';
import { AssetViewType } from '../components/AssetView/types';

interface Fns<T> {
  create: (item: T, suffix: string) => Promise<any>;
  remove: (id?: string, item?: T) => Promise<any>;
  update: (id?: string, item?: T) => Promise<any>;
}

export interface AssetContextValue {
  assets: StubbornData[];
  loading: boolean;
  selectedAsset?: StubbornData;
  recentAssets: StubbornData[];
  activePanel: string;
  setActivePanel: Dispatch<SetStateAction<string>>;
  removeRecentAsset: (asset: StubbornData) => void | Promise<void>;
  selectAsset: (asset: StubbornData) => void | Promise<void>;
  assetFns: Fns<StubbornData>;
  reloadAssets: (opts?: Omit<ApiFindOptions, 'autoCall'>) => Promise<StubbornData[] | null | undefined>;
}

export const AssetContext = createContext<AssetContextValue>({
  assets: [],
  loading: false,
  selectedAsset: undefined,
  recentAssets: [],
  activePanel: 'assetView',
  setActivePanel: () => {},
  removeRecentAsset: () => {},
  selectAsset: () => {},
  assetFns: {
    create: async () => {},
    remove: async () => {},
    update: async () => {},
  },
  reloadAssets: async () => null,
});

export const availablePanelsByType: Record<string, string[]> = {
  Screen: ['assetView', 'editor'],
  Service: ['assetView', 'editor'],
  Router: ['assetView', 'editor'],
  Datasource: ['assetView'],
  SessionConfiguration: ['assetView'],
};

export interface AssetContextProviderProps {
  children: ReactNode | ReactNode[];
}

export const AssetContextProvider = ({ children }: AssetContextProviderProps) => {
  const { data, loading, call: callRosterFind } = useRosterFind();
  const { addEditor, setOpenSnackbar } = useEditorContext();
  const [selectedAsset, setSelectedAsset] = useState<StubbornData>();
  const [recentAssets, setRecentAssets] = useState<StubbornData[]>([]);
  const [activePanel, setActivePanel] = useState<string>('editor');
  const { call: removeAsset } = useEditionAssetRemove();
  const { call: updateAsset } = useEditionAssetUpdate();
  const { call: createAsset } = useEditionAssetCreate();
  const assetFns = useMemo(() => ({ create: createAsset, remove: removeAsset, update: updateAsset }), [createAsset, removeAsset, updateAsset]);

  const selectAsset = useCallback(
    (asset: StubbornData) => {
      setSelectedAsset(asset);
      const editor = asset.type.toLowerCase() === 'screen' ? new EditorScreenItem(asset as StubbornScreen, () => setOpenSnackbar(true)) : asset.type.toLowerCase() === 'router' ? new EditorRouterItem(asset as StubbornRouter, () => setOpenSnackbar(true)) : new EditorServiceItem(asset as StubbornService, () => setOpenSnackbar(true));
      if (editor) addEditor(editor);
      setRecentAssets((prev) => {
        if (prev.find((item) => item.name === asset.name && item.type === asset.type)) {
          return prev;
        }
        return [...prev, asset];
      });
    },
    [addEditor, setOpenSnackbar],
  );

  const removeRecentAsset = useCallback((asset: StubbornData) => {
    setRecentAssets((prev) => prev.filter((item) => !(item.type === asset.type && item.name === asset.name)));
  }, []);

  useEffect(() => {
    const asset = data?.find((asset: StubbornAsset) => selectedAsset?.name === asset.name && selectedAsset.type === asset.type);
    setSelectedAsset(asset);
  }, [data, selectedAsset?.name, selectedAsset?.type]);

  const value = useMemo<AssetContextValue>(
    () => ({
      assets: !data ? [] : (data as unknown as StubbornData[]),
      loading,
      selectedAsset,
      selectAsset,
      recentAssets,
      removeRecentAsset,
      assetFns,
      reloadAssets: callRosterFind,
      activePanel,
      setActivePanel,
    }),
    [data, loading, selectedAsset, selectAsset, recentAssets, removeRecentAsset, assetFns, callRosterFind, activePanel],
  );

  // checkeamos en el recent si existe en los assets
  useEffect(() => {
    if (data) {
      const assets = data as unknown as StubbornData[];
      setRecentAssets((prev) => prev.filter((item) => assets.find((asset) => asset.type === item.type && asset.name === item.name)));
    }
  }, [data]);

  useEffect(() => {
    if (selectedAsset) {
      const availablePanels = availablePanelsByType[selectedAsset.type] || availablePanelsByType.Datasource;
      if (!availablePanels.includes(activePanel)) {
        setActivePanel(availablePanels[0]);
      }
    }
  }, [selectedAsset, setActivePanel, activePanel]);

  // guardo el recent
  useEffect(() => {
    setTimeout(() => localStorage.setItem('stubborn:asset:recent', JSON.stringify(recentAssets)), 0);
  }, [recentAssets]);
  // restauro
  useEffect(() => {
    const str = localStorage.getItem('stubborn:asset:recent');
    if (str) {
      const saved = JSON.parse(str);
      if (saved && saved.length) {
        setRecentAssets((prev) => {
          if (prev && prev.length) {
            return prev;
          }
          if (saved.length) {
            setSelectedAsset(saved[0]);
          }
          return saved;
        });
      }
    }
  }, []);
  return <AssetContext.Provider value={value}>{children}</AssetContext.Provider>;
};

export const useAssetContext = () => useContext<AssetContextValue>(AssetContext);
