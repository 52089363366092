import {useRosterScreenContextFind} from "../../../../../../../platform/api/endpoints";
import {useEffect, useState} from "react";
import {ScreensAppContext} from "../../../appContext/ScreensAppContext";

export const useScreensDAO = () => {
  const { data: screens, loading } = useRosterScreenContextFind();
  const [ScreensDAO, setScreensDAO] = useState<ScreensAppContext | null>(null);
  useEffect(() => {
    if (screens) {
      setScreensDAO(new ScreensAppContext(screens));
    }
  }, [screens]);
  return { screens, ScreensDAO, loading };
};
