import React, { useCallback, useState } from 'react';
import { GRID_CELL_EDIT_PROPS_CHANGE_COMMITTED, GridCellParams, GridCellValue } from '@material-ui/data-grid';
import {
  BooleanAttributeEditor,
  NumberAttributeEditor,
  TextAttributeEditor,
  AcceptedFilesAttributeEditor,
  SelectAttributeEditor,
  JsonAttributeEditor,
  CodeAttributeEditor,
  SqlAttributeEditor,
  BreakpointPropertyEditor,
  CollectionAttributeEditor,
  DatasourceAttributeEditor,
  IconAttributeEditor,
  ServiceDatasourceAttributeEditor,
  ParamsAttributeEditor,
  TargetScreenAttributeEditor,
  SessionsPickerAttributeEditor,
  ActionOptionsAttributeEditor,
} from './editors';
import { EditorPropertiesManager } from './EditorPropertiesAttributesEditorManager';
import { TextAttributeView, EditorAttributeView, BooleanAttributeView } from './views';
import { TargetRouterAttributeEditor } from './editors/TargetRouterAttributeEditor';

export interface EditPropertyValueProps extends GridCellParams {
  type?: string;
}

export interface EditPropertyViewProps extends GridCellParams {
  type?: string;
}

EditorPropertiesManager.register('string', TextAttributeEditor, TextAttributeView);
EditorPropertiesManager.register('number', NumberAttributeEditor, TextAttributeView);
EditorPropertiesManager.register('boolean', BooleanAttributeEditor, BooleanAttributeView);
EditorPropertiesManager.register('fallback', TextAttributeEditor, TextAttributeView);
EditorPropertiesManager.register('object', JsonAttributeEditor, EditorAttributeView);
EditorPropertiesManager.register('select', SelectAttributeEditor, TextAttributeView);
EditorPropertiesManager.register('code', CodeAttributeEditor, EditorAttributeView);
EditorPropertiesManager.register('SQL', SqlAttributeEditor, EditorAttributeView);
EditorPropertiesManager.register('breakpoints', BreakpointPropertyEditor, EditorAttributeView);
EditorPropertiesManager.register('collection', CollectionAttributeEditor, EditorAttributeView);
EditorPropertiesManager.register('datasource', DatasourceAttributeEditor, TextAttributeView);
EditorPropertiesManager.register('acceptedFiles', AcceptedFilesAttributeEditor, EditorAttributeView);
EditorPropertiesManager.register('icon', IconAttributeEditor, TextAttributeView);
EditorPropertiesManager.register('paramsDatasource', ParamsAttributeEditor, EditorAttributeView);
EditorPropertiesManager.register('serviceDatasource', ServiceDatasourceAttributeEditor, EditorAttributeView);
EditorPropertiesManager.register('targetScreen', TargetScreenAttributeEditor, TextAttributeView);
EditorPropertiesManager.register('targetRouter', TargetRouterAttributeEditor, TextAttributeView);
EditorPropertiesManager.register('sessionPicker', SessionsPickerAttributeEditor, TextAttributeView);
EditorPropertiesManager.register('actionOptions', ActionOptionsAttributeEditor, EditorAttributeView);

export const EditPropertyValue = (props: EditPropertyValueProps) => {
  const { id, value, api, field } = props;
  const [newValue, setNewValue] = useState(value);
  const save = useCallback(
    (_value: GridCellValue) => {
      api.commitCellChange({ id, field, props: { value: _value } });
      api.publishEvent(GRID_CELL_EDIT_PROPS_CHANGE_COMMITTED, { id, field, props: { value: _value } });
      api.setCellMode(id, field, 'view');
    },
    [api, id, field],
  );

  const storeData = useCallback(() => {
    save(newValue);
  }, [save, newValue]);
  const cancel = useCallback(() => {
    save(value);
  }, [save, value]);
  const type = props.type || 'string';

  const Comp = EditorPropertiesManager.get(type) || EditorPropertiesManager.get('fallback');
  return <Comp value={newValue} onChange={setNewValue} onSave={storeData} onCancel={cancel} options={props?.row?.list} metadata={props?.row?.metadata} cellName={props?.row.label} />;
};

export const EditPropertyView = (props: EditPropertyViewProps) => {
  const { value } = props;
  const type = props.type || 'string';
  const Comp = EditorPropertiesManager.getView(type) || EditorPropertiesManager.getView('fallback');

  return <Comp value={value} />;
};
