import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { AssetPanelCompProps } from '../../types';
import { StubbornScreen, StubbornService } from '../../../../../../../business/StubbornAsset';
import { StubbornRouter } from '../../../../../../../business/StubbornRouter';
import { RelatedAssets } from '../../../../../../../utils/RelatedAssets';
import { useAssetContext } from '../../../../context/AssetContext';
import { AssetCard } from '../../commons/AssetCard';
import { StubbornData } from '../../../../../../../business';

export interface RelatedAssetsPanelProps extends AssetPanelCompProps<StubbornService | StubbornScreen | StubbornRouter> {}

export const ScreenRelatedAssetsPanel = ({ asset }: RelatedAssetsPanelProps) => {
  const [relatedAssets, setRelatedAssets] = useState<[string, string][]>([]);
  const { selectAsset, assets } = useAssetContext();

  useEffect(() => {
    const data = new RelatedAssets(asset);
    const relatedAssets = data.getRelatedAssets();
    setRelatedAssets(relatedAssets || []);
  }, [asset]);

  const handleClick = (assetName: string) => {
    const asset = assets.find((asset: StubbornData) => assetName === asset.name);
    if (asset) selectAsset(asset);
  };

  return (
    <Grid container flexWrap="wrap">
      {relatedAssets.map((asset) => {
        const [type, assetName] = asset;
        return (
          <Grid onClick={() => handleClick(assetName)} item sx={{ margin: '5px' }}>
            <AssetCard type={type} assetName={assetName} />
          </Grid>
        );
      })}
    </Grid>
  );
};
