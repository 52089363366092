import {FieldType} from "../../manager/FieldType";
import {Card} from "./Card";
import {attributeGroups} from "./attributeGroups";
import {toolbox} from "./toolbox";
import {FieldTypeManager} from "../../manager/FieldTypeManager";

export const cardFieldType: FieldType = {
  name: 'card',
  component: Card,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(cardFieldType.name, cardFieldType);
