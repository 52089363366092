import React, { Fragment, useEffect, useState } from 'react';
import { Box, Paper } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { AppThemeType } from '../../../../../../theme';
import { StubbornAssetRenderer } from './components/StubbornAssetRenderer/StubbornAssetRenderer';
import { useScreenDAOContext } from './context/ScreenDAOContext';
import { breakpoints, HIDDEN_SCREENS } from '../../constants';
import { BaseAppConst } from '../../../../../const/BaseAppConst';
import { useEditorScreenContext } from '../../EditorScreenContext';
import { useProjectDAOContext } from './context/ProjectDAOContext';
import { Loading } from '../../../../../components';
import { getAssetByAssetSelector } from '../../../../../utils';
import { StubbornAsset, StubbornField } from '../../../../../business/StubbornAsset';

interface ScreenEditorProps {
  currentBreakpoint: number;
  currentZoom: number;
}

const { bg_color, dot_size, dot_space } = BaseAppConst.uiEditor;

const useClasses = makeStyles<AppThemeType>((theme) =>
  createStyles({
    container: {
      margin: 0,
      display: 'flex',
      height: `100%`,
      width: '100%',
      transition: 'transform .1s',
      marginTop: 40,
    },
    dotsStyle: {
      width: '100%',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      overflowY: 'auto',
      backgroundColor: 'white'
    },
    controls: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    breakpointSwitcher: {
      marginRight: 5,
    },
    zoomSwitcher: {
      marginRight: 20,
    },
  }),
);

const emptyArray: number[] = [];

export function ScreenEditor({ currentBreakpoint = 1, currentZoom = 100 }: ScreenEditorProps) {
  const { editable, assetSelector, setParentSelector, parentSelector } = useEditorScreenContext();
  const { screen } = useScreenDAOContext();
  const { loading, ProjectDAO } = useProjectDAOContext();
  const classes = useClasses();

  const selectedAsset = getAssetByAssetSelector(screen, assetSelector);
  const selectedScreen = parentSelector ? getAssetByAssetSelector(screen, parentSelector) : screen;

  useEffect(() => {
    if ((selectedAsset as StubbornField).type?.toLowerCase() === 'screen') {
      if ((selectedAsset as StubbornField).fieldType?.toLowerCase() === 'dialog') {
        setParentSelector(assetSelector);
      } else {
        setParentSelector(parentSelector.length ? parentSelector : emptyArray);
      }
    }
  }, [selectedAsset, assetSelector, parentSelector, setParentSelector]);
  return (
    <Fragment key={editable ? 'editable' : 'preview'}>
      <Paper className={classes.container} style={{ width: breakpoints[currentBreakpoint].res, margin: '0 auto', transform: `scale(${currentZoom})` }}>
        {loading ? (
          <Loading />
        ) : (
          <Box className={classes.dotsStyle}>
            <StubbornAssetRenderer {...selectedScreen} parentAssetSelector={parentSelector} />
          </Box>
        )}
      </Paper>
    </Fragment>
  );
}
