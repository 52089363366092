import _ from 'lodash';
import React, { Fragment, useMemo } from 'react';
import { GridSpans } from '../../GridSpans';
import { RendererContainer } from '../../RendererContainer';
import { Grid, Icon } from '@material-ui/core';
import { StubbornField, StubbornScreen } from '../../../../../../../../business/StubbornAsset';
import { BaseRendererProps } from './BaseRendererProps';
import { useCurrentBreakpoint } from '../../../../../../../../commons/hooks';
import { processGroupStyles, processScreenLayout } from '../../../fields/utils';
import { HIDDEN_SCREENS } from '../../../../../constants';
import { Container } from '../../../fields/fieldTypes/Container/Container';
import { useScreenDAOContext } from '../../../context/ScreenDAOContext';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';
import { securedJSRunner } from '../../../../../../../../utils/functionHelpers';
import { useProjectDAOContext } from '../../../context/ProjectDAOContext';
import { AnyObject } from '../../../../../../../../commons/types';
import { FieldTypeManager } from '../../../fields';
import { useRouterDAOContext } from '../../../context/RouterDAOContext';

export interface ScreenRendererProps extends StubbornScreen, BaseRendererProps {
  Context: AnyObject;
}

const dummyObj = {};
// TODO: el screen va  atener un fieldType que hara referencia al tipo de RendererContainer
export const ScreenRenderer = ({ Context = dummyObj, metadata, parentAssetSelector, assetIndex, attributes, fieldType }: ScreenRendererProps) => {
  const newParentAssetSelector = useMemo(() => (!assetIndex ? [] : [...parentAssetSelector, assetIndex]), [parentAssetSelector, assetIndex]);
  const { ScreenDAO, params } = useScreenDAOContext();
  const { editable } = useEditorScreenContext();
  const { ProjectDAO } = useProjectDAOContext();
  const { ProfileDAO } = useRouterDAOContext();
  const defaultValues = FieldTypeManager.getDefaultAttributes('container');

  const formattedAttributes = useMemo(() => {
    let fullAttributes: AnyObject = {};
    Object.keys(defaultValues).forEach((attributeGroup) => {
      fullAttributes = {
        ...fullAttributes,
        [attributeGroup]: {
          ...defaultValues[attributeGroup],
          ...attributes[attributeGroup],
        },
      };
    });
    // doing interpolation of strings
    const attributesTypes = FieldTypeManager.getAttributesTypes('container');
    Object.keys(fullAttributes).forEach((attributeGroup) => {
      Object.keys(fullAttributes[attributeGroup]).forEach((attributeName) => {
        const type = attributesTypes[attributeGroup][attributeName];
        const value = fullAttributes[attributeGroup][attributeName];
        if (type === 'string') {
          const compiled = _.template(value);
          fullAttributes[attributeGroup][attributeName] = compiled({
            SBScreen: ScreenDAO,
            ...fullAttributes,
            SBContext: { ...Context, Params: params },
          });
        }
      });
    });

    return fullAttributes;
  }, [ScreenDAO, Context, defaultValues, attributes, params]);

  const eventFormatted = useMemo(() => {
    let events = {};
    if (!editable) {
      attributes.events &&
        Object.keys(attributes.events).forEach((event) => {
          if (!!attributes.events[event]) {
            events = {
              ...events,
              [event]: (e: React.EventHandler<any>) => securedJSRunner(attributes.events[event], e, ScreenDAO, ProfileDAO?.RouterDAO, ProjectDAO.services, ProjectDAO, { ...Context, Params: params }),
            };
          }
        });
    }
    return events;
  }, [ProjectDAO, ScreenDAO, ProfileDAO, editable, attributes.events, Context, params]);

  const bp = useCurrentBreakpoint();
  // eslint-disable-next-line no-eval
  const renderCondition = useMemo(() => eval(formattedAttributes.basic.renderConditions || true), [formattedAttributes]);

  const componentStyles = useMemo(() => {
    const styles = _.get(formattedAttributes, 'basic.styles');
    return processGroupStyles(styles, bp, renderCondition);
  }, [formattedAttributes, bp, renderCondition]);

  const isHiddenField = Object.values(HIDDEN_SCREENS).includes(fieldType as HIDDEN_SCREENS);

  return !renderCondition && !editable ? null : (
    <Grid container style={{ position: 'relative' }}>
      {attributes?.iterator?.enabled && editable && <Icon style={{ backgroundColor: 'limegreen', borderRadius: '50%', color: 'white', position: 'absolute', zIndex: 999999, bottom: -12, right: 0, fontSize: 20 }}>loop</Icon>}
      <Container metadata={metadata} newParentAssetSelector={newParentAssetSelector} events={eventFormatted} states={attributes.states} styles={componentStyles} Params={params} iterator={attributes.iterator} />
    </Grid>
  );
};
