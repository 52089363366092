import {FieldType} from "../../manager/FieldType";
import {Dialog} from "./Dialog";
import {attributeGroups} from "./attributeGroups";
import {toolbox} from "./toolbox";
import {FieldTypeManager} from "../../manager/FieldTypeManager";

export const dialogFieldType: FieldType = {
  name: 'dialog',
  component: Dialog,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(dialogFieldType.name, dialogFieldType);
