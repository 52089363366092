import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { ScreenBaseProps } from '../ScreenBaseProps';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';
import { StubbornAsset } from '../../../../../../../../business/StubbornAsset';
import { GridSpans, RendererContainer } from '../../../components';
import { useEditorContext } from '../../../../../EditorContext';
import { HIDDEN_SCREENS } from '../../../../../constants';
import { AnyObject } from '../../../../../../../../commons/types';
import { useServicesDAO } from '../../../hooks';
import { useRosterScreenContextFind } from '../../../../../../../../../platform/api/endpoints';
import { StubbornAssetRenderer } from '../../../components/StubbornAssetRenderer/StubbornAssetRenderer';
import { EditorScreenItem } from '../../../../../EditorItem';
import { EditorScreenContextProvider } from '../../../../../EditorScreenContext';
import { useScreenDAOContext } from '../../../context/ScreenDAOContext';

export interface TargetScreenProps extends ScreenBaseProps {
  metadata: StubbornAsset[];
  newParentAssetSelector: number[];
  iterator: {
    enabled: boolean;
    service?: {
      serviceName: string;
      params: {
        keys: Array<string>;
        values: Array<string>;
      };
      path: string;
    };
    items?: AnyObject[];
  };
}

const TargetRenderer = ({ parentSelector }: { parentSelector: number[],  }) => {
  const { screen } = useScreenDAOContext();
  return <StubbornAssetRenderer {...screen} parentAssetSelector={parentSelector} />;
};

export const TargetScreen = ({ metadata, events, states, newParentAssetSelector, styles, iterator }: TargetScreenProps) => {
  const { editable, parentSelector } = useEditorScreenContext();
  const { data } = useRosterScreenContextFind();
  const selectedScreen = useMemo(() => data?.find((screenItem) => screenItem.name === states.target), [data, states.target]);
  const editor = useMemo(() => {
    return selectedScreen && new EditorScreenItem(selectedScreen);
  }, [selectedScreen]);

  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container {...events}>
      {selectedScreen && editor ? (
        <EditorScreenContextProvider editor={editor} isEditable={editable} key={selectedScreen.name}>
          <TargetRenderer parentSelector={parentSelector}/>
        </EditorScreenContextProvider>
      ) : null}
    </Grid>
  );
};
