import React from 'react';
import {FormProps} from "./FormProps";
import {TextField, Typography} from "@material-ui/core";

export const DefaultForm = ({value}: FormProps) => (
  <>
    <TextField autoFocus margin="dense" id="name" label="Nombre" name="name" fullWidth value={value.name} disabled/>
    <Typography color="danger">Formulario especifico para {value.kind} no encontrado</Typography>
  </>
);

