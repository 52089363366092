import React, { useEffect } from 'react';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, Typography, Paper } from '@material-ui/core';
import { FieldBaseProps } from '../FieldBaseProps';
import { AccordionEntry } from '../../../properties/editors';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';
export interface AccordionProps extends FieldBaseProps {
  component: {
    defaultExpanded?: boolean;
    disabled?: boolean;
    disableGutters: boolean;
    square?: boolean;
    entries?: AccordionEntry[];
    showExpandIcon?: boolean;
  };
}

export const Accordion = ({ component, events, states, styles }: AccordionProps) => {
  const { editable } = useEditorScreenContext();
  
  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const entries = component.entries?.map((entry: AccordionEntry, index: number) => {
    return (
      <MuiAccordion key={`accordion-entry-${index}`} defaultExpanded={component.defaultExpanded} disabled={component.disabled} disableGutters={component.disableGutters} square={component.square}>
        <AccordionSummary>
          <Typography>{entry.label}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{entry.content}</Typography>
        </AccordionDetails>
      </MuiAccordion>
    );
  });

  const noEntriesTextStyle = {
    display: "flex",
    justifyContent: "center"
  };

  return component.entries?.length === 0 ? <Typography style={noEntriesTextStyle}>[Sin entradas]</Typography> : <Paper style={styles}>{entries}</Paper>;
};
