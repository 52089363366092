import {FieldType} from "../../manager/FieldType";
import {CodeArea} from "./CodeArea";
import {attributeGroups} from "./attributeGroups";
import {toolbox} from "./toolbox";
import {FieldTypeManager} from "../../manager/FieldTypeManager";

export const codeAreaFieldType: FieldType = {
  name: 'codeArea',
  component: CodeArea,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(codeAreaFieldType.name, codeAreaFieldType);