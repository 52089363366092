import React from 'react';
import {AssetPanelCompProps} from "../../types";
import {StubbornPlugin} from "../../../../../../../business/StubbornConfiguration";
import {Grid, Typography} from "@material-ui/core";
import {PluginForm} from "./components/PluginForm/PluginForm";

export interface PluginConfigurationPanelProps extends AssetPanelCompProps<StubbornPlugin> {}

export const PluginConfigurationPanel = ({asset}: PluginConfigurationPanelProps) => (
  <Grid container direction="column">
    <Grid item>
      <Typography variant="caption">Tipo: {asset.kind}</Typography>
    </Grid>
    <Grid item>
      <PluginForm asset={asset} />
    </Grid>
  </Grid>
);
