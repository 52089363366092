export interface StorageManager {
  getItem: (key: string) => Promise<string | undefined | null>;
  setItem: (key: string, value: string) => Promise<void>;
  removeItem: (key: string) => Promise<void>;
}

export abstract class TokenManager {
  abstract retrieve(name: string): Promise<string | undefined | null | void>;
  abstract clean(name: string): Promise<boolean | void | null | undefined>;
  abstract cleanAll(): Promise<boolean | void | null | undefined>;
  abstract persist(name: string, token: string): Promise<string | boolean | undefined | null | void>;
}

const manager: StorageManager = {
  getItem: async (key: string) => localStorage.getItem(key),
  setItem: async (key: string, value: string) => localStorage.setItem(key, value),
  removeItem: async (key: string) => localStorage.removeItem(key),
};

const STORE_KEY = 'stubborn-token';
const getStoreKey = (name: string) => `${STORE_KEY}:${name}`;

export class WebTokenManager extends TokenManager {
  protected names: Set<string> = new Set();

  async retrieve(name: string = 'default') {
    try {
      const token = await manager.getItem(getStoreKey(name));
      this.names.add(name);
      return token;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async clean(name: string = 'default') {
    try {
      const result = await manager.removeItem(getStoreKey(name));
      this.names.delete(name);
      return result;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async cleanAll() {
    try {
      for (const name of this.names) {
        await manager.removeItem(getStoreKey(name));
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async persist(name: string = 'default', token: string) {
    try {
      return await manager.setItem(getStoreKey(name), token);
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  static create() {
    return new WebTokenManager();
  }
}

export class MemoryTokenManager extends TokenManager {
  protected token: Map<string, string> = new Map<string, string>();

  async retrieve(name: string = 'default') {
    return this.token.get(name);
  }

  async clean(name: string = 'default') {
    this.token.delete(name);
  }

  async persist(name: string = 'default', token: string) {
    this.token.set(name, token);
  }

  async cleanAll() {
    this.token.clear();
  }

  static create() {
    return new MemoryTokenManager();
  }
}
