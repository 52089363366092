import { StubbornAttribute, StubbornAttributeGroup } from '../../../../../../../../business/StubbornAttributeMapping';

import { basicAttributeGroup, makeComponentAttributeGroup, makeEventAttributeGroup } from '../../commons/attributes';

const attributes = new Set<StubbornAttribute>();
attributes.add({
  label: 'Content',
  name: 'content',
  type: 'icon',
  default: 'star',
});
attributes.add({
  label: 'Color',
  name: 'color',
  type: 'select',
  default: 'primary',
  list: ['disabled', 'primary', 'secondary', 'error', 'inherit', 'action'],
});

attributes.add({
  label: 'Font Size',
  name: 'fontSize',
  type: 'select',
  default: 'medium',
  list: ['inherit', 'large', 'medium', 'small'],
});

export const attributeGroups: StubbornAttributeGroup[] = [basicAttributeGroup, makeComponentAttributeGroup(attributes), makeEventAttributeGroup()];
