import { StubbornAttribute, StubbornAttributeGroup } from '../../../../../../../../business/StubbornAttributeMapping';

import { basicAttributeGroup, makeComponentAttributeGroup, makeEventAttributeGroup, makeStateAttributeGroups } from '../../commons/attributes';

const attributes = new Set<StubbornAttribute>();
attributes.add({
  label: 'Multiple Uploads',
  name: 'multipleUploads',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Max size',
  name: 'maxSize',
  type: 'string',
  default: '100000',
});
attributes.add({
  label: 'Accepted types',
  name: 'acceptedTypes',
  type: 'acceptedFiles',
  default: ['.jpg', '.png', '.gif', '.tiff', '.psd', '.bmp', '.mp4', '.avi', '.mpeg', '.mov', '.mkv', '.pdf', '.doc', '.docx', '.txt', '.mp3', '.wav', '.wma', '.zip', '.rar'
  ]
});

const eventAttributes: StubbornAttribute[] = [
  {
    label: 'OnChange',
    name: 'onChange',
    type: 'code',
    default: '',
  },
];

const stateAttributes: StubbornAttribute[] = [
  {
    label: 'Value',
    name: 'value',
    type: 'object',
    default: '{}',
  },
];

export const attributeGroups: StubbornAttributeGroup[] = [basicAttributeGroup, makeStateAttributeGroups(stateAttributes), makeComponentAttributeGroup(attributes), makeEventAttributeGroup(eventAttributes)];
