import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {AppApi} from "../../../../platform/api";
import {useProfileContext} from "../../../../lib/phinxapp/profiles/ProfileContext";
import {Loading} from "../../../components";

function Copyright() {
  return (
    <Grid container>
      <Typography fontSize={13}>All rights reserved 2021. ©</Typography>
      <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
        <Typography fontSize={13} style={{ marginRight: 5 }}>
          Powered by{' '}
        </Typography>
        <a href="https://www.phinxlab.com/" target="_blank" rel="noreferrer">
          <img alt="Phinx" src="https://www.phinxlab.com/images/logo/logo13.svg" width={42} />
        </a>
      </Grid>
    </Grid>
  );
}

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string|null>(null);
  const profile = useProfileContext();

  // TODO: Reformat all of this
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoading(true);
      setError(null);
      const data = new FormData(event.currentTarget);
      // eslint-disable-next-line no-console
      const credentials = {
        username: data.get('email'),
        password: data.get('password'),
      }

      const response = await AppApi.httpService.post('/login', credentials);
      const token = response.headers.get('X-JustRPC-Token');
      if (!token) throw new Error('Token not found');
      localStorage.setItem('stubborn:logged:account', token);
      profile.setProfile('private2');
    } catch (e) {
      console.log('login error', e);
      setError("Incorrect credentials");
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Username"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {!!error && (
            <Typography variant="h4" color="red">{error}</Typography>
          )}
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            {loading ? <Loading /> : 'Entrar'}
          </Button>
        </Box>
      </Box>
      <Copyright />
    </Container>
  );
}
