import React, { useEffect } from "react";
import { Avatar as MuiAvatar } from "@material-ui/core";
import { FieldBaseProps } from "../FieldBaseProps";
import { useEditorScreenContext } from "../../../../../EditorScreenContext";
export interface AvatarProps extends FieldBaseProps {
  component: {
    src?: string;
    alt?: string;
    variant?: "circular" | "rounded" | "square";
  };
}

export const Avatar = ({ component, events, states, styles }: AvatarProps) => {
  const { editable } = useEditorScreenContext();
  
  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MuiAvatar style={styles} alt={component.alt} src={component.src} variant={component.variant} />;
};
