import React, { useEffect } from 'react';
import { Avatar, Chip as MuiChip, Icon } from '@material-ui/core';
import { FieldBaseProps } from '../FieldBaseProps';
import { FieldBaseEvents } from '../FieldBaseEvents';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';

type Color = 'default' | 'primary' | 'secondary' | undefined;

interface ChipEvents extends FieldBaseEvents {
  onDeleteClick: () => void;
}

export interface ChipProps extends FieldBaseProps {
  component: {
    color: Color;
    clickable?: boolean;
    disabled?: boolean;
    content: string;
    size?: 'medium' | 'small';
    variant?: 'filled' | 'outlined';
    avatarSrc?: string;
    avatarAlt?: string;
    deleteIcon?: string;
    icon?: string;
  };
  events: ChipEvents;
};

export const Chip = ({ component, events, states, styles }: ChipProps) => {
  const { editable } = useEditorScreenContext();

  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const avatarOrIconProps: any = {
    icon: <Icon>{component.icon}</Icon>,
  };

  if (component.avatarSrc !== '') {
    avatarOrIconProps.avatar = <Avatar src={component.avatarSrc} alt={component.avatarAlt} />;
    delete avatarOrIconProps.icon;
  }

  if (component.avatarSrc === '') {
    avatarOrIconProps.icon = component.icon;
    delete avatarOrIconProps.avatar;
  }

  return <MuiChip {...avatarOrIconProps} icon={<Icon>{component.icon}</Icon>} onClick={events.onClick} deleteIcon={<Icon>{component.deleteIcon}</Icon>} style={styles} color={component.color} clickable={component.clickable} label={component.content} onDelete={events.onDeleteClick} size={component.size} />;
};
