import React, {useCallback} from "react";
import {Switch} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {BaseAttributeEditorProps} from "./BaseAttributeEditorProps";
import {useCheckboxOnChangeValue} from "../../../../../../../commons/hooks";

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 16,
  },
});

export interface BooleanAttributeEditorProps extends BaseAttributeEditorProps {}

export const BooleanAttributeEditor = ({value, onChange, onSave}: BooleanAttributeEditorProps) => {
  const classes = useStyles();
  const handleChange = useCheckboxOnChangeValue(onChange);

  const handleOnBlur = useCallback((event: React.FocusEvent<HTMLButtonElement>) => {
    onSave();
    event.stopPropagation();
  }, [onSave]);

  return (
    <div className={classes.root}>
      <Switch
        checked={value as boolean}
        onChange={handleChange}
        onBlur={handleOnBlur}
      />
    </div>
  );
};
