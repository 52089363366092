import DeleteIcon from '@material-ui/icons/Delete';
import { Chip, List, ListItem, ListItemText, ListItemButton, Typography } from '@material-ui/core';
import { Box } from '@material-ui/system';
import { useTheme } from '@material-ui/styles';
import { useStyles } from '../../../../../../../Main/styles';

interface BasicInformationProps {
  name: string;
  type: string;
  tags: string[];
  handleDelete: (tag: string) => void;
}

export const BasicInformation = ({ name, type, tags, handleDelete }: BasicInformationProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <List aria-label="contacts" sx={{ width: 600 }}>
      <ListItem divider>
        <ListItemText
          primary={
            <Box className={classes.flexContainerSpaced}>
              Name: <Typography>{name}</Typography>
            </Box>
          }
        />
      </ListItem>
      <ListItem divider>
        <ListItemText
          primary={
            <Box className={classes.flexContainerSpaced}>
              Type: <Typography>{type}</Typography>
            </Box>
          }
        />
      </ListItem>
      {!!tags.length && (
        <ListItem divider>
          <ListItemText
            primary={
              <Box className={classes.flexContainerSpaced}>
                Tags:
                <Box className={classes.flexContainerSpaced}>
                  {tags.map((tag: string) => (
                    <Chip sx={{ marginX: '1px' }} label={tag} key={tag} variant="outlined" onDelete={() => handleDelete(tag)} deleteIcon={<DeleteIcon />} />
                  ))}
                </Box>
              </Box>
            }
          />
        </ListItem>
      )}
    </List>
  );
};
