import { ActionPanelCompProps } from '../types';
import { Button, Container } from '@material-ui/core';
import ContentCopyIcon from '@material-ui/icons/ContentCopy';
import { useAssetContext } from '../../../context/AssetContext';
import { confirmDialog } from '../../../../../../services';
import { StubbornData } from '../../../../../../business';

export interface CloneActionProps extends ActionPanelCompProps<StubbornData> {}

export const CloneAction = ({ asset }: CloneActionProps) => {
  const { assetFns, reloadAssets } = useAssetContext();

  const handleClone = async () => {
    const confirm = await confirmDialog.show({
      title: 'Confirmacion',
      content: 'Esta seguro de clonar este asset?',
    });

    if (!confirm) return;

    const newAsset: StubbornData = { ...asset, name: `${asset.name}Clon` };
    try {
      await assetFns.create(newAsset, newAsset.type);
      reloadAssets({});
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <Button onClick={handleClone} startIcon={<ContentCopyIcon />}>
        Clone
      </Button>
    </Container>
  );
};
