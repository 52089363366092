import React, { useCallback } from 'react';

export const useInputOnChangeValue = (fun?: (value: any) => void, validate?: boolean, setError?: (value: any) => void) =>
  useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      const errorFormatter = (input: any) => {
        // eslint-disable-next-line
        const specialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        const blankSpace = input.includes(' ');
        const upperCase = /^[A-Z]*$/;
        const digits = /^[0-9]*$/;

        if (upperCase.test(input[0])) return { bool: true, message: 'No uppercase at the start of name' };
        else if (digits.test(input[0])) return { bool: true, message: 'No numbers at the start of name' };
        else if (blankSpace) return { bool: true, message: 'No blankspace' };
        else if (specialChar.test(input)) return { bool: true, message: 'No special characters' };
        else return { bool: false, message: 'All good' };
      };
      const error = errorFormatter(value);
      const { bool } = error;

      if (setError) setError(error);
      if (fun && (validate ? !bool : true)) fun(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fun],
  );
