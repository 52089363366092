import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { BaseAttributeEditorProps } from './BaseAttributeEditorProps';
import { Modal } from '../../../../../../../commons/ui';
import { Button, Checkbox, Typography } from '@material-ui/core';
import { acceptedFiles } from "../../../../constants";

const useStyles = makeStyles({
  backdrop: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 16,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#00000080',
    zIndex: 20000,
    top: 0,
    left: 0,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    height: '55%',
    zIndex: 20000,
    width: '50%',
  },
  modalHeader: {
    padding: 20,
  },
  headerTitle: {
    fontSize: 20,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    overflow: 'auto',
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'row',
    padding: 5,
    justifyContent: 'flex-end',
  },
  cancelButton: { marginRight: 20 },
  checkboxs: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export interface AcceptedFilesAttributeEditorProps extends BaseAttributeEditorProps { }

export const AcceptedFilesAttributeEditor = ({ value, onCancel, onChange, onSave }: AcceptedFilesAttributeEditorProps) => {
  const classes = useStyles();
  const [readyToSave, setReadyToSave] = useState<boolean>(false);
  const [checkedTypes, setCheckedTypes] = useState<any>(value);

  const checkType = (event: any, type: string) => {
    if (checkedTypes.includes(type)) {
      const newArray = checkedTypes.filter((item: any) => item !== type);
      setCheckedTypes(newArray);
    } else {
      setCheckedTypes([...checkedTypes, type]);
    }
    event.stopPropagation();
  };

  useEffect(() => {
    if (readyToSave) onSave();
  }, [readyToSave, onSave]);

  const handleOnBlur = useCallback(
    (event) => {
      onChange(checkedTypes);
      setReadyToSave(true);
      event.stopPropagation();
    },
    [setReadyToSave, onChange, checkedTypes],
  );

  return (
    <Modal>
      <div className={classes.backdrop} onClick={onCancel}>
        <div className={classes.modal}>
          <div className={classes.modalHeader}>
            <Typography className={classes.headerTitle}>Accepted File Types</Typography>
          </div>
          <div className={classes.modalContent}>
            {acceptedFiles.map((item) => (
              <div className={classes.checkboxs}>
                <Checkbox onClick={(e) => checkType(e, item.type)} checked={checkedTypes.includes(item.type)} />
                <Typography>{item.label}</Typography>
              </div>
            ))}
          </div>
          <div className={classes.modalFooter}>
            <Button className={classes.cancelButton} variant="outlined" fullWidth={false} onClick={handleOnBlur}>
              Guardar
            </Button>
            <Button className={classes.cancelButton} variant="outlined" fullWidth={false} onClick={onCancel}>
              Cancelar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
