import React, {useMemo} from "react";
import ContentCopyIcon from "@material-ui/icons/ContentCopy";
import DeleteIcon from '@material-ui/icons/Delete';
import {CardButtonAction} from "../../../../../../commons/ui";
import {MenuCardItem} from "../../../../../../components";
import {confirmDialog} from "../../../../../../services";
import {StubbornScreen, StubbornService} from "../../../../../../business/StubbornAsset";
import {StubbornRouter} from "../../../../../../business/StubbornRouter";

interface Fns<T> {
  create: (item: T) => Promise<any>;
  remove: (id?: string, item?: T) => Promise<any>
}

export const useCardActions = (screenFns: Fns<StubbornScreen>, serviceFns: Fns<StubbornService>, routerFns: Fns<StubbornRouter>, recallRoster: () => void) => useMemo<CardButtonAction<MenuCardItem>[]>(() => [
  {
    id: 'clone',
    icon: <ContentCopyIcon />,
    title: 'Clonar',
    onClick: async data => {
      const confirm = await confirmDialog.show({
        title: 'Confirmacion',
        content: 'Esta seguro de clonar este asset?',
      });
      if (confirm) {
        // TODO: creo que es preferible volver a fetchear el asset antes de clonarlo
        if (data.asset.type === 'Service') {
          // TODO: evaluar si usar el naming strategy aqui
          const newService: StubbornService = { ...data.asset, name: `${data.asset.name}Clon` };
          await serviceFns.create(newService);
          recallRoster();
        } else if (data.asset.type === 'Screen') {
          // TODO: evaluar si usar el naming strategy aqui
          const newScreen: StubbornScreen = { ...data.asset, name: `${data.asset.name}Clon` };
          await screenFns.create(newScreen);
          recallRoster();
        } else if (data.asset.type === 'Router') {
          const newRouter: StubbornRouter = { ...data.asset, name: `${data.asset.name}Clon` };
          await routerFns.create(newRouter);
          recallRoster();
        } else {
          alert(`Clonacion no implementada para el tipo ${data.asset.type}`);
        }
      }
    }
  },
  {
    id: 'remove',
    icon: <DeleteIcon />,
    title: 'Eliminar',
    onClick: async data => {
      const confirm = await confirmDialog.show({
        title: 'Confirmacion',
        content: 'Esta seguro de eliminar este asset?',
      });
      if (confirm) {
        if (data.asset.type === 'Service') {
          await serviceFns.remove(undefined, data.asset);
          recallRoster();
        } else if (data.asset.type === 'Screen') {
          await screenFns.remove(undefined, data.asset);
          recallRoster();
        }else if (data.asset.type === 'Router') {
          await routerFns.remove(undefined, data.asset);
          recallRoster();
        } else {
          alert(`Eliminacion no implementada para el tipo ${data.asset.type}`);
        }
      }
    }
  },
// eslint-disable-next-line react-hooks/exhaustive-deps
], [recallRoster, screenFns, serviceFns]);
