import React from 'react';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FilterListIcon from '@material-ui/icons/FilterList';
import {AddAssetMenu, SearchInput, SearchInputProps} from "./components";

export interface ToolbarProps extends SearchInputProps {
  onCreateService: (name: string, description: string) => Promise<void>;
  onCreateScreen: (name: string) => Promise<void>;
  onCreateRouter: (name: string) => Promise<void>;
}

export const Toolbar = ({ search, onSearchChange, onCreateService, onCreateScreen, onCreateRouter }: ToolbarProps) => {
  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <SearchInput search={search} onSearchChange={onSearchChange} />
      </Grid>
      <Grid item>
        <IconButton color="primary" size="large" title="Filtrar">
          <FilterListIcon/>
        </IconButton>
        <FormControl sx={{minWidth: 250}}>
          <InputLabel id="agrupar-por">Agrupar por</InputLabel>
          <Select
            labelId="agrupar-por"
            id="agrupar-por-input"
            value=""
            label="Agrupar por"
            onChange={() => {
            }}
          >
            <MenuItem value="">
              <em>Ninguno</em>
            </MenuItem>
            <MenuItem value="type">Tipo</MenuItem>
            <MenuItem value="tags">Tag</MenuItem>
          </Select>
        </FormControl>
        <AddAssetMenu onCreateService={onCreateService} onCreateScreen={onCreateScreen} onCreateRouter={onCreateRouter} />
      </Grid>
    </Grid>
  );
};
