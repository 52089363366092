import React, {useCallback, useState} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import LoadingButton from "@material-ui/lab/LoadingButton";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import {useInputState} from "../../../../../../../../../../commons/hooks";

export interface NewPluginDialogProps {
  open: boolean;
  onClose: () => void;
  //onCreateSession: (name: string) => Promise<void>;
  onCreatePlugin: (name: string, secret: string, header: string) => Promise<void>;
}

export const NewPluginDialog = ({ open, onClose, onCreatePlugin }: NewPluginDialogProps) => {
  const validate = true;
  const [name, onNameChange, setName, error] = useInputState('', validate);
  const [kind, onKindChange, setKind] = useInputState('');
  const [type, onTypeChange, setType] = useInputState('');
  
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);

  const onCreate = useCallback(async () => {
    setDirty(true);
    if (name && kind && type) {
      try {
        setLoading(true);
        await onCreatePlugin(name,kind,type);
        setLoading(false);
        onClose();
        setName('');
        setDirty(false);
      } catch (e: any) {
        alert(e.message); // TODO: notistack
        setLoading(false);
      }
    }
  }, [name, kind, type, onCreatePlugin, onClose, setName]);
  return (
    <Dialog
      open={open}
      onClose={loading ? undefined : onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Nuevo Plugin</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="description"
          label="Nombre"
          name="name"
          fullWidth
          value={name}
          onChange={onNameChange}
          error={(dirty && !name)  || error?.bool}
          helperText={(dirty && !name && 'Nombre requerido') || (error?.bool && error.message)}
          disabled={loading}
        />

        <TextField
          autoFocus
          margin="dense"
          id="kind"
          label="kind"
          name="kind"
          fullWidth
          value={kind}
          onChange={onKindChange}
          error={dirty && !kind}
          helperText={dirty && !kind && 'kind requerido'}
          disabled={loading}
        />

        <TextField
          autoFocus
          margin="dense"
          id="type"
          label="type"
          name="type"
          fullWidth
          value={type}
          onChange={onTypeChange}
          error={dirty && !type}
          helperText={dirty && !type && 'type requerido'}
          disabled={loading}
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" disabled={loading}>
          Cerrar
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          autoFocus
          onClick={onCreate}
          disabled={loading}
          loading={loading}
        >
          Crear
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
