import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { StubbornConfiguration, StubbornSessionConfiguration } from '../../../../../../../business';

export interface GenerateSessionProps {
  session: StubbornSessionConfiguration[] | StubbornConfiguration | undefined;
}

const useStyles = makeStyles({
  columnFlex: {
    margin:'10px',
   // border: '1px solid red',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
    alignItems:'left',
    justifyContent:'center'
  },
  subtitle:{
    marginTop:'10px',
    marginBottom:'2px'
  }
});



export const InfoSession = ({session}: GenerateSessionProps) => {

  const classes = useStyles();
  //@ts-ignore
  const {type, header, secret, condition, name, service} = session ? session[0] : {};

  return (
          <div className={classes.columnFlex}>
              {<h4 className={classes.subtitle}>Session name:</h4>}
              {<p>{name}</p>}
              {<h4 className={classes.subtitle}>Session type:</h4>}
              {<p>{type}</p>}
              {<h4 className={classes.subtitle}>Session header:</h4>}
              {<p>{header}</p>}
              {<h4 className={classes.subtitle}>Session secret:</h4>}
              {<p>{secret}</p>}
              {<h4 className={classes.subtitle}>Session service</h4>}
              {<p>{service}</p>}
              {<h4 className={classes.subtitle}>Session condition:</h4>}
              {<p>{condition}</p>}
          </div>
  );
}