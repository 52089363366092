import React, { useEffect, useMemo } from 'react';
import { Button as MuiButton, Icon } from '@material-ui/core';
import { FieldBaseProps } from '../FieldBaseProps';
import { FieldBaseEvents } from '../FieldBaseEvents';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';

interface ButtonEvents extends FieldBaseEvents {
  onBlur: () => void;
}

export interface ButtonProps extends FieldBaseProps {
  component: {
    color?: 'primary' | 'secondary' | 'inherit';
    variant?: 'text' | 'outlined' | 'contained';
    size?: 'small' | 'medium' | 'large';
    disableElevation?: boolean;
    href?: string;
    fullWidth?: boolean;
    disabled?: boolean;
    label: string;
    startIcon?: string;
    endIcon?: string;
  };
  events: ButtonEvents;
}

export const Button = ({ component, events, states, styles, basic: { renderConditions } }: ButtonProps) => {
  const { editable } = useEditorScreenContext();

  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const iconProps = {
    startIcon: component.startIcon !== '' ? <Icon>{component.startIcon}</Icon> : undefined,
    endIcon: component.endIcon !== '' ? <Icon>{component.endIcon}</Icon> : undefined,
  };

  return (
    <MuiButton
      {...iconProps}
      style={styles}
      size={component.size}
      variant={component.variant}
      fullWidth={component.fullWidth}
      color={component.color}
      onClick={events.onClick}
      onBlur={events.onBlur}
      disableElevation={component.disableElevation}
      href={component.href}
      disabled={component.disabled}
      onMouseEnter={events.onMouseEnter}
      onMouseLeave={events.onMouseLeave}
    >
      {component.label}
    </MuiButton>
  );
};
