import { StubbornAttribute, StubbornAttributeGroup } from '../../../../../../../../business/StubbornAttributeMapping';

import { basicAttributeGroup, makeComponentAttributeGroup, makeEventAttributeGroup } from '../../commons/attributes';

const attributes = new Set<StubbornAttribute>();
attributes.add({
  label: 'Texto',
  name: 'label',
  type: 'string',
  default: 'Boton',
});
attributes.add({
  label: 'Variant',
  name: 'variant',
  type: 'select',
  default: 'contained',
  list: ['text', 'outlined', 'contained'],
});
attributes.add({
  label: 'Color',
  name: 'color',
  type: 'select',
  default: 'primary',
  list: ['primary', 'secondary', 'inherit'],
});
attributes.add({
  label: 'Size',
  name: 'size',
  type: 'select',
  default: 'medium',
  list: ['small', 'medium', 'large'],
});
attributes.add({
  label: 'Disabled',
  name: 'disabled',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Ancho completo',
  name: 'fullWidth',
  type: 'boolean',
  default: true,
});
attributes.add({
  label: 'Enlace',
  name: 'href',
  type: 'string',
  default: '',
});
attributes.add({
  label: 'Start Icon',
  name: 'startIcon',
  type: 'icon',
  default: '',
});
attributes.add({
  label: 'End Icon',
  name: 'endIcon',
  type: 'icon',
  default: '',
});
attributes.add({
  label: 'Desactivar elevación (sombra)',
  name: 'disableElevation',
  type: 'boolean',
  default: false,
});

const eventAttributes: StubbornAttribute[] = [
  {
    label: 'OnClick',
    name: 'onClick',
    type: 'code',
    default: '',
  },
  {
    label: 'OnBlur',
    name: 'onBlur',
    type: 'code',
    default: '',
  },
  {
    label: 'OnMouseEnter',
    name: 'onMouseEnter',
    type: 'code',
    default: '',
  },
  {
    label: 'OnMouseLeave',
    name: 'onMouseLeave',
    type: 'code',
    default: '',
  },
];

export const attributeGroups: StubbornAttributeGroup[] = [basicAttributeGroup, makeComponentAttributeGroup(attributes), makeEventAttributeGroup(eventAttributes)];
