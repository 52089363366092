import { StubbornRoute, StubbornRouter } from '../../../../business/StubbornRouter';
import { AnyObject } from '../../../../commons/types';
import { RouterAppContext } from './RouterAppContext';

export class ProfileAppContext {
  protected routers: StubbornRouter[];
  protected selectedProfile: StubbornRouter | null;
  protected router: RouterAppContext | null;
  protected onNavigate: (path: StubbornRoute, params?: AnyObject) => void;

  constructor(routers: StubbornRouter[]) {
    this.routers = [...routers];
    this.selectedProfile = null;
    this.onNavigate = (path, params) => {};
    this.router = null;

    this.routers.forEach((router) => {
      Object.defineProperty(this, router.name, {
        get: () => ({
          mount: () => {
            this.selectedProfile = router;
            //this.router = new RouterAppContext(this, this.selectedProfile);
            /*this.router.setOnNavigate((...params) => {
              this.onNavigate(...params);
            });*/
          },
        }),
      });
    });
    
    //this.router = new RouterAppContext(this, this.selectedProfile);
  }

  setOnNavigate(onNavigate: (path: StubbornRoute, params?: AnyObject) => void) {
    this.onNavigate = onNavigate;
  }

  get RouterDAO() {
    return this.router;
  }

  get mounted() {
    return this.selectedProfile;
  }

  getRouter(routerName: string) {
    const router = this.routers.find((router) => router.name === routerName);
    if (!router) throw new Error('Router Not Found');
    return router;
  }

  getProfileType() {
    let routersType = `interface StubbornRouteEvents {
      // ver qcomo nombrear estas vergas
      beforeMount: string;
      onMount: string;
      beforeRouteChange: string;
      onRouteChange: string;
    }
    
    interface StubbornRouteCondition {
      condition?: string; // esto es una expresion que devuelva true o false
      router: string; //  router name. ej: publico.nahnahnah
      params?: object; // en caso de necesitarlo
    }
    
    interface StubbornRoute {
      name: string;
      path?: string;
      target: StubbornScreen | StubbornRouter | string; // el string puede ser un nombre de otro Router
    }
    
    interface StubbornRouter {
      name: string;
      basePath?: string;
      layout?: StubbornScreen; // <== StubbornScreen con target
      layoutTargetName?: string; // StubbornScreen2.StubbornScreen35.elTargetStubbornScreenADondeSeVaAMontar
      routes: StubbornRoute[];
      redirect?: StubbornRouteCondition[];
      events?: StubbornRouteEvents; // code
    }
    interface SBProfile {
      mounted: StubbornRouter | null;`;
    this.routers.forEach((router) => {
      routersType += `${router.name}: {
            mount: () => void;
        }`;
    });
    routersType = `${routersType} } const SBProfile: SBProfile`;
    return routersType;
  }
}
