import React, {useCallback, useEffect, useState} from 'react';
import {
  Box,
  Button,
  Input,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import { StubbornConfiguration, StubbornSessionConfiguration } from '../../../../../../../business';
import { API_URL } from '../../../../../../../../config';
import {useEditionServiceFind} from "../../../../../../../../platform/api/endpoints/edition";
import Editor from '@monaco-editor/react';
import LoadingButton from '@material-ui/lab/LoadingButton';

interface parametersProps {
  name:string;
  Mandatory:boolean;
}


export interface ParamsSessionProps {
  session: StubbornSessionConfiguration[] | StubbornConfiguration | undefined;
  gettoken?: (e:any)=> void;
}

const useStyles = makeStyles({
  modalContent: {
    display: 'flex',
    flex: 1,
    height:'inherit'
    // border: '1px solid black',
  },
  columnFlex: {
    // border: '1px solid red',
    display: 'flex',
    flexDirection: 'column',
    alignItems:'center',
    height:'100%'
  },
  paramsInput: {
    padding: 10,
  },
  editorNotEditable: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
  },
  inputEditor:{
    width: '50%',
    height: '50px'
  },
  subtitle:{
    marginTop:'10px',
    marginBottom:'2px'
  }
});



export const ParamsSession = ({session, gettoken}: ParamsSessionProps) => {

  const classes = useStyles();
  //@ts-ignore
  const {header, name, service} = session ? session[0] : {};
  const { data, loading, call, error } = useEditionServiceFind({autoCall: false});
  const [parameters, setParameters] = useState<parametersProps[]>();
  const [response,setResponse] = useState('');
  const [params, setParams] = useState<any>();

  const handlefecth = async () =>{
    let newHeader = new Headers();
    newHeader.append("Content-Type", "application/json");
    let body = params;

    try {
      const call = await fetch(`${API_URL}/auth/${name}`,{
        method:'POST',
        headers: newHeader,
        body: JSON.stringify(body)
      });

      let res = await call;
      let token = res.headers.get(header.toString());
      let json = await res.json();
      setResponse(JSON.stringify(json,null,2));
      //@ts-ignore
      gettoken([header, token]);



    } catch (e) {
      setResponse('Error:' + e.message);
      console.log(e);
    }

  }

  const handleChangeParams = useCallback(
    (event, name: string) => {
      setParams({
        ...params,
        [name]: event.target.value,
      });
    },
    [setParams, params],
  );

  useEffect(()=>{
    if (service){
      call({ query: {name: service}});
    }
  },[service,call])

  useEffect(()=>{
    if (data){
      //@ts-ignore
      setParameters(data[0]?.parameters)
    }
  },[data])



  return (
        <div className={classes.modalContent}>
          <div className={classes.columnFlex} style={{ margin: '10px', justifyContent:'space-between', width:'35%'}}>
            <Box className={classes.columnFlex}>
            <h4 style={{ margin: 0, padding: 10 }}>PARAMS</h4>
            {parameters && (
              parameters.map((item)=>(
                <Input style={{width:'90%'}} type={item.name === 'password' ? 'password': 'text'} key={item.name} className={classes.inputEditor} placeholder={item.name} value={params?.[item.name]} onChange={(e) => handleChangeParams(e, item.name)}/>
              ))
            )}
            </Box>
            <Box>
              <LoadingButton loading={loading} variant={'contained'} onClick={()=>{handlefecth()}} >GENERATE</LoadingButton>
            </Box>

          </div>
          <div className={classes.columnFlex} style={{width:'60%', height:'100%', margin:'10px'}}>
              <Editor height="100%" width="100%" theme="vs-dark" defaultLanguage="json" value={response || ''} />

          </div>
        </div>
  );
}