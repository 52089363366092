import { StubbornAttribute, StubbornAttributeGroup } from '../../../../../../../../business/StubbornAttributeMapping';

const attributes = new Set<StubbornAttribute>();
attributes.add({
  label: 'Layout',
  name: 'layouts',
  type: 'breakpoints',
  default: { md: { col: 3 } },
  metadata: { dataType: 'layout' },
});

attributes.add({
  label: 'Style',
  name: 'styles',
  type: 'breakpoints',
  default: {},
  metadata: { dataType: 'styles' },
});

attributes.add({
  label: 'Render condition',
  name: 'renderConditions',
  type: 'code',
  default: ''
});

export const basicAttributeGroup: StubbornAttributeGroup = {
  label: 'Basic',
  name: 'basic',
  priority: 1,
  attributes,
};

const hiddenFieldAttributes = new Set<StubbornAttribute>();
hiddenFieldAttributes.add({
  label: 'Layout',
  name: 'layouts',
  type: 'breakpoints',
  default: {},
  metadata: { dataType: 'layout' },
  disabled: true,
});

hiddenFieldAttributes.add({
  label: 'Style',
  name: 'styles',
  type: 'breakpoints',
  default: {},
  metadata: { dataType: 'styles' },
  disabled: true,
});

export const hiddenFieldsBasicAttributeGroup: StubbornAttributeGroup = {
  label: 'Basic',
  name: 'basic',
  priority: 1,
  attributes: hiddenFieldAttributes,
};