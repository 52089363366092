import {FieldType} from "./FieldType";

class FieldTypeManager {
  protected types = new Map<string, FieldType>();

  get(name: string): FieldType {
    const editor = this.types.get(name);
    if (!editor) throw new Error(`Field Type ${name} not found`);
    return editor;
  }

  register(name: string, type: Omit<FieldType, 'name'>): void {
    this.types.set(name, { ...type, name });
  }

  getRegisteredFields(): FieldType[] {
    return Array.from(this.types.values());
  }

  getDefaultAttributes(name: string) {
    const editor = this.get(name);
    let attributes: any = {};
    editor.attributeGroups.forEach((group) => {
      group.attributes.forEach((attribute) => {
        if (attribute.default !== undefined) {
          attributes = {
            ...attributes,
            [group.name]: {
              ...attributes[group.name],
              [attribute.name]: attribute.default,
            }
          }
        }
      });
    });
    return attributes;
  }

  getAttributesTypes(name: string) {
    const editor = this.get(name);
    let attributes: any = {};
    editor.attributeGroups.forEach((group) => {
      group.attributes.forEach((attribute) => {
        attributes = {
          ...attributes,
          [group.name]: {
            ...attributes[group.name],
            [attribute.name]: attribute.type,
          }
        }
      });
    });
    return attributes;
  }
}

const i = new FieldTypeManager();
export {i as FieldTypeManager};
