import { NodeData } from './types';
import { typesConfig } from './typesConfig';

export const getConfig = (node: NodeData) => !node.assetType ? null : typesConfig[node.assetType] || typesConfig.Datasource;

export const tagsFormatter = (assets: any) => {
  let allTags: string[] = [];
  assets.forEach((asset: any) => {
    if (asset.tags) {
      asset.tags.forEach((tag: any) => {
        allTags = [...allTags, tag];
      });
    }
  });
  return allTags;
};