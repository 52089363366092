import React from 'react';
import {StubbornAsset, StubbornAssetType} from "../../../../../../../business/StubbornAsset";
import {FieldRenderer, ScreenRenderer, NotImplemented, HiddenFieldRenderer} from "./renderers";
import {BaseRendererProps} from "./renderers/BaseRendererProps";

const assetType: { [k in StubbornAssetType]: any } = {
  Field: FieldRenderer,
  Image: NotImplemented,
  Menu: NotImplemented,
  Screen: ScreenRenderer,
  Service: NotImplemented,
  Router: NotImplemented,
  Hidden: HiddenFieldRenderer,
};

export interface StubbornAssetRendererProps extends StubbornAsset, BaseRendererProps {}

export const StubbornAssetRenderer = (props: StubbornAssetRendererProps) => {
  const Comp = assetType[props.type];
  return (
    <Comp {...props} />
  );
};
