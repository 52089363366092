import { StubbornService } from '../../../../business/StubbornAsset';
import { Api, QueryObject } from '../../../../../lib/HttpService';

export class ServicesAppContext {
  protected services: Pick<StubbornService, 'name' | 'description' | 'parameters' | 'type'>[];
  protected api: Api;
  protected dani: any;

  constructor(services: Pick<StubbornService, 'name' | 'description' | 'parameters' | 'type'>[], api: Api) {
    this.services = services;
    this.api = api;

    services.forEach((service) => {
      Object.defineProperty(this, service.name, {
        get: () => (query?: QueryObject) => {
          const endpoint = api.getEndpoint('/service');
          return endpoint.findOne(service.name, query);
        },
      });
    });
  }

  getServicesType() {
    let serviceType = `interface ExecuteResponse<T> {
      data: T | null | undefined;
      statusCode: number;
      headers: {[k: string]: any};
      error?: any;
    }

    interface SBContext {
      Params: {[k: string]: any};
    }

    const SBContext: SBContext;
    interface SBServices {`;
    this.services.forEach((service) => {
      serviceType = `${serviceType} ${service.name}: (query: {${service.parameters.map((param) => `${param.name}${!param.mandatory ? '?' : ''}: ${param.type}`).join(',')}}) => Promise<ExecuteResponse<any>>;`;
    });
    serviceType = `${serviceType} } const SBServices: SBServices`;
    return serviceType;
  }
}
