import React, { useCallback, useMemo, useState } from 'react';
import { Profile as ProfileType } from './types';
import { ProfileContext, useProfileContext } from './ProfileContext';
import { Profile } from './Profile';
import { PermissionProvider } from '../permission';
import { Rules } from '../permission/types';

export const PhinxAppProvider = ({
  profiles,
  profile,
  children,
  rules,
}: {
  profiles: ProfileType[];
  children: JSX.Element;
  profile: string;
  rules: Rules[];
}) => {
  const [activeProfileName, setActiveProfileName] = useState<string>(profile);
  const getActiveProfile = useCallback(() => {
    const activeProfile = profiles.find(
      ({ name }) => name === activeProfileName,
    );
    if (!activeProfile) {
      // eslint-disable-next-line
      console.error(`Phinx Profile ${activeProfileName} not found`);
      return profiles.find(({ name }) => name === profile) || profiles[0];
    }
    return activeProfile;
  }, [profile, profiles, activeProfileName]);
  const value = useMemo(
    () => ({
      profile: activeProfileName,
      setProfile: setActiveProfileName,
      getActiveProfile,
    }),
    [activeProfileName, getActiveProfile],
  );
  return (
    <PermissionProvider rules={rules}>
      <ProfileContext.Provider value={value}>
        {children}
      </ProfileContext.Provider>
    </PermissionProvider>
  );
};

export const PhinxApp = () => {
  const { getActiveProfile } = useProfileContext();
  const activeProfile = useMemo(() => getActiveProfile(), [getActiveProfile]);
  return <Profile profile={activeProfile} />;
};
