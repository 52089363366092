import React, { useEffect } from "react";
import { Link as MuiLink } from "@material-ui/core";
import { FieldBaseProps } from "../FieldBaseProps";
import { useEditorScreenContext } from "../../../../../EditorScreenContext";

type Variant = "body1" | "body2" | "button" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "inherit" | "overline" | "subtitle1" | "subtitle2";

export interface LinkProps extends FieldBaseProps {
  component: {
    color?: "primary" | any;
    href?: string;
    title?: string;
    underline?: "always" | "hover" | "none";
    variant?: Variant;
    target?: "_blank" | "_self";
  };
}

export const Link = ({ component, events, states, styles }: LinkProps) => {
  const { editable } = useEditorScreenContext();

  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <MuiLink style={styles} target={component.target} href={component.href} color={component.color} underline={component.underline} variant={component.variant}>
      {component.title}
    </MuiLink>
  );
};
