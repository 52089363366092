import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useEditorServiceContext } from '../../../../EditorServiceContext';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon, IconButton,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useEditionConfigurationSessionFind } from '../../../../../../../../platform/api/endpoints/edition';
import MenuItem from '@material-ui/core/MenuItem';
import { StubbornConfiguration, StubbornSessionConfiguration } from '../../../../../../../business';
import { InfoSession } from './InfoSession';
import { ParamsSession } from './ParamsSession';




export interface PreviewModalProps {
  open: boolean;
  onClose: () => void;
}
export interface sessionProps {
  name: string;
}


const useStyles = makeStyles({
  modalContent: {
    display: 'flex',
    flex: 1,
    height: 600,
   //border: '1px solid black',
  },
  columnFlex: {
   // border: '1px solid red',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    position: 'relative',
  },
  paramsInput: {
    padding: 10,
  },
  editorNotEditable: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
  },
});

export const SessionsModal = ({ open, onClose }: PreviewModalProps) => {
  const classes = useStyles();
  const { service } = useEditorServiceContext();
  const { parameters } = service;
  const [selectedSession, setSelectedSession] = useState<StubbornSessionConfiguration[] | StubbornConfiguration | undefined>();
  const [selectedToken, setSelectedToken] = useState<any>();
  const [newToken, setNewToken] = useState<any>();
  const [isNew, setIsNew ] = useState(false);
  const [tokenName, setTokenName] = useState<any>([]);
  const [tokens, setTokens] = useState();
  const [openGenerate, setOpenGenerate] = useState(false);
  const [tabSelected, setTabSelected] = useState('');
  const [state, setState] = useState('')

    const {data, loading, error} = useEditionConfigurationSessionFind()

  const handleSessionSelected = (event:any) =>{
    let session = data?.filter(item => item.name === event.target.value) as StubbornSessionConfiguration[]
    setSelectedSession(session);
  }


  //console.log('seledtedToken',selectedToken[0]);



  const handleTokens = useCallback(() =>{
    try {
      let local = JSON.parse(localStorage['sb-editors-token']);
      //@ts-ignore
      local = local[selectedSession[0]?.name];
      setTokens(local);
    } catch (e) {
      console.log('sin tokens',e);
    }
  }, [selectedSession]);

  const handleDeleteTokens = () =>{
    try {
      let local = JSON.parse(localStorage['sb-editors-token']);
      console.log('seledtedtoken', Object.keys(selectedToken)[0]);
      //@ts-ignore
      console.log('a borrar',local[selectedSession[0]?.name])
      //@ts-ignore
      console.log('a borrar',local[selectedSession[0]?.name][Object.keys(selectedToken)[0]])
      //@ts-ignore
      delete local[selectedSession[0]?.name][Object.keys(selectedToken)[0]];
      localStorage['sb-editors-token'] = JSON.stringify(local);
      setState('');
      setSelectedToken(undefined);
      setIsNew(false);
      handleTokens();
    } catch (e) {
      console.log('sin tokens',e);
    }
  }

  const handleNewToken = (token:any) =>{
    console.log('que es tokenName',typeof tokenName);
      if (tokenName[0]){
        //@ts-ignore
        setNewToken({[tokenName[0]]:token})
      } else {
        setTokenName([`Token:${Math.floor(Math.random() * (1000 - 200))}`])
        setNewToken({[`Token:${Math.floor(Math.random() * (1000 - 200))}`]:token})
      }
  }

  const handleActualToken = (item:any) =>{
      //@ts-ignore
    setSelectedToken({[item]:tokens[item]})
  }

  const closeModal = () => {
    setState('');
    setSelectedToken(undefined);
    setIsNew(false);
    onClose()
  }

  const saveToken = useCallback( () => {
    if (isNew && !newToken){
      setState('Error: nada para salvar');
    } else{

    if(newToken){
      if (!localStorage.getItem('sb-editors-token')){
        //@ts-ignore
        console.log('token',newToken);
        //@ts-ignore
        console.log('sessionName',selectedSession[0]?.name);
        //@ts-ignore
        let obj = {[selectedSession[0]?.name]:newToken};
        localStorage['sb-editors-token'] = JSON.stringify(obj);
        setState('');
        setSelectedToken(undefined);
        setIsNew(false);
        setState('Guardado');
        handleTokens();
      } else {
        let local = JSON.parse(localStorage['sb-editors-token']);
        //@ts-ignore
        if (!local[selectedSession[0]?.name]){
          //@ts-ignore
          let obj = {[selectedSession[0]?.name]:newToken};
          Object.assign(local,obj);
          localStorage['sb-editors-token'] = JSON.stringify(local);
          setState('');
          setSelectedToken(undefined);
          setIsNew(false);
          handleTokens();
        } else {
          //@ts-ignore
          let session = local[selectedSession[0]?.name];
          console.log('session',session);
          //@ts-ignore
          let obj = newToken;
          console.log('token solamente',Object.values(obj));
          Object.assign(session,obj)
          console.log('session nuevo', session);
          //console.log('session',session);
          //@ts-ignore
          //Object.assign(local[selectedSession[0]?.name],session);
          local[selectedSession[0]?.name] = session;
          console.log('token nuevo', local);
          localStorage['sb-editors-token'] = JSON.stringify(local);
          setState('');
          setSelectedToken(undefined);
          setIsNew(false);
          setState('Guardado');
          handleTokens();

        }
      }
    }
    else if(tokenName[0] !== Object.keys(selectedToken)[0]){
      let local = JSON.parse(localStorage['sb-editors-token']);
      console.log('local',local);
      //@ts-ignore
      let session = local[selectedSession[0]?.name];
      console.log('token',Object.values(selectedToken));
      //@ts-ignore
      let obj = {[tokenName[0]]:Object.values(selectedToken)[0]};
      console.log('token solamente',Object.values(selectedToken)[0]);
      Object.assign(session,obj)
      //console.log('session',session);
      //@ts-ignore
      Object.assign(local[selectedSession[0]?.name],session);
      //@ts-ignore
      delete local[selectedSession[0]?.name][Object.keys(selectedToken)[0]];
      localStorage['sb-editors-token'] = JSON.stringify(local);

      setState('');
      setSelectedToken(undefined);
      setIsNew(false);
      setState('Guardado');
      handleTokens();

    } else {
      setState('Error: sin cambios');
    }
    }

  },[newToken,selectedToken,tokenName,handleTokens,isNew,selectedSession])

  useEffect(()=>{
    if (selectedSession) {
      handleTokens();
      setSelectedToken(undefined);
      setTabSelected('');
    }
  },[selectedSession, handleTokens])

  useEffect(()=>{
    if (selectedToken){
      setTokenName(Object.keys(selectedToken));
      console.log(Object.keys(selectedToken));
      setIsNew(false);
      setNewToken(undefined);
      setState('Editando Token');
    }
  },[selectedToken])

  useEffect(()=>{
    if (isNew){
      setSelectedToken(undefined);
      setTabSelected('');
      setTokenName([]);
      setState('Nuevo Token');
    }
  },[isNew])

  useEffect(()=>{
    if (newToken){
      setTimeout(()=>{
        saveToken();
      },1000);

    }
  },[newToken,saveToken])

  useEffect(()=>{
    if (state === 'Guardado'){
      setTimeout(()=>{
        setState('');
      },1000);

    }
  },[state])






  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose}>
      <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
      <DialogTitle>Sessions</DialogTitle>
      <DialogTitle>{state}</DialogTitle>
      </Box>
      <DialogContent>
        <div className={classes.modalContent}>
          <div className={classes.columnFlex} style={{ marginRight: 20, width:'30%' }}>
            <Box sx={{
              margin:'10px',
            }}>
            <h4 style={{ margin: 0, padding: 10 }}>Configuracion</h4>
            <Select
              sx={{width:'100%', height:'50px'}}
              onChange={event => {handleSessionSelected(event)}}
            >
              {data && (
                data.map((item)=> (
                <MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>
              ))
              )
              }
            </Select>
            <Box>
              {selectedSession && (
                tokens ? (
                //@ts-ignore
                Object.keys(tokens).map(item => (<MenuItem key={item} onClick={()=> handleActualToken(item)} value={tokens[item]}>{item}</MenuItem>))
                ) : <h4>sin tokens</h4>
              )}
              {selectedSession && (<Box>
                <IconButton onClick={() => handleDeleteTokens()}>
                  <Icon style={{ color: 'black', marginLeft:'0' }}>remove</Icon>
                </IconButton>
                <IconButton onClick={() => setIsNew(true)}>
                  <Icon style={{ color: 'black', marginLeft:'0' }}>add</Icon>
                </IconButton>
              </Box>)}
            </Box>
            </Box>
          </div>
          <div className={classes.columnFlex} style={{width:'70%',border:'1px solid lightgray', borderRadius:5}}>
            {(selectedToken || isNew) ? (
              <>
            <Box sx={{
              margin:'10px',
              height:'20%',
            }}>
              {(selectedToken || isNew) &&
                <Box sx={{display:'flex',flexDirection:'row', justifyContent:'space-between', alignItems:'start', marginBottom:'5px'}}>
              <h2 style={{margin:0}}>{
                //@ts-ignore
                selectedSession[0].name
              }</h2>
                  <Button sx={{height:'35px'}}  variant={'outlined'} onClick={()=>{saveToken()}}>save</Button>
                </Box>}
              {(selectedToken || isNew) &&
              <Box sx={{display:'flex',flexDirection:'row', justifyContent:'space-between'}}>
                <TextField sx={{ width:'45%'}} label={'alias'} onChange={(e)=> {setTokenName([e.target.value])}} value={ tokenName ? tokenName : Object.keys(selectedToken) ? Object.keys(selectedToken) : ''}/>
                {/*//@ts-ignore*/}
                <TextField sx={{ width:'45%'}} label={'token'} value={ selectedToken ? selectedToken[Object.keys(selectedToken)[0]][1]:newToken ? Object.values(newToken)[0]: ''}/>
                {/*{<p style={{width:'300px',border:'1px solid yellow'}}>Current Value:{Object.values(selectedToken)}</p>}*/}
              </Box>}
              {/*{<h4>Alias:{Object.keys(selectedToken)}</h4>}*/}

              {/*{<p style={{width:'300px',border:'1px solid yellow'}}>Current Value:{Object.values(selectedToken)}</p>}*/}
            </Box>
            <Box sx={{margin:'10px', height:'70%', overflow:'hidden'}} >
              <Box sx={{background:'lightgray', borderRadius:2}}>
                <Button sx={{background:'white', borderRadius:2, borderBottomLeftRadius:0, borderBottomRightRadius:0, marginRight:'10px'}} onClick={()=> {setTabSelected('params')}}>params</Button>
                <Button sx={{background:'white', borderRadius:2, borderBottomLeftRadius:0, borderBottomRightRadius:0, marginRight:'10px'}} onClick={()=> {setTabSelected('info')}}>Info</Button>
              </Box>
              <Box sx={{height:'90%'}}>
                  {tabSelected === 'info' && <InfoSession session={selectedSession}/>}
                {(tabSelected === 'params' || tabSelected === '') && <ParamsSession gettoken={(token)=> handleNewToken(token)} session={selectedSession}/>}
              </Box>
            </Box>
              </>
             ): <Box sx={{height:'100%',display: 'flex', justifyContent:'center', alignItems:'center'}}>
              <h1 style={{color:'lightgray'}}>Seleccione o cree un Token</h1>
            </Box>}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={()=>{closeModal()}}>
          Close
        </Button>
      </DialogActions>
     {/* <GenerateSessionModal open={openGenerate} session={selectedSession} onClose={()=> setOpenGenerate(false)}/>*/}
    </Dialog>
  );
}
