import { ChangeEvent, useCallback, useState, useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import LoadingButton from '@material-ui/lab/LoadingButton';
import { Box } from '@material-ui/system';
import { useEditionConfigurationSessionCreate } from '../../../../../../../../../platform/api/endpoints/edition';
import { StubbornSessionConfiguration } from '../../../../../../../../business/StubbornConfiguration';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useAssetContext } from '../../../../../context/AssetContext';
import { StubbornData } from '../../../../../../../../business';

export interface SessionConfigurationFormProps {
  configuration: StubbornSessionConfiguration;
  onUpdate: (newConfiguration: StubbornSessionConfiguration) => Promise<void>;
  onCreate: (newConfiguration: StubbornSessionConfiguration) => Promise<void>;
  isUpdate: boolean;
}

const getTextFieldProps = (
  key: keyof StubbornSessionConfiguration,
  label: string,
  {
    configuration,
    loading,
  }: {
    configuration: StubbornSessionConfiguration;
    loading: boolean;
  },
) => ({
  id: key,
  label,
  name: key,
  fullWidth: true,
  value: configuration[key],
  error: !configuration[key],
  helpertext: !configuration[key] ? 'Campo requerido' : '',
  disabled: loading,
});

export const SessionConfigurationForm = ({ configuration: initialConfiguration, onUpdate, onCreate, isUpdate }: SessionConfigurationFormProps) => {
  const { assets } = useAssetContext();
  const services = useMemo(() => assets.filter((asset: StubbornData) => asset.type === 'Service'), [assets]);
  const { call } = useEditionConfigurationSessionCreate();
  const [configuration, setConfiguration] = useState<StubbornSessionConfiguration>(initialConfiguration);
  const [loading, setLoading] = useState(false);

  const onTextFieldChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setConfiguration((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  }, []);

  const onSelectChange = useCallback((event: any /* SelectChangeEvent<unknown> */) => {
    setConfiguration((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  }, []);

  const onClickSave = useCallback(async () => {
    try {
      setLoading(true);
      if (!configuration) return;
      setLoading(false);
      if (isUpdate) {
        await onUpdate(configuration);
        alert('Session configuration updated');
      } else {
        await onCreate(configuration);
        alert('Session configuration created');
      }
    } catch (e: any) {
      setLoading(false);
      alert('Something went wrong');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call, configuration]);

  const commonProps = { configuration, loading };

  return (
    <Grid>
      <TextField margin="normal" onChange={onTextFieldChange} {...getTextFieldProps('name', 'Name', commonProps)} disabled={true} />
      <TextField margin="normal" onChange={onTextFieldChange} {...getTextFieldProps('header', 'Header', commonProps)} />
      <FormControl margin="normal" fullWidth>
        <InputLabel id="service-select-label">Service</InputLabel>
        <Select labelId="service-select-label" onChange={onSelectChange} {...getTextFieldProps('service', 'Service', commonProps)}>
          {services.map((service: StubbornData) => (
            <MenuItem key={service.name} value={service.name}>
              {service.name.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField margin="normal" onChange={onTextFieldChange} {...getTextFieldProps('secret', 'Secret', commonProps)} />
      <TextField margin="normal" onChange={onTextFieldChange} {...getTextFieldProps('condition', 'Condition', commonProps)} />
      <Box sx={{ mx: 'auto', width: 200 }}>
        <LoadingButton color="primary" variant="contained" autoFocus onClick={onClickSave} disabled={loading} loading={loading}>
          {isUpdate ? 'Save' : 'Create'}
        </LoadingButton>
      </Box>
    </Grid>
  );
};
