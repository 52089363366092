import { AssetView } from '../types';
import { CloneAction, RemoveAction } from '../actions';
import { ScreenRelatedAssetsPanel, BasicInformationPanel } from '../panels';
import {PluginConfigurationPanel} from "../panels/PluginConfigurationPanel";

export const PluginConfigurationAssetView: AssetView = {
  type: 'PluginConfiguration',
  panels: [
    {
      label: 'Configuration',
      component: PluginConfigurationPanel,
    },
  ],
  actions: [CloneAction, RemoveAction],
};
