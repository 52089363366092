import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Icon, Switch } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Editor from '@monaco-editor/react';
import { BaseAttributeEditorProps } from './BaseAttributeEditorProps';
import { EditorLayout } from '../EditorLayout';
import { EditorStyles } from '../EditorStyles';
import { Modal } from '../../../../../../../commons/ui';
import { EditorMapper, Mapper } from '../EditorMapper';
import { EditorParameters } from '../EditorParameters';

const useStyles = makeStyles({
  backdrop: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 16,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#00000080',
    zIndex: 2000,
    top: 0,
    left: 0,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    height: '75%',
    minWidth: 600,
    overflowY: 'visible',
    overflowX: 'auto',
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 10px',
  },
  modalContent: {
    display: 'flex',
    flex: 1,
    padding: '0px 10px',
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    justifyContent: 'flex-end',
  },
  cancelButton: { marginRight: 20 },
  switcher: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export interface BreakpointPropertyEditorProps extends BaseAttributeEditorProps {}

export const BreakpointPropertyEditor = ({ value, onChange, onCancel, onSave, cellName, metadata }: BreakpointPropertyEditorProps) => {
  const classes = useStyles();

  const [isAdvancedMode, setAdvancedMode] = useState<boolean>(false);
  const [intermediateValue, setIntermediateValue] = useState<string>(JSON.stringify(value, null, '\t'));
  const [layoutState, setLayoutState] = useState<any>({});
  const [readyToSave, setReadyToSave] = useState(false);

  const setBreakpointParams = useCallback(
    (property: string, value: any, breakpointCode: string) => {
      const layout = layoutState;
      if (value === 'Unset') {
        delete layout[breakpointCode][property];
        setLayoutState({ ...layout });

        onChange({ ...layout });
      } else {
        setLayoutState({
          ...layout,
          [breakpointCode]: {
            ...layout[breakpointCode],
            [property]: value,
          },
        });

        onChange({
          ...layout,
          [breakpointCode]: {
            ...layout[breakpointCode],
            [property]: value,
          },
        });
      }
    },
    [layoutState, onChange],
  );

  const setBreakPointDisabled = useCallback(
    (breakpointCode: string, val: boolean) => {
      const layout = layoutState;
      if (!val) {
        delete layout[breakpointCode];
        setLayoutState({ ...layout });

        onChange({ ...layout });
      } else {
        setLayoutState({
          ...layout,
          [breakpointCode]:
            metadata?.dataType === 'styles'
              ? {}
              : {
                  col: 1,
                  span: 1,
                  spanAfter: 1,
                },
        });

        onChange({
          ...layout,
          [breakpointCode]:
            metadata?.dataType === 'styles'
              ? {}
              : {
                  col: 1,
                  span: 1,
                  spanAfter: 1,
                },
        });
      }
    },
    [layoutState, metadata?.dataType, onChange],
  );

  const onClose = () => {
    setLayoutState({});
  };

  const handleOnBlur = useCallback(
    (event) => {
      if (isAdvancedMode || metadata?.dataType === 'mapper' || metadata?.dataType === 'params') onChange(JSON.parse(intermediateValue));
      if (metadata?.dataType === 'styles' && !isAdvancedMode) onChange(layoutState);
      setReadyToSave(true);
      event.stopPropagation();
    },
    [isAdvancedMode, setReadyToSave, intermediateValue, onChange, layoutState, metadata],
  );

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Escape':
          onCancel();
          e.stopPropagation();
          e.preventDefault();
          break;
      }
    },
    [onCancel],
  );

  const callOnCancel = () => {
    if (!isAdvancedMode) onClose();
    onCancel();
  };

  const callOnBlur = (e: any) => {
    handleOnBlur(e);
  };

  useEffect(() => {
    try {
      const layouts = value;
      if (layouts) setLayoutState(layouts);
    } catch (e: any) {
      console.error('Could not find breakpoint layout or style data', e);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (readyToSave) onSave();
  }, [readyToSave, onSave]);

  const setIntermediateStylesValue = useCallback(
    (value: string, breakpointCode: string) => {
      setLayoutState({ ...layoutState, [breakpointCode]: JSON.parse(value) });
    },
    [setLayoutState, layoutState],
  );

  const layoutContent = useMemo(() => <EditorLayout layoutState={layoutState} setBreakpointParams={setBreakpointParams} setBreakPointDisabled={setBreakPointDisabled} />, [layoutState, setBreakPointDisabled, setBreakpointParams]);

  const stylesContent = useMemo(() => <EditorStyles setIntermediateStylesValue={setIntermediateStylesValue} styleState={layoutState} setBreakPointDisabled={setBreakPointDisabled} />, [layoutState, setIntermediateStylesValue, setBreakPointDisabled]);

  const mapperContent = useMemo(() => <EditorMapper value={intermediateValue} setValue={setIntermediateValue} />, [intermediateValue, setIntermediateValue]);
  
  const paramsContent = useMemo(() => <EditorParameters value={intermediateValue} setValue={setIntermediateValue} />, [intermediateValue, setIntermediateValue]);

  const selectEditor: Record<string, JSX.Element> = {
    styles: stylesContent,
    layout: layoutContent,
    mapper: mapperContent,
    params: paramsContent,
  };

  return (
    <Modal>
      <div className={classes.backdrop} onClick={onCancel}>
        <div
          className={classes.modal}
          style={isAdvancedMode ? { width: '80%' } : { width: 'auto' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={handleKeyPress}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={classes.modalHeader}
          >
            <h3>{cellName}</h3>
            <div className={classes.switcher}>
              <Typography>Advanced mode</Typography>
              <Switch checked={isAdvancedMode as boolean} onChange={() => setAdvancedMode(!isAdvancedMode)} />
            </div>
            <Icon component={CloseIcon} onClick={onCancel} />
          </div>
          <div className={classes.modalContent}>
            <div style={{ display: 'flex', flex: 1 }}>{isAdvancedMode ? <Editor height="100%" width="100%" theme="vs-dark" defaultLanguage="json" defaultValue={intermediateValue} onChange={(value) => setIntermediateValue(value ? value?.toString() : '')} /> : selectEditor[metadata?.dataType]}</div>
          </div>
          <div className={classes.modalFooter}>
            <Button className={classes.cancelButton} variant="outlined" fullWidth={false} onClick={callOnCancel}>
              Cancelar
            </Button>
            <Button variant="contained" fullWidth={false} onClick={callOnBlur}>
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
