import { Card, CardContent, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { getAssetIcon } from '../../../../../../../utils/assetIcon';
import { useStyles } from '../../../../../Main/styles';

interface AssetCardProps {
  assetName: string;
  type: string;
}

export const AssetCard = ({ type, assetName }: AssetCardProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Card className={classes.assetCard}>
      <CardContent>
        {getAssetIcon(type, '#fff')}
        <Typography sx={{ color: '#fff', fontWeight: 'bold' }} gutterBottom>
          {assetName}
        </Typography>
      </CardContent>
    </Card>
  );
};
