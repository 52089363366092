import React, { FunctionComponent } from 'react';
import { ListItem, ListItemIcon, ListItemText, Avatar as MuiAvatar, Grid, Typography } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import { makeStyles, useTheme, createStyles } from '@material-ui/styles';
import { useDrag } from 'react-dnd';
import { AppThemeType } from '../../../../../../../theme';
import { FieldType } from '../fields/manager/FieldType';
import { BaseAppConst } from '../../../../../../const/BaseAppConst';

const useStyles = makeStyles<AppThemeType>((theme) =>
  createStyles({
    drawer: {
      width: BaseAppConst.uiEditorToolbox.drawerWidth,
      flexShrink: 0,
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    drawerPaper: {
      width: BaseAppConst.uiEditorToolbox.drawerWidth,
    },
    groupTitle: {
      margin: '0 10px 0 5px',
      fontWeight: 'bold',
    },
    icons: { display: 'flex', padding: 10, height: 40, width: 40, justifyContent: 'center', alignContent: 'center', backgroundColor: '#1976D2' },
  }),
);

export interface ItemListProps {
  name: string;
  field: FieldType;
  image: string | undefined;
  label: string;
  id: string;
  disabled: boolean;
  onClick: (field: FieldType) => void;
}

interface DropResult {
  allowedDropEffect: string;
  dropEffect: string;
  name: string;
}

export const DraggableItemList: FunctionComponent<ItemListProps> = ({ field, name, image, label, id, disabled, onClick }) => {
  const theme = useTheme();
  //@ts-ignore
  const classes = useStyles(theme);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'component',
    item: { name, field },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      if (item && dropResult) {
        onClick(item.field);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <Grid key={id} ref={drag} style={{ backgroundColor: isDragging ? 'lightgrey' : '#4b4e61', margin: 5, width: "40%", height: 100, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      {image ? (
          <MuiAvatar src={image} sizes="1" className={classes.icons} />
        ) : (
          <MuiAvatar style={{ backgroundColor: '#1976D2' }}>
            <ImageIcon />
          </MuiAvatar>
        )}
      <Typography className={classes.title}>{label}</Typography>
    </Grid>
    
  );
};
