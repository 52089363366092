import _ from 'lodash';
import {StubbornAsset, StubbornScreen} from "../business/StubbornAsset";

export const getAssetByAssetSelector = (asset: StubbornScreen, assetSelector: number[], max: number = assetSelector.length - 1, index = 0): StubbornAsset => {
  if (max < 0) {
    return asset;
  }
  const item = asset.metadata[assetSelector[index]];
  if (index + 1 > max) {
    return item;
  }
  return getAssetByAssetSelector(item as StubbornScreen, assetSelector, max, index + 1);
};

export const setAssetByAssetSelector = (asset: StubbornScreen, assetSelector: number[], assetToSet: StubbornAsset): StubbornScreen => {
  if (!assetSelector.length) {
    return { ...assetToSet } as StubbornScreen;
  }
  const path = assetSelector.map(num => `metadata[${num}]`).join('.');
  let _asset: StubbornScreen = { ...asset };
  _.set(_asset, path, assetToSet)
  return _asset;
};

export const getContainerByAssetSelector = (asset: StubbornScreen, assetSelector: number[]): StubbornScreen => {
  const newAssetSelector =
    assetSelector.length > 1 ? [assetSelector[assetSelector.length - 2]] : [];
  const selectedScreen = getAssetByAssetSelector(
    asset,
    newAssetSelector
  ) as StubbornScreen;
  return selectedScreen
}
