import { AssetView } from '../types';
import { CloneAction, RemoveAction } from '../actions';
import { ScreenRelatedAssetsPanel, BasicInformationPanel } from '../panels';

export const ScreenAssetView: AssetView = {
  type: 'Screen',
  panels: [
    {
      label: 'Basic',
      component: BasicInformationPanel,
    },
    {
      label: 'Related Assets',
      component: ScreenRelatedAssetsPanel,
    },
  ],
  actions: [CloneAction, RemoveAction],
};
