import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AppThemeType } from '../../../../../../../theme';
import { useEditorServiceContext } from '../../../EditorServiceContext';
import { StubbornService, StubbornServiceAction } from '../../../../../../business/StubbornAsset';
import { ActionEditor } from './actions';
import { ServicePropertyEditor } from './service';
import { BaseAppConst } from '../../../../../../const/BaseAppConst';
import { confirmDialog } from '../../../../../../services';
import { ServiceEditorToolbox } from '../toolbox/ServiceEditorToolbox';

const useStyles = makeStyles<AppThemeType>(() => ({
  drawer: {
    width: BaseAppConst.uiEditorProperties.drawerWidth,
    flexShrink: 0,
    height: '100%',
    backgroundColor: '#2F343D',
  },
  drawerPaper: {
    width: BaseAppConst.uiEditorProperties.drawerWidth,
    overflowY: 'auto',
    maxHeight: '93%',
    backgroundColor: '#2F343D',
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  tab: {
    width: '100%',
    display: 'flex',
    height: 66,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 600,
    color: 'white',
    backgroundColor: '#4b4e61',
  },
  selectedTab: {
    color: '#707ef9',
    width: '100%',
    display: 'flex',
    height: 66,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 600,
  },
}));

const isServiceAction = (item: StubbornService | StubbornServiceAction): item is StubbornServiceAction => {
  return (item as StubbornServiceAction).script !== undefined;
};

export const ServiceEditorProperties = () => {
  const { service, setService, selectedAction, setSelectedAction } = useEditorServiceContext();
  const classes = useStyles();
  const [tabSelected, setTabSelected] = useState<string>('toolbar');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selected = useMemo<StubbornService | StubbornServiceAction>(() => (selectedAction === null ? service : service.actions[selectedAction]), [selectedAction]);

  useEffect(() => {
    isServiceAction(selected) && setTabSelected('properties');
  }, [selected]);

  // callbacks de las actions
  const onActionChange = useCallback(
    (newAction: StubbornServiceAction) => {
      if (selectedAction !== null) {
        setService((prev) => {
          const newService = {
            ...prev,
            actions: [...prev.actions],
          };
          newService.actions[selectedAction as number] = newAction;
          return newService;
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedAction],
  );

  const removeAction = useCallback(async () => {
    const confirm = await confirmDialog.show({
      title: 'Confirmacion',
      content: 'Estas seguro de eliminar esta action?',
    });
    if (confirm) {
      setSelectedAction(null);
      setService((prev) => ({
        ...prev,
        actions: prev.actions.filter((action, index) => index !== selectedAction),
      }));
    }
  }, [selectedAction, setSelectedAction, setService]);

  // service callbacks
  const onServiceChange = useCallback(
    (newService: StubbornService) => {
      setService(newService);
    },
    [setService],
  );

  return (
    <Box className={classes.drawer} key={selectedAction}>
      <div className={classes.tabContainer}>
        <div className={tabSelected === 'toolbar' ? classes.selectedTab : classes.tab} onClick={() => setTabSelected('toolbar')}>
          Toolbox
        </div>
        <div className={tabSelected === 'properties' ? classes.selectedTab : classes.tab} onClick={() => setTabSelected('properties')}>
          Properties
        </div>
      </div>
      {tabSelected === 'properties' ? <Paper className={classes.drawerPaper}>{isServiceAction(selected) ? <ActionEditor action={selected} onActionChange={onActionChange} onRemoveClick={removeAction} /> : <ServicePropertyEditor service={service} onServiceChange={onServiceChange} />}</Paper> : <ServiceEditorToolbox />}
    </Box>
  );
};
