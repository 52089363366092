import { useMemo, useState, SyntheticEvent } from 'react';
import { Grid } from '@material-ui/core';
import { AssetPanelCompProps } from '../../types';
import { StubbornAsset, StubbornScreen, StubbornService, StubbornRouter } from '../../../../../../../business';
import { useAssetContext } from '../../../../context/AssetContext';
import { confirmDialog } from '../../../../../../../services';
import { AddTags } from './components/AddTags';
import { BasicInformation } from './components/BasicInformation';

export interface BasicInformationPanelProps extends AssetPanelCompProps<StubbornService | StubbornScreen | StubbornRouter> {}

export const BasicInformationPanel = ({ asset }: BasicInformationPanelProps) => {
  const tags = useMemo(() => Array.from(asset.tags || []), [asset.tags]);
  const [loading, setLoading] = useState(false);
  const { reloadAssets, assetFns } = useAssetContext();
  const [newTags, setNewTags] = useState<string[]>([]);

  const handleAddTag = (e: SyntheticEvent, value: (string | string[])[]) => {
    const cleanedValues = new Set(value);
    setNewTags(Array.from(cleanedValues) as string[]);
  };

  const handleDelete = async (tagToRemove: string) => {
    const confirm = await confirmDialog.show({
      title: 'Warning',
      content: 'Confirm tag removal',
    });
    if (!confirm) return;
    const modifiedAsset = {
      ...asset,
      tags: Array.from(asset.tags).filter((assetTag: string) => tagToRemove !== assetTag),
    };
    try {
      await assetFns.update(modifiedAsset.type, modifiedAsset);
      alert('Tag removed successfully');
      reloadAssets({});
    } catch (error) {
      console.error(error);
      alert('Something went wrong');
    }
  };

  const handleSaveTags = async () => {
    try {
      setLoading(true);
      const updatedService: StubbornAsset = { ...asset, tags: [...asset.tags, ...newTags] };
      await assetFns.update(updatedService.type, updatedService);
      reloadAssets({});
      setNewTags([]);
      alert('Tags added successfully');
    } catch (error) {
      console.error(error);
      alert('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container alignItems="center" flexDirection="column">
      <Grid item>
        <BasicInformation name={asset.name} type={asset.type} tags={tags} handleDelete={handleDelete} />
      </Grid>
      <Grid item marginTop="30px" paddingX="20px">
        <AddTags loading={loading} handleSaveTags={handleSaveTags} newTags={newTags} handleAddTag={handleAddTag} />
      </Grid>
    </Grid>
  );
};
