import { StubbornAsset, StubbornAssetAttributes, StubbornService, StubbornServiceAction } from '../business/StubbornAsset';
import { AssetNameSpaces } from '../screens/Private2/commons/components/AssetView/types';
import { StubbornAssetTypeKey, StubbornData, StubbornScreen, StubbornRouter, StubbornRoute, StubbornRouteKey} from '../business';

// TODO: Apply design patterns to this class.
export class RelatedAssets {
  private asset: StubbornData;
  private relatedAssets: Set<string>;
  private findServicesRegex: RegExp = /(?<=SBServices\.)(.*?)(?=\()/g;
  private stringDivider = '///';

  constructor(asset: StubbornData) {
    this.asset = asset;
    this.relatedAssets = new Set([]);
  }

  private getServicesMatches(event: string): string[] | null {
    return event.match(this.findServicesRegex);
  }

  private addAsset(type: string, assetName: string = '') {
    this.relatedAssets.add(`${type}${this.stringDivider}${assetName}`);
  }

  private generateAssetsArray(): [string, string][] {
    return Array.from(this.relatedAssets).map((relAsset: string) => {
      const [type, assetName] = relAsset.split(this.stringDivider);
      return [type, assetName];
    });
  }

  private searchInAttributes(attributes: StubbornAssetAttributes) {
    Object.entries(attributes).forEach((attribute) => {
      const attributeKey = attribute[0];
      const attributeValue = attribute[1];

      if (attributeKey === 'iterator' && attributeValue.service?.serviceName) {
        // "iterator" key exists for Screen assets.
        this.addAsset(StubbornAssetTypeKey.SERVICE, attributeValue.service.serviceName);
        return;
      }

      if (attributeKey !== 'events') return;

      Object.values(attributeValue).forEach((value: unknown) => {
        if (typeof value !== 'string') return;
        this.getServices(value);
      });
    });
  }

  private searchForScreens(asset: StubbornAsset) {
    if (asset.type === StubbornAssetTypeKey.SCREEN) {
      this.addAsset(StubbornAssetTypeKey.SCREEN, asset.name);
      return;
    }

    // the target Screen might be empty.
    if (asset.fieldType === 'targetScreen' && !!asset.attributes.states?.target) {
      this.addAsset(StubbornAssetTypeKey.SCREEN, asset.attributes.states.target);
    }
  }

  private searchInMetadata(metadata: StubbornAsset[]) {
    metadata.forEach((asset: StubbornAsset) => {
      this.searchInAttributes(asset.attributes);
      this.searchForScreens(asset);
      if (!asset.metadata?.length) return;
      this.searchInMetadata(asset.metadata);
    });
  }

  private searchForAssetsScreen() {
    this.searchInAttributes((this.asset as StubbornScreen).attributes);
    if (!(this.asset as StubbornScreen).metadata?.length) return;
    this.searchInMetadata((this.asset as StubbornScreen).metadata);
  }

  private getServices(event: string) {
    if (!event.includes(AssetNameSpaces.SBSERVICES)) return;

    const matches = this.getServicesMatches(event);

    matches?.forEach((match: string) => {
      this.addAsset(StubbornAssetTypeKey.SERVICE, match);
    });
  }

  private searchForDatasources() {
    (this.asset as StubbornService).actions.forEach((action: StubbornServiceAction) => {
      if (action.datasource) {
        this.addAsset(StubbornAssetTypeKey.DATASOURCE, action.datasource);
      }
    });
  }

  private getRelatedAssetsScreen(): [string, string][] {
    this.searchForAssetsScreen();
    return this.generateAssetsArray();
  }

  private getRelatedDatasourcesService() {
    this.searchForDatasources();
    return this.generateAssetsArray();
  }

  private searchForAssetsRouter() {
    (this.asset as StubbornRouter).routes.forEach((route: StubbornRoute) => {
        // If type is 'Route', means that it's an screen.
        if (route.type === StubbornRouteKey.ROUTE) {
          this.addAsset(StubbornAssetTypeKey.SCREEN,route.target);
          return;
        }

        // If type is not 'Route', means it's a 'Router'.
        this.addAsset(StubbornAssetTypeKey.ROUTER, route.target);
        return;
    });
  }

  private getRelatedAssetsRouter() {
    this.searchForAssetsRouter();
    return this.generateAssetsArray();
  }

  getRelatedAssets() {
    if (this.asset.type === StubbornAssetTypeKey.SCREEN) {
      return this.getRelatedAssetsScreen();
    }

    if (this.asset.type === StubbornAssetTypeKey.SERVICE) {
      return this.getRelatedDatasourcesService();
    }

    if (this.asset.type === StubbornAssetTypeKey.ROUTER) {
      return this.getRelatedAssetsRouter();
    }
  }
}
