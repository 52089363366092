import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Button, IconButton, Switch, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Icon } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { BaseAttributeEditorProps } from './BaseAttributeEditorProps';
import { Modal } from '../../../../../../../commons/ui';
import Editor from '@monaco-editor/react';

const useStyles = makeStyles({
  backdrop: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 16,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#00000080',
    zIndex: 2000,
    top: 0,
    left: 0,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    height: '75%',
    minWidth: 600,
    overflowY: 'visible',
    overflowX: 'auto',
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 10px',
  },
  modalContent: {
    display: 'flex',
    flex: 1,
    padding: '0px 10px',
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    justifyContent: 'flex-end',
  },
  cancelButton: { marginRight: 20 },
  switcher: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputs: {
    marginRight: 10,
  },
  columns: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  params: {
    marginBottom: 20,
  },
});

export interface ActionOptionsParams {
  name: string;
  type: string;
  mandatory: boolean;
  default: string;
}

export interface ActionOptionsAttributeEditorProps extends BaseAttributeEditorProps {}

export const ActionOptionsAttributeEditor = ({ value, onChange, onCancel, onSave, cellName, metadata }: ActionOptionsAttributeEditorProps) => {
  const classes = useStyles();
  const [intermediateValue, setIntermediateValue] = useState<string>(JSON.stringify(value, null, '\t'));
  const [readyToSave, setReadyToSave] = useState(false);
  const parsedValue = JSON.parse(intermediateValue);
  const [isAdvancedMode, setAdvancedMode] = useState<boolean>(false);

  const handleOnBlur = useCallback(
    (event) => {
      if (isAdvancedMode) {
        onChange(JSON.parse(intermediateValue));
      } else {
        onChange(parsedValue);
      }
      setReadyToSave(true);
      event.stopPropagation();
    },
    [isAdvancedMode, setReadyToSave, parsedValue, intermediateValue, onChange],
  );

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Escape':
          onCancel();
          e.stopPropagation();
          e.preventDefault();
          break;
      }
    },
    [onCancel],
  );

  const callOnBlur = (e: any) => {
    handleOnBlur(e);
  };

  useEffect(() => {
    if (readyToSave) onSave();
  }, [readyToSave, onSave]);

  const handleChangeParams = (key: string, paramsValue: string | boolean) => {
    const newParams = parsedValue;
    newParams[key] = paramsValue;
    setIntermediateValue(JSON.stringify(newParams, null, 2));
  };

  return (
    <Modal>
      <div className={classes.backdrop} onClick={onCancel}>
        <div
          className={classes.modal}
          style={isAdvancedMode ? { width: '80%' } : { width: 'auto' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={handleKeyPress}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={classes.modalHeader}
          >
            <h3>{cellName}</h3>
            <div className={classes.switcher}>
              <Typography>Advanced mode</Typography>
              <Switch checked={isAdvancedMode as boolean} onChange={() => setAdvancedMode(!isAdvancedMode)} />
            </div>
            <Icon component={CloseIcon} onClick={onCancel} />
          </div>
          <div className={classes.modalContent}>
            <div style={{ display: 'flex', flex: 1 }}>
              {isAdvancedMode ? (
                <Editor height="100%" width="100%" theme="vs-dark" defaultLanguage="json" defaultValue={intermediateValue} onChange={(value) => setIntermediateValue(value ? value?.toString() : '')} />
              ) : (
                <div className={classes.columns}>
                  {Object.keys(metadata || {}).map((key: any) => (
                    <div className={classes.params}>
                      <h3>{(metadata && metadata[key].label) || 'Opción'}</h3>
                      <TextField onChange={(event) => handleChangeParams(key, event.target.value)} variant="standard" className={classes.inputs} size="small" InputLabelProps={{ shrink: true }} label="Valor" value={parsedValue[key]} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className={classes.modalFooter}>
            <Button className={classes.cancelButton} variant="outlined" fullWidth={false} onClick={onCancel}>
              Cancelar
            </Button>
            <Button variant="contained" fullWidth={false} onClick={callOnBlur}>
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
