import React, { useCallback, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { BaseAttributeEditorProps } from './BaseAttributeEditorProps';
import { Modal } from '../../../../../../../commons/ui';
import { SearchInput } from '../../../../../Dashboard/components/AssetList/components/Toolbar/components';
import { Button, Icon, IconButton } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { List } from 'react-virtualized';

const useStyles = makeStyles({
  backdrop: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 16,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#00000080',
    zIndex: 2000000,
    top: 0,
    left: 0,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    height: '80%',
    width: '50%',
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 10px',
  },
  modalContent: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    padding: '0px 10px',
    overflow: 'auto',
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    justifyContent: 'flex-end',
  },
  cancelButton: { marginRight: 20 },
  iconName: {
    fontSize: 11,
    marginLeft: 20,
  },
  searchedIconsContent: {
    display: 'flex',
    overflowY: 'scroll',
    backgroundColor: 'red',
  },
});

export interface IconAttributeEditorProps extends BaseAttributeEditorProps {}

export interface IconProps {
  index: number;
  style: any;
}

export const IconAttributeEditor = ({ onChange, onCancel, onSave, cellName }: IconAttributeEditorProps) => {
  const classes = useStyles();
  const [readyToSave, setReadyToSave] = useState<boolean>(false);
  const [renderedIcons, setRenderedIcons] = useState<Array<string>>([]);
  const [search, setSearch] = useState<string>('');

  const handleOnBlur = useCallback(
    (event, name: string) => {
      onChange(name);
      setReadyToSave(true);
      event.stopPropagation();
    },
    [setReadyToSave, onChange],
  );

  const iconArray = Object.keys(Icons);

  useEffect(() => {
    if (!renderedIcons.length) {
      setRenderedIcons(iconArray);
    }
    if (search.length) {
      const searchedIcons = iconArray.filter((item: string) => item.toLowerCase().includes(search.toLowerCase()));
      setRenderedIcons(searchedIcons);
    } else {
      setRenderedIcons(iconArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (readyToSave) onSave();
  }, [readyToSave, onSave]);

  const rowRenderer = useCallback(
    ({ index, style }: IconProps) => {
      if (renderedIcons.length) {
        const iconSnakeCase = renderedIcons[index]
          .replace(/(?:^|\.?)([A-Z])/g, function (x: any, y: any) {
            return '_' + y.toLowerCase();
          })
          .replace(/^_/, '');
        return (
          <div key={index} style={style}>
            <IconButton onClick={(e) => handleOnBlur(e, iconSnakeCase)}>
              <Icon>{iconSnakeCase}</Icon>
              <p className={classes.iconName}>{iconSnakeCase}</p>
            </IconButton>
          </div>
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [renderedIcons],
  );

  return (
    <Modal>
      <div className={classes.backdrop}>
        <div className={classes.modal}>
          <div className={classes.modalHeader}>
            <h3>{cellName}</h3>
            <Icon component={Icons.Close} onClick={onCancel} />
          </div>
          <SearchInput search={search} onSearchChange={(e) => setSearch(e)} />
          <List width={650} height={400} rowCount={renderedIcons.length} rowHeight={40} rowRenderer={rowRenderer} />
          <div className={classes.modalFooter}>
            <Button className={classes.cancelButton} variant="outlined" fullWidth={false} onClick={onCancel}>
              Cancelar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
