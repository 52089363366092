import React, { useCallback, useState } from 'react';
import { StubbornService } from '../../../../../../../business/StubbornAsset';
import { useEditionServiceCreate } from '../../../../../../../../platform/api/endpoints/edition';
import { NewServiceDialog } from '../../../../../../Private/Dashboard/components/AssetList/components/Toolbar/components/AddAssetMenu/dialogs';
import { FieldTypeManager } from '../../../../../../Private/Editor/editors/ScreenEditor/fields';
import { NewAssetProps } from '../../types';

export const NewServiceModal = ({ onComplete, onClose }: NewAssetProps) => {
  const [modalOpen, setModalOpen] = useState(true);
  const { call: createService } = useEditionServiceCreate();
  const onCloseModal = useCallback(() => {
    setModalOpen(false);
    setTimeout(() => onClose(), 0);
  }, [onClose]);
  const onCreateService = useCallback(
    async (name: string, description: string) => {
      const newService: StubbornService = {
        name,
        type: 'Service',
        tags: [],
        metadata: [],
        attributes: { ...FieldTypeManager.getDefaultAttributes('container') },
        title: '',
        description,
        parameters: [],
        actions: [],
        methods: [],
      };
      await createService(newService);
      onComplete();
      onCloseModal();
    },
    [createService, onCloseModal, onComplete],
  );

  return <NewServiceDialog open={modalOpen} onClose={onCloseModal} onCreateService={onCreateService} />;
};
