import React, {
  createContext,
  Dispatch,
  ReactNode,
  ReactNodeArray,
  SetStateAction,
  useContext, useEffect,
  useMemo,
  useState
} from 'react';
import {EditorItem} from "./EditorItem";
import {StubbornService} from "../../../business/StubbornAsset";

export interface EditorServiceContextType {
  // esto es del contexto del editor
  selectedAction: number | null;
  setSelectedAction: Dispatch<SetStateAction<number | null>>;
  service: StubbornService;
  setService: Dispatch<SetStateAction<StubbornService>>;
  editor: EditorItem<StubbornService>,
}

export const EditorServiceContext = createContext<EditorServiceContextType>({
  selectedAction: null,
  setSelectedAction: () => {},
  // @ts-ignore
  service: {},
  setService: () => {},
  // @ts-ignore
  editor: {},
});

export interface EditorServiceContextProviderProps {
  children: ReactNode | ReactNodeArray;
  editor: EditorItem<StubbornService>;
}

export const EditorServiceContextProvider = ({ children, editor }: EditorServiceContextProviderProps) => {
  const [service, setService] = useState<StubbornService>(editor.getItem());
  const [selectedAction, setSelectedAction] = useState<number | null>(null);

  const value = useMemo<EditorServiceContextType>(() => ({
    selectedAction,
    setSelectedAction,
    service,
    setService,
    editor,
  }), [editor, selectedAction, service]);
  useEffect(() => {
    editor.setItem(service);
  }, [editor, service]);
  return (
    <EditorServiceContext.Provider value={value}>
      {children}
    </EditorServiceContext.Provider>
  );
};

export const useEditorServiceContext = () => useContext<EditorServiceContextType>(EditorServiceContext);
