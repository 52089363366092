import React from 'react';
import { StubbornAssetType } from '../../../../../business/StubbornAsset';

export type AssetViewType = StubbornAssetType | 'SessionConfiguration' | 'Datasource' | 'postgres' | 'PluginConfiguration';

export enum AssetNameSpaces {
  SBSERVICES = 'SBServices',
}

export interface ActionPanelCompProps<T = any> {
  asset: T;
}

export interface AssetPanelCompProps<T = any> {
  asset: T;
}

export interface AssetPanel<T = any> {
  label: string;
  component: React.FC<AssetPanelCompProps<T>>;
}

export interface AssetView {
  type: AssetViewType;
  actions: Array<React.FC<ActionPanelCompProps>>;
  panels: Array<AssetPanel>;
}
