import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { PermissionContextValue, Rules } from './types';

export const PermissionContext = createContext<PermissionContextValue>({
  // @ts-ignore
  data: {},
  working: false,
});

export const usePermissionContext = () =>
  useContext<PermissionContextValue>(PermissionContext);

export interface PermissionProviderProps {
  children: JSX.Element;
  rules: Rules[];
}

export const PermissionProvider = ({
  children,
  rules,
}: PermissionProviderProps) => {
  const [activeRulesSets, setActiveRulesSets] = useState<Rules[]>([]);
  const [rulesSets, setRulesSet] = useState<Rules[]>(rules);

  const activateRulesSet = useCallback(
    (idRule: string) => {
      const findRule = rulesSets.find((rule) => rule.idRule === idRule);
      if (findRule) {
        setActiveRulesSets((old) => {
          return [...old, findRule];
        });
      } else {
        console.error(`Cannot find rule ${idRule}`);
      }
    },
    [setActiveRulesSets, rulesSets],
  );

  const desactivateRulesSet = useCallback(
    (idRule: string) => {
      setActiveRulesSets((old) => old.filter((_old) => _old.idRule !== idRule));
    },
    [setActiveRulesSets],
  );

  const desactivateAllRulesSets = useCallback(() => {
    setActiveRulesSets([]);
  }, [setActiveRulesSets]);
  const activeRules = useMemo(() => {
    return activeRulesSets.reduce<Rules>(
      (acumn, item) => ({
        ...acumn,
        idRule: item.idRule,
        routes: [...acumn.routes, ...item.routes],
        blockers: [...acumn.blockers, ...item.blockers],
        sideMenu: [...acumn.sideMenu, ...item.sideMenu],
        tabBars: [...acumn.tabBars, ...item.tabBars],
      }),
      { idRule: '', routes: [], blockers: [], sideMenu: [], tabBars: [] },
    );
  }, [activeRulesSets]);

  const value = useMemo<PermissionContextValue>(
    () => ({
      activeRulesSets,
      activeRules,
      rulesSets,
      deactivateAllRulesSets: desactivateAllRulesSets,
      activateRulesSet,
      deactivateRulesSet: desactivateRulesSet,
      setRulesSet,
      setActiveRulesSets,
    }),
    [
      activeRulesSets,
      activeRules,
      rulesSets,
      desactivateAllRulesSets,
      activateRulesSet,
      desactivateRulesSet,
      setRulesSet,
      setActiveRulesSets,
    ],
  );
  return (
    <PermissionContext.Provider value={value}>
      {children}
    </PermissionContext.Provider>
  );
};
