import React, {useCallback, useState} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import LoadingButton from "@material-ui/lab/LoadingButton";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import {useInputState} from "../../../../../../../../../../commons/hooks";
//import { StubbornSessionConfiguration } from '../../../../../../../../../../../application/business/StubbornConfiguration' 


export interface NewSessionDialogProps {
  open: boolean;
  onClose: () => void;
  //onCreateSession: (name: string) => Promise<void>;
  onCreateSession: (name: string, secret: string, header: string) => Promise<void>;
}

export const NewSessionDialog = ({ open, onClose, onCreateSession }: NewSessionDialogProps) => {
  const validate = true;
  const [name, onNameChange, setName, error] = useInputState('', validate);
  const [secret, onSecretChange, setSecret] = useInputState('');
  const [header, onHeaderChange, setHeader] = useInputState('');
  
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);

  const onCreate = useCallback(async () => {
    setDirty(true);
    if (name && secret && header) {
      try {
        setLoading(true);
        await onCreateSession(name,secret,header);
        setLoading(false);
        onClose();
        setName('');
        setDirty(false);
      } catch (e: any) {
        alert(e.message); // TODO: notistack
        setLoading(false);
      }
    }
  }, [name, secret, header, onCreateSession, onClose, setName]);
  return (
    <Dialog
      open={open}
      onClose={loading ? undefined : onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Nueva Session</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="description"
          label="Nombre"
          name="name"
          fullWidth
          value={name}
          onChange={onNameChange}
          error={(dirty && !name)  || error?.bool}
          helperText={(dirty && !name && 'Nombre requerido') || (error?.bool && error.message)}
          disabled={loading}
        />

        <TextField
          autoFocus
          margin="dense"
          id="secret"
          label="secret"
          name="secret"
          fullWidth
          value={secret}
          onChange={onSecretChange}
          error={dirty && !secret}
          helperText={dirty && !secret && 'secret requerido'}
          disabled={loading}
        />

        <TextField
          autoFocus
          margin="dense"
          id="header"
          label="header"
          name="header"
          fullWidth
          value={header}
          onChange={onHeaderChange}
          error={dirty && !header}
          helperText={dirty && !header && 'user requerido'}
          disabled={loading}
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" disabled={loading}>
          Cerrar
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          autoFocus
          onClick={onCreate}
          disabled={loading}
          loading={loading}
        >
          Crear
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
