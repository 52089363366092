import {StubbornAttribute, StubbornAttributeGroup} from "../../../../../../../../business/StubbornAttributeMapping";

import { basicAttributeGroup, makeComponentAttributeGroup, makeCustomAttributeGroup, makeEventAttributeGroup } from "../../commons/attributes";

const attributes = new Set<StubbornAttribute>();
attributes.add({
  label: 'Data',
  name: 'tableData',
  type: 'object',
  default: [
    {key1: 'value 1', key2: 'value 2'},
    {key1: 'value 1', key2: 'value 2'},
  ],
});
attributes.add({
  label: 'Table Name',
  name: 'tableName',
  type: 'string',
  default: 'defaultName'
});
attributes.add({
  label: 'Hover',
  name: 'hover',
  type: 'boolean',
  default: false
});
attributes.add({
  label: 'Size',
  name: 'size',
  type: 'select',
  default: 'medium',
  list: ['medium', 'small']
});

const datasourceAttributes = new Set<StubbornAttribute>();
datasourceAttributes.add({
  label: 'Enabled',
  name: 'enabled',
  type: 'boolean',
  default: false,
});
datasourceAttributes.add({
  label: 'Service',
  name: 'service',
  type: 'serviceDatasource',
  default: {
    serviceName: '',
    params: {
      keys: [],
      values: [],
    },
    path: '',
  },
});
datasourceAttributes.add({
  label: 'Items',
  name: 'items',
  type: 'object',
  default: [],
});

export const attributeGroups: StubbornAttributeGroup[] = [
  basicAttributeGroup,
  makeComponentAttributeGroup(attributes),
  makeCustomAttributeGroup('Datasource', 'datasource', datasourceAttributes),
  makeEventAttributeGroup(),
];
