import { StubbornAttribute, StubbornAttributeGroup } from '../../../../../../../../business/StubbornAttributeMapping';

import { basicAttributeGroup, makeComponentAttributeGroup, makeCustomAttributeGroup, makeEventAttributeGroup, makeStateAttributeGroups } from '../../commons/attributes';

const attributes = new Set<StubbornAttribute>();

const stateAttributes: StubbornAttribute[] = [
  {
    label: 'Data',
    name: 'data',
    type: 'object',
    default: {},
  },
];

const eventAttributes: StubbornAttribute[] = [
  {
    label: 'OnClick',
    name: 'onClick',
    type: 'code',
    default: '',
  },
  {
    label: 'OnBlur',
    name: 'onBlur',
    type: 'code',
    default: '',
  },
  {
    label: 'OnMouseEnter',
    name: 'onMouseEnter',
    type: 'code',
    default: '',
  },
  {
    label: 'OnMouseLeave',
    name: 'onMouseLeave',
    type: 'code',
    default: '',
  },
];

const iteratorAttributes = new Set<StubbornAttribute>();
iteratorAttributes.add({
  label: 'Enabled',
  name: 'enabled',
  type: 'boolean',
  default: false,
});
iteratorAttributes.add({
  label: 'Counter',
  name: 'counter',
  type: 'number',
  default: 0,
});
iteratorAttributes.add({
  label: 'Service',
  name: 'service',
  type: 'serviceDatasource',
  default: {
    serviceName: '',
    params: {
      keys: [],
      values: [],
    },
    path: '',
  },
});
iteratorAttributes.add({
  label: 'API',
  name: 'api',
  type: 'string',
  default: '',
});
iteratorAttributes.add({
  label: 'Items',
  name: 'items',
  type: 'object',
  default: [],
});

export const attributeGroups: StubbornAttributeGroup[] = [basicAttributeGroup, makeStateAttributeGroups(stateAttributes), makeComponentAttributeGroup(attributes), makeCustomAttributeGroup('Iterator', 'iterator', iteratorAttributes), makeEventAttributeGroup(eventAttributes)];
