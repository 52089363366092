import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Box, Grid } from '@material-ui/core';
import { useDrop } from 'react-dnd';
import { useEditorContext } from './EditorContext';
import { AppToolbar } from './editors/ScreenEditor/AppToolbar';
import { AppThemeType } from '../../../../theme';
import { EditorItem } from './EditorItem';
import { StubbornAsset } from '../../../business/StubbornAsset';
import { StubbornData } from '../../../business';
import { AssetViewType } from '../../Private2/commons/components/AssetView/types';

interface CustomEditorScreenStyle {
  customMinWidth: string;
}

const useClasses = makeStyles<AppThemeType, CustomEditorScreenStyle>((theme) =>
  createStyles({
    tab: {
      display: 'flex',
      flex: 1,
      padding: 10,
      alignItems: 'center',
      '&:hover': {
        backgroundColor: 'lightgray',
      },
    },
    tabIcon: {
      marginRight: 10,
      marginTop: 5,
    },
    container: {
      display: 'flex',
      flex: 1,
      minWidth: '100%',
      margin: 0,
      padding: 0,
      height: '100%',
    },
    tabPanel: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      width: '100%',
      height: '100%',
      margin: 0,
      padding: 0,
    },
    panel: {
      width: '100%',
      overflow: 'auto',
      flexGrow: 1,
      margin: 0,
      padding: 0,
      paddingBottom: 5,
    },
    centralArea: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: ({ customMinWidth }) => customMinWidth,
      width: '100%',
    },
    tabPanelRoot: {
      padding: 0,
      display: 'flex',
      flexGrow: 1,
      flex: 1,
    },
    tabsRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  }),
);

export interface EditorProps {
  asset: StubbornData;
  type: AssetViewType;
}

export const Editor = ({ asset, type }: EditorProps) => {
  const { editors, selected, select, removeEditor, addEditor } = useEditorContext();
  const [draggedTabs, setDraggedTabs] = useState<EditorItem<StubbornAsset>[]>(editors);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'component',
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const [isEditable, setIsEditable] = useState<boolean>(true);
  const customEditorScreenStyle: CustomEditorScreenStyle = {
    customMinWidth: isEditable ? '65%' : '100%',
  };

  useEffect(() => {
    setDraggedTabs(editors);
  }, [editors]);

  const classes = useClasses(customEditorScreenStyle);

  const [currentBreakpoint, setCurrentBreakpoint] = useState<number>(0);
  const [currentZoom, setCurrentZoom] = useState<number>(1);
  const setBreakpoint = (breakpointIndex: number) => setCurrentBreakpoint(breakpointIndex);
  const setZoom = (zoom: number) => setCurrentZoom(zoom);
  return (
    <>
      {editors.map((editor) => {
        const Provider = editor.getProvider();
        const Editor = editor.getEditor();
        const Toolbox = editor.getToolbox();
        const PropertiesEditor = editor.getPropertiesEditor();
        return (
          <Provider editor={editor} key={editor.uniqueName()}>
            <div className={classes.container}>
              <Box className={classes.tabPanel}>
                <div className={classes.centralArea}>
                  {/* TODO: hay que mover el AppToolbar al ScreenEditor*/}
                  <AppToolbar currentBreakpoint={currentBreakpoint} currentZoom={currentZoom} setZoom={setZoom} setBreakpoint={setBreakpoint} setEditable={(editable: boolean) => setIsEditable(editable)} />
                  <Box className={classes.panel} ref={drop}>
                    <Editor currentZoom={currentZoom} currentBreakpoint={currentBreakpoint} />
                  </Box>
                </div>
                <div style={{ height: '100%', backgroundColor: '#2F343D', display: 'flex', flexDirection: 'column' }}>
                  <PropertiesEditor />
                </div>
              </Box>
            </div>
          </Provider>
        );
      })}
    </>
  );
};
