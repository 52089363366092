import { FieldType } from '../../manager/FieldType';
import { SterTable } from './SterTable';
import { attributeGroups } from './attributeGroups';
import { toolbox } from './toolbox';
import { FieldTypeManager } from '../../manager/FieldTypeManager';

export const sterTableFieldType: FieldType = {
  name: 'sterTable',
  component: SterTable,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(sterTableFieldType.name, sterTableFieldType);
