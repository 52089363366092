import { useCallback, useState } from 'react';

export const useDialogState = (initialState = false): [boolean, () => void, () => void] => {
  const [open, setOpen] = useState(initialState);
  const onOpen = useCallback(() => setOpen(true), [setOpen]);
  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  return [open, onOpen, onClose];
};
