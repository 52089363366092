import React, { ReactNode, ReactNodeArray } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { AppThemeType } from '../../../../theme';

export interface ScreenTitleProps {
  children: ReactNode | ReactNodeArray;
}

const useStyles = makeStyles<AppThemeType>((theme) =>
  createStyles({
    container: {
      display: 'flex',
      width: '100%',
      height: 100,
      justifyContent: 'center',
      alignItems: 'center',
    },
    typography: {
      color: 'black',
    },
  }),
);

export const ScreenTitle = ({ children }: ScreenTitleProps) => {
  const classes = useStyles();
  return (
    <Grid className={classes.container}>
      <Typography variant="body1" className={classes.typography}>
        {children}
      </Typography>
    </Grid>
  );
};
