import React from 'react';
import SaveIcon from '@material-ui/icons/Save';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import AltRouteIcon from '@material-ui/icons/AltRoute';
import DnsIcon from '@material-ui/icons/Dns';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { StubbornAssetTypeKey } from '../business';

export const getAssetIcon = (type: string, color?: string) => {
  const iconStyles = { fontSize: 35, color: color || 'initial' };
  const icons: Record<string, JSX.Element> = {
    [StubbornAssetTypeKey.SCREEN]: <WebAssetIcon sx={iconStyles} />,
    [StubbornAssetTypeKey.SERVICE]: <DnsIcon sx={iconStyles} />,
    [StubbornAssetTypeKey.ROUTER]: <AltRouteIcon sx={iconStyles} />,
    [StubbornAssetTypeKey.SESSIONCONFIGURATION]: <SupervisedUserCircleIcon sx={iconStyles} />,
    [StubbornAssetTypeKey.DATASOURCE]: <SaveIcon sx={iconStyles} />,
    [StubbornAssetTypeKey.POSTGRES]: <SaveIcon sx={iconStyles} />,
  };
  return icons[type];
};
