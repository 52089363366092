import {StubbornRouter} from "../business/StubbornRouter";

export class RouteNamingHelper {
  protected registeredNames = new Set<string>();
  protected iterator: number = 0;
  protected router: StubbornRouter;

  constructor(router: StubbornRouter) {
    this.router = router;

    this.router.routes.forEach(route => {
      this.registeredNames.add(route.name);
    });
  }

  protected nameIsAvailable(name: string): boolean {
    return !this.registeredNames.has(name);
  }

  protected addToIterator(): number {
    this.iterator += 1;
    return this.iterator;
  }

  getDefaultName(): string {
    const newName = `route${this.addToIterator()}`;
    if (!this.nameIsAvailable(newName)) return this.getDefaultName();
    return newName;
  }

}
