import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FilterListIcon from '@material-ui/icons/FilterList';
import { AddDatasourceMenu, SearchInput, SearchInputProps } from './components';
import { StubbornDatasourceKind, StubbornDatasourceType } from '../../../../../../../../business/StubbornAsset';

export interface ToolbarProps extends SearchInputProps {
  onCreateDatasource: (name: string, kind: StubbornDatasourceKind, type: StubbornDatasourceType, host: string, port: number, user: string, password: string, database: string, onconnect: string) => Promise<void>;
}

export const Toolbar = ({ search, onSearchChange, onCreateDatasource }: ToolbarProps) => {
  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <SearchInput search={search} onSearchChange={onSearchChange} />
      </Grid>
      <Grid item>
        <IconButton color="primary" size="large" title="Filtrar">
          <FilterListIcon />
        </IconButton>
        <FormControl sx={{ minWidth: 250 }}>
          <InputLabel id="agrupar-por">Agrupar por</InputLabel>
          <Select labelId="agrupar-por" id="agrupar-por-input" value="" label="Agrupar por" onChange={() => {}}>
            <MenuItem value="">
              <em>Ninguno</em>
            </MenuItem>
            <MenuItem value="kind">Tipo</MenuItem>
            <MenuItem value="type">Manejador de BD</MenuItem>
          </Select>
        </FormControl>
        <AddDatasourceMenu onCreateDatasource={onCreateDatasource} />
      </Grid>
    </Grid>
  );
};
