import React from "react";
import {BaseAttributeViewProps} from "./BaseAttributeViewProps";
import {Switch} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 16,
  },
});

export interface BooleanAttributeViewProps extends BaseAttributeViewProps {}

export const BooleanAttributeView = ({value}: BooleanAttributeViewProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Switch
        checked={value as boolean}
      />
    </div>
  );
};
