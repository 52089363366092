import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { AssetPanelCompProps } from '../../types';
import { RelatedAssets } from '../../../../../../../utils/RelatedAssets';
import { useAssetContext } from '../../../../context/AssetContext';
import { StubbornData, StubbornSessionConfiguration } from '../../../../../../../business';
import { AssetCard } from '../../commons/AssetCard';

interface RelatedAssetsPanelProps extends AssetPanelCompProps<StubbornSessionConfiguration> {}

export const RouterRelatedAssetsPanel = ({ asset }: RelatedAssetsPanelProps) => {
  const [relatedAssets, setRelatedAssets] = useState<[string, string][]>([]);
  const { selectAsset, assets } = useAssetContext();

  useEffect(() => {
    const data = new RelatedAssets(asset);
    const relatedAssets = data.getRelatedAssets();
    setRelatedAssets(relatedAssets || []);
  }, [asset]);

  const handleClick = (assetName: string) => {
    const asset = assets.find((asset: StubbornData) => assetName === asset.name);
    if (asset) selectAsset(asset);
  };

  return (
    <Grid container flexWrap="wrap">
      {relatedAssets.map((asset) => {
        const [type, assetName] = asset;
        return (
          <Grid
            item
            sx={{ margin: '5px' }}
            onClick={() => handleClick(assetName)}
          >
            <AssetCard type={type} assetName={assetName} />
          </Grid>
        );
      })}
    </Grid>
  );
};
