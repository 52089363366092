import { useEffect, useState } from 'react';
import { Icon, Paper, List as MuiList, ListItem, ListItemText, ListItemIcon, Typography } from '@material-ui/core';
import { FieldBaseProps } from '../FieldBaseProps';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';
import { useDatasource, DatasourceObject } from '../../../hooks/useDatasource';

type ListData = { listItemName: string | number };
export interface ListProps extends FieldBaseProps {
  component: {
    alignItems: 'center' | 'flex-start';
    dense: boolean;
    disableGutters: boolean;
    disablePadding: boolean;
    listData: ListData[];
    listName: string;
    startIconName: string;
    endIconName: string;
  };
  datasource: DatasourceObject<ListData>;
}

export const List = ({ component, events, datasource, styles }: ListProps) => {
  const { editable } = useEditorScreenContext();
  const [initialItems, _] = useState<ListData[]>(datasource.items || []);
  const { items } = useDatasource<ListData>(datasource);
  const itemsToRender = !!items.length ? items : initialItems;

  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper style={styles}>
      {!itemsToRender.length && <Typography>Empty list</Typography>}
      <MuiList dense={component.dense} disablePadding={component.disablePadding}>
        {itemsToRender.map((item, index) => (
          <ListItem disableGutters={component.disableGutters} alignItems={component.alignItems} key={`${component.listName}-list-item-${index}`}>
            {component.startIconName !== '' && (
              <ListItemIcon>
                <Icon>{component.startIconName}</Icon>
              </ListItemIcon>
            )}
            <ListItemText>{item.listItemName}</ListItemText>
            {component.endIconName !== '' && (
              <ListItemIcon>
                <Icon>{component.endIconName}</Icon>
              </ListItemIcon>
            )}
          </ListItem>
        ))}
      </MuiList>
    </Paper>
  );
};
