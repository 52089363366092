import { AnyObject } from '../../../../../../commons/types';

export const processGroupStyles = (styles: any, breakpoint: string, render?: any) => {
  let stylesToUse = {};
  if (styles.general)
    stylesToUse = {
      ...styles.general,
      ...(!render && {opacity: 0.5})
    };
  if (styles[breakpoint]) {
    stylesToUse = {
      ...stylesToUse,
      ...styles[breakpoint],
    };
  }
  return stylesToUse;
};

export const processScreenLayout = (layout: any, breakpoint: string, isHidden?: boolean) => {
  if (isHidden) return {
    [breakpoint]: {}
  };
  let layoutToUse = {};
  if (layout[breakpoint]) {
    layoutToUse = {
      ...layout[breakpoint],
    };
  }
  return layoutToUse;
};

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const tableKeysFormatter = (array: AnyObject[]) => {
  let keys: string[] = [];
  array.forEach(item => {
    for (const key in item) {
      if (!keys.includes(key)) keys = [...keys, key];
    }
  });
  return keys;
}
