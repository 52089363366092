import React from 'react';
import { ApiContextProvider } from './lib/HttpService';
import { AppApi } from './platform/api';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PhinxApp } from './lib/phinxapp/profiles/PhinxApp';
import { StubbornMenuContextProvider } from './application/context';
import { EditorContextProvider } from './application/screens/Private/Editor/EditorContext';

export const App = () => (
  <StubbornMenuContextProvider>
    <ApiContextProvider api={AppApi}>
      <EditorContextProvider>
        <DndProvider backend={HTML5Backend}>
          <PhinxApp />
        </DndProvider>
      </EditorContextProvider>
    </ApiContextProvider>
  </StubbornMenuContextProvider>
);
