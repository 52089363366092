import React, { useEffect } from 'react';
import { Alert as MuiAlert, Icon, IconButton } from '@material-ui/core';
import { FieldBaseProps } from '../FieldBaseProps';
import { FieldBaseEvents } from '../FieldBaseEvents';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';

interface AlertEvents extends FieldBaseEvents {
  onClose: () => void;
}

export interface AlertProps extends FieldBaseProps {
  component: {
    content?: string;
    closeText?: string;
    variant?: 'filled' | 'outlined' | 'standard';
    color?: 'error' | 'info' | 'success' | 'warning';
    severity?: 'error' | 'info' | 'success' | 'warning';
    role?: string;
    successIcon?: string;
    infoIcon?: string;
    warningIcon?: string;
    errorIcon?: string;
  };
  events: AlertEvents;
}

export const Alert = ({ component, events, states, styles }: AlertProps) => {
  const { editable } = useEditorScreenContext();
  
  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customIconMapping = {
    ...(component.successIcon && { success: <Icon>{component.successIcon}</Icon> }),
    ...(component.infoIcon && { info: <Icon>{component.infoIcon}</Icon> }),
    ...(component.warningIcon && { warning: <Icon>{component.warningIcon}</Icon> }),
    ...(component.errorIcon && { error: <Icon>{component.errorIcon}</Icon> }),
  };

  return (
    <MuiAlert iconMapping={customIconMapping} style={styles} role={component.role} closeText={component.closeText} color={component.color} severity={component.severity} variant={component.variant} onClose={events.onClose}>
      {component.content}
    </MuiAlert>
  );
};
