import { FieldType } from "../../manager/FieldType";
import { IconButton } from "./IconButton";
import { attributeGroups } from "./attributeGroups";
import { toolbox } from "./toolbox";
import { FieldTypeManager } from "../../manager/FieldTypeManager";

export const iconButtonFieldType: FieldType = {
  name: "iconButton",
  component: IconButton,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(iconButtonFieldType.name, iconButtonFieldType);
