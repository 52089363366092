import { StubbornAttribute, StubbornAttributeGroup } from '../../../../../../../../business/StubbornAttributeMapping';

import { basicAttributeGroup, makeComponentAttributeGroup, makeCustomAttributeGroup, makeEventAttributeGroup, makeStateAttributeGroups } from '../../commons/attributes';

const attributes = new Set<StubbornAttribute>();

const stateAttributes: StubbornAttribute[] = [
  {
    label: 'Data',
    name: 'data',
    type: 'object',
    default: {},
  },
  {
    label: 'Target Screen',
    name: 'target',
    type: 'targetScreen',
    default: '',
  },
];

const eventAttributes: StubbornAttribute[] = [
  {
    label: 'OnClick',
    name: 'onClick',
    type: 'code',
    default: '',
  },
  {
    label: 'OnBlur',
    name: 'onBlur',
    type: 'code',
    default: '',
  },
  {
    label: 'OnMouseEnter',
    name: 'onMouseEnter',
    type: 'code',
    default: '',
  },
  {
    label: 'OnMouseLeave',
    name: 'onMouseLeave',
    type: 'code',
    default: '',
  },
];

export const attributeGroups: StubbornAttributeGroup[] = [basicAttributeGroup, makeStateAttributeGroups(stateAttributes), makeComponentAttributeGroup(attributes), makeEventAttributeGroup(eventAttributes)];
