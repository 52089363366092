import {MenuItem} from "../../../../lib/phinxapp/profiles/types";
import {Editor} from "../../../../application/screens/Private/Editor/Editor";

export const routes: MenuItem[] = [
  {
    key: 'editorMain',
    basePath: '/',
    target: {
      component: Editor,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Editor',
    children: [],
    default: true,
  },
];
