import {StubbornAttribute, StubbornAttributeGroup} from "../../../../../../../../business/StubbornAttributeMapping";

import { basicAttributeGroup, makeComponentAttributeGroup, makeEventAttributeGroup } from "../../commons/attributes";

const attributes = new Set<StubbornAttribute>();
attributes.add({
  label: 'Source',
  name: 'src',
  type: 'string',
  default: '',
});
attributes.add({
  label: 'Width',
  name: 'width',
  type: 'string',
  default: "100",
});
attributes.add({
  label: 'height',
  name: 'height',
  type: 'string',
  default: "100",
});
attributes.add({
  label: 'Descripción',
  name: 'alt',
  type: 'string',
  default: '',
});

export const attributeGroups: StubbornAttributeGroup[] = [
  basicAttributeGroup,
  makeComponentAttributeGroup(attributes),
  makeEventAttributeGroup(),
];
