import { FieldType } from '../../manager/FieldType';
import { Switch } from './Switch';
import { attributeGroups } from './attributeGroups';
import { toolbox } from './toolbox';
import { FieldTypeManager } from '../../manager/FieldTypeManager';

export const switchFieldType: FieldType = {
  name: 'switch',
  component: Switch,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(switchFieldType.name, switchFieldType);
