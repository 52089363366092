import React, { useCallback } from 'react';
import { Paper, List } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/styles';
import { AppThemeType } from '../../../../../../../theme';
import { StubbornRoute } from '../../../../../../business/StubbornRouter';
import { BaseAppConst } from '../../../../../../const/BaseAppConst';
import { RouteNamingHelper } from '../../../../../../utils';
import { useEditorRouterContext } from '../../../EditorRouterContext';
import { DraggableRouterItem } from './components';

const useStyles = makeStyles<AppThemeType>((theme) =>
  createStyles({
    drawer: {
      width: '100%',
      flexShrink: 0,
      height: '100%',
      boxShadow: 'none',
    },
    drawerPaper: {
      width: '100%',
      height: '100%',
      background: '#2F343D',
      boxShadow: 'none',
    },
  }),
);

const actionTypes: StubbornRoute['type'][] = ['Router', 'Route'];

export const RouterEditorToolbox = () => {
  const classes = useStyles();
  const { router, setRouter, setSelectedRoute } = useEditorRouterContext();

  const onAssetClick = useCallback(
    (type: StubbornRoute['type']) => {
      const route: StubbornRoute = {
        name: 'estrategia de nombrado',
        path: '',
        target: '',
        type,
      };
      setRouter((prev) => {
        const naming = new RouteNamingHelper(prev);
        route.name = naming.getDefaultName();
        return {
          ...prev,
          routes: [...prev.routes, route],
        };
      });
    },
    [setRouter],
  );

  return (
    <Box className={classes.drawer}>
      <Paper className={classes.drawerPaper}>
        <List style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', boxShadow: 'none' }} component="nav" aria-label="main mailbox folders">
          {actionTypes.map((type) => (
            <DraggableRouterItem type={type} key={type} onClick={() => onAssetClick(type)} />
          ))}
        </List>
      </Paper>
    </Box>
  );
};
