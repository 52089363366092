import React, { useCallback, useEffect, useState } from 'react';
import { DataGrid, GridCellParams, GridColDef, GridEditCellPropsParams } from '@material-ui/data-grid';
import { EditPropertyValue, EditPropertyView } from './EditorPropertyValue';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AppThemeType } from '../../../../../../../theme';

const useStyles = makeStyles<AppThemeType>(() => ({
  title: {
    color: '#FFFFFF',
    padding: '10px',
    fontWeight: 600,
  },
  gridStyle: {
    '& .MuiDataGrid-root': {
      border: 'none',
    },
    '& .MuiDataGrid-cell': {
      border: 'solid 2px #2F343D',
      color: '#FFFFFF',
    },
    '& .MuiDataGrid-cellEditable': {
      background: '#4A5160',
    },
    border: 'none',
  },
}));

export function renderEditPropertyValue(params: GridCellParams) {
  const type = params.row.type;
  return <EditPropertyValue type={type} {...params} />;
}

export function renderPropertyValue(params: GridCellParams) {
  const type = params.row.type;
  return <EditPropertyView type={type} {...params} />;
}

export function renderPropertyName(params: GridCellParams) {
  return <Typography style={{ fontWeight: 'bold', fontSize: 13 }}>{params.value}</Typography>;
}

const columns: GridColDef[] = [
  { field: 'label', headerName: 'Propiedad', width: 120, editable: false, sortable: false, renderCell: renderPropertyName },
  {
    field: 'value',
    headerName: 'Valor',
    width: 175,
    editable: true,
    sortable: false,
    renderEditCell: renderEditPropertyValue,
    renderCell: renderPropertyValue,
  },
];

export interface EditorPropertiesDataGridProps {
  initialRows: any[];
  onChange: (rows: any[]) => void;
}

export const EditorPropertiesDataGrid = ({ initialRows, onChange }: EditorPropertiesDataGridProps) => {
  const [listenerSetted, setListenerSetted] = useState(false);

  const [rows, setRows] = useState<any[]>(initialRows);

  const classes = useStyles();

  // Esto no se ejecuta por el stop Propagation en el save de la celda
  const handleEditCellChangeCommitted = React.useCallback(
    (params: GridEditCellPropsParams) => {
      const updatedRows = rows.map((row) => {
        if (row.id === params.id) {
          return { ...row, [params.field]: params.props.value };
        }
        return row;
      });
      setRows(updatedRows);
    },
    [rows],
  );

  // es la unica manera de accesar a la api con el DataGrid
  const hackToGetApi = useCallback(
    ({ api }) => {
      if (!listenerSetted) {
        setListenerSetted(true);
        api.subscribeEvent('cellEditPropsChangeCommitted', (params: GridEditCellPropsParams) => {
          setRows((prevRows) => {
            return prevRows.map((row) => {
              if (row.id === params.id) {
                return { ...row, [params.field]: params.props.value };
              }
              return row;
            });
          });
        });
      }
    },
    [listenerSetted],
  );

  useEffect(() => {
    onChange(rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  return (
    <div style={{ display: 'flex', flexDirection: "column" }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          className={classes.gridStyle}
          autoHeight
          hideFooter={true}
          hideFooterPagination={true}
          hideFooterRowCount={true}
          hideFooterSelectedRowCount={true}
          rowHeight={30}
          headerHeight={0}
          rows={rows}
          columns={columns}
          onEditCellChangeCommitted={handleEditCellChangeCommitted}
          onEditRowModelChange={hackToGetApi}
          onCellBlur={(cellInfo, event) => {
            if (cellInfo.row.type === 'code' || cellInfo.row.type === 'SQL' || cellInfo.row.type === 'object' || cellInfo.row.type 
             || cellInfo.row.type === 'serviceDatasource' || cellInfo.row.type === 'collection' || cellInfo.row.type === 'icon' || cellInfo.row.type === 'acceptedFiles') {
              event.stopPropagation();
              event.preventDefault();
            }
          }}
        />
      </div>
    </div>
  );
};
