import React, {useMemo, useState} from 'react';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TextField from '@material-ui/core/TextField';
import { useAssetContext } from '../../../commons/context/AssetContext';
import { AnyObject } from '../../../../../commons/types';
import { typesConfig } from './typesConfig';
import { NodeData } from './types';
import { TreeNode } from './components/TreeNode';
import { FilterTags } from './components/FilterTags';
import { useTheme } from '@material-ui/styles';
import { useStyles } from '../../styles';
import { Grid } from '@material-ui/core';
import { tagsFormatter } from './utils';

export const getTreeData = (treeData: AnyObject) => {
  const data = Object.values(treeData);
  return data.map((item) => {
    if (item.items) {
      item.items = getTreeData(item.items);
    }
    return item;
  });
};

export const ProjectAssets = () => {
  const { assets, selectAsset, recentAssets } = useAssetContext();
  const [searchItem, setSearchItem] = useState("");
  
  const theme = useTheme();
  const classes = useStyles(theme);

  const assetTree = useMemo(() => {
    const treeData: AnyObject = {};
    for (const [type, config] of Object.entries(typesConfig)) {
      if (config.parent) {
        if (!treeData[config.parent]) {
          treeData[config.parent] = {
            id: config.parent,
            label: config.parent,
            items: {},
            isGroup: true,
            hideButtons: true,
          };
        }
        if (!treeData[config.parent].items[config.group]) {
          treeData[config.parent].items[config.group] = {
            id: `${config.parent}__${config.group}`,
            label: config.group,
            items: {},
            isGroup: true,
            assetType: type,
          };
        }
      } else {
        if (!treeData[config.group]) {
          treeData[config.group] = {
            id: `${config.group}`,
            label: config.group,
            items: {},
            isGroup: true,
            assetType: type,
          };
        }
      }
    }
    for (const asset of assets) {
      const config = typesConfig[asset.type] || typesConfig.Datasource;
      if (config.parent) {
        treeData[config.parent].items[config.group].items[asset.name] = {
          id: `${config.parent}__${config.group}__${asset.name}`,
          label: asset.name,
          data: asset,
          onClick: selectAsset,
          assetType: asset.type,
        };
      } else {
        treeData[config.group].items[asset.name] = {
          id: `${config.group}__${asset.name}`,
          label: asset.name,
          data: asset,
          onClick: selectAsset,
          assetType: asset.type,
        };
      }
    }

    return getTreeData(treeData) as NodeData[];
  }, [assets, selectAsset]);

  const recentTree = useMemo<NodeData>(() => {
    return {
      id: 'recentlyOpenAssets',
      label: 'Recents',
      isRecentOpened: true,
      items: recentAssets.map<NodeData>((item) => ({
        id: `recentlyOpenAsset__${item.type}__${item.name}`,
        label: item.name,
        data: item,
        onClick: selectAsset,
        assetType: item.type,
        isRecentOpened: true,
      })).filter(item => item.label.toLowerCase().includes(searchItem.toLowerCase())),
    };
  }, [recentAssets, selectAsset, searchItem]);

  const filteredAssets = assetTree
    .map((item) => {
      return item.items
        ? {
            ...item,
            items: item.items.filter((innerItem) =>
              innerItem.label.toLowerCase().includes(searchItem.toLowerCase())
            ),
          }
        : item;
    });

  const tags = tagsFormatter(assets);

  return (
    <Grid item style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
      <div className={classes.searchContainer}> 
      <TextField
        id="filled-margin-dense"
        margin="dense"
        variant="filled"
        label="Search"
        type="search"
        value={searchItem}
        onChange={(event) => setSearchItem(event.target.value)}
        className={classes.inputStyles}
      />
      <FilterTags tags={tags} setSearchItem={setSearchItem} />
      </div>
      <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{ width: 300 }}
        className={classes.assetContainer}
      >
        <TreeNode node={recentTree} bordered/>
        {filteredAssets.map((item) => (
          <TreeNode node={item} key={item.id} />
        ))}
      </TreeView>
    </Grid>
  );
};
