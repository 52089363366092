import {FieldType} from "../../manager/FieldType";
import {Alert} from "./Alert";
import {attributeGroups} from "./attributeGroups";
import {toolbox} from "./toolbox";
import {FieldTypeManager} from "../../manager/FieldTypeManager";

export const alertFieldType: FieldType = {
  name: 'alert',
  component: Alert,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(alertFieldType.name, alertFieldType);
