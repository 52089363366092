import {useState, useMemo, useCallback} from 'react';
import { NodeData } from '../../types';
import TreeItem from '@material-ui/lab/TreeItem';
import { useTheme } from '@material-ui/styles';
import { TreeNodeLabel } from '../TreeNodeLabel';
import { useStyles } from '../../../../styles';

export interface TreeNodeProps {
    node: NodeData;
    bordered?: boolean;
  }
  
  export const TreeNode = ({ node, bordered }: TreeNodeProps) => {
    const [hasMouseIn, setHasMouseIn] = useState(false);
    const onMouseEnter = useCallback(() => setHasMouseIn(true), []);
    const onMouseLeave = useCallback(() => setHasMouseIn(false), []);
    const onClick = useMemo(
      () =>
        !node.onClick
          ? undefined
          : () => {
              node.onClick && node.onClick(node.data);
            },
      [node],
    );

    const theme = useTheme();
    const classes = useStyles(theme);

    return (
      <TreeItem
        nodeId={node.id}
        label={<TreeNodeLabel node={node} hasMouseIn={hasMouseIn} />}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={classes.assetItem}
        style={{ borderBottom: bordered ? "1px solid #4A4A4A" :  0}}
      >
        {!node.items || !node.items.length
          ? []
          : node.items.map((item) => <TreeNode node={item} key={item.id} />)}
      </TreeItem>
    );
  };
  