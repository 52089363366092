import { StubbornAttribute, StubbornAttributeGroup } from '../../../../../../../../business/StubbornAttributeMapping';

import { basicAttributeGroup, makeStateAttributeGroups, makeComponentAttributeGroup, makeEventAttributeGroup } from '../../commons/attributes';

const attributes = new Set<StubbornAttribute>();
attributes.add({
  label: 'Label',
  name: 'label',
  type: 'string',
  default: 'Label',
});
attributes.add({
  label: 'Items',
  name: 'items',
  type: 'collection',
  default: [],
  metadata: {
    dataType: 'select',
  },
});
attributes.add({
  label: 'Display empty',
  name: 'displayEmpty',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Default value',
  name: 'defaultValue',
  type: 'string',
  default: '',
});
attributes.add({
  label: 'Variant',
  name: 'variant',
  type: 'select',
  default: 'standard',
  list: ['filled', 'outlined', 'standard'],
});
attributes.add({
  label: 'Disabled',
  name: 'disabled',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Required',
  name: 'required',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Auto width',
  name: 'autoWidth',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Controlled',
  name: 'controlled',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Input props',
  name: 'inputProps',
  type: 'object',
  default: {},
});
attributes.add({
  label: 'Menu props',
  name: 'menuProps',
  type: 'object',
  default: {},
});
attributes.add({
  label: 'Icon name',
  name: 'iconName',
  type: 'icon',
  default: '',
});
// attributes.add({
//   label: 'Open',
//   name: 'open',
//   type: 'boolean',
//   default: false,
// });

const stateAttributes: StubbornAttribute[] = [
  {
    label: 'Error',
    name: 'error',
    type: 'boolean',
    default: false,
  },
  {
    label: 'Value',
    name: 'value',
    type: 'string',
    default: '',
  },
  {
    label: 'Helper text',
    name: 'helperText',
    type: 'string',
    default: '',
  },
];

const eventAttributes: StubbornAttribute[] = [
  {
    label: 'OnChange',
    name: 'onChange',
    type: 'code',
    default: '',
  },
  {
    label: 'OnOpen',
    name: 'onOpen',
    type: 'code',
    default: '',
  },
  {
    label: 'OnClose',
    name: 'onClose',
    type: 'code',
    default: '',
  },
];

export const attributeGroups: StubbornAttributeGroup[] = [basicAttributeGroup, makeStateAttributeGroups(stateAttributes), makeComponentAttributeGroup(attributes), makeEventAttributeGroup(eventAttributes)];
