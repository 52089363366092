import React, { useEffect } from "react";
import { LoadingButton as MuiLoadingButton } from "@material-ui/lab";
import { Icon } from '@material-ui/core';
import { CircularProgress } from "@material-ui/core";
import { FieldBaseProps } from "../FieldBaseProps";
import { useEditorScreenContext } from "../../../../../EditorScreenContext";

export interface LoadingButtonProps extends FieldBaseProps {
  component: {
    label: string;
    loadingSize?: string;
    loadingPosition?: "start" | "end" | "center";
    loadingColor?: "primary" | "secondary" | "inherit";
    loadingIndicator?: string;
    variant?: "text" | "outlined" | "contained";
    size?: "small" | "medium" | "large";
    disabled: boolean;
    fullWidth?: boolean;
    disableElevation?: boolean;
    loading?: boolean;
    startIcon?: string;
    endIcon?: string;
  };
}

export const LoadingButton = ({ component, events, states, styles }: LoadingButtonProps) => {
  const { editable } = useEditorScreenContext();

  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadingIndicator = component.loadingIndicator === "" ? <CircularProgress size={component.loadingSize} color={component.loadingColor} /> : component.loadingIndicator;
  const loadingPosition = component.startIcon === "" ? undefined : component.loadingPosition;

  const startIcon = component.loadingPosition === "start" ? <Icon>{component.startIcon}</Icon> : undefined;
  const endIcon = <Icon>{component.endIcon}</Icon>;

  return (
    <MuiLoadingButton
      startIcon={startIcon}
      endIcon={endIcon}
      loading={component.loading}
      disableElevation={component.disableElevation}
      fullWidth={component.fullWidth}
      disabled={component.disabled}
      size={component.size}
      variant={component.variant}
      style={styles}
      loadingIndicator={!component.startIcon ? loadingIndicator : undefined}
      loadingPosition={loadingPosition}
    >
      {component.label}
    </MuiLoadingButton>
  );
};
