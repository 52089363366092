import { routes } from './routes';
import {MenuItem} from "../../../../lib/phinxapp/profiles/types";

export const configurationMenu: MenuItem = {
  key: 'configurationMenu',
  basePath: '/configuration',
  enabled: true,
  icon: 'List',
  text: 'Configuration',
  children: routes,
};
