import {MenuCollection} from "../../../lib/phinxapp/profiles/types";
import {Login} from "../../../application/screens/Public";

export const publicMenu: MenuCollection = [
  {
    key: 'public',
    target: {
      component: Login,
      path: '/',
    },
    basePath: '/',
    enabled: true,
    icon: 'List',
    text: 'Public',
    children: [],
  },
];
