import { routes } from './routes';
import {MenuItem} from "../../../../lib/phinxapp/profiles/types";

export const dashboardMenu: MenuItem = {
  key: 'dashboardMenu',
  basePath: '/dashboard',
  enabled: true,
  icon: 'List',
  text: 'Dashboard',
  children: routes,
};
