import { FieldType } from "../../manager/FieldType";
import { Select } from "./Select";
import { attributeGroups } from "./attributeGroups";
import { toolbox } from "./toolbox";
import { FieldTypeManager } from "../../manager/FieldTypeManager";

export const selectFieldType: FieldType = {
  name: "select",
  component: Select,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(selectFieldType.name, selectFieldType);
