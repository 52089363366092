import React, { useEffect, useState } from "react";
import Editor from "@monaco-editor/react";
import { FieldBaseProps } from "../FieldBaseProps";
import { FieldBaseEvents } from "../FieldBaseEvents";
import { useEditorScreenContext } from "../../../../../EditorScreenContext";

interface CodeAreaEvents extends FieldBaseEvents {
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
}

export interface TableProps extends FieldBaseProps {
  component: {
    language: string;
    height: number;
  };
  events: CodeAreaEvents;
}

export const CodeArea = ({ component, events, states }: TableProps) => {
  const { editable, screen, assetSelector, setScreen } =
    useEditorScreenContext();
  const [code, setCode] = useState(states?.value);

  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleEditorChange(event: any) {
    setCode(event);
    const updatedScreen = {
      ...screen,
      metadata: screen.metadata.map((el, index) => {
        if (index === assetSelector[0])
          return {
            ...el,
            attributes: { ...el.attributes, states: { value: event } },
          };
        else return el;
      }),
    };

    if (events.onChange) events.onChange(event);
    else setScreen(updatedScreen);
  }

  return (
    <Editor
      height={`${component.height}px`}
      width="100%"
      theme="vs-dark"
      defaultLanguage={component.language.toLowerCase()}
      value={code}
      onChange={handleEditorChange}
    />
  );
};