import { createContext, ReactNode, ReactNodeArray, useContext, useEffect, useMemo, useState } from 'react';
import { StubbornProject } from '../../../../../../business/StubbornProject';
import { useProfileDAO, useScreensDAO, useServicesDAO } from '../hooks';
import { ProjectAppContext } from '../../../appContext/ProjectAppContext';
import { ServicesAppContext } from '../../../appContext/ServicesAppContext';
import { ScreensAppContext } from '../../../appContext/ScreensAppContext';
import { useApi } from '../../../../../../../lib/HttpService';
import { ProfileAppContext } from '../../../appContext/ProfileAppContext';

export interface ProjectDAOContextValue {
  project: StubbornProject;
  ProjectDAO: ProjectAppContext;
  loading: boolean;
}

export const ProjectDAOContext = createContext<ProjectDAOContextValue>({
  // @ts-ignore
  project: {},
  // @ts-ignore
  ProjectDAO: {},
  loading: false,
});

export interface ProjectDAOContextProviderProps {
  children: ReactNode | ReactNodeArray;
}

export const ProjectDAOContextProvider = ({ children }: ProjectDAOContextProviderProps) => {
  // TODO: mover la logica de llamadade los screens y los services adentro del ProjectAppContext
  const { services, ServicesDAO, loading: loadingServices } = useServicesDAO();
  const { screens, ScreensDAO, loading: loadingScreens } = useScreensDAO();
  const { routers, ProfileDAO, loading: loadingRouters } = useProfileDAO();
  // Esto e suna chanchada para que el editor espere que el ProjectDAO este listo
  const { api } = useApi();
  const [projectDAOReady, setProjectDAOReady] = useState(false);
  const [ProjectDAO, setProjectDAO] = useState<ProjectAppContext | null>(null);
  useEffect(() => {
    if (ServicesDAO && ScreensDAO && ProfileDAO) {
      setProjectDAO(new ProjectAppContext(ServicesDAO, ScreensDAO));
      setProjectDAOReady(true);
    }
  }, [ScreensDAO, ServicesDAO, ProfileDAO]);

  const value = useMemo<ProjectDAOContextValue>(
    () => ({
      project: {
        name: 'Project', // TODO: poner el nombre del proyecto cuando tengamos la funcionalidad del proyecto
        images: [],
        screens: screens || [],
        services: services || [],
        routers: routers || [],
        tags: [],
      },
      ProjectDAO: ProjectDAO || new ProjectAppContext(new ServicesAppContext([], api), new ScreensAppContext([])),
      loading: loadingServices || loadingScreens || loadingRouters || !projectDAOReady,
    }),
    [ProjectDAO, api, loadingScreens, loadingServices, projectDAOReady, screens, services, routers, loadingRouters],
  );
  return <ProjectDAOContext.Provider value={value}>{children}</ProjectDAOContext.Provider>;
};

export const useProjectDAOContext = () => useContext<ProjectDAOContextValue>(ProjectDAOContext);
