import {dashboardMenu} from "./Dashboard";
import {MenuItem} from "../../../lib/phinxapp/profiles/types";
import {configurationMenu} from "./Configuration";
import {editorMenu} from "./Editor";

export const routes: MenuItem[] = [
  dashboardMenu,
  configurationMenu,
  editorMenu,
];
