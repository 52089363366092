import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { ScreenBaseProps } from '../ScreenBaseProps';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';
import { StubbornAsset } from '../../../../../../../../business/StubbornAsset';
import { GridSpans, RendererContainer } from '../../../components';
import { AnyObject } from '../../../../../../../../commons/types';
import { useServicesDAO } from '../../../hooks';
import { SterContainer } from '../SterContainer/SterContainer';

export interface ContainerProps extends ScreenBaseProps {
  metadata: StubbornAsset[];
  newParentAssetSelector: number[];
  iterator: {
    enabled: boolean;
    service?: {
      serviceName: string;
      params: {
        keys: Array<string>;
        values: Array<string>;
      };
      path: string;
    };
    counter: number;
    api: string; //TODO: Change this to Object { url: string, path: string, token: string }
    items?: AnyObject[];
  };
}

export const Container = ({ metadata, events, states, newParentAssetSelector, styles, iterator }: ContainerProps) => {
  const { editable, screen } = useEditorScreenContext();
  const [layouts, setLayouts] = useState<any>();
  const { ServicesDAO } = useServicesDAO();
  const [intermediateItems, setIntermediateItems] = useState<Array<AnyObject>>(iterator?.enabled && iterator?.items?.length ? iterator?.items : []);
  const chanchada = useCallback(async () => {
    //TODO: Cambiar chanchada
    let query = {};
    if (iterator?.service?.serviceName) {
      iterator?.service?.params?.keys.forEach((key, index) => {
        query = {
          ...query,
          [key]: iterator?.service?.params?.values[index],
        };
      });
      // @ts-ignore
      const res = await ServicesDAO?.[iterator?.service?.serviceName]?.(query);
      if (res) {
        const result = res.data[iterator?.service?.path || ''];
        setIntermediateItems(Array.isArray(result) ? result : []);
      }
    } else {
      const responseFromAPI = await fetch(iterator?.api);
      const data = await responseFromAPI.json();
      if (Array.isArray(data)) {
        setIntermediateItems(data);
      } else setIntermediateItems([data]);
    }
  }, [ServicesDAO, iterator?.api, iterator?.service?.params?.keys, iterator?.service?.params?.values, iterator?.service?.path, iterator?.service?.serviceName]);

  useEffect(() => {
    (async () => {
      if (iterator?.enabled) {
        chanchada();
      } else {
        setIntermediateItems([]);
      }
    })();
  }, [iterator, chanchada, iterator?.counter]);

  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container style={styles} {...events}>
      {!editable && iterator?.enabled
        ? intermediateItems.map((iteratorItem, iterator) =>
          metadata.map((item, index) => {
            const metadataRendererProps = {
              ...item,
              Context: { Item: iteratorItem, ItemIterator: iterator },
              parentAssetSelector: newParentAssetSelector,
              assetIndex: index,
            };
            return (
              <Fragment key={`component-${iterator}-${index}`}>
                <GridSpans layouts={item.attributes.basic.layouts} />
                <RendererContainer {...metadataRendererProps} /> {/* aqui deberiamos tener un render por cada tipo de container */}
                <GridSpans after layouts={item.attributes.basic.layouts} />
              </Fragment>
            );
          }),
        )
        : metadata.map((item, index) => {
          const isSterScreen = item.name.includes('ster');
          return (
            <Fragment key={`component-${index}`}>
              <GridSpans layouts={item.attributes.basic.layouts} />
              {isSterScreen ? <SterContainer {...item} parentAssetSelector={newParentAssetSelector} assetIndex={index} /> : <RendererContainer {...item} parentAssetSelector={newParentAssetSelector} assetIndex={index} />}
              <GridSpans after layouts={item.attributes.basic.layouts} />
            </Fragment>
          );
        })}
    </Grid>
  );
};
