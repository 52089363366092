import React, { useEffect, useCallback, useState } from 'react';
import { Button, Typography, Icon, IconButton, AppBar, Tabs, Tab, Select, MenuItem, TextField, FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import Editor, { useMonaco } from '@monaco-editor/react';
import { BaseAttributeEditorProps } from './BaseAttributeEditorProps';
import { useScreenDAOContext } from '../../context/ScreenDAOContext';
import { useProjectDAOContext } from '../../context/ProjectDAOContext';
import { Modal } from '../../../../../../../commons/ui';

export interface ComponentAction {
  icon: string;
  label: string;
  onClick: string;
  title: string;
  type: 'icon' | 'button';
}

export interface DialogAction {
  label: string;
  onClick: string;
  title: string;
}

export interface AccordionEntry {
  label: string;
  content: string;
}

export interface SelectEntry {
  label: string;
  content: string;
}

const useStyles = makeStyles({
  backdrop: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 16,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#00000080',
    zIndex: 2000,
    top: 0,
    left: 0,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    height: '75%',
    overflowY: 'visible',
    overflowX: 'auto',
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 10px',
  },
  modalContent: {
    display: 'flex',
    flex: 1,
    padding: '0px 10px',
    flexDirection: 'column',
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    justifyContent: 'flex-end',
  },
  cancelButton: { marginRight: 20 },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30,
    marginBottom: 20,
  },
  controlSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  inputs: {
    marginRight: 10,
  },
  select: {
    width: 100,
  },
  selectLabel: {
    marginTop: 5,
    marginLeft: -14,
  },
  codeEditorSubtitle: {
    fontSize: 16,
    marginBottom: 10,
  },
  tabs: {
    color: 'white !important',
  },
  tabsIndicator: {
    background: 'white !important',
  },
  appBar: {
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sideIcons: {
    color: 'white',
  },
  noActions: {
    marginLeft: 10,
  },
  disabledIcon: {
    color: 'gray',
  },
  accordionContentField: {
    marginTop: 30,
  },
});

export interface CollectionAttributeEditorProps extends BaseAttributeEditorProps {}

export const CollectionAttributeEditor = ({ value, onChange, onCancel, onSave, cellName, metadata }: CollectionAttributeEditorProps) => {
  const classes = useStyles();
  const monaco = useMonaco();
  const { ScreenDAO } = useScreenDAOContext();
  const { ProjectDAO } = useProjectDAOContext();

  const [collectionState, setCollectionState] = useState<any>([]);
  const [readyToSave, setReadyToSave] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<number>(0);

  const setTab = (event: any, tab: number) => {
    setCurrentTab(tab);
  };

  useEffect(() => {
    if (monaco && ProjectDAO && ScreenDAO) {
      monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
        noSemanticValidation: true,
        noSyntaxValidation: false,
      });

      // compiler options
      monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
        target: monaco.languages.typescript.ScriptTarget.ES2015,
        allowNonTsExtensions: true,
      });
      monaco.languages.typescript.javascriptDefaults.addExtraLib([ProjectDAO?.getProyectType(), ScreenDAO?.getScreenTypes()].join('\n'), 'file/file.d.ts');
    }
  }, [monaco, ProjectDAO, ScreenDAO]);

  const handleOnBlur = useCallback(
    (event) => {
      onChange(collectionState);
      setReadyToSave(true);
      event.stopPropagation();
    },
    [setReadyToSave, onChange, collectionState],
  );

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Escape':
          onCancel();
          e.stopPropagation();
          e.preventDefault();
          break;
      }
    },
    [onCancel],
  );

  const callOnCancel = () => {
    onClose();
    onCancel();
  };

  const callOnBlur = (e: any) => {
    handleOnBlur(e);
  };

  const onClose = () => {
    setCollectionState([]);
  };

  useEffect(() => {
    try {
      const actions = value;
      if (actions) setCollectionState(actions);
    } catch (e: any) {
      console.error('Could not find actions value', e);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (readyToSave) onSave();
  }, [readyToSave, onSave]);

  const addNewTab = () => {
    if (metadata?.dataType === 'actions') {
      setCollectionState([
        ...collectionState,
        {
          icon: '',
          title: 'Nuevo elemento',
          label: '',
          onClick: '',
          type: 'button',
        },
      ]);
    } else {
      setCollectionState([
        ...collectionState,
        {
          label: 'Nuevo elemento',
          content: '',
        },
      ]);
    }
  };

  const removeTab = () => {
    const newCollectionState = [...collectionState];
    newCollectionState.splice(currentTab, 1);
    if (newCollectionState.length >= 1) setCurrentTab(newCollectionState.length - 1);
    if (newCollectionState.length === 0) {
      setCollectionState([]);
    } else {
      setCollectionState(newCollectionState);
    }
  };

  const handleChangeEntryProp = (e: any, propName: string) => {
    const newAction = collectionState[currentTab];

    const newCollectionState = [...collectionState];

    newCollectionState[currentTab] = {
      ...newAction,
      [propName]: e.target.value,
    };

    setCollectionState(newCollectionState);
  };

  const setEditorValueForState = (value: string) => {
    const actionToSet = collectionState[currentTab];

    const newCollectionState = [...collectionState];

    newCollectionState[currentTab] = {
      ...actionToSet,
      onClick: value,
    };

    setCollectionState(newCollectionState);
  };

  const saveDisabled = metadata?.dataType === 'actions' ? collectionState.some((entry: ComponentAction) => entry.icon === '' || entry.title === '' || entry.label === '') : collectionState.some((entry: AccordionEntry) => entry.label === '' || entry.content === '');

  return (
    <Modal>
      <div className={classes.backdrop} onClick={onCancel}>
        <div
          className={classes.modal}
          style={{ width: '80%' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={handleKeyPress}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={classes.modalHeader}
          >
            <h3>{cellName}</h3>
            <Icon component={CloseIcon} onClick={onCancel} />
          </div>

          <div className={classes.modalContent}>
            <AppBar position="static" className={classes.appBar}>
              {collectionState.length > 0 ? (
                <Tabs classes={{ indicator: classes.tabsIndicator }} style={{ color: 'white' }} value={currentTab} onChange={setTab}>
                  {collectionState.map((action: any, index: number) => (
                    <Tab className={classes.tabs} label={action.title || action.label || 'Nuevo elemento'} key={`tab-action-editor-${index}`} />
                  ))}
                </Tabs>
              ) : (
                <Typography className={classes.noActions}>[Sin elementos]</Typography>
              )}
              <div>
                <IconButton onClick={addNewTab}>
                  <Icon className={classes.sideIcons}>add</Icon>
                </IconButton>
                <IconButton disabled={collectionState.length === 0} onClick={removeTab}>
                  <Icon className={collectionState.length === 0 ? classes.disabledIcon : classes.sideIcons}>delete</Icon>
                </IconButton>
              </div>
            </AppBar>

            {collectionState.length > 0 && metadata?.dataType === 'actions' && (
              <div>
                <div className={classes.controls}>
                  <div className={classes.controlSection}>
                    <TextField onChange={(e) => handleChangeEntryProp(e, 'title')} variant="standard" className={classes.inputs} size="small" InputLabelProps={{ shrink: true }} label="Title" value={collectionState[currentTab]?.title || ''} />
                    <TextField onChange={(e) => handleChangeEntryProp(e, 'label')} variant="standard" className={classes.inputs} size="small" InputLabelProps={{ shrink: true }} label="Label" value={collectionState[currentTab]?.label || ''} />
                    <TextField onChange={(e) => handleChangeEntryProp(e, 'icon')} variant="standard" className={classes.inputs} size="small" InputLabelProps={{ shrink: true }} label="Icon name" value={collectionState[currentTab]?.icon || ''} />
                    <FormControl className={classes.select}>
                      <InputLabel className={classes.selectLabel}>
                        Action control
                      </InputLabel>
                      <Select
                        className={classes.inputs}
                        classes={{ root: classes.select }}
                        label="Action control"
                        size="small"
                        MenuProps={{
                          style: { zIndex: 2500 },
                        }}
                        variant="standard"
                        value={collectionState[currentTab]?.type || 'button'}
                        onChange={(e) => handleChangeEntryProp(e, 'type')}
                      >
                        <MenuItem value="button">Button</MenuItem>
                        <MenuItem value="icon">Icon</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div>
                  <Typography className={classes.codeEditorSubtitle}>onClick handler code:</Typography>
                  <Editor height="330px" width="100%" theme="vs-dark" defaultLanguage="javascript" defaultValue={collectionState[currentTab]?.onClick} value={collectionState[currentTab]?.onClick} onChange={(value) => setEditorValueForState(value ? value?.toString() : '')} />
                </div>
              </div>
            )}

            {collectionState.length > 0 && (metadata?.dataType === 'accordion' || metadata?.dataType === 'select') && (
              <div className={classes.controls}>
                <TextField onChange={(e) => handleChangeEntryProp(e, 'label')} variant="standard" size="small" InputLabelProps={{ shrink: true }} label="Label" value={collectionState[currentTab]?.label || ''} />
                <TextField onChange={(e) => handleChangeEntryProp(e, 'content')} variant="outlined" classes={{ root: classes.accordionContentField }} multiline InputLabelProps={{ shrink: true }} label="Content" value={collectionState[currentTab]?.content || ''} />
              </div>
            )}
          </div>

          <div className={classes.modalFooter}>
            <Button className={classes.cancelButton} variant="outlined" fullWidth={false} onClick={callOnCancel}>
              Cancelar
            </Button>
            <Button variant="contained" disabled={saveDisabled} fullWidth={false} onClick={callOnBlur}>
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
