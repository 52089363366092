import {MenuItem} from "../../../../lib/phinxapp/profiles/types";
import {Configuration, Datasources, Project} from "../../../../application/screens/Private/Configuration";
import {Sessions} from "../../../../application/screens/Private/Configuration/Sessions/Sessions";

export const routes: MenuItem[] = [
  {
    key: 'configurationSessions',
    basePath: '/sessions',
    target: {
      component: Sessions,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Sessions',
    children: [],
    default: true,
  },
  {
    key: 'configurationDatasources',
    basePath: '/datasources',
    target: {
      component: Datasources,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Datasources',
    children: [],
    default: true,
  },
  {
    key: 'configurationProject',
    basePath: '/project',
    target: {
      component: Project,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Proyecto',
    children: [],
    default: true,
  },
  {
    key: 'configurationMain',
    basePath: '/',
    target: {
      component: Configuration,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Configuration',
    children: [],
    default: true,
  },
];
