import React, { Fragment, useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { FieldBaseProps } from '../FieldBaseProps';
import { FieldBaseEvents } from '../FieldBaseEvents';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';
import { AnyObject } from '../../../../../../../../../lib/phinxapp/profiles/types';

interface FileUploadEvents extends FieldBaseEvents {
  onChange?: (e: any) => void;
}

export interface FileUploadProps extends FieldBaseProps {
  component: {
    multipleUploads: boolean;
    maxSize: string;
    acceptedTypes: string;
    defaultValue: AnyObject;
  };
  states: {
    value: AnyObject;
  };
  events: FileUploadEvents;
}

export const FileUpload = ({ component, events, states, styles }: FileUploadProps) => {
  const { editable, screen, assetSelector, setScreen } = useEditorScreenContext();

  useEffect(() => {
    if (events.onMount) events.onMount();

    if (events.onUnmount)
      return () => {
        events.onUnmount();
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: any) => {
    const file = event.target.files[0];
    const updatedScreen = {
      ...screen,
      metadata: screen.metadata.map((item, index) => {
        if (index === assetSelector[0])
          return {
            ...item,
            attributes: { ...item.attributes, states: { value: file } },
          };
        else return item;
      }),
    };
    setScreen(updatedScreen);
  };

  return (
    <Fragment>
      <input accept={component.acceptedTypes} style={{ display: 'none' }} onChange={(e) => handleChange(e)} id="raised-button-file" multiple={component.multipleUploads} type="file" />
      <label htmlFor="raised-button-file">
        <Button component="span">Upload</Button>
      </label>
    </Fragment>
  );
};
