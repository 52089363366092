import { FieldType } from "../../manager/FieldType";
import { LoadingButton } from "./LoadingButton";
import { attributeGroups } from "./attributeGroups";
import { toolbox } from "./toolbox";
import { FieldTypeManager } from "../../manager/FieldTypeManager";

export const loadingButtonFieldType: FieldType = {
  name: "loadingButton",
  component: LoadingButton,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(loadingButtonFieldType.name, loadingButtonFieldType);
