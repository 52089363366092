import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Button, IconButton, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Icon } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { BaseAttributeEditorProps } from './BaseAttributeEditorProps';
import { Modal } from '../../../../../../../commons/ui';

const useStyles = makeStyles({
  backdrop: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 16,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#00000080',
    zIndex: 2000,
    top: 0,
    left: 0,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    height: '75%',
    overflowY: 'visible',
    overflowX: 'auto',
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 10px',
  },
  modalContent: {
    display: 'flex',
    flex: 1,
    padding: '0px 10px',
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    justifyContent: 'flex-end',
  },
  cancelButton: { marginRight: 20 },
  switcher: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  params: {
    marginBottom: 20,
  },
  inputs: {
    marginRight: 10,
  },
  columns: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
});

export interface Params {
  name: string;
  type: string;
  mandatory: boolean;
  default: string;
}

export interface ParamsAttributeEditorProps extends BaseAttributeEditorProps {}

export const ParamsAttributeEditor = ({ value, onChange, onCancel, onSave, cellName }: ParamsAttributeEditorProps) => {
  const classes = useStyles();

  const [intermediateValue, setIntermediateValue] = useState<string>(JSON.stringify(value, null, '\t'));
  const [readyToSave, setReadyToSave] = useState(false);
  const parsedValue = JSON.parse(intermediateValue);

  const handleOnBlur = useCallback(
    (event) => {
      onChange(parsedValue);
      setReadyToSave(true);
      event.stopPropagation();
    },
    [setReadyToSave, parsedValue, onChange],
  );

  const addParams = useCallback(() => {
    let newParams = parsedValue;
    newParams?.keys.push('');
    newParams?.values.push('');

    setIntermediateValue(JSON.stringify(newParams, null, 2));
  }, [setIntermediateValue, parsedValue]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Escape':
          onCancel();
          e.stopPropagation();
          e.preventDefault();
          break;
      }
    },
    [onCancel],
  );

  const callOnBlur = (e: any) => {
    handleOnBlur(e);
  };

  useEffect(() => {
    if (readyToSave) onSave();
  }, [readyToSave, onSave]);

  const handleChangeParams = (key: string, index: number, paramsValue: string | boolean) => {
    const newParams = parsedValue;

    newParams[key][index] = paramsValue;

    setIntermediateValue(JSON.stringify(newParams, null, 2));
  };

  const handleDelete = useCallback(
    (index: number) => {
      const newParams = parsedValue;

      newParams.keys.splice(index, 1);
      newParams.values.splice(index, 1);

      setIntermediateValue(JSON.stringify(newParams, null, 2));
    },
    [setIntermediateValue, parsedValue],
  );

  return (
    <Modal>
      <div className={classes.backdrop} onClick={onCancel}>
        <div
          className={classes.modal}
          style={{ width: 'auto' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={handleKeyPress}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={classes.modalHeader}
          >
            <h3>{cellName}</h3>
            <Icon component={CloseIcon} onClick={onCancel} />
          </div>
          <div className={classes.modalContent}>
            <div className={classes.columns}>
              {parsedValue?.keys.map((key: Params, index: number) => (
                <div className={classes.params}>
                  <TextField onChange={(event) => handleChangeParams('keys', index, event.target.value)} variant="standard" className={classes.inputs} size="small" InputLabelProps={{ shrink: true }} label="Nombre" value={key} />
                  <TextField onChange={(event) => handleChangeParams('values', index, event.target.value)} variant="standard" className={classes.inputs} size="small" InputLabelProps={{ shrink: true }} label="Valor" value={parsedValue.values[index]} />
                  <IconButton onClick={() => handleDelete(index)}>
                    <Icon>clear</Icon>
                  </IconButton>
                </div>
              ))}
              <Button variant="contained" onClick={addParams}>
                Agregar Parametros
              </Button>
            </div>
          </div>
          <div className={classes.modalFooter}>
            <Button className={classes.cancelButton} variant="outlined" fullWidth={false} onClick={onCancel}>
              Cancelar
            </Button>
            <Button variant="contained" fullWidth={false} onClick={callOnBlur}>
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
