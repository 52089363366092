import React from 'react';
import {ScreenContainer, ScreenTitle} from "../../../components";
import {AssetList} from "./components";

export const Dashboard = () => {
  return (
    <ScreenContainer>
      <ScreenTitle>Dashboard</ScreenTitle>
      <AssetList />
    </ScreenContainer>
  );
};
