import React, { useEffect } from 'react';
import { Icon as MuiIcon } from '@material-ui/core';
import { FieldBaseProps } from '../FieldBaseProps';

type Color = 'disabled' | 'primary' | 'secondary' | 'error' | 'inherit' | 'action' | undefined;

type FontSize = 'inherit' | 'large' | 'medium' | 'small';

export interface IconProps extends FieldBaseProps {
  component: {
    content?: string;
    color: Color;
    fontSize?: FontSize;
  };
}

// TODO:
// 1. implementar dynamic import

export const Icon = ({ component, events, states, styles }: IconProps) => {
  useEffect(() => {
    if (events.onMount) events.onMount();

    if (events.onUnmount)
      return () => {
        events.onUnmount();
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MuiIcon style={styles} fontSize={component.fontSize} color={component.color}>
      {component.content}
    </MuiIcon>
  );
};
