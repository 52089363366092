import { AnyObject } from '../commons/types';

export async function securedJSRunner(script: string, event: any, Screen: any, Router: any, Services: any, Project: any, Context: AnyObject = {}, callback?: () => void) {
  const additionalTemplate = Object.keys(Context).reduce<string>((acum, key) => {
    return `${acum}const ${key} = SBContext['${key}'];`;
  }, '');
  const handler = {
    get: (target: AnyObject, name: string) => {
      return Project.routers;
    },
  };

  const SBRouter = new Proxy({}, handler);
  // eslint-disable-next-line no-new-func
  return await Function(`return (async function execute(event, SBScreen, SBScreens, SBServices, SBProject, SBContext, SBRouter, SBProfile, Callback) { ${additionalTemplate}${script} })`)()(event, Screen, Project.screens, Services, Project, Context, Project.routers, Project.routers, callback);
}
