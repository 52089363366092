import {FieldType} from "../../manager/FieldType";
import {List} from "./List";
import {attributeGroups} from "./attributeGroups";
import {toolbox} from "./toolbox";
import {FieldTypeManager} from "../../manager/FieldTypeManager";

export const listFieldType: FieldType = {
  name: 'list',
  component: List,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(listFieldType.name, listFieldType);
