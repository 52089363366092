import React, { useMemo } from 'react';
import { AppBar, Breadcrumbs, Button, Link, Toolbar, Select, MenuItem, ButtonGroup, IconButton, Icon, Tooltip, Typography, List, ListItem, Divider, Grid } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles, createStyles } from '@material-ui/styles';
import { AppThemeType } from '../../../../../../theme';
import { StubbornAsset, StubbornScreen } from '../../../../../business/StubbornAsset';
import { getAssetByAssetSelector } from '../../../../../utils';
import { useEditorScreenContext } from '../../EditorScreenContext';
import { useScreenDAOContext } from './context/ScreenDAOContext';
import { breakpoints, HIDDEN_SCREENS } from '../../constants';
import { availablePanelsByType, useAssetContext } from '../../../../Private2/commons/context/AssetContext';

const useClasses = makeStyles<AppThemeType>((theme) =>
  createStyles({
    appbar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 50,
    },
    controls: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    breakpointSwitcher: {
      marginRight: 5,
    },
    zoomSwitcher: {},
    zoomIndicator: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: 5,
      paddingRight: 5,
      background: '#222',
      borderRight: '2px solid #1976d2 !important',
      borderLeft: '1px solid #1976d2 !important',
      color: 'white',
      '&:hover': {
        backgroundColor: 'gray',
      },
    },
    increaseButton: {
      background: '#222',
      '&:hover': {
        backgroundColor: 'gray',
      },
    },
    decreaseButton: {
      background: '#222',
      '&:hover': {
        backgroundColor: 'gray',
      },
    },
    hiddenElementsTooltip: {},
    hiddenFieldListItem: {
      cursor: 'pointer',
      fontSize: 14,
    },
    hiddenFieldListDivider: {
      marginTop: 20,
      background: 'white',
    },
    clickable: {
      cursor: 'pointer',
      color: 'white',
      textDecoration: 'none',
    },
    nonClickable: {
      color: '#707ef9',
      textDecoration: 'none',
    },
    changeMode: {
      marginLeft: 20,
      textTransform: 'capitalize',
    },
    assetSelectorItem: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
    },
  }),
);

interface AppToolbarProps {
  setBreakpoint: (index: number) => void;
  setZoom: (zoom: number) => void;
  currentZoom: number;
  currentBreakpoint: number;
  setEditable: (editable: boolean) => void;
}

export function AppToolbar({ setBreakpoint, setZoom, currentZoom, currentBreakpoint, setEditable }: AppToolbarProps) {
  const { editable, toggleEditable, assetSelector, setAssetSelector, setParentSelector } = useEditorScreenContext();
  const { screen } = useScreenDAOContext();

  const { setActivePanel, activePanel, selectedAsset } = useAssetContext();
  const metadata = screen?.metadata;

  const hiddenEntriesFound = useMemo(() => metadata?.some((elem: any) => Object.values(HIDDEN_SCREENS).includes(elem.fieldType as HIDDEN_SCREENS)) || false, [metadata]);
  const classes = useClasses();

  const zoomIsEquallTo10 = Math.round(currentZoom * 100) === 10;
  const zoomIsEqualTo200 = Math.round(currentZoom * 100) === 200;

  const decreaseZoom = () => {
    setZoom(currentZoom - 0.1);
  };

  const increaseZoom = () => {
    setZoom(currentZoom + 0.1);
  };

  const onChangeEditMode = () => {
    toggleEditable();
    setParentSelector([]);
    setAssetSelector([]);
    setEditable(!editable);
  };

  const selectHiddenField = (e: any, index: number) => {
    setAssetSelector((prev) => [index]);
  };

  const selectAsset = (e: any, index: number) => {
    setAssetSelector((prev) => [...prev, index]);
  };

  return (
    !!screen && (
      <Grid container style={{ background: '#2F343D', height: 66, margin: 0, display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10, justifyContent: 'space-between' }}>
        <Toolbar style={{ padding: 0, margin: 0 }}>
          <IconButton
            style={{ color: 'white' }}
            onClick={() => {
              selectedAsset && setActivePanel(availablePanelsByType[selectedAsset?.type][0]);
            }}
          >
            <Icon>settings</Icon>
          </IconButton>
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" color="disabled" />} style={{ marginLeft: 10 }}>
            <div className={classes.assetSelectorItem}>
              <Link
                className={classes.clickable}
                color="inherit"
                onClick={() => {
                  setAssetSelector([]);
                  setParentSelector([]);
                }}
              >
                {screen?.name}
              </Link>
              {metadata && metadata.length ? (
                <Tooltip
                  arrow
                  title={
                    <div className={classes.hiddenElementsTooltip}>
                      <Typography>Internal assets: </Typography>
                      <Divider className={classes.hiddenFieldListDivider} />
                      <List>
                        {metadata?.map((elem: any, index) => (
                          <ListItem
                            className={classes.hiddenFieldListItem}
                            onClick={(e) => {
                              setParentSelector([]);
                              selectHiddenField(e, index);
                            }}
                            key={`hidden-screen-type-item-${index}`}
                          >
                            {elem.name}
                          </ListItem>
                        ))}
                      </List>
                    </div>
                  }
                >
                  <Icon style={{ color: 'white', fontSize: 15, marginLeft: 5 }}>keyboard_arrow_down</Icon>
                </Tooltip>
              ) : null}
            </div>
            {editable && assetSelector?.map((assetIndex, index) => {
              const item: StubbornAsset = getAssetByAssetSelector(screen as StubbornScreen, assetSelector, index);
              return (
                <div className={classes.assetSelectorItem}>
                  <Link className={index !== assetSelector.length - 1 ? classes.clickable : classes.nonClickable} key={`${index}${assetIndex}`} color="inherit" onClick={() => setAssetSelector((prev) => prev.slice(0, index + 1))}>
                    {item.name}
                  </Link>
                  {item?.metadata && item?.metadata.length ? (
                    <Tooltip
                      arrow
                      title={
                        <div className={classes.hiddenElementsTooltip}>
                          <Typography>Internal assets: </Typography>
                          <Divider className={classes.hiddenFieldListDivider} />
                          <List>
                            {item?.metadata?.map((elem: any, metaIndex) => (
                              <ListItem className={classes.hiddenFieldListItem} onClick={(e) => setAssetSelector((prev) => [...prev.slice(0, index + 1), metaIndex])} key={`hidden-screen-type-item-${metaIndex}`}>
                                {elem.name}
                              </ListItem>
                            ))}
                          </List>
                        </div>
                      }
                    >
                      <Icon style={{ color: 'white', fontSize: 15, marginLeft: 5 }}>keyboard_arrow_down</Icon>
                    </Tooltip>
                  ) : null}
                </div>
              );
            })}
          </Breadcrumbs>
        </Toolbar>
        <div className={classes.controls}>
          {hiddenEntriesFound && editable && (
            <Tooltip
              title={
                <div className={classes.hiddenElementsTooltip}>
                  <Typography>Elegí un elemento escondido para acceder a sus propiedades: </Typography>
                  <Divider className={classes.hiddenFieldListDivider} />
                  <List>
                    {metadata.map((elem: any, index) =>
                      Object.values(HIDDEN_SCREENS).includes(elem.fieldType as HIDDEN_SCREENS) ? (
                        <ListItem className={classes.hiddenFieldListItem} onClick={(e) => selectHiddenField(e, index)} key={`hidden-screen-type-item-${index}`}>
                          {elem.name}
                        </ListItem>
                      ) : null,
                    )}
                  </List>
                </div>
              }
            >
              <IconButton>
                <Icon style={{ color: 'white' }}>info</Icon>
              </IconButton>
            </Tooltip>
          )}

          <div>
            <IconButton onClick={() => setBreakpoint(0)}>
              <Icon style={{ color: currentBreakpoint === 0 ? '#707ef9' : 'white' }}>phone_android</Icon>
            </IconButton>
            <IconButton onClick={() => setBreakpoint(1)}>
              <Icon style={{ color: currentBreakpoint === 1 ? '#707ef9' : 'white' }}>tablet_android</Icon>
            </IconButton>
            <IconButton onClick={() => setBreakpoint(2)}>
              <Icon style={{ color: currentBreakpoint === 2 ? '#707ef9' : 'white' }}>tablet</Icon>
            </IconButton>
            <IconButton onClick={() => setBreakpoint(3)}>
              <Icon style={{ color: currentBreakpoint === 3 ? '#707ef9' : 'white' }}>laptop</Icon>
            </IconButton>
            <IconButton onClick={() => setBreakpoint(4)}>
              <Icon style={{ color: currentBreakpoint === 4 ? '#707ef9' : 'white' }}>desktop_windows</Icon>
            </IconButton>
          </div>

          {/* <Select
            size="small"
            className={classes.breakpointSwitcher}
            value={currentBreakpoint}
            onChange={(e) => setBreakpoint(+e.target.value)}
            MenuProps={{
              style: { zIndex: 2500 },
            }}
          >
            {breakpoints.map((breakpoint, index) => (
              <MenuItem key={`breakpoint-select-${index}`} value={index}>
                {breakpoint.name}
              </MenuItem>
            ))}
          </Select>
          <ButtonGroup className={classes.zoomSwitcher} variant="contained" color="secondary" aria-label="contained primary button group">
            <Button className={classes.decreaseButton} disabled={zoomIsEquallTo10} onClick={decreaseZoom}>
              -
            </Button>
            <Button className={classes.zoomIndicator} onClick={() => setZoom(1)}>{`${Math.round(currentZoom * 100)}%`}</Button>
            <Button className={classes.increaseButton} disabled={zoomIsEqualTo200} onClick={increaseZoom}>
              +
            </Button>
          </ButtonGroup>*/}
          <IconButton onClick={onChangeEditMode}>
            <Icon style={{ color: 'white', marginLeft: 20 }}>{editable ? 'play_arrow' : 'pause'}</Icon>
          </IconButton>
        </div>
      </Grid>
    )
  );
}
