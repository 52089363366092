import _ from 'lodash';
import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Select, MenuItem, Switch } from '@material-ui/core';
import Editor from '@monaco-editor/react';
import { AppThemeType } from '../../../../../../../theme';
import { makeStyles, useTheme, createStyles } from '@material-ui/styles';
import { breakpoints } from '../../../constants';

const useStyles = makeStyles<AppThemeType>((theme) =>
  createStyles({
    editLayoutContent: {
      minWidth: 500,
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

interface EditorStylesProps {
  styleState: any;
  setBreakPointDisabled: (breakpointCode: string, val: boolean) => void;
  setIntermediateStylesValue: (value: string, breakpointCode: string) => void;
}

export function EditorStyles({ styleState, setIntermediateStylesValue, setBreakPointDisabled }: EditorStylesProps) {
  const theme = useTheme();
  //@ts-ignore
  const classes = useStyles(theme);

  const [isExpanded, setIsExpanded] = useState<string | false>(false);

  const setExpandedState = (panel: string) => (event: any, isExpanded: boolean) => {
    setIsExpanded(isExpanded ? panel : false);
  };

  const editors = [
    {
      name: 'General',
      code: 'general',
    },
    ...breakpoints,
  ].map((breakpoint: any, index: number) => {
    return (
      <Accordion expanded={isExpanded === `expanded-editor-${index}`} onChange={setExpandedState(`expanded-editor-${index}`)} TransitionProps={{ unmountOnExit: true }} key={`breakpoint-editor-${index}`}>
        <AccordionSummary>
          <Typography>{breakpoint.name}</Typography>
        </AccordionSummary>

        <div className={classes.column}>
          <AccordionDetails>
            <Typography>{breakpoint.code === "general" ? "Activar el estilo general" : "Activar el breakpoint"}</Typography>
            <Switch checked={!!styleState.hasOwnProperty(breakpoint.code)} onChange={(e) => setBreakPointDisabled(breakpoint.code, e.target.checked)} />
          </AccordionDetails>

          <AccordionDetails>
          {styleState[breakpoint.code] &&
            <Editor height="100px" width="100%" theme="vs-dark" defaultLanguage="json"

            defaultValue={JSON.stringify(styleState[breakpoint.code], null, '\t')}
            onChange={(value) => setIntermediateStylesValue(value ? value?.toString() : '', breakpoint.code)}
            
            />
          }
          </AccordionDetails>
        </div>
      </Accordion>
    );
  });

  return <div className={classes.editLayoutContent}>{editors}</div>;
}
