import React, {useCallback} from 'react';
import {StubbornAttribute} from "../../../../../../../business/StubbornAttributeMapping";
import {StubbornRouter} from "../../../../../../../business/StubbornRouter";
import {EditorPropertiesDataGrid} from "../../../ScreenEditor/properties/EditorPropertiesDataGrid";
import {useRowsFromAttributes} from "../../../../../../../commons/hooks/useRowsFromAttributes";
import {AnyObject} from "../../../../../../../commons/types";

const attributes = new Set<StubbornAttribute>();
attributes.add({
  name: 'name',
  type: 'string',
  label: 'Nombre',
});
attributes.add({
  name: 'basePath',
  type: 'string',
  label: 'Base Path',
  default: '',
});
attributes.add({
  name: 'layout',
  type: 'string',
  label: 'Target Layout',
  default: undefined,
});

//const conditions = new Set<StubbornAttribute>();
attributes.add({
  name: 'condition',
  type: 'string',
  label: 'Condition',
  default: ''
});
attributes.add({
  name: 'router',
  type: 'string',
  label: 'Router',
  default: '',
});
attributes.add({
  name: 'parameters',
  type: 'breakpoints',
  label: 'Parameters',
  default: {},
  metadata: {
    dataType: 'params',
  },
});

//const events = new Set<StubbornAttribute>();
attributes.add({
  name: 'beforeMount',
  type: 'code',
  label: 'BeforeMount',
});
attributes.add({
  name: 'onMount',
  type: 'code',
  label: 'OnMount',
  default: '',
});
attributes.add({
  name: 'beforeRouteChange',
  type: 'code',
  label: 'BeforeRouteChange',
  default: '',
});
attributes.add({
  name: 'onRouteChange',
  type: 'code',
  label: 'OnRouteChange',
  default: '',
});

export interface RouterPropertyEditorProps {
  router: StubbornRouter;
  onServiceChange: (newService: StubbornRouter) => void;
}

export const RouterPropertyEditor = ({ router, onServiceChange }: RouterPropertyEditorProps) => {
  const rows = useRowsFromAttributes(attributes, router);
  const onChange = useCallback((newRows: AnyObject[]) => {
    const newService: StubbornRouter = { ...router };
    newRows.forEach((item) => {
      // @ts-ignore
      newService[item.prop as string] = item.value as any;
    });
    onServiceChange(newService);
  }, [onServiceChange, router]);
  return (
      <EditorPropertiesDataGrid initialRows={rows} onChange={onChange} />
  );
};
