import React, {useCallback, useEffect, useState} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import LoadingButton from "@material-ui/lab/LoadingButton";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import {useInputState} from "../../../../../../../../../../commons/hooks";

export interface NewScreenDialogProps {
  open: boolean;
  onClose: () => void;
  onCreateScreen: (name: string) => Promise<void>;
}

export const NewScreenDialog = ({ open, onClose, onCreateScreen }: NewScreenDialogProps) => {
  const validate = true;
  const [name, onNameChange, setName, error] = useInputState('', validate);
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);

  const onCreate = useCallback(async () => {
    setDirty(true);
    if (name) {
      try {
        setLoading(true)
        await onCreateScreen(name);
        setLoading(false);
        onClose();
        setName('');
        setDirty(false);
      } catch (e: any) {
        alert(e.message); // TODO: notistack
        setLoading(false);
      }
    }
  }, [name, onCreateScreen, onClose, setName]);
  return (
    <Dialog
      open={open}
      onClose={loading ? undefined : onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Nueva Screen</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="description"
          label="Nombre"
          name="name"
          fullWidth
          value={name}
          onChange={onNameChange}
          error={(dirty && !name)  || error?.bool}
          helperText={(dirty && !name && 'Nombre requerido') || (error?.bool && error.message)}
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" disabled={loading}>
          Cerrar
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          autoFocus
          onClick={onCreate}
          disabled={loading}
          loading={loading}
        >
          Crear
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
