import { FieldType } from '../../manager/FieldType';
import { SterContainer } from './SterContainer';
import { attributeGroups } from './attributeGroups';
import { toolbox } from './toolbox';
import { FieldTypeManager } from '../../manager/FieldTypeManager';

export const SterContainerFieldType: FieldType = {
  name: 'sterContainer',
  component: SterContainer,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(SterContainerFieldType.name, SterContainerFieldType);
