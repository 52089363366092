import React, {Dispatch, useState} from 'react';
import { useInputOnChangeValue } from './useInputOnChangeValue';

export const useInputState = <T = any>(
  initialState: T,
  validate?: boolean,
): [T, (event: React.ChangeEvent<HTMLInputElement>) => void, Dispatch<T>, { bool: boolean; message: string; } | undefined] => {
  const [value, setValue] = useState<T>(initialState);
  const [error, setError] = useState<{ bool: boolean; message: string; } | undefined>(undefined);
  const onValueChange = useInputOnChangeValue(setValue, validate, setError);

  return [value, onValueChange, setValue, error];
};
