import React, {Fragment, ReactNode, ReactNodeArray, useCallback} from 'react';
import {Card, CardActionArea, CardActions, CardContent, CardMedia, Typography, IconButton, Button} from '@material-ui/core';

export interface CardButtonAction<T> {
  id: string,
  label?: string,
  icon?: ReactNode,
  title?: string,
  render?: (data: T, action: CardButtonAction<T>) => ReactNode | ReactNodeArray,
  onClick?: (data: T, event: any) => void;
}

export interface CardButtonProps<T> {
  title: string | JSX.Element;
  image?: string;
  data: T;
  onClick?: (data: T) => any;
  actions?: CardButtonAction<T>[];
}

export function CardButton<T = any>({
  title,
  image,
  onClick,
  data,
  actions = [],
}: CardButtonProps<T>) {
  const onCardClick = useCallback(() => onClick && onClick(data), [data, onClick]);
  return (
    <Card>
      <CardActionArea onClick={onCardClick}>
        {!!image && (
          <CardMedia
            component="img"
            alt="Conf image"
            height="200"
            image={image}
          />
        )}
        <CardContent>
          {typeof title !== 'string' ? (
            title
          ) : (
            <Typography variant="h4">{title}</Typography>
          )}
        </CardContent>
      </CardActionArea>
      {!!actions.length && (
        <CardActions >
          {actions.map(action => {
            // Render logic
            if (action.render) {
              return (
                <Fragment key={action.id}>
                  {action.render(data, action)}
                </Fragment>
              );
            }
            if (action.icon && !action.label) {
              return (
                <IconButton title={action.title} size="small" color="primary" key={action.id} onClick={(event) => !!action.onClick && action.onClick(data, event)}>
                  {action.icon}
                </IconButton>
              );
            }
            return (
              <Button startIcon={action.icon} size="small" color="primary" key={action.id} onClick={(event) => !!action.onClick && action.onClick(data, event)}>
                {action.icon}
              </Button>
            );
          })}
        </CardActions>
      )}
    </Card>
  );
}
