import React, { useEffect } from 'react';

export const usePortal = (id: string | number) => {
  const rootElemRef = React.useRef(document.createElement('div'));

  useEffect(() => {
    // Look for existing target dom element to append to
    const parentElem = document.querySelector(`#${id}`);
    if (parentElem) {
      // Add the detached element to the parent
      parentElem.appendChild(rootElemRef.current);
    }
    // This function is run on unmount
    const currentRef = rootElemRef.current;
    return () => {
      currentRef.remove();
    };
  }, [id]);

  return rootElemRef.current;
};
