import { StubbornAttribute, StubbornAttributeGroup } from '../../../../../../../../business/StubbornAttributeMapping';
import { actionAttributes, contentAttributes, headerAttributes, mediaAttributes } from '../Card/attributeGroups';
import { basicAttributeGroup, makeComponentAttributeGroup, makeCustomAttributeGroup, makeEventAttributeGroup, makeStateAttributeGroups } from '../../commons/attributes';

const cardBasicAttributes = new Set<StubbornAttribute>();
cardBasicAttributes.add({
  label: 'Layout',
  name: 'layouts',
  type: 'breakpoints',
  default: { md: { col: 3 } },
  metadata: { dataType: 'layout' },
});

cardBasicAttributes.add({
  label: 'Style',
  name: 'styles',
  type: 'breakpoints',
  default: {},
  metadata: { dataType: 'styles' },
});

const cardBasicAttributeGroup: StubbornAttributeGroup = {
  label: 'Card Basic',
  name: 'cardBasic',
  priority: 1,
  attributes: cardBasicAttributes,
};

const attributes = new Set<StubbornAttribute>();

const stateAttributes: StubbornAttribute[] = [
  {
    label: 'Items',
    name: 'items',
    type: 'object',
    default: [],
  },
];

const eventAttributes: StubbornAttribute[] = [
  {
    label: 'OnCardClick',
    name: 'onCardClick',
    type: 'code',
    default: '',
  },
  {
    label: 'OnCardMount',
    name: 'onCardMount',
    type: 'code',
    default: '',
  },
  {
    label: 'OnCardUnmount',
    name: 'onCardUnmount',
    type: 'code',
    default: '',
  },
];

export const datasourceAttributes = new Set<StubbornAttribute>();
datasourceAttributes.add({
  label: 'Enabled',
  name: 'enabled',
  type: 'boolean',
  default: false,
});
datasourceAttributes.add({
  label: 'Service',
  name: 'service',
  type: 'serviceDatasource',
  default: {
    serviceName: '',
    params: {
      keys: [],
      values: [],
    },
    path: '',
  },
});
datasourceAttributes.add({
  label: 'Items',
  name: 'items',
  type: 'object',
  default: [],
});

export const attributeGroups: StubbornAttributeGroup[] = [
  basicAttributeGroup,
  makeStateAttributeGroups(stateAttributes),
  makeComponentAttributeGroup(attributes),
  makeCustomAttributeGroup('Datasource', 'datasource', datasourceAttributes),
  cardBasicAttributeGroup,
  makeCustomAttributeGroup('Header', 'header', headerAttributes),
  makeCustomAttributeGroup('Contenido', 'content', contentAttributes),
  makeCustomAttributeGroup('Media', 'media', mediaAttributes),
  makeCustomAttributeGroup('Acciones', 'actions', actionAttributes),
  makeEventAttributeGroup(eventAttributes),
];
