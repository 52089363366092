import React, { useMemo } from 'react';
import { Grid } from "@material-ui/core";
import { StubbornAsset } from "../../../../../../business/StubbornAsset";
import { GridProps } from "@material-ui/core/Grid/Grid";
import { useCurrentBreakpoint } from "../../../../../../commons/hooks";

export interface GridSpansProps {
  layouts: StubbornAsset['attributes']['basic']['layouts'];
  after?: boolean;
}

export const GridSpans = ({ layouts, after }: GridSpansProps) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const hidden = useMemo(() => {
    const entries = layouts ? Object.entries(layouts) : [];
    // nos fijamos si existe algun span, sino retornamos hidden
    if (!entries.some(([, item]) => (after ? !!item.spanAfter : !!item.span))) {
      return true;
    }
    // obtenemos los span que sean cero
    const hiddenBreakpoints = entries.reduce<string[]>((acum, [breakpoint, item]) => {
      // si el span no existe o es 0, o el col es 0 no agregamos el span
      if ((!after && !item.span) || !item.col) {
        return [...acum, breakpoint];
      } else if ((after && !item.spanAfter) || !item.col) {
        return [...acum, breakpoint];
      }
      return acum;
    }, []);
    // devolvemos true si el current bp existe el array de span cero
    return hiddenBreakpoints.includes(currentBreakpoint);
  }, [layouts, currentBreakpoint, after]);
  const gridProps = useMemo<GridProps>(
    () =>
      layouts &&
      Object.entries(layouts).reduce<GridProps>(
        (acum, [breakpoint, layout]) => ({
          ...acum,
          ...(after ? (!layout.spanAfter ? {} : { [breakpoint]: layout.spanAfter }) : !layout.span ? {} : { [breakpoint]: layout.span }),
        }),
        {},
      ),
    [layouts, after],
  );
  return hidden ? null : <Grid item {...gridProps} />;
};
