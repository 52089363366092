import { useEffect, useState } from 'react';
import { useServicesDAO } from '.';
import { useEditorScreenContext } from '../../../EditorScreenContext';

export interface DatasourceObject<T> {
  enabled: boolean;
  service?: {
    serviceName: string;
    params: {
      keys: Array<string>;
      values: Array<string>;
    };
    path: string;
  };
  items?: T[];
}

export const useDatasource = <T>(datasource: DatasourceObject<T>) => {
  const { screen } = useEditorScreenContext();
  const { ServicesDAO } = useServicesDAO();
  const [items, setItems] = useState<T[]>(datasource.items || []);

  useEffect(() => {
    (async () => {
      if (!datasource?.enabled) return;
      let query = {};
      datasource.service?.params?.keys.forEach((key, index) => {
        query = {
          ...query,
          [key]: datasource.service?.params?.values[index],
        };
      });

      try {
        // @ts-ignore
        const res = await ServicesDAO?.[datasource.service?.serviceName]?.(query);

        if (!res) return;

        const result = res.data[datasource.service?.path || ''];

        if (!Array.isArray(result)) return;

        const newItems = !!result.length ? result : items;

        setItems(newItems);
      } catch (error) {
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasource, ServicesDAO, screen]);

  return { items };
};
