import {BaseAttributeEditorProps} from "./BaseAttributeEditorProps";
import {TextField} from "@material-ui/core";
import React, {useCallback} from "react";
import {makeStyles} from "@material-ui/styles";
import {useInputOnChangeValue} from "../../../../../../../commons/hooks";

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    fontSize: 10,
  }
});

export interface TextAttributeEditorProps extends BaseAttributeEditorProps {}

export const TextAttributeEditor = ({value, onChange, onCancel, onSave}: TextAttributeEditorProps) => {
  const classes = useStyles();
  const handleChange = useInputOnChangeValue(onChange);

  const handleOnBlur = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    onSave();
    event.stopPropagation();
  }, [onSave]);

  const handleKeyPress = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        onSave();
        e.stopPropagation();
        e.preventDefault();
        break;
      case 'Escape':
        onCancel();
        e.stopPropagation();
        e.preventDefault();
        break;
    }
  }, [onSave, onCancel]);

  return (
    <div className={classes.root}>
      <TextField
        autoFocus={true}
        variant="outlined"
        value={value}
        onChange={handleChange}
        onBlur={handleOnBlur}
        onKeyDown={handleKeyPress}
      />
    </div>
  );
};
