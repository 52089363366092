import { routes } from './routes';
import {MenuItem} from "../../../../lib/phinxapp/profiles/types";

export const editorMenu: MenuItem = {
  key: 'editorMenu',
  basePath: '/editor',
  enabled: true,
  icon: 'List',
  text: 'Editor',
  children: routes,
};
