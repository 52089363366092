import { useCallback, useState } from 'react';
import LoadingButton from '@material-ui/lab/LoadingButton';
import TextField from '@material-ui/core/TextField';
import { Box } from '@material-ui/system';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { StubbornDatasourceKind, StubbornDatasourceType } from '../../../../../../../../business/StubbornAsset';
import { useInputState } from '../../../../../../../../commons/hooks';

export interface DatasourceProps {
  onUpdateDatasource: (name: string, kind: StubbornDatasourceKind, type: StubbornDatasourceType, host: string, port: number, user: string, password: string, database: string, onconnect: string) => Promise<void>;
  onTestDatasource: (name: string, kind: StubbornDatasourceKind, type: StubbornDatasourceType, host: string, port: number, user: string, password: string, database: string, onconnect: string) => Promise<void>;
  defaultData: { name?: string; kind?: StubbornDatasourceKind; type?: StubbornDatasourceType; host?: string; port?: number; user?: string; password?: string; database?: string; onconnect?: string };
  update?: boolean;
}

export const DatasourcePanelForm = ({ onUpdateDatasource, onTestDatasource, defaultData, update }: DatasourceProps) => {
  const [name, onNameChange, setName] = useInputState(defaultData.name || '');
  const [kind, onKindChange, setKind] = useInputState<StubbornDatasourceKind>(defaultData.kind || StubbornDatasourceKind.SQL);
  const [port, onPortChange, setPort] = useInputState<number>(defaultData.port || 8080);
  const [host, onHostChange, setHost] = useInputState(defaultData.host || '');
  const [user, onUserChange, setUser] = useInputState(defaultData.user || '');
  const [password, onPasswordChange, setPassword] = useInputState(defaultData.password || '');
  const [database, onDatabaseChange, setDatabase] = useInputState(defaultData.database || '');
  const [onConnect, onOnConnectChange, setOnConnect] = useInputState(defaultData.onconnect || '');
  const [type, onTypeChange, setType] = useInputState<StubbornDatasourceType>(StubbornDatasourceType.POSTGRES);
  //const [type, setType] = useState<string>(defaultData.type || StubbornDatasourceType.POSTGRES);

  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);

  const onCreate = useCallback(async () => {
    setDirty(true);
    if (name && kind) {
      try {
        setLoading(true);
        await onUpdateDatasource(name, kind, type, host, port, user, password, database, onConnect);
        alert('Datasource updated');
        setLoading(false);
        setDirty(false);
      } catch (e: any) {
        alert('Something went wrong');
        setLoading(false);
      }
    }
  }, [name, kind, type, host, port, user, password, database, onConnect, onUpdateDatasource]);

  const onTest = useCallback(async () => {
    setDirty(true);
    if (name && kind) {
      try {
        setLoading(true);
        await onTestDatasource(name, kind, type, host, port, user, password, database, onConnect);
        setLoading(false);
        setDirty(false);
      } catch (e: any) {
        alert('Something went wrong');
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, kind, type, host, port, user, password, database, onConnect, onTestDatasource, setName, setDatabase, setHost, setOnConnect, setPassword, setPort, setUser]);

  return (
    <Grid>
      <TextField autoFocus margin="dense" id="name" label="Nombre" name="name" fullWidth value={name} onChange={onNameChange} error={dirty && !name} helperText={dirty && !name && 'Nombre requerido'} disabled={loading || update} />
      <TextField autoFocus margin="dense" id="kind" name="kind" label="Tipo" fullWidth value={kind} onChange={onKindChange} disabled />
      <FormControl sx={{ width: '100%', marginTop: 1 }}>
        <InputLabel id="tipo">Manejador de Base de Datos</InputLabel>
        <Select margin="dense" labelId="tipo" id="tipo-input" value={type} label="Manejador de Base de Datos" disabled={kind === StubbornDatasourceKind.REDIS} onChange={(e) => setType(e.target.value as StubbornDatasourceType)}>
          {kind === StubbornDatasourceKind.SQL ? (
            Object.values(StubbornDatasourceType).map((type) => {
              if (type !== StubbornDatasourceType.REDIS)
                return (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                );
            })
          ) : (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <TextField autoFocus margin="dense" id="host" name="host" label="Host" fullWidth value={host} onChange={onHostChange} error={dirty && !host} helperText={dirty && !host && 'Host requerida'} disabled={loading} />
      <TextField type="number" autoFocus margin="dense" id="port" name="port" label="Puerto" fullWidth value={port} onChange={onPortChange} error={dirty && !port} helperText={dirty && !port && 'Puerto requerido'} disabled={loading} />
      <TextField autoFocus margin="dense" id="user" name="user" label="Usuario" fullWidth value={user} onChange={onUserChange} error={dirty && !user} helperText={dirty && !user && 'Usuario requerido'} disabled={loading} />
      <TextField type="password" autoFocus margin="dense" id="password" name="password" label="Contraseña" fullWidth value={password} onChange={onPasswordChange} error={dirty && !password} helperText={dirty && !password && 'Contraseña requerida'} disabled={loading} />
      <TextField autoFocus margin="dense" id="database" name="database" label="Base de Datos" fullWidth value={database} onChange={onDatabaseChange} error={dirty && !database} helperText={dirty && !database && 'Base de datos requerida'} disabled={loading} />
      <TextField autoFocus margin="dense" id="onConnect" name="onConnect" label="OnConnect" fullWidth value={onConnect} onChange={onOnConnectChange} disabled={loading} />
      <Box sx={{ mx: 'auto', width: 200 }}>
        <LoadingButton color="primary" variant="contained" autoFocus onClick={onCreate} disabled={loading} loading={loading}>
          {update ? 'Save' : 'Create'}
        </LoadingButton>
        <LoadingButton color="primary" sx={{ marginLeft: '5px' }} variant="contained" autoFocus onClick={onTest} disabled={loading} loading={loading}>
          Test
        </LoadingButton>
      </Box>
    </Grid>
  );
};
