import React, { useEffect, useState } from 'react';
import { Table as MuiTable, TableBody, TableContainer as MuiTableContainer, TableCell, TableHead, Paper, TableRow } from '@material-ui/core';
import { FieldBaseProps } from '../FieldBaseProps';
import { useServicesDAO } from '../../../hooks';
import { tableKeysFormatter } from '../../utils';
import { AnyObject } from '../../../../../../../../commons/types';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';
import Joi from 'joi';
export interface TableProps extends FieldBaseProps {
  component: {
    hover: boolean;
    tableData: { [key: string]: string | number }[];
    tableName: string;
    size: 'medium' | 'small';
  };
  datasource: {
    enabled: boolean;
    service?: {
      serviceName: string;
      params: {
        keys: Array<string>;
        values: Array<string>;
      };
      path: string;
    };
    items?: AnyObject[];
  };
}

export const Table = ({ component, events, styles, datasource }: TableProps) => {
  const { ServicesDAO } = useServicesDAO();
  const { editable, screen } = useEditorScreenContext();
  const [items, setItems] = useState<AnyObject[]>([]);

  const keyNames = Object.keys(component.tableData?.[0] || []);
  const datasourceItems = datasource.items || [];

  useEffect(() => {
    (async () => {
      if (datasource?.enabled) {
        let query = {};
        datasource.service?.params?.keys.forEach((key, index) => {
          query = {
            ...query,
            [key]: datasource.service?.params?.values[index],
          };
        });
        // @ts-ignore
        const res = await ServicesDAO?.[datasource.service?.serviceName]?.(query);
        if (res) {
          const result = res.data[datasource.service?.path || ''];
          setItems(Array.isArray(result) && !datasourceItems.length ? result : datasourceItems);
        } else {
          setItems(datasourceItems);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasource, ServicesDAO, screen]);

  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const keys = datasource.enabled ? tableKeysFormatter(items) : keyNames;
  const itemsBody = datasource.enabled ? items : component.tableData;

  return (
    <>
      {!itemsBody?.length && <p>Empty table</p>}
      <MuiTableContainer component={Paper} style={styles}>
        <MuiTable size={component.size}>
          <TableHead>
            <TableRow key={`${component.tableName}-row-titles`}>
              {keys.map((keyName, index) => (
                <TableCell key={`${component.tableName}-cell-title-${index}`}>{keyName}</TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {itemsBody.map((entry, index) => (
              <TableRow hover={component.hover} key={`${component.tableName}-row-${index}`}>
                {keys.map((keyName, index) => (
                  <TableCell key={`${component.tableName}-cell-${index}`}>{entry[keyName]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </MuiTableContainer>
    </>
  );
};
