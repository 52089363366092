import {StubbornScreen} from "../../../../business/StubbornAsset";
import {AnyObject} from "../../../../commons/types";

export class ScreensAppContext {
  protected screens: Array<StubbornScreen>;
  public onScreenGo?: (screen: StubbornScreen, params?: AnyObject) => void;

  constructor(screens: Array<StubbornScreen>) {
    this.screens = screens;

    this.screens.forEach(screen => {
      Object.defineProperty(this, screen.name, {
        get: () => ({
          go: (params?: AnyObject) => {
            this.callOnScreenGo(screen, params);
          },
          name: screen.name,
        })
      });
    });
  }

  protected callOnScreenGo(screen: StubbornScreen, params?: AnyObject) {
    if (this.onScreenGo) {
      this.onScreenGo(screen, params);
    }
  }

  getScreensType() {
    let screenType = 'interface SBScreens {';
    this.screens.forEach((screen) => {
      screenType += ` ${screen.name}: {
        go: () => void;
        name: string;
      }; `
    });
    screenType += ' } const SBScreens: SBScreens';
    return screenType;
  }
}
