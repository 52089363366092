import {MenuItem} from "../../../../lib/phinxapp/profiles/types";
import {Main} from "../../../../application/screens/Private2/Main";
import {SpecialParams} from "../../../../application/screens/Private2/Main/SpecialsParams";

export const routes: MenuItem[] = [

  {
    key: 'mainSpecial',
    basePath: '/special-params',
    target: {
      component: SpecialParams,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Special',
    children: [],
  },
  {
    key: 'mainMain',
    basePath: '/',
    target: {
      component: Main,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Main',
    children: [],
    default: true,
  },
];
