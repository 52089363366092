import { ServicesAppContext } from './ServicesAppContext';
import { ScreensAppContext } from './ScreensAppContext';
import { StubbornScreen } from '../../../../business/StubbornAsset';
import { AnyObject } from '../../../../commons/types';
import { ProfileAppContext } from './ProfileAppContext';

export class ProjectAppContext {
  protected ServiceDAO: ServicesAppContext;
  protected ScreensDAO: ScreensAppContext;
  /* protected ProfileDAO: ProfileAppContext; */

  constructor(ServiceDAO: ServicesAppContext, ScreensDAO: ScreensAppContext/* , ProfileDAO: ProfileAppContext */) {
    this.ServiceDAO = ServiceDAO;
    this.ScreensDAO = ScreensDAO;
    /* this.ProfileDAO = ProfileDAO; */
  }

  get services() {
    return this.ServiceDAO;
  }

  get screens() {
    return this.ScreensDAO;
  }


  setOnScreenGo(onScreenGo: (screen: StubbornScreen, params?: AnyObject) => void) {
    this.ScreensDAO.onScreenGo = onScreenGo;
  }

  getProyectType() {
    let proyectType = `${this.ScreensDAO.getScreensType()}
    ${this.ServiceDAO.getServicesType()}
      interface SBProject {
        services: SBServices;
        screens: SBScreens;
        routers: SBRouters;
      }
      
      const SBProject: SBProject`;

    return proyectType;
  }
}
