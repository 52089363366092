import React, { useCallback, useMemo } from 'react';
import { BaseAttributeEditorProps } from './BaseAttributeEditorProps';
import { makeStyles } from '@material-ui/styles';
import { useSelectOnChangeValue } from '../../../../../../../commons/hooks';
import { useRosterDatasourceFind, useRosterScreenContextFind, useRosterServiceContextFind} from '../../../../../../../../platform/api/endpoints';
import { useScreenDAOContext } from '../../context/ScreenDAOContext';
import { useEditorScreenContext } from '../../../../EditorScreenContext';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    paddingRight: 16,
    position: 'relative',
  },
  selectContainer: { width: '100%', height: '100%' },
});

export interface TargetScreenAttributeEditorProps extends BaseAttributeEditorProps {}

export const TargetScreenAttributeEditor = ({ value, onChange, onSave }: TargetScreenAttributeEditorProps) => {
  const classes = useStyles();
  const { data, loading } = useRosterScreenContextFind();
  const { screen: mainScreen } = useEditorScreenContext();

  const dataToRender = useMemo(
    () =>
      data?.filter((screenItem) => {
        // This condition is to avoid selecting the parent screen as a target screen
        if (!!screenItem.name && screenItem.name !== mainScreen.name) {
          return true;
        }
        return false;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  const handleChange = useSelectOnChangeValue(onChange);

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLSelectElement>) => {
      onSave();
      event.stopPropagation();
    },
    [onSave],
  );

  return (
    <div className={classes.root}>
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <select className="selectContainer" value={value?.toString()} onChange={handleChange} onBlur={handleOnBlur}>
          <option key={`option-select-none`} value={''}>
            Seleccione una opción
          </option>
          {dataToRender?.map((option, index) => (
            <option key={`option-select-${option?.name}-${index}`} value={option?.name.toString()}>
              {option.name}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};
