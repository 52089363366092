import {FunctionComponent} from "react";
import {FormProps} from "./FormProps";
import {FirebaseForm} from "./FirebaseForm";
import {DefaultForm} from "./DefaultForm";

class PluginFormsManager {
  protected forms: Map<string, FunctionComponent<FormProps>> = new Map<string, FunctionComponent<FormProps>>();

  register(name: string, item: FunctionComponent<FormProps<any>>) {
    this.forms.set(name, item);
  }

  get(type: string) {
    const Form = this.forms.get(type);
    if (!Form) return DefaultForm;
    return Form;
  }
}

const instance = new PluginFormsManager();
instance.register('Firebase', FirebaseForm);
export { instance as PluginFormsManager};
