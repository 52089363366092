import {StubbornService} from "../business/StubbornAsset";

export class ActionNamingHelper {
  protected registeredNames = new Set<string>();
  protected iterator: number = 0;
  protected service: StubbornService;

  constructor(service: StubbornService) {
    this.service = service;

    this.service.actions.forEach(action => {
      this.registeredNames.add(action.name);
    });
  }

  protected nameIsAvailable(name: string): boolean {
    return !this.registeredNames.has(name);
  }

  protected addToIterator(): number {
    this.iterator += 1;
    return this.iterator;
  }

  getDefaultName(): string {
    const newName = `action${this.addToIterator()}`;
    if (!this.nameIsAvailable(newName)) return this.getDefaultName();
    return newName;
  }

}
