import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Dialog as MuiDialog, Button, DialogContent as MuiDialogContent, DialogActions, DialogTitle, IconButton, Icon } from '@material-ui/core';
import { FieldBaseProps } from '../FieldBaseProps';
import { DialogAction } from '../../../properties/editors/CollectionAttributeEditor';
import { securedJSRunner } from '../../../../../../../../utils/functionHelpers';
import { useScreenDAOContext } from '../../../context/ScreenDAOContext';
import { useProjectDAOContext } from '../../../context/ProjectDAOContext';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';
import { FieldBaseEvents } from '../FieldBaseEvents';
import { FieldState } from '../../types';
import { useRouterDAOContext } from '../../../context/RouterDAOContext';

interface DialogEvents extends FieldBaseEvents {
  onClose?: () => void;
}

interface DialogStates extends FieldState {
  open: boolean;
}

export interface DialogProps extends FieldBaseProps {
  component: {
    title?: string;
  };
  actions: {
    actions?: DialogAction[];
    style?: any;
  };
  content: {
    style?: any;
  };
  states: DialogStates;
  children: any;
  events: DialogEvents;
}

const useStyles = makeStyles({
  modalTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  modalContentContainer: {
    minWidth: 400,
  },
});

export const Dialog = ({ component, content, events, actions, states, styles, children, Context = {}, Params }: DialogProps) => {
  const { editable } = useEditorScreenContext();

  const { ScreenDAO } = useScreenDAOContext();
  const { ProjectDAO } = useProjectDAOContext();
  const { ProfileDAO } = useRouterDAOContext();

  const [open, setOpen] = useState<boolean>(states.open);

  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeHandler = useCallback(() => {
    setOpen(false);
    events?.onClose?.();
  }, [setOpen, events]);

  const dialogActions = actions.actions?.map((action: DialogAction, index: number) => {
    const onClick = (event: any) => securedJSRunner(action.onClick, event, ScreenDAO, ProfileDAO?.RouterDAO, ProjectDAO.services, ProjectDAO, { ...Context, Params }, closeHandler);
    return (
      <Button key={`action-entry-${index}`} variant="contained" onClick={onClick}>
        {action.label}
      </Button>
    );
  });

  const classes = useStyles();

  useEffect(() => {
    if (states.open) {
      setOpen(true);
    }
  }, [states]);
  
  return (
    !editable && (
      <MuiDialog onClose={closeHandler} style={{ ...styles, zIndex: 2000 }} open={editable ? false : open}>
        <DialogTitle classes={{ root: classes.modalTitleContainer }}>
          <div className={classes.modalTitle}>{component.title}</div>
          <div>
            <IconButton onClick={closeHandler}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        </DialogTitle>
        <MuiDialogContent classes={{ root: classes.modalContentContainer }} style={content.style}>
          {children ? children : '[agregá el contenido]'}
        </MuiDialogContent>
        <DialogActions style={actions.style}>{dialogActions}</DialogActions>
      </MuiDialog>
    )
  );
};
