import {useRosterServiceContextFind} from "../../../../../../../platform/api/endpoints";
import {useApi} from "../../../../../../../lib/HttpService";
import {useEffect, useState} from "react";
import {ServicesAppContext} from "../../../appContext/ServicesAppContext";

export const useServicesDAO = () => {
  const { data: services, loading } = useRosterServiceContextFind();
  const { api } = useApi();
  const [ServicesDAO, setServiceDAO] = useState<ServicesAppContext | null>(null);
  useEffect(() => {
    if (services) {
      setServiceDAO(new ServicesAppContext(services, api));
    }
  }, [services, api]);
  return { services, ServicesDAO, loading };
};
