import { useCallback } from 'react';
import { useApiEndpoint } from './useApiEndpoint';

export const useApiCreate = <T>(path: string) => {
  const ServiceEndpoint = useApiEndpoint<T>(path);

  const call = useCallback(
    async (data: T, suffix?: string) => {
      try {
        const response = await ServiceEndpoint.create(data, suffix);
        return response.data;
      } catch (e: any) {
        if (e.error) {
          throw new Error(e.error);
        }
        throw e;
      }
    },
    [ServiceEndpoint],
  );

  return { call };
};
