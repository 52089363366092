import React, {useCallback, useMemo, useState} from 'react';
import {StubbornPlugin} from "../../../../../../../../../business/StubbornConfiguration";
import {PluginFormsManager} from "./forms";
import {Grid} from "@material-ui/core";
import LoadingButton from "@material-ui/lab/LoadingButton";
import {useAssetContext} from "../../../../../../context/AssetContext";

export interface PluginFormProps {
  asset: StubbornPlugin;
}

export const PluginForm = ({asset}: PluginFormProps) => {
  const { assetFns } = useAssetContext();
  const FormComp = useMemo(() => PluginFormsManager.get(asset.kind), [asset.kind]);
  const [value, setValue] = useState(asset);
  const [loading, setLoading] = useState(false);
  const onSaveClick = useCallback(async () => {
    try {
      setLoading(true);
      await assetFns.update(asset.type, asset);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [asset, assetFns]);
  return (
    <Grid container direction="column">
      <Grid item>
        <FormComp value={value} onChange={setValue} loading={loading} />
      </Grid>
      <Grid item>
        <LoadingButton color="primary" variant="contained" autoFocus onClick={onSaveClick} disabled={loading} loading={loading}>
          Save
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
