import { StubbornAsset, StubbornAssetAttributes, StubbornField, StubbornScreen } from '../../../../business/StubbornAsset';

export const isStubbornField = (asset: StubbornAsset): asset is StubbornField => {
  return !!(asset as StubbornField).fieldType;
};
export const isStubbornScreen = (asset: StubbornAsset): asset is StubbornScreen => {
  return !!(asset as StubbornScreen).metadata;
};

export class AssetAppContext<T extends StubbornAsset> {
  protected raw: T;
  protected attributes: StubbornAssetAttributes;
  protected onAttributeChange?: (group: string, attribute: string, value: any) => void;

  constructor(asset: T, onAttributeChange?: AssetAppContext<T>['onAttributeChange']) {
    this.raw = { ...asset };
    this.attributes = { ...this.raw.attributes };
    this.onAttributeChange = onAttributeChange;

    // attributes getters:
    Object.entries(this.attributes).forEach(([groupName, attributes]) => {
      Object.defineProperty(this, groupName, {
        get: () => {
          const attrObj = {};
          // TODO: esto funcionaria parcialmente con los tipo objects
          Object.entries(attributes).forEach(([attributeName, value]) => {
            Object.defineProperty(attrObj, attributeName, {
              get: () => value,
              set: (value: any) => {
                this.attributes[groupName][attributeName] = value;
                if (this.onAttributeChange) {
                  this.onAttributeChange(groupName, attributeName, value);
                }
              },
            });
            if (attributeName === 'counter') {
              Object.defineProperty(attrObj, 'refresh', {
                get: () => () => {
                  this.attributes[groupName][attributeName] += 1;
                  if (this.onAttributeChange) {
                    this.onAttributeChange(groupName, attributeName, this.attributes[groupName][attributeName]);
                  }
                },
              });
            }
          });
          return attrObj;
        },
      });
    });
  }
}

export class FieldAppContext extends AssetAppContext<StubbornField> {}

const makeOnAttributeChange = (accessTree: string, onAttributeChange?: (group: string, attribute: string, value: any, accessTree: string) => void) => (group: string, attribute: string, value: any) => {
  if (onAttributeChange) {
    onAttributeChange(group, attribute, value, accessTree);
  }
};
export class ScreenAppContext extends AssetAppContext<StubbornScreen> {
  metadata: AssetAppContext<StubbornAsset>[];

  constructor(screen: StubbornScreen, onAttributeChange?: (group: string, attribute: string, value: any, accessTree: string) => void, parentTree?: string) {
    const accessTree = !parentTree ? screen.name : `${parentTree}.${screen.name}`;
    super(screen, makeOnAttributeChange(accessTree, onAttributeChange));
    this.metadata = [];
    this.raw.metadata.forEach((asset, index) => {
      if (isStubbornField(asset)) {
        this.metadata.push(new FieldAppContext(asset, makeOnAttributeChange(`${accessTree}.${asset.name}`, onAttributeChange)));
      } else if (isStubbornScreen(asset)) {
        this.metadata.push(new ScreenAppContext(asset, onAttributeChange, accessTree));
      } else {
        this.metadata.push(new AssetAppContext(asset, makeOnAttributeChange(`${accessTree}.${asset.name}`, onAttributeChange)));
      }
      // TODO: handlear otro tipo de assets

      // defining metadata getters
      Object.defineProperty(this, asset.name, {
        get: () => this.metadata[index],
      });
    });
  }

  protected getRecursiveTypes(metadata: StubbornAsset[]) {
    let assetType = ``;
    metadata.forEach((asset) => {
      assetType += ` ${asset.name}: { `;
      if (isStubbornField(asset)) {
        Object.entries(asset.attributes).forEach(([nameAttr, valueAttr]: [nameAttr: string, valueAttr: any]) => {
          assetType += ` ${nameAttr}: { `;
          Object.entries(valueAttr).forEach(([name, value]) => {
            if (Array.isArray(value)) {
              assetType += ` ${name}: Array<${typeof value[0] === 'undefined' ? 'any' : typeof value[0]}>; `;
            } else if (nameAttr === 'events') {
              assetType += ` ${name}: (event: React.EventHandler<any>) => void;`;
            } else {
              assetType += ` ${name}: ${typeof value}; `;
            }
          });
          assetType += ` }; `;
        });
      } else if (isStubbornScreen(asset)) {
        assetType += this.getRecursiveTypes(asset.metadata);
      }
      assetType += ` }; `;
    });

    return assetType;
  }

  getScreenTypes() {
    let screenType = 'interface SBScreen {';
    screenType += this.getRecursiveTypes(this.raw.metadata);
    screenType = `${screenType} } const SBScreen: SBScreen`;
    return screenType;
  }
}
