import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { StubbornAsset, StubbornAssetType } from '../../../business/StubbornAsset';
import { EditorItem, EditorRouterItem, EditorScreenItem, EditorServiceItem } from './EditorItem';
import { useEditionRouterApi, useEditionScreenApi, useEditionServiceApi } from '../../../../platform/api/endpoints/edition';
import { Alert, Snackbar } from '@material-ui/core';

export interface SavedEditor {
  openEditors: Array<{ type: StubbornAssetType; name: string }>;
  selectedEditor?: string;
}

export type EditorContextType = {
  // TODO: esto es para que
  isPropertiesOpen: boolean;
  toggleProperties: (state?: boolean | undefined) => void;
  editors: EditorItem<StubbornAsset>[];
  selected: string;
  select: (item: string) => void;
  addEditor: (editor: EditorItem<StubbornAsset>) => void;
  removeEditor: (editor: EditorItem<StubbornAsset>) => void;
  setOpenSnackbar: (value: boolean) => void;
};

export const EditorContext = React.createContext<EditorContextType>({
  isPropertiesOpen: true,
  toggleProperties: (state: boolean | undefined) => {},
  editors: [],
  selected: '',
  select: (item) => {},
  addEditor: () => {},
  removeEditor: () => {},
  setOpenSnackbar: () => {},
});

export type EditorContextProviderProps = {
  children: JSX.Element[] | JSX.Element;
};

// TODO: refactorizar los hooks de este provider
export const EditorContextProvider = (props: EditorContextProviderProps) => {
  const serviceEndpoint = useEditionServiceApi();
  const screenEndpoint = useEditionScreenApi();
  const routerEndpoint = useEditionRouterApi();
  const [localStorageLoaded, setLocalStorageLoaded] = useState(false);
  const [isPropertiesOpen, setMenuOpen] = useState(false);
  const [editors, setEditors] = useState<EditorItem<StubbornAsset>[]>([]);
  const [selected, select] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const toggleProperties = useCallback(
    (state: boolean | undefined) => {
      state === undefined ? setMenuOpen(!isPropertiesOpen) : setMenuOpen(state);
    },
    [isPropertiesOpen],
  );
  const addEditor = useCallback((editor: EditorItem<StubbornAsset>) => {
    setEditors([editor]);
    select(editor.uniqueName());
  }, []);
  const removeEditor = useCallback(
    (editor: EditorItem<StubbornAsset>) => {
      const uniqueName = editor.uniqueName();
      const editorIndex = editors.findIndex((item) => item.uniqueName() === uniqueName);
      if (editorIndex !== -1) {
        const newEditors = editors.filter((item, index) => index !== editorIndex);
        setEditors(newEditors);
        if (newEditors.length) {
          select(newEditors[0].uniqueName());
        }
      }
    },
    [editors],
  );
  const value = useMemo<EditorContextType>(
    () => ({
      isPropertiesOpen,
      toggleProperties,
      editors,
      select,
      selected,
      addEditor,
      removeEditor,
      setOpenSnackbar,
    }),
    [isPropertiesOpen, toggleProperties, editors, selected, addEditor, removeEditor, setOpenSnackbar],
  );
  // Esto es para guardar el localstorage
  useEffect(() => {
    if (localStorageLoaded) {
      const openEditors = editors.map((editor) => {
        const asset = editor.getItem();
        return { name: asset.name, type: asset.type };
      });
      const savedEditor: SavedEditor = { openEditors, selectedEditor: selected };
      localStorage.setItem('stubborn:editor', JSON.stringify(savedEditor));
    }
  }, [editors, localStorageLoaded, selected]);
  // Esto es para levantar del localstorage
  useEffect(() => {
    let savedEditorRaw = localStorage.getItem('stubborn:editor');
    if (!savedEditorRaw) {
      const initialSavedEditor: SavedEditor = { openEditors: [] };
      savedEditorRaw = JSON.stringify(initialSavedEditor);
      localStorage.setItem('stubborn:editor', savedEditorRaw);
    }
    if (savedEditorRaw && screenEndpoint && serviceEndpoint && !localStorageLoaded) {
      (async () => {
        const savedEditor: SavedEditor = JSON.parse(savedEditorRaw);
        const promises = savedEditor.openEditors.map(async ({ name, type }) => {
          let asset;
          if (type === 'Service') {
            const { data } = await serviceEndpoint.find({ name });
            if (data && data.length) {
              asset = new EditorServiceItem(data[0], () => setOpenSnackbar(true));
            }
          } else if (type === 'Screen') {
            const { data } = await screenEndpoint.find({ name });
            if (data && data.length) {
              asset = new EditorScreenItem(data[0], () => setOpenSnackbar(true));
            }
          } else if (type === 'Router') {
            const { data } = await routerEndpoint.find({ name });
            if (data && data.length) {
              asset = new EditorRouterItem(data[0], () => setOpenSnackbar(true));
            }
          }
          return asset;
        });
        const editors = await Promise.all<EditorItem<any> | undefined>(promises);
        const filteredEditors: Array<EditorItem<any>> = editors.filter((editor) => !!editor) as Array<EditorItem<any>>;
        setEditors(filteredEditors);
        if (savedEditor.selectedEditor) {
          select(savedEditor.selectedEditor);
        } else if (filteredEditors.length) {
          select(filteredEditors[0].uniqueName());
        }
        setLocalStorageLoaded(true);
      })();
    }
  }, [localStorageLoaded, screenEndpoint, serviceEndpoint, routerEndpoint]);
  return (
    <EditorContext.Provider value={value}>
      <>
        {props.children}
        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)} style={{ margin: 'auto' }} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
            Guardado con exito
          </Alert>
        </Snackbar>
      </>
    </EditorContext.Provider>
  );
};

export const useEditorContext = () => useContext<EditorContextType>(EditorContext);
