import { AssetView } from '../types';
import { DatasourcesPanel } from '../panels';
import { RemoveAction, CloneAction } from '../actions';

export const DatasourceConfigurationAssetView: AssetView = {
  type: 'postgres',
  panels: [
    {
      label: 'Datasource config',
      component: DatasourcesPanel,
    },
  ],
  actions: [CloneAction, RemoveAction],
};
