import {StubbornAttribute, StubbornAttributeGroup} from "../../../../../../../../business/StubbornAttributeMapping";

import { basicAttributeGroup, makeComponentAttributeGroup, makeEventAttributeGroup } from "../../commons/attributes";

const attributes = new Set<StubbornAttribute>();
attributes.add({
  label: 'Icon name',
  name: 'iconName',
  type: 'icon',
  default: 'star',
});
attributes.add({
  label: 'Color',
  name: 'color',
  type: 'select',
  list: ["inherit", "default", "primary", "secondary"],
  default: 'primary',
});
attributes.add({
  label: 'Size',
  name: 'size',
  type: 'select',
  list: ["small", "medium"],
  default: 'small',
});
attributes.add({
  label: 'Disabled',
  name: 'disabled',
  type: 'boolean',
  default: false,
});
attributes.add({
  label: 'Edge',
  name: 'edge',
  type: 'select',
  list: ["end", "start"],
  default: 'start',
});
attributes.add({
  label: 'Disable ripple',
  name: 'disableRipple',
  type: 'boolean',
  default: false,
});

const eventAttributes: StubbornAttribute[] = [
  {
    label: "OnClick",
    name: "onClick",
    type: "code",
    default: "",
  },
  {
    label: "OnBlur",
    name: "onBlur",
    type: "code",
    default: "",
  },
];

export const attributeGroups: StubbornAttributeGroup[] = [
  basicAttributeGroup,
  makeComponentAttributeGroup(attributes),
  makeEventAttributeGroup(eventAttributes),
];
