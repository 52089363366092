import React, { useEffect, useState } from 'react';
import { Link, Breadcrumbs, Typography } from '@material-ui/core';
import { FieldBaseProps } from '../FieldBaseProps';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';
import { useRosterRouterContextFind } from '../../../../../../../../../platform/api/endpoints';

export interface BreadCrumbProps extends FieldBaseProps {
  component: {
    separator: string;
    maxItems: number;
    router: string;
  };
}

export const BreadCrumb = ({ component, events, states, styles }: BreadCrumbProps) => {
  const { editable, screen, setScreen, assetSelector } = useEditorScreenContext();
  const { data } = useRosterRouterContextFind();
  const [routes, setRoutes] = useState<any>([]);

  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (component.router.length && data?.length) {
      const routes = data.filter((item) => item.name === component.router);
      setRoutes(routes[0].routes);
      const updatedScreen = {
        ...screen,
        metadata: screen.metadata.map((item, index) => {
          if (index === assetSelector[0])
            return {
              ...item,
              attributes: { ...item.attributes, component: { ...item.attributes.component, items: component.router } },
            };
          else return item;
        }),
      };
      setScreen(updatedScreen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component.router, data]);

  return (
    <div>
      {routes.length ? (
        <Breadcrumbs separator={component.separator} maxItems={component.maxItems}>
          {routes.map((item: any) => (
            <Link href={item.path}>{item.name}</Link>
          ))}
        </Breadcrumbs>
      ) : (
        <Typography> Choose a Router </Typography>
      )}
    </div>
  );
};
