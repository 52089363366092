import React, {useCallback, useState} from 'react';
import {NewPluginDialog} from "../../../../../../Private/Dashboard/components/AssetList/components/Toolbar/components/AddAssetMenu/dialogs";
import {NewAssetProps} from '../../types';
import {StubbornPlugin} from '../../../../../../../business/StubbornConfiguration';
import {useEditionPluginCreate} from '../../../../../../../../platform/api/endpoints/edition'




export const NewPluginConfigurationModal = ({onComplete, onClose}: NewAssetProps) => {
    const [modalOpen, setModalOpen] = useState(true);
    const { call: createPlugin } = useEditionPluginCreate();

    const onCloseModal = useCallback(() => {
      setModalOpen(false);
      setTimeout(() => onClose(), 0);
    }, [onClose]);

    const onCreatePlugin = useCallback(async (name: string, kind: string, type: string) => {
      
      const newPluggin: StubbornPlugin = {
        name: '',
        kind: '',
        type: '',
        configurations: {},
      };
      await createPlugin(newPluggin);
      onComplete();
      onCloseModal();
    }, [createPlugin, onCloseModal, onComplete]);
  
    return (
      <NewPluginDialog open={modalOpen} onClose={onCloseModal} onCreatePlugin={onCreatePlugin} />
    );
};
