import React, { useCallback, useState } from 'react';
import { StubbornDatasource } from '../../../../../../../business/StubbornAsset';
import { StubbornDatasourceKind } from '../../../../../../../business/StubbornAsset';
import { StubbornDatasourceType } from '../../../../../../../business/StubbornAsset';
import { useEditionDatasourceCreate } from '../../../../../../../../platform/api/endpoints/edition/datasources';
import { NewDataSourceDialog } from '../../../../../../Private/Dashboard/components/AssetList/components/Toolbar/components/AddAssetMenu/dialogs';
import { NewAssetProps } from '../../types';

export const NewDataSourceModal = ({ onComplete, onClose }: NewAssetProps) => {
  const [modalOpen, setModalOpen] = useState(true);
  const { call: createDatasource } = useEditionDatasourceCreate();

  const onCloseModal = useCallback(() => {
    setModalOpen(false);
    setTimeout(() => onClose(), 0);
  }, [onClose]);

  const onCreateDataSource = useCallback(
    async (name: string, kind: StubbornDatasourceKind, type: StubbornDatasourceType, host: string, port: number) => {
      const newDatasource: StubbornDatasource = {
        name,
        kind,
        type,
        host,
        port,
        user: '',
        password: '',
        database: '',
        onconnect: '',
      };
      await createDatasource(newDatasource);
      onComplete();
      onCloseModal();
    },
    [createDatasource, onCloseModal, onComplete],
  );

  return <NewDataSourceDialog open={modalOpen} onClose={onCloseModal} onCreateDataSource={onCreateDataSource} />;
};
