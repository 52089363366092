import React, { useEffect } from 'react';
import { Radio as MuiRadioButton } from '@material-ui/core';
import { FieldBaseProps } from '../FieldBaseProps';
import { FieldBaseEvents } from '../FieldBaseEvents';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';

interface RadioButtonEvents extends FieldBaseEvents {
  onChange: () => void;
}

export interface RadioButtonProps extends FieldBaseProps {
  component: {
    checked?: boolean;
    checkedIcon?: string;
    color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
    defaultChecked?: boolean;
    disabled?: boolean;
    disableRipple?: boolean;
    edge?: 'end' | 'start';
    icon?: string;
    required?: boolean;
    size?: 'medium' | 'small';
  };
  events: RadioButtonEvents;
}

export const RadioButton = ({ component, events, states, styles, basic: { renderConditions } }: RadioButtonProps) => {
  const { editable } = useEditorScreenContext();

  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MuiRadioButton checked={component.checked} defaultChecked={component.defaultChecked} color={component.color} disabled={component.disabled} disableRipple={component.disableRipple} edge={component.edge} required={component.required} size={component.size} icon={component.icon} checkedIcon={component.checkedIcon} onChange={events.onChange} />;
};
