import React, { useCallback } from 'react';
import { Typography, Button, ButtonGroup } from '@material-ui/core';
import { StubbornAttribute } from '../../../../../../../business/StubbornAttributeMapping';
import { EditorPropertiesDataGrid } from '../../../ScreenEditor/properties/EditorPropertiesDataGrid';
import { AnyObject } from '../../../../../../../commons/types';
import { useRowsFromAttributes } from '../../../../../../../commons/hooks/useRowsFromAttributes';
import { StubbornRoute } from '../../../../../../../business/StubbornRouter';

const attributes = new Set<StubbornAttribute>();
attributes.add({
  name: 'name',
  type: 'string',
  label: 'Nombre',
});
attributes.add({
  name: 'path',
  type: 'string',
  label: 'Path',
  default: '',
});
attributes.add({
  name: 'target',
  label: 'Target',
  type: 'targetScreen',
  default: '',
});

const routerAttributes = new Set<StubbornAttribute>();
routerAttributes.add({
  name: 'name',
  type: 'string',
  label: 'Nombre',
});
routerAttributes.add({
  name: 'path',
  type: 'string',
  label: 'Path',
  default: '',
});
routerAttributes.add({
  name: 'target',
  label: 'Target Router',
  type: 'targetRouter',
  default: '',
});

export interface RouteEditorProps {
  action: StubbornRoute;
  onActionChange: (newAction: StubbornRoute) => void;
  onUpClick: () => void;
  onDownClick: () => void;
  onRemoveClick: () => void;
}

export const RouteEditor = ({ action, onActionChange, onUpClick, onDownClick, onRemoveClick }: RouteEditorProps) => {
  const rows = useRowsFromAttributes(action.type === 'Route' ? attributes : routerAttributes, action);
  const onChange = useCallback(
    (newRows: AnyObject[]) => {
      const newRoute: StubbornRoute = { ...action };
      newRows.forEach((item) => {
        // @ts-ignore
        newRoute[item.prop as string] = item.value as any;
      });
      onActionChange(newRoute);
    },
    [onActionChange, action],
  );
  return (
    <>
      <Typography style={{ padding: '0 10px', color: '#FFFFFF', fontWeight: 600, fontSize: 13 }}>Route: {action.name}</Typography>
      <EditorPropertiesDataGrid initialRows={rows} key={action.name} onChange={onChange} />
      <ButtonGroup style={{ width: '100%', margin: '10px 0', boxShadow: 'none', display: 'flex', justifyContent: 'center' }} variant="contained" aria-label="outlined primary button group">
        <Button style={{ background: '#707EF9', width: '100px' }} onClick={onRemoveClick}>
          Delete
        </Button>
      </ButtonGroup>
    </>
  );
};
