import {MenuItem} from "../../../../lib/phinxapp/profiles/types";
import {Dashboard} from "../../../../application/screens/Private/Dashboard";

export const routes: MenuItem[] = [
  {
    key: 'dashboardMain',
    basePath: '/',
    target: {
      component: Dashboard,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Dashboard',
    children: [],
    default: true,
  },
];
