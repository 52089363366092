import React, {useCallback} from 'react';
import {MenuCardList, MenuCardItem, ScreenTitle, ScreenContainer} from "../../../components";
import {useHistory, useRouteMatch} from "react-router-dom";

const menus: MenuCardItem[] = [
  {
    id: 'project',
    title: 'Project',
    image: 'https://image.flaticon.com/icons/png/512/2782/2782163.png',
  },
  {
    id: 'datasources',
    title: 'Datasouces',
    image: 'https://image.flaticon.com/icons/png/512/2782/2782163.png',
  },
  {
    id: 'sessions',
    title: 'Sessions',
    image: 'https://image.flaticon.com/icons/png/512/2782/2782163.png',
  },
];

export const Configuration = () => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const onItemClick = useCallback((item: MenuCardItem) => {
    history.push(`${url}/${item.id}`)
  }, [history, url]);
  return (
    <ScreenContainer>
      <ScreenTitle>Configuration</ScreenTitle>
      <MenuCardList items={menus} onItemClick={onItemClick}/>
    </ScreenContainer>
  );
};
