import { useCallback } from 'react';
import { useApiEndpoint } from './useApiEndpoint';

export const useApiUpdate = <T>(path: string) => {
  const ServiceEndpoint = useApiEndpoint<T>(path);

  const call = useCallback(
    async (id?: string, data?: T) => {
      try {
        const response = await ServiceEndpoint.update(id, data);
        return response.data;
      } catch (e: any) {
        if (e.error) {
          throw new Error(e.error);
        }
        throw e;
      }
    },
    [ServiceEndpoint],
  );

  return { call };
};
