import { BaseAttributeEditorProps } from "./BaseAttributeEditorProps";
import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import { useSelectOnChangeValue } from "../../../../../../../commons/hooks";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    position: "relative"
  },
  selectContainer: {width: "100%", height: "100%"}
});

export interface SelectAttributeEditorProps extends BaseAttributeEditorProps {}

export const SelectAttributeEditor = ({ value, onChange, onCancel, onSave, options }: SelectAttributeEditorProps) => {
  const classes = useStyles();
  const handleChange = useSelectOnChangeValue(onChange);

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLSelectElement>) => {
      onSave();
      event.stopPropagation();
    },
    [onSave]
  );

  return (
    <div className={classes.root}>
      <select className={classes.selectContainer} value={value?.toString()} onChange={handleChange} onBlur={handleOnBlur}>
        {options?.map(
          (option, index) => <option key={`option-select-${option?.toString}-${index}`} value={option?.toString()}>{option}</option>
        )}
      </select>
    </div>
  );
};
