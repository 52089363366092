import { makeStyles } from "@material-ui/styles";
import { AppThemeType } from "../../../../../../../../theme";

export const useStyles = makeStyles<AppThemeType>((theme: any) => ({
  button: {
    height: "100%",
    borderLeft: "3px solid #2F343D",
    background: "#4A5160",
    borderBottom: "1px solid #2F343D",
    fontSize: 14,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    width: 60,
  },
  buttonSelected: {
    color: "#707ef9",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontSize: 14,
    border: "2px solid #556cd6",
    width: 60,
    background: "#2F343D",
  },
  menu: {
    "& .MuiMenu-list": {
      background: "#4A5160",
      padding: 10,
      border: "3px solid #2F343D",
      color: "#FFFFFF",
    },
  },
  label: {
    color: "#FFFFFF",
    borderRadius: "10px",
  },
  divider: {
    width: "100%",
    background: "gray",
    height: 1,
  },
}));