import React from 'react';
import { Grid } from '@material-ui/core';
import { ScreenContainer, ScreenTitle } from '../../../../../components';

// TODO: los index.ts no deben tener codigo, solo deben tener los exports de los modulos
export const NoSelectedAssetView = () => {
  return (
    <ScreenContainer>
      <Grid container>
        <ScreenTitle>No component selected.</ScreenTitle>
      </Grid>
    </ScreenContainer>
  );
};
