import { createContext, ReactNode, ReactNodeArray, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useEditorScreenContext } from '../../../EditorScreenContext';
import { getAssetByAssetSelector, setAssetByAssetSelector } from '../../../../../../utils';
import { isStubbornScreen, ScreenAppContext } from '../../../appContext/ScreenAppContext';
import { StubbornScreen } from '../../../../../../business/StubbornAsset';
import { useProjectDAOContext } from './ProjectDAOContext';
import { AnyObject } from '../../../../../../commons/types';

export type ScreenDAOContextValue = {
  screen: StubbornScreen;
  params: AnyObject;
  ScreenDAO: ScreenAppContext;
};

export const ScreenDAOContext = createContext<ScreenDAOContextValue>({
  // @ts-ignore
  screen: null,
  params: {},
  // @ts-ignore
  ScreenDAO: null,
});

export interface ScreenDAOContextProviderProps {
  children: ReactNode | ReactNodeArray;
}

export const ScreenDAOContextProvider = ({ children }: ScreenDAOContextProviderProps) => {
  const { screen, editable } = useEditorScreenContext();
  const { ProjectDAO } = useProjectDAOContext();
  const [screenState, setScreenState] = useState<StubbornScreen>(JSON.parse(JSON.stringify(screen)));
  const [routerParams, setRouterParams] = useState<AnyObject | undefined>();
  // Este screen hook es el encargado de actualizar de modo reactivo los atributos de los elementos dentro del screen

  const screenHookAttributeHook = (group: string, attribute: string, _value: any, accessTree: string) => {
    setScreenState((prev) => {
      // del accessTree descarto el primero, ya que siempre va a ser el MainScreen
      const [, ...splitted] = accessTree.split('.');
      // recorro el accessTree para buscar el selector donde esta el atributo
      const selector: number[] = [];
      splitted.forEach((name, index) => {
        const current = getAssetByAssetSelector(prev, selector);
        if (isStubbornScreen(current)) {
          const assetIndex = (current as StubbornScreen).metadata.findIndex((item) => item.name === name);
          selector.push(assetIndex);
        }
      });
      // Obtengo y actualizo el asset
      const assetTo = getAssetByAssetSelector(prev, selector);
      assetTo.attributes[group][attribute] = _value;
      return setAssetByAssetSelector(prev, selector, assetTo);
    });
  };
  const [screenHook, setScreenHook] = useState(new ScreenAppContext(screenState, screenHookAttributeHook));
  useEffect(() => {
    // el editable tiene que estar en el array de dependencias para que funcione
    const copy = JSON.parse(JSON.stringify(screen));
    setScreenHook(new ScreenAppContext(copy, screenHookAttributeHook));
    setScreenState(copy);
  }, [editable, screen]);
  // Este se encarga de manejar el preview del router
  useEffect(() => {
    ProjectDAO.setOnScreenGo((screen, params?: AnyObject) => {
      // TODO: no se que tan bien esta esto que sigue
      const copy = JSON.parse(JSON.stringify(screen));
      setScreenHook(new ScreenAppContext(copy, screenHookAttributeHook));
      setRouterParams(params);
      setScreenState(copy);
    });
  }, [ProjectDAO]);

  const value = useMemo<ScreenDAOContextValue>(
    () => ({
      screen: editable ? screen : screenState,
      params: routerParams || {},
      ScreenDAO: screenHook,
    }),
    [editable, routerParams, screen, screenState, screenHook],
  );
  return <ScreenDAOContext.Provider value={value}>{children}</ScreenDAOContext.Provider>;
};

export const useScreenDAOContext = () => useContext<ScreenDAOContextValue>(ScreenDAOContext);
