import React, { useCallback, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import LoadingButton from '@material-ui/lab/LoadingButton';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useInputState } from '../../../../../../../../../../commons/hooks';
import { StubbornDatasourceKind } from '../../../../../../../../../../business/StubbornAsset';
import { StubbornDatasourceType } from '../../../../../../../../../../business/StubbornAsset';

export interface NewDataSourceDialogProps {
  open: boolean;
  onClose: () => void;
  //onCreateDataSource: (name: string) => Promise<void>;
  onCreateDataSource: (name: string, kind: StubbornDatasourceKind, type: StubbornDatasourceType, host: string, port: number, user: string) => Promise<void>;
}

export const NewDataSourceDialog = ({ open, onClose, onCreateDataSource }: NewDataSourceDialogProps) => {
  const validate = true;
  const [name, onNameChange, setName, error] = useInputState('', validate);
  const [type, onTypeChange, setType] = useInputState<StubbornDatasourceType>(StubbornDatasourceType.POSTGRES);
  const [kind, onKindChange, setKind] = useInputState<StubbornDatasourceKind>(StubbornDatasourceKind.SQL);
  const [host, onHostChange, setHost] = useInputState('');
  const [port, onPortChange, setPort] = useInputState(0);
  const [user, onUserChange, setUser] = useInputState('');

  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);

  const onCreate = useCallback(async () => {
    setDirty(true);
    if (name && kind && type) {
      let NumberPort = parseInt(port.toString()); //Simon. encontrar solucion
      try {
        setLoading(true);
        await onCreateDataSource(name, kind, type, host, NumberPort, user);
        setLoading(false);
        onClose();
        setName('');
        setDirty(false);
      } catch (e: any) {
        alert(e.message); // TODO: notistack
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, type, kind, host, port, user, onCreateDataSource, onClose, setName, setType, setKind, setHost]);
  return (
    <Dialog open={open} onClose={loading ? undefined : onClose} maxWidth="md" fullWidth>
      <DialogTitle>Nueva DataSource</DialogTitle>
      <DialogContent>
        <TextField 
          autoFocus 
          margin="dense" 
          id="description" 
          label="Nombre" 
          name="name" 
          fullWidth 
          value={name} 
          onChange={onNameChange} 
          error={(dirty && !name)  || error?.bool}
          helperText={(dirty && !name && 'Nombre requerido') || (error?.bool && error.message)}
          disabled={loading} 
        />
        <FormControl sx={{ width: '100%', marginTop: 1 }}>
          <InputLabel id="tipo">Tipo</InputLabel>
          <Select margin="dense" labelId="tipo" id="tipo-input" value={type} label="Tipo" onChange={(e) => setType(e.target.value as StubbornDatasourceType)}>
            {Object.values(StubbornDatasourceType).map((type) => {
              return (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl sx={{ width: '100%', marginTop: 1 }}>
          <InputLabel id="Kind">Manejador de Base de Datos</InputLabel>
          <Select margin="dense" labelId="Kind" id="Kind-input" value={kind} label="Kind" onChange={(e) => setKind(e.target.value as StubbornDatasourceKind)}>
            {Object.values(StubbornDatasourceKind).map((kind) => {
              if ((type === 'postgres' || type === 'mysql') && kind !== 'Redis') {
                return (
                  <MenuItem key={kind} value={kind}>
                    {kind}
                  </MenuItem>
                );
              } else if (type === 'redis' && kind !== 'SQL') {
                return (
                  <MenuItem key={kind} value={kind}>
                    {kind}
                  </MenuItem>
                );
              }
            })}
          </Select>
        </FormControl>

        <TextField autoFocus margin="dense" id="host" label="host" name="host" fullWidth value={host} onChange={onHostChange} error={dirty && !host} helperText={dirty && !name && 'host requerido'} disabled={loading} />

        <TextField autoFocus margin="dense" id="port" label="Port" name="Port" fullWidth type="number" value={port} onChange={onPortChange} error={dirty && !port} helperText={dirty && !port && 'port requerido'} disabled={loading} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" disabled={loading}>
          Cerrar
        </Button>
        <LoadingButton color="primary" variant="contained" autoFocus onClick={onCreate} disabled={loading} loading={loading}>
          Crear
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
