import React, {useCallback, useState} from 'react';
import {NewSessionDialog} from "../../../../../../Private/Dashboard/components/AssetList/components/Toolbar/components/AddAssetMenu/dialogs";
import {NewAssetProps} from '../../types';
import {StubbornSessionConfiguration} from '../../../../../../../business';
import {useEditionConfigurationSessionCreate} from '../../../../../../../../platform/api/endpoints/edition'




export const NewSessionConfigurationModal = ({onComplete, onClose}: NewAssetProps) => {
    const [modalOpen, setModalOpen] = useState(true);
    const { call: createSession } = useEditionConfigurationSessionCreate();

    const onCloseModal = useCallback(() => {
      setModalOpen(false);
      setTimeout(() => onClose(), 0);
    }, [onClose]);

    const onCreateSession = useCallback(async (name: string, secret: string, header: string) => {
      
      const newSession: StubbornSessionConfiguration = {
      name,
      type: 'SessionConfiguration',
      description:'',
      secret,
      header,
      service: '',
      condition: ''
      };
      await createSession(newSession);
      onComplete();
      onCloseModal();
    }, [createSession, onCloseModal, onComplete]);
  
    return (
      <NewSessionDialog open={modalOpen} onClose={onCloseModal} onCreateSession={onCreateSession} />
    );
};
