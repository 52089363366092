import {AssetView, AssetViewType} from "./types";

class AssetViewManager {
  protected assetViews: Map<AssetViewType, AssetView> = new Map<AssetViewType, AssetView>();

  register(assetView: AssetView) {
    this.assetViews.set(assetView.type, assetView);
  }

  get(type: AssetViewType) {
    return this.assetViews.get(type);
  }
}

const instance = new AssetViewManager();
export { instance as AssetViewManager};
