import React, { useEffect } from "react";
import { IconButton as MuiIconButton, Icon } from "@material-ui/core";
import { FieldBaseProps } from "../FieldBaseProps";
import { useEditorScreenContext } from "../../../../../EditorScreenContext";

export interface IconButtonProps extends FieldBaseProps {
  component: {
    color: "inherit" | "default" | "primary" | "secondary";
    disabled: boolean;
    disableRipple: boolean;
    edge: "end" | "start";
    size: "small" | "medium";
    iconName: string;
  };
}

export const IconButton = ({ component, events, states, styles }: IconButtonProps) => {
  const { editable } = useEditorScreenContext();

  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <MuiIconButton onClick={events.onClick} style={styles} color={component.color} disabled={component.disabled} disableRipple={component.disableRipple} edge={component.edge} size={component.size}>
      <Icon>{component.iconName}</Icon>
    </MuiIconButton>
  );
};
