import React, {
  createContext,
  Dispatch,
  ReactNode,
  ReactNodeArray,
  SetStateAction,
  useContext, useEffect,
  useMemo,
  useState
} from 'react';
import {EditorItem} from "./EditorItem";
import { StubbornRouter } from '../../../business/StubbornRouter';

export interface EditorRouterContextType {
  // esto es del contexto del editor
  selectedRoute: number | null;
  setSelectedRoute: Dispatch<SetStateAction<number | null>>;
  router: StubbornRouter;
  setRouter: Dispatch<SetStateAction<StubbornRouter>>;
}

export const EditorRouterContext = createContext<EditorRouterContextType>({
  selectedRoute: null,
  setSelectedRoute: () => {},
  // @ts-ignore
  router: {},
  setRouter: () => {},
});

export interface EditorRouterContextProviderProps {
  children: ReactNode | ReactNodeArray;
  editor: EditorItem<StubbornRouter>;
}

export const EditorRouterContextProvider = ({ children, editor }: EditorRouterContextProviderProps) => {
  const [router, setRouter] = useState<StubbornRouter>(editor.getItem());
  const [selectedRoute, setSelectedRoute] = useState<number | null>(null);

  const value = useMemo<EditorRouterContextType>(() => ({
    selectedRoute,
    setSelectedRoute,
    router,
    setRouter,
  }), [selectedRoute, router]);
  useEffect(() => {
    editor.setItem(router);
  }, [editor, router]);
  return (
    <EditorRouterContext.Provider value={value}>
      {children}
    </EditorRouterContext.Provider>
  );
};

export const useEditorRouterContext = () => useContext<EditorRouterContextType>(EditorRouterContext);
