import moment, { Moment } from 'moment';
import {StubbornScreen, StubbornAsset, StubbornService} from "../../../business/StubbornAsset";
import {EventEmitter} from "../../../utils";
import {EditorScreenContextProvider} from "./EditorScreenContext";
import {EditorServiceContextProvider} from "./EditorServiceContext";
import {ScreenEditor} from "./editors/ScreenEditor";
import {ServiceEditor} from "./editors/ServiceEditor";
import {EditorToolbox} from "./editors/ScreenEditor/toolbox/EditorToolbox";
import {EditorProperties} from "./editors/ScreenEditor/properties/EditorProperties";
import {ServiceEditorToolbox} from "./editors/ServiceEditor/toolbox";
import {ServiceEditorProperties} from "./editors/ServiceEditor/properties";
import {AppApi} from "../../../../platform/api";
import { PATH as SERVICE_PATH } from "../../../../platform/api/endpoints/edition/service/const"
import { PATH as SCREEN_PATH } from "../../../../platform/api/endpoints/edition/screen/const"
import { PATH as ROUTER_PATH } from "../../../../platform/api/endpoints/edition/router/const"
import { StubbornRouter } from '../../../business/StubbornRouter';
import { RouterEditor } from './editors/RouterEditor';
import { RouterEditorToolbox } from './editors/RouterEditor/toolbox';
import { RouterEditorProperties } from './editors/RouterEditor/properties';
import { EditorRouterContextProvider } from './EditorRouterContext';

export abstract class EditorItem<T extends StubbornAsset> {

    protected abstract provider: any;
    protected abstract editor: any;
    protected abstract toolbox: any;
    protected abstract propertiesEditor: any;
    protected changeListener = new EventEmitter();
    protected lastUpdate: Moment;
    protected lastSave: Moment;
    protected saveInternalId?: NodeJS.Timeout;
    protected notExecutedCount = 0;

    constructor( protected item:T, protected successCallback?:() => void) {
        this.lastUpdate = moment();
        this.lastSave = moment().add(10, 'second');
        this.successCallback = successCallback;
    }

    protected abstract save(item: T): Promise<void> | void;

    getProvider() {
        return this.provider;
    }

    getEditor() {
        return this.editor;
    }

    getToolbox() {
        return this.toolbox;
    }

    getPropertiesEditor() {
        return this.propertiesEditor;
    }

    getItem():T {
        return this.item;
    }

    uniqueName():string {
        return `${this.item.name}@${this.item.type}`
    }

    setItem(item: T) {
        this.item = item;
        this.changeListener.emit(this.item);
        this.lastUpdate = moment();
        this.setSaveInterval();
    }

    subscribe(fn: (newItem: T) => void) {
        return this.changeListener.subscribe(fn);
    }

    forceSave() {
        return this.save(this.item)
    }

    protected setSaveInterval() {
        if (!this.saveInternalId) {
            this.saveInternalId = setInterval(() => {
                this.evaluateSave();
            }, 5000);
        }
    }

    protected async evaluateSave() {
        if (this.lastUpdate.isAfter(this.lastSave)) {
            await this.save(this.item);
            this.lastSave = moment();
        } else {
            if (this.notExecutedCount > 5 && this.saveInternalId) {
                clearInterval(this.saveInternalId);
                this.saveInternalId = undefined;
                this.notExecutedCount = 0;
            } else {
                this.notExecutedCount++;
            }
        }
    }
}

export class EditorScreenItem extends EditorItem<StubbornScreen> {
    provider = EditorScreenContextProvider;
    editor = ScreenEditor;
    toolbox = EditorToolbox;
    propertiesEditor = EditorProperties;

    protected async save(item: StubbornScreen): Promise<void> {
        const screenEndpoint = AppApi.getEndpoint(SCREEN_PATH);
        await screenEndpoint.update(undefined, item);
        if (this.successCallback) {
            this.successCallback();
        }
    }
}
export class EditorServiceItem extends EditorItem<StubbornService> {
    provider = EditorServiceContextProvider;
    editor = ServiceEditor;
    toolbox = ServiceEditorToolbox;
    propertiesEditor = ServiceEditorProperties;

    protected async save(item: StubbornService): Promise<void> {
        const serviceEndpoint = AppApi.getEndpoint(SERVICE_PATH);
        await serviceEndpoint.update(undefined, item)
        if (this.successCallback) {
            this.successCallback();
        }
    }
}

export class EditorRouterItem extends EditorItem<StubbornRouter> {
    provider = EditorRouterContextProvider;
    editor = RouterEditor;
    toolbox = RouterEditorToolbox;
    propertiesEditor = RouterEditorProperties;

    protected async save(item: StubbornRouter): Promise<void> {
        const routerEndpoint = AppApi.getEndpoint(ROUTER_PATH);
        await routerEndpoint.update(undefined, item)
        if (this.successCallback) {
            this.successCallback();
        }
    }
}

