import React, { useEffect } from 'react';
import { Checkbox as MuiCheckbox } from '@material-ui/core';
import { FieldBaseProps } from '../FieldBaseProps';
import { FieldBaseEvents } from '../FieldBaseEvents';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';

interface CheckboxEvents extends FieldBaseEvents {
  onChange: () => void;
}

export interface CheckboxProps extends FieldBaseProps {
  component: {
    checked: boolean;
    icon?: string;
    checkedIcon?: string;
    color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    defaultChecked?: boolean;
    disabled?: boolean;
    disableRipple?: boolean;
    required?: boolean;
    size?: 'small' | 'medium';
  };
  events: CheckboxEvents;
}

export const Checkbox = ({ component, events, states, styles, basic: { renderConditions } }: CheckboxProps) => {
  const { editable } = useEditorScreenContext();

  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MuiCheckbox checked={component.checked} style={styles} icon={component.icon} checkedIcon={component.checkedIcon} color={component.color} defaultChecked={component.defaultChecked} disabled={component.disabled} disableRipple={component.disableRipple} required={component.required} size={component.size} onChange={events.onChange} />;
};
