/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AppThemeType } from '../../../../../../../theme';
import { useEditorServiceContext } from '../../../EditorServiceContext';
import { StubbornRouter, StubbornRoute } from '../../../../../../business/StubbornRouter';
import { RouteEditor } from './actions';
import { RouterPropertyEditor } from './service';
import { BaseAppConst } from '../../../../../../const/BaseAppConst';
import { confirmDialog } from '../../../../../../services';
import { useEditorRouterContext } from '../../../EditorRouterContext';
import { RouterEditorToolbox } from '../toolbox/RouterEditorToolbox';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles<AppThemeType>(() => ({
  drawer: {
    width: BaseAppConst.uiEditorProperties.drawerWidth,
    flexShrink: 0,
    height: '100%',
  },
  drawerPaper: {
    width: BaseAppConst.uiEditorProperties.drawerWidth,
    maxHeight: '90%',
    background: '#2F343D',
    boxShadow: "none",
  },
  button: {
    width: '50%',
    height: '100%',
    color: '#FFFFFF',
    background: '#4A5160',
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  tab: {
    width: '100%',
    display: 'flex',
    height: 66,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 600,
    color: 'white',
    backgroundColor: '#4b4e61',
  },
  selectedTab: {
    color: '#707ef9',
    width: '100%',
    display: 'flex',
    height: 66,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 600,
  },
}));

const isRoute = (item: StubbornRouter | StubbornRoute): item is StubbornRoute => {
  return (item as StubbornRoute).target !== undefined;
};

export const RouterEditorProperties = () => {
  const { router, setRouter, selectedRoute, setSelectedRoute } = useEditorRouterContext();
  const [switcher, setSwitcher] = useState('toolbar');

  const classes = useStyles();
  const selected = useMemo<StubbornRouter | StubbornRoute>(() => (selectedRoute === null ? router : router.routes[selectedRoute]), [selectedRoute]);
  // callbacks de las actions
  const onActionChange = useCallback(
    (newAction: StubbornRoute) => {
      if (selectedRoute !== null) {
        setRouter((prev) => {
          const newRouter = {
            ...prev,
            actions: [...prev.routes],
          };
          newRouter.routes[selectedRoute as number] = newAction;
          return newRouter;
        });
      }
    },
    [selectedRoute],
  );
  const switchActionPosition = useCallback(
    (current, next) => {
      setRouter((prev) => {
        const newRouter = {
          ...prev,
          routes: [...prev.routes],
        };
        const old = newRouter.routes[next];
        newRouter.routes[next] = newRouter.routes[current];
        newRouter.routes[current] = old;
        return newRouter;
      });
    },
    [selectedRoute],
  );
  const upSelectedAction = useCallback(() => {
    if (selectedRoute !== null) {
      const newIndex = selectedRoute - 1;
      if (newIndex >= 0) {
        switchActionPosition(selectedRoute, newIndex);
        setSelectedRoute(newIndex);
      }
    }
  }, [selectedRoute, switchActionPosition]);
  const downSelectedAction = useCallback(() => {
    if (selectedRoute !== null) {
      const newIndex = selectedRoute + 1;
      if (newIndex < router.routes?.length) {
        switchActionPosition(selectedRoute, newIndex);
        setSelectedRoute(newIndex);
      }
    }
  }, [selectedRoute, router.routes?.length, setSelectedRoute, switchActionPosition]);
  const removeAction = useCallback(async () => {
    const confirm = await confirmDialog.show({
      title: 'Confirmation',
      content: 'Are you sure you want to delete this route?',
    });
    if (confirm) {
      setSelectedRoute(null);
      setRouter((prev) => ({
        ...prev,
        routes: prev.routes.filter((action, index) => index !== selectedRoute),
      }));
    }
  }, [selectedRoute, setSelectedRoute, setRouter]);
  // router callbacks
  const onServiceChange = useCallback(
    (newRouter: StubbornRouter) => {
      setRouter(newRouter);
    },
    [setRouter],
  );

  useEffect(() =>{
    isRoute(selected) && setSwitcher('properties');
  }, [selectedRoute]);

  return (
    <Box className={classes.drawer}>
      <div className={classes.tabContainer}>
        <div className={switcher === 'toolbar' ? classes.selectedTab : classes.tab} onClick={() => setSwitcher('toolbar')}>
          Toolbox
        </div>
        <div className={switcher === 'properties' ? classes.selectedTab : classes.tab} onClick={() => setSwitcher('properties')}>
          Properties
        </div>
      </div>
      <Paper className={classes.drawerPaper}>{switcher !== 'properties' ? <RouterEditorToolbox /> : isRoute(selected) ? <RouteEditor action={selected} onActionChange={onActionChange} onUpClick={upSelectedAction} onDownClick={downSelectedAction} onRemoveClick={removeAction} /> : <RouterPropertyEditor router={router} onServiceChange={onServiceChange} />}</Paper>

      
    </Box>
  );
};
