import React, { useEffect, useMemo } from 'react';
import { Card as MuiCard, CardActionArea, CardHeader, CardMedia, CardContent, Avatar, Icon, Typography, CardActions, Button, IconButton } from '@material-ui/core';
import { FieldBaseProps } from '../FieldBaseProps';
import { useCurrentBreakpoint } from '../../../../../../../../commons/hooks';
import { processGroupStyles } from '../../utils';
import { securedJSRunner } from '../../../../../../../../utils/functionHelpers';
import { useScreenDAOContext } from '../../../context/ScreenDAOContext';
import { useProjectDAOContext } from '../../../context/ProjectDAOContext';
import { ComponentAction } from '../../../properties/editors/CollectionAttributeEditor';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';
import { AnyObject } from '../../../../../../../../commons/types';
import { useRouterDAOContext } from '../../../context/RouterDAOContext';

export interface CardProps extends FieldBaseProps {
  component: {
    raised?: boolean;
  };
  header: {
    enabled?: boolean;
    avatarSrc?: string;
    avatarLabel?: string;
    title?: string;
    subheader?: string;
    style: any;
  };
  content: {
    enabled?: boolean;
    title?: string;
    text?: string;
    style: any;
  };
  media: {
    enabled?: boolean;
    src?: string;
    width?: string;
    height?: string;
    alt?: string;
    style: any;
  };
  actions: {
    enabled?: boolean;
    actions?: ComponentAction[];
    style: any;
  };
  datasource: {
    enabled: boolean;
    service?: {
      serviceName: string;
      params: {
        keys: Array<string>;
        values: Array<string>;
      };
      path: string;
    };
    items?: AnyObject[];
  };
}

export const Card = ({ component, header, content, media, actions, events, states, styles, Context = {}, Params }: CardProps) => {
  const { editable } = useEditorScreenContext(); // FIXME: consumir el editable del contexto me parece que esta mal
  const { ScreenDAO } = useScreenDAOContext();
  const { ProjectDAO } = useProjectDAOContext();
  const { ProfileDAO } = useRouterDAOContext();

  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentBp = useCurrentBreakpoint();

  const headerStyle = useMemo(() => processGroupStyles(header.style, currentBp), [header.style, currentBp]);
  const contentStyle = useMemo(() => processGroupStyles(content.style, currentBp), [content.style, currentBp]);
  const actionStyle = useMemo(() => processGroupStyles(actions.style, currentBp), [actions.style, currentBp]);

  const customActions = actions.actions?.map((action: ComponentAction, index: number) => {
    const isButton = action.type === 'button';
    // TODO: esta logica no debe estar aqui, tiene que estar en el FieldRenderer
    const onClick = (event: any) => securedJSRunner(action.onClick, event, ScreenDAO, ProfileDAO?.RouterDAO, ProjectDAO.services, ProjectDAO, { ...Context, Params });
    return isButton ? (
      <Button key={`action-entry-${index}`} variant="contained" onClick={onClick}>
        {action.label}
      </Button>
    ) : (
      <IconButton key={`action-entry-${index}`} onClick={onClick}>
        <Icon>{action.icon}</Icon>
      </IconButton>
    );
  });

  const mainContent = (
    <CardActionArea onClick={events.onClick}>
      {header.enabled && <CardHeader avatar={<Avatar alt={header.avatarLabel} src={header.avatarSrc} />} title={header.title} subheader={header.subheader} style={headerStyle} />}

      {media.enabled && <CardMedia sx={{ ...(media.width && { width: `${media.width}px` }), ...(media.height && { height: `${media.height}px` }) }} component="img" image={media.src} alt={media.alt} />}

      {content.enabled && (
        <CardContent style={contentStyle}>
          <Typography gutterBottom variant="h5" component="div">
            {content.title}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            {content.text}
          </Typography>
        </CardContent>
      )}
    </CardActionArea>
  );

  const cardActionArea = <CardActions style={actionStyle}>{customActions}</CardActions>;

  return (
    <MuiCard style={styles} raised={component.raised}>
      {mainContent}
      {cardActionArea}
    </MuiCard>
  );
};
