import React, {useCallback} from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { BaseAttributeEditorProps } from "./BaseAttributeEditorProps";
import {useRosterSessionConfigurationContextFind} from "../../../../../../../../platform/api/endpoints";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    paddingRight: 16,
    position: "relative"
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface SessionsPickerAttributeEditorProps extends BaseAttributeEditorProps {}

export const SessionsPickerAttributeEditor = ({
  value,
  onChange,
  onSave,
}: SessionsPickerAttributeEditorProps) => {
  const classes = useStyles();
  const { data, loading } = useRosterSessionConfigurationContextFind();

  const handleOnBlur = useCallback(
    (event: any) => {
      onSave();
      event.stopPropagation();
    },
    [onSave]
  );

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    onChange(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">Name</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={value}
            onChange={handleChange}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
            onBlur={handleOnBlur}
          >
            {data?.map((option) => (
              <MenuItem
                key={option.name}
                value={option.name}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
};
