import React, { useCallback, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { StubbornAsset } from '../../../../../../business/StubbornAsset';
import { GridProps } from '@material-ui/core/Grid/Grid';
import { useCurrentBreakpoint } from '../../../../../../commons/hooks';
import { StubbornAssetRenderer } from './StubbornAssetRenderer/StubbornAssetRenderer';
import { useEditorScreenContext } from '../../../EditorScreenContext';
import { HIDDEN_SCREENS } from '../../../constants';

const useStyles = makeStyles({
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: ({ itemPosition }: { itemPosition: number }) => 1000 + itemPosition,
  },
  containerOverlay: {
    position: 'relative',
    boxShadow: ({ isSelected, isScreen }: { isSelected: boolean; itemPosition: number; isScreen: boolean }) => (!isSelected && isScreen ? '0px 0px 0px 2px blue' : !isSelected ? 'unset' : '0px 0px 0px 2px red'),
    zIndex: ({ isSelected, itemPosition }: { isSelected: boolean; itemPosition: number }) => (isSelected ? 2000 + itemPosition : 1000 + itemPosition),
    minHeight: ({ isScreen }: { isScreen: boolean }) => (isScreen ? '10px' : 'auto'),
  },
});

export interface RendererContainerProps extends StubbornAsset {
  parentAssetSelector: number[];
  assetIndex: number;
}

export const RendererContainer = ({ assetIndex, parentAssetSelector, ...restProps }: RendererContainerProps) => {
  const { editable, setAssetSelector, assetSelector, parentSelector } = useEditorScreenContext();
  const { attributes, type } = restProps;
  const {
    basic: { layouts },
  } = attributes;

  const isSelected = useMemo(() => {
    if (assetSelector?.length !== [...parentSelector, ...parentAssetSelector].length + 1) {
      return false;
    }
    return ![...parentSelector, ...parentAssetSelector, assetIndex].some((item, index) => item !== assetSelector[index]);
  }, [parentAssetSelector, assetIndex, assetSelector, parentSelector]);

  // @ts-ignore
  const fieldType = restProps?.fieldType;
  const isHiddenField = Object.values(HIDDEN_SCREENS).includes(fieldType as HIDDEN_SCREENS);
  const isScreen = useMemo(() => type === 'Screen' && !isHiddenField, [isHiddenField, type]);

  const classes = useStyles({ isSelected, itemPosition: [...parentSelector, ...parentAssetSelector].length, isScreen });
  const currentBreakpoint = useCurrentBreakpoint();
  const hidden = useMemo(() => {
    const entries = Object.entries(layouts);
    // nos fijamos si existe algun span, sino retornamos hidden
    if (!entries.length) {
      return true;
    }
    // obtenemos los span que sean cero
    const hiddenBreakpoints = entries.reduce<string[]>((acum, [breakpoint, item]) => {
      if (!item.col) {
        return [...acum, breakpoint];
      }
      return acum;
    }, []);
    // devolvemos true si el current bp existe el array de span cero
    return hiddenBreakpoints.includes(currentBreakpoint);
  }, [layouts, currentBreakpoint]);
  const gridProps = useMemo<GridProps>(
    () =>
      Object.entries(layouts).reduce<GridProps>(
        (acum, [breakpoint, layout]) => ({
          ...acum,
          [breakpoint]: layout.col,
        }),
        {},
      ),
    [layouts],
  );
  const onGridClick = useCallback(
    (e) => {
      e.stopPropagation();
      const parent = [...parentSelector, ...parentAssetSelector];
      setAssetSelector([...parent, assetIndex]);
    },
    [setAssetSelector, parentAssetSelector, assetIndex, parentSelector],
  );

  return (
    <Grid item {...gridProps} onClick={onGridClick} className={editable ? classes.containerOverlay : undefined}>
      <StubbornAssetRenderer {...restProps} type={isHiddenField ? 'Hidden' : restProps.type} assetIndex={assetIndex} parentAssetSelector={parentAssetSelector} />
      {editable && <div className={classes.overlay} />} {/* TODO: hay que handlear el recursivo */}
    </Grid>
  );
};
