import React, { useEffect } from 'react';
import { Select as MuiSelect, MenuItem, Icon, FormHelperText, InputLabel } from '@material-ui/core';
import { FieldBaseProps } from '../FieldBaseProps';
import { FieldBaseEvents } from '../FieldBaseEvents';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';
import { SelectEntry } from '../../../properties/editors';

interface SelectEvents extends FieldBaseEvents {
  onChange?: (e: any) => void;
  onOpen?: (e: any) => void;
  onClose?: (e: any) => void;
}
export interface SelectProps extends FieldBaseProps {
  component: {
    defaultValue?: string;
    disabled?: boolean;
    label?: string;
    required?: boolean;
    variant?: 'filled' | 'outlined' | 'standard';
    controlled?: boolean;
    autoWidth?: boolean;
    displayEmpty?: boolean;
    iconName?: string;
    inputProps?: any;
    menuProps?: any;
    open?: any;
    items?: any;
  };
  states: {
    error?: boolean;
    value?: string;
    helperText?: string;
  };
  events: SelectEvents;
}

// TODO:
// 1. Implementar multiple
// 2. Implementar open

export const Select = ({ component, events, states, styles }: SelectProps) => {
  const { editable } = useEditorScreenContext();

  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const iconElement = <Icon style={{ fontSize: 14, cursor: 'pointer' }}>{component.iconName}</Icon>;

  const iconProps = {
    ...(component.iconName !== '' && { IconComponent: () => iconElement }),
  };

  const valueProps = {
    ...(component.controlled ? { value: states?.value } : { defaultValue: component?.defaultValue }),
  };

  const mappedListItems = component.items?.map((item: SelectEntry, index: number) => {
    return (
      <MenuItem key={`select-list-item-${index}`} value={item.content}>
        {item.label}
      </MenuItem>
    );
  });

  return (
    <>
      <InputLabel>{component.label}</InputLabel>
      <MuiSelect
        {...valueProps}
        {...iconProps}
        autoWidth={component.autoWidth}
        label={component.label}
        disabled={component.disabled}
        onChange={events.onChange}
        variant={component.variant}
        error={states?.error}
        required={component.required}
        style={styles}
        displayEmpty={component.displayEmpty}
        inputProps={component.inputProps}
        MenuProps={component.menuProps}
        onOpen={events?.onOpen}
        onClose={events?.onClose}
        // open={open}
      >
        {mappedListItems}
      </MuiSelect>
      {states?.helperText && <FormHelperText error>{states?.helperText}</FormHelperText>}
    </>
  );
};
