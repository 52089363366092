import { FieldType } from "../../manager/FieldType";
import { Textfield } from "./Textfield";
import { attributeGroups } from "./attributeGroups";
import { toolbox } from "./toolbox";
import { FieldTypeManager } from "../../manager/FieldTypeManager";

export const textfieldFieldType: FieldType = {
  name: "textfield",
  component: Textfield,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(textfieldFieldType.name, textfieldFieldType);
