import {useMediaQuery} from "@material-ui/core";
import {Theme} from "@material-ui/core/styles/createTheme";
import {useMemo} from "react";

export const useBreakpointsState = () => {
  const xs = useMediaQuery<Theme>(theme => theme.breakpoints.only('xs'));
  const sm = useMediaQuery<Theme>(theme => theme.breakpoints.only('sm'));
  const md = useMediaQuery<Theme>(theme => theme.breakpoints.only('md'));
  const lg = useMediaQuery<Theme>(theme => theme.breakpoints.only('lg'));
  const xl = useMediaQuery<Theme>(theme => theme.breakpoints.only('xl'));
  return useMemo(() => ({ xs, sm, md, lg, xl }), [xs, sm, md, lg, xl]);
};
