import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { DraggableService } from './components';
import { createStyles, makeStyles } from '@material-ui/styles';
import { AppThemeType } from '../../../../../../../theme';
import { StubbornServiceAction } from '../../../../../../business/StubbornAsset';
import { useEditorServiceContext } from '../../../EditorServiceContext';
import { BaseAppConst } from '../../../../../../const/BaseAppConst';
import { ActionNamingHelper } from '../../../../../../utils';
import { expression, redis, sql, script } from '../../../../../../../theme/images';

const useStyles = makeStyles<AppThemeType>((theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      overflowY: 'auto',
      maxHeight: '90%',
      alignItems: 'center',
      marginTop: 10,
    },
  }),
);

const actionTypes: StubbornServiceAction['type'][] = ['SQL', 'Redis', 'Expression', 'Script', 'HttpCall'];

export const actionImages: Record<string, string> = {
  expression,
  redis,
  sql,
  script,
};

export const ServiceEditorToolbox = () => {
  const classes = useStyles();
  const { service, setService, setSelectedAction } = useEditorServiceContext();

  const onAssetClick = useCallback(
    (type: StubbornServiceAction['type']) => {
      const action: StubbornServiceAction = {
        type,
        name: 'estrategia de nombrado',
        condition: '',
        datasource: '',
        hasResponse: false,
        isList: false,
        mapper: [],
        script: '',
      };
      setService((prev) => {
        const naming = new ActionNamingHelper(prev);
        action.name = naming.getDefaultName();
        return {
          ...prev,
          actions: [...prev.actions, action],
        };
      });
    },
    [setService],
  );

  return (
    <Grid container component="nav" className={classes.container} aria-label="main mailbox folders">
      {actionTypes.map((type) => (
        <DraggableService onClick={() => onAssetClick(type)} key={type} type={type} />
      ))}
    </Grid>
  );
};
