import { ExecuteOptions, ExecuteResponse, HttpService, METHOD } from './HttpService';

export class FetchHttpService extends HttpService {
  async doCall<T>(method: METHOD, url: string, options?: ExecuteOptions): Promise<ExecuteResponse<T>> {
    let response: Response | undefined;
    try {
      response = await fetch(this.config.baseUrl + url, {
        method,
        body: options?.body ? JSON.stringify(options.body) : undefined,
        headers: options?.headers ? { ...this.config.headers, ...options.headers } : this.config.headers,
        ...(this.config.credentials ? { credentials: this.config.credentials as RequestCredentials } : {}),
      });
      const data = await response.text();
      if (response.status < 200 || response.status >= 300) {
        // eslint-disable-next-line
        throw data;
      }
      return {
        data: JSON.parse(data),
        statusCode: response.status,
        headers: response.headers,
      };
    } catch (e: any) {
      if (response) {
       throw {
         data: null,
         error: e,
         statusCode: response.status,
         headers: response.headers,
       };
      }
      throw e;
    }
  }
}
