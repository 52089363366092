import React, { useState } from 'react';
import { NodeData } from "../../types";
import {IconButton} from "@material-ui/core";
import { useAssetContext } from '../../../../../commons/context/AssetContext';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { getConfig } from '../../utils';

// TODO: abrir automaticamente el recien creado\
export interface GroupButtonsProps {
    node: NodeData;
  }  

export const GroupButtons = ({node}: GroupButtonsProps) => {
    const {reloadAssets} = useAssetContext();
    const config = getConfig(node);
    const [openModal, setOpenModal] = useState(false);
    const ModalComp = config?.createModal;
    return !ModalComp ? null : (<IconButton color="inherit" onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      setOpenModal(true);
    }}>
      <ControlPointIcon />
      {openModal && (
        <ModalComp onClose={() => setOpenModal(false)} onComplete={() => reloadAssets()} />
      )}
    </IconButton>);
  };