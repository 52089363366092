import { ActionPanelCompProps } from '../types';
import { Button, Container } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useAssetContext } from '../../../context/AssetContext';
import { confirmDialog } from '../../../../../../services';
import { StubbornData } from '../../../../../../business';

export interface RemoveActionProps extends ActionPanelCompProps<StubbornData> {}

export const RemoveAction = ({ asset }: RemoveActionProps) => {
  const { assetFns, reloadAssets } = useAssetContext();

  const handleRemove = async () => {
    const confirm = await confirmDialog.show({
      title: 'Confirmacion',
      content: 'Esta seguro de eliminar este asset?',
    });

    if (!confirm) return;
    try {
      await assetFns.remove(asset.type, asset);
      reloadAssets({});
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <Button onClick={handleRemove} startIcon={<DeleteIcon />}>
        Remove
      </Button>
    </Container>
  );
};
