import React from 'react';
import {ScreenContainer, ScreenTitle} from "../../../../components";
import {SessionConfigurationsList} from "./SessionConfigurationsList";

export const Sessions = () => (
  <ScreenContainer>
    <ScreenTitle>Sessions</ScreenTitle>
    {/*<SessionConfigurationsList />*/}
  </ScreenContainer>
);
