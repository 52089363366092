import React, { useEffect } from "react";
import { FieldBaseProps } from "../FieldBaseProps";
import { useEditorScreenContext } from "../../../../../EditorScreenContext";

export interface ImageProps extends FieldBaseProps {
  component: {
    alt?: string;
    height?: string;
    src?: string;
    width?: string;
  };
}

export const Image = ({ component, events, states, styles }: ImageProps) => {
  const { editable } = useEditorScreenContext();

  useEffect(() => {
    if (!editable) {
      if (events.onMount) events.onMount();

      if (events.onUnmount)
        return () => {
          events.onUnmount();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <img style={styles} alt={component.alt} height={component.height} src={component.src} width={component.width} />;
};
