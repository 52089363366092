import React, { FunctionComponent, useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useStyles } from "./styles";

export interface FilterTabsProps {
  setSearchItem: (item: any) => void;
  tags: string[];
}

export const FilterTags: FunctionComponent<FilterTabsProps> = ({
  setSearchItem,
  tags,
}) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const classes = useStyles();

  return (
    <div>
      <div
        className={
          Boolean(anchorElement) ? classes.buttonSelected : classes.button
        }
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={(event: React.MouseEvent<HTMLElement>) =>
          setAnchorElement(event.currentTarget)
        }
      >
        Tags
      </div>
      <Menu
        elevation={0}
        id="customized-menu"
        className={classes.menu}
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={() => setAnchorElement(null)}
      >
        {!tags.length && "No tags saved"}
        {tags.map((tag: string) => {
          return (
            <MenuItem
              className={classes.label}
              onClick={() => {
                setSearchItem(tag);
              }}
            >
              {tag}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};