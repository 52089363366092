import React from 'react';
import {StubbornFirebasePlugin} from "../../../../../../../../../../business/StubbornConfiguration";
import {TextField} from "@material-ui/core";
import {FormProps} from "./FormProps";

export interface FirebaseFormProps extends FormProps<StubbornFirebasePlugin> {}

export const FirebaseForm = ({value, onChange, loading}: FirebaseFormProps) => {
  const onChangeValue = (key: 'projectId' | 'clientEmail' | 'privateKey', val: string) => {
    const newValue: StubbornFirebasePlugin = {
      ...value,
      configurations: {
        credentials: {
          ...value.configurations.credentials,
          [key]: val,
        },
      },
    };
    onChange(newValue);
  };
  return (
    <>
      <TextField autoFocus margin="dense" id="name" label="Nombre" name="name" fullWidth value={value.name} disabled/>
      <TextField autoFocus margin="dense" id="projectId" name="projectId" label="Proyect Id" fullWidth value={value.configurations.credentials.projectId}
                 onChange={event => onChangeValue('projectId', event.target.value)} disabled={loading}/>
      <TextField autoFocus margin="dense" id="clientEmail" name="clientEmail" label="Cliente Email" fullWidth
                 value={value.configurations.credentials.clientEmail} onChange={event => onChangeValue('clientEmail', event.target.value)} disabled={loading}/>
      <TextField autoFocus margin="dense" id="privateKey" name="privateKey" label="Private Key" fullWidth
                 value={value.configurations.credentials.privateKey} onChange={event => onChangeValue('privateKey', event.target.value)} disabled={loading}/>
    </>
  );
};
