import asService from "../../../lib/asService/asService";
import {ConfirmDialog} from "../../components/dialogs";

const options = {
  activeProp: 'open',
  resolveProp: 'onConfirm',
  rejectProp: 'onCancel',
  forceResolveOnReject: true,
  rejectValue: false,
  resolveValue: true,
};
export default asService(options)(ConfirmDialog);
