import { FieldType } from '../../manager/FieldType';
import { Container } from './Container';
import { attributeGroups } from './attributeGroups';
import { toolbox } from './toolbox';
import { FieldTypeManager } from '../../manager/FieldTypeManager';

export const containerFieldType: FieldType = {
  name: 'container',
  component: Container,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(containerFieldType.name, containerFieldType);
