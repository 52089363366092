import { useCallback, useState } from 'react';
import { useAssetContext } from '../../../../context/AssetContext';
import { AssetPanelCompProps } from '../../types';
import { StubbornSessionConfiguration } from '../../../../../../../business/StubbornConfiguration';
import { SessionConfigurationForm } from './form';

export interface SessionConfigurationPanelProps extends AssetPanelCompProps<StubbornSessionConfiguration> {}

export const SessionConfigurationPanel = ({ asset }: SessionConfigurationPanelProps) => {
  const [defaultData] = useState<StubbornSessionConfiguration>({ ...asset });
  const { reloadAssets, assetFns } = useAssetContext();

  const onUpdateSessionConfig = useCallback(
    async (sessionConfig: StubbornSessionConfiguration) => {
      try {
        await assetFns.update(sessionConfig.type, sessionConfig);
        reloadAssets({});
      } catch (error) {
        console.error(error);
      }
    },
    [assetFns, reloadAssets],
  );

  const onCreateSessionConfig = useCallback(
    async (sessionConfig: StubbornSessionConfiguration) => {
      try {
        await assetFns.create(sessionConfig, sessionConfig.type);
        reloadAssets({});
      } catch (error) {
        console.error(error);
      }
    },
    [assetFns, reloadAssets],
  );

  return <SessionConfigurationForm onCreate={onCreateSessionConfig} onUpdate={onUpdateSessionConfig} configuration={defaultData} isUpdate />;
};
