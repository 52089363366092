import React from 'react';
import { Grid } from '@material-ui/core';
import {PermissionBlocker} from "../../../../lib/phinxapp/permission";
import {CardButton, CardButtonAction} from "../../../commons/ui";

export type MenuCardItem = {
  id: string | number;
  title: string;
  image?: string;
  [k: string]: any;
};

export interface MenuCardListProps<T extends MenuCardItem = MenuCardItem> {
  items: T[];
  onItemClick: (item: T) => void;
  actions?: CardButtonAction<T>[];
}

export function MenuCardList<T extends MenuCardItem = MenuCardItem>({items, onItemClick, actions}: MenuCardListProps<T>) {
  return <Grid container spacing={2}>
    {items.map((item) => (
      <PermissionBlocker key={item.id} id={item.id}>
        <Grid item sm={3}>
          <CardButton<T>
            title={item.title}
            image={item.image}
            data={item}
            onClick={onItemClick}
            actions={actions}
          />
        </Grid>
      </PermissionBlocker>
    ))}
  </Grid>;
}
