import {FieldType} from "../../manager/FieldType";
import {attributeGroups} from "./attributeGroups";
import {toolbox} from "./toolbox";
import {FieldTypeManager} from "../../manager/FieldTypeManager";
import { TargetScreen } from "./TargetScreen";

export const targetScreenFieldType: FieldType = {
  name: 'targetScreen',
  component: TargetScreen,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(targetScreenFieldType.name, targetScreenFieldType);
