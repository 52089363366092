import { FieldType } from '../../manager/FieldType';
import { FileUpload } from './FileUpload';
import { attributeGroups } from './attributeGroups';
import { toolbox } from './toolbox';
import { FieldTypeManager } from '../../manager/FieldTypeManager';

export const fileUploadFieldType: FieldType = {
  name: 'fileUpload',
  component: FileUpload,
  attributeGroups: attributeGroups,
  toolbox,
};

FieldTypeManager.register(fileUploadFieldType.name, fileUploadFieldType);
