import {Box, Typography} from "@material-ui/core";
import React, {FunctionComponent} from 'react';
import {NodeData} from './../../types';
import { getConfig } from '../../utils';
import { GroupButtons } from '../GroupButtons';
import { AssetButtons } from '../AssetButtons';
import { CloseAssetButton } from '../CloseAssetButton';


export interface TreeNodeLabelProps {
    node: NodeData;
    hasMouseIn: boolean;
  }

export const TreeNodeLabel = ({node, hasMouseIn}: TreeNodeLabelProps) => {
    const config = getConfig(node);
    return (
      <Box sx={{display: 'flex', alignItems: 'center', p: 0.5, pr: 0, height: 40}}>
        {!!config && (
          <Box component={(() => config.icon) as FunctionComponent} color="inherit" sx={{mr: 1}}/>
        )}
        <Typography variant="body2" sx={{fontWeight: 'inherit', flexGrow: 1}}>
          {node.label}
        </Typography>
        {node.hideButtons || !hasMouseIn ? null : (node.isGroup && node.assetType) ? (
          <GroupButtons node={node} />
        ) : node.isRecentOpened ? (
          <CloseAssetButton node={node} />
        ) : (
          <AssetButtons node={node} />
        )}
      </Box>
    );
  };
