import {FieldType} from "../../manager/FieldType";
import {FieldTypeManager} from "../../manager";
import {toolbox} from "./toolbox";
import {attributeGroups} from "./attributeGroups";
import { Text } from './Text';

export const textFieldType: FieldType = {
  name: 'text',
  component: Text,
  attributeGroups,
  toolbox,
};

FieldTypeManager.register(textFieldType.name, textFieldType);
