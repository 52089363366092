import { makeStyles, createStyles } from '@material-ui/styles';
import { AppThemeType } from '../../../../theme';

export const useStyles = makeStyles<AppThemeType>((theme) =>
  createStyles({
    assetContainer: {
      background: '#2F343D',
      color: '#FFFFFF',
      height: '100%',
    },
    '@global': {
      '*::-webkit-scrollbar': {
        width: '0.4em',
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px #2F343D',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#434A57',
      },
    },
    assetItem: {
      color: '#FFFFFF',
      margin: '10px 0',
      '& .Mui-selected': {
        '& .MuiTypography-root': {
          color: '#707EF9',
        },
      },
    },
    inputStyles: {
      background: '#4A5160',
      width: '100%',
      color: '#FFFFFF',
      height: '66px',
      margin: 0,
      '& label': {
        color: '#FFFFFF',
        fontSize: '14px',
      },
      '& input': {
        color: '#FFFFFF',
      },
      '& .MuiFilledInput-root': {
        height: '100%',
      },
    },
    assetCard: {
      background: '#2F343D',
      color: '#FFFFFF',
      width: 180,
      height: 120,
      cursor: 'pointer',
    },
    flexContainerSpaced: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    searchContainer: {
      display: "flex",
    }
  }),
);
