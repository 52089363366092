import React from 'react';
import { ScreenContainer, ScreenTitle } from '../../../../components';
import { DatasourcesList } from './components';

export const Datasources = () => (
  <ScreenContainer>
    <ScreenTitle>Datasources</ScreenTitle>
    <DatasourcesList />
  </ScreenContainer>
);
