import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles, useTheme } from '@material-ui/styles';
import { useDrag } from 'react-dnd';
import { AppThemeType } from '../../../../../../../../theme';
import { useEditorServiceContext } from '../../../../EditorServiceContext';
import { Avatar, Typography, Grid } from '@material-ui/core';
import { StubbornServiceAction } from '../../../../../../../business/StubbornAsset';
import { expression, redis, sql, script } from '../../../../../../../../theme/images';

export interface ItemListProps {
  type: string;
  onClick: () => void;
}

interface DropResult {
  allowedDropEffect: string;
  dropEffect: string;
}

export const actionImages: Record<string, string> = {
  expression,
  redis,
  sql,
  script,
};

const useStyles = makeStyles<AppThemeType>((theme) =>
  createStyles({
    icons: {
      display: 'flex',
      padding: 10,
      height: 40,
      width: 40,
      justifyContent: 'center',
      alignContent: 'center',
      backgroundColor: '#4b4e61',
    },
    title: {
      color: 'white',
      fontSize: 13,
      paddingTop: 5,
      fontWeight: 600,
    },
  }),
);

export const DraggableService: FunctionComponent<ItemListProps> = ({ onClick, type }) => {
  const { setSelectedAction } = useEditorServiceContext();
  const theme = useTheme();
  //@ts-ignore
  const classes = useStyles(theme);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'service',
    item: { type },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      if (item && dropResult) {
        setSelectedAction(null);
        onClick();
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <Grid ref={drag} onClick={()=>{onClick()}} style={{ backgroundColor: isDragging ? 'lightgrey' : '#4b4e61', margin: 5, width: '40%', height: 100, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
      <Avatar src={actionImages[type.toLowerCase()]} className={classes.icons} />
      <Typography className={classes.title}>{type}</Typography>
    </Grid>
  );
};
