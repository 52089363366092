import { NodeData } from '../../types';
import { IconButton } from '@material-ui/core';
import ContentCopyIcon from '@material-ui/icons/ContentCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import { confirmDialog } from '../../../../../../../services';
import { useAssetContext } from '../../../../../commons/context/AssetContext';
import { ActionPanelCompProps } from '../../../../../../Private2/commons/components/AssetView/types';
import { StubbornData } from '../../../../../../../business';

export interface AssetButtonsProps {
  node: NodeData;
}

export interface ActionProps extends ActionPanelCompProps<StubbornData> {}

export const AssetButtons = ({ node }: AssetButtonsProps) => {
  const { assetFns, reloadAssets } = useAssetContext();

  return (
    <>
      <IconButton
        color="inherit"
        onClick={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          const confirm = await confirmDialog.show({
            title: 'Confirmacion',
            content: 'Esta seguro de clonar este asset?',
          });
          if (confirm) {
            const newAsset: StubbornData = { ...node.data, name: `${node.data.name}Clon` };
            try {
              await assetFns.create(newAsset, newAsset.type);
              reloadAssets({});
            } catch (error) {
              console.error(error);
            }
          }
        }}
      >
        <ContentCopyIcon />
      </IconButton>
      <IconButton
        color="inherit"
        onClick={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          const confirm = await confirmDialog.show({
            title: 'Confirmacion',
            content: 'Esta seguro de eliminar este asset?',
          });
          if (confirm) {
            await assetFns.remove(node.data.type, node.data);
            reloadAssets({});
          }
        }}
      >
        <DeleteIcon />
      </IconButton>
    </>
  );
};
