import React, { useCallback, useState, useMemo } from 'react';
import { useEditorScreenContext } from '../../../../../EditorScreenContext';
import { StubbornAsset } from '../../../../../../../../business/StubbornAsset';
import { Grid } from '@material-ui/core';
import { Loading } from '../../../../../../../../components';
import { makeStyles } from '@material-ui/styles';
import { GridProps } from '@material-ui/core/Grid/Grid';
import { StubbornAssetRenderer } from '../../../components/StubbornAssetRenderer/StubbornAssetRenderer';
import { HIDDEN_SCREENS } from '../../../../../constants';

export interface RendererContainerProps extends StubbornAsset {
  parentAssetSelector: number[];
  assetIndex: number;
}

const useStyles = makeStyles({
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: ({ itemPosition }: { itemPosition: number }) => 1000 + itemPosition,
  },
  containerOverlay: {
    position: 'relative',
    boxShadow: ({ isSelected, isScreen }: { isSelected: boolean; itemPosition: number; isScreen: boolean }) => (!isSelected && isScreen ? '0px 0px 0px 2px blue' : !isSelected ? 'unset' : '0px 0px 0px 2px red'),
    zIndex: ({ isSelected, itemPosition }: { isSelected: boolean; itemPosition: number }) => (isSelected ? 2000 + itemPosition : 1000 + itemPosition),
    minHeight: ({ isScreen }: { isScreen: boolean }) => (isScreen ? '10px' : 'auto'),
  },
});

export const SterContainer = ({ assetIndex, parentAssetSelector, ...restProps }: RendererContainerProps) => {
  const [findApiKey, setFindApiKey] = useState<boolean>(true);
  const { editable, setAssetSelector, assetSelector, parentSelector } = useEditorScreenContext();
  const { attributes, type } = restProps;
  const {
    basic: { layouts },
  } = attributes;

  const isSelected = useMemo(() => {
    if (assetSelector?.length !== [...parentSelector, ...parentAssetSelector].length + 1) {
      return false;
    }
    return ![...parentSelector, ...parentAssetSelector, assetIndex].some((item, index) => item !== assetSelector[index]);
  }, [parentAssetSelector, assetIndex, assetSelector, parentSelector]);

  // @ts-ignore
  const fieldType = restProps?.fieldType;
  const isHiddenField = Object.values(HIDDEN_SCREENS).includes(fieldType as HIDDEN_SCREENS);
  const isScreen = useMemo(() => type === 'Screen' && !isHiddenField, [isHiddenField, type]);

  const classes = useStyles({ isSelected, itemPosition: [...parentSelector, ...parentAssetSelector].length, isScreen });

  const gridProps = useMemo<GridProps>(
    () =>
      Object.entries(layouts).reduce<GridProps>(
        (acum, [breakpoint, layout]) => ({
          ...acum,
          [breakpoint]: layout.col,
        }),
        {},
      ),
    [layouts],
  );
  const onGridClick = useCallback(
    (e) => {
      e.stopPropagation();
      const parent = [...parentSelector, ...parentAssetSelector];
      setAssetSelector([...parent, assetIndex]);
    },
    [setAssetSelector, parentAssetSelector, assetIndex, parentSelector],
  );

  return findApiKey ? (
    <Grid item {...gridProps} onClick={editable ? onGridClick : undefined} className={editable ? classes.containerOverlay : undefined}>
      {!editable ? <Loading /> : <StubbornAssetRenderer {...restProps} type={isHiddenField ? 'Hidden' : restProps.type} assetIndex={assetIndex} parentAssetSelector={parentAssetSelector} />}
    </Grid>
  ) : (
    <Loading />
  );
};
