import {MenuItem} from "../../../../lib/phinxapp/profiles/types";
import {routes} from "./routes";

export const mainMenu: MenuItem = {
  key: 'mainMenu',
  basePath: '/main',
  enabled: true,
  icon: 'List',
  text: 'Main',
  children: routes,
};
