export const BaseAppConst = {
  drawerWidth: 240,
  appBarHeight: 64,
  uiEditor: {
    bg_color: '#cccccc',
    dot_color: '#cfcfcf',
    dot_size: 2,
    dot_space: 22,
  },
  uiEditorProperties: {
    drawerWidth: 300,
  },
  uiEditorToolbox: {
    drawerWidth: 250,
  },
  theme: {
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
  },
};
