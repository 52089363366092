import React, {useState} from 'react';
import {Grid, TextField, Typography, Stack, Button, Container} from '@material-ui/core';

export const SpecialParams = () => {
  const [urlName, setUrlName] = useState<string>('');
  const [alert, setAlert] = useState({show: false, msg: ''});

  const showAlert = (show = false, msg = '') => {
    setAlert({show, msg});
  };

  let list = localStorage.getItem('stubbornParams');

  const handleSet = () => {
    if (!urlName) {
      window.alert("cannot be empty")
    } else {
      localStorage.setItem('stubbornParams', urlName)
      showAlert(true, `current API: ${list}`)
      window.location.reload()
    }
  }
  const handleUnset = () => {
    showAlert(true, 'API URL has been deleted')
    localStorage.removeItem('stubbornParams');
    window.location.reload()
  };

  return (
    <Container maxWidth={"lg"}>
    <Grid container justifyContent={'flex-start'} alignContent={'flex-start'} direction={'column'} p={2}>
      <Grid item direction={'row'} alignContent={'flex-start'}  >
        <Typography mb={4} variant={'h3'} style={{fontWeight: 550}}>Special Parameters</Typography>
        <Typography sx={{mb: '10px'}} variant={'h6'}
                    style={{fontWeight: 550}}>{`REACT_APP_API_URL: ${process.env.REACT_APP_API_URL}`}</Typography>
        {!list ?
          <Stack direction={'row'} justifyContent={'flex-start'}  spacing={2}>
            <TextField type='text'
                       value={urlName}
                       placeholder={'Please enter value'}
                       onChange={(e) => setUrlName(e.target.value)}/>
            <Button variant="contained" color="primary" size={'large'}
                    onClick={handleSet}>Set</Button>
          </Stack> :
          <Stack direction={'row'} justifyContent={'flex-start'}>
            <Button variant="contained" color="primary" onClick={handleUnset}>UnSet</Button>
            <Typography>{`Current Value: ${list}`}</Typography>
          </Stack>}
        <Typography variant={'body1'}>{alert && `${alert.msg}`}</Typography>
      </Grid>
    </Grid>
    </Container>
  );
};
