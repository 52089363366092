import React from 'react'
import { BaseAttributeViewProps } from './BaseAttributeViewProps'


export interface EditorAttributeViewProps extends BaseAttributeViewProps {}

export const EditorAttributeView = () => {
    return (
        <p>Doble click para editar</p>
    )
}
