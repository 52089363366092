import { StubbornAttribute } from '../../../../../../../../business/StubbornAttributeMapping';

const coreStateAttributes: StubbornAttribute[] = [
 // TODO: agregar attributos por defecto en futuro si surge la necesidad
];

export const makeStateAttributeGroups = (otherStateAttributes?: StubbornAttribute[]) => {
  const otherStates = otherStateAttributes ? otherStateAttributes : [];
  return {
    label: "States",
    name: "states",
    priority: 1,
    attributes: new Set([...coreStateAttributes, ...otherStates ]),
  };
};
