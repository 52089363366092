import React, { ReactNode, ReactNodeArray } from 'react';
import { Grid } from '@material-ui/core';

export interface ScreenContainerProps {
  children: ReactNode | ReactNodeArray;
}

export const ScreenContainer = ({ children }: ScreenContainerProps) => (
  <Grid container style={{ backgroundColor: '#DBDBDB', display: 'flex', margin: 0, height: '100%', flexDirection: 'column' }}>
    {children}
  </Grid>
);
