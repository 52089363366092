import {MenuCollection} from "../../../lib/phinxapp/profiles/types";
import {Initial} from "../../../application/screens/Initial";

export const initialMenu: MenuCollection = [
  {
    key: 'initial',
    target: {
      component: Initial,
      path: '/',
    },
    basePath: '/',
    enabled: true,
    icon: 'List',
    text: 'Initial',
    children: [],
  },
];
